let dataItems = {
    1: {
        img: "vita_saw",
        eng: "Vita-Saw",
        pol: "Vita-piła",
        sch: "维塔锯",
        fre: "La Vita-Scie",
        bra: "A Vitasserra",
        schema: 173,
        hun: "Az Életbe-Vágó",
        swe: "Vita-Sågen"
    },
    2: {
        img: "gunslinger",
        eng: "Gunslinger",
        pol: "Jednoręki bandyta",
        sch: "枪炮工之臂",
        fre: "Le Flingueur",
        bra: "O Pistoleiro",
        schema: 142,
        hun: "A Fegyverforgató",
        swe: "Revolvermannen"
    },
    3: {
        img: "equalizer",
        eng: "Equalizer",
        pol: "Wyrównywacz",
        sch: "十字镐",
        fre: "L'Equalizer",
        bra: "O Compensador",
        schema: 128,
        hun: "Az Egyenlítő",
        swe: "Utjämnaren"
    },
    4: {
        img: "scottish_resistance",
        eng: "Scottish Resistance",
        pol: "Szkocki opór",
        sch: "苏格兰防御者",
        fre: "La Scottish Resistance",
        bra: "A Resistência Escocesa",
        schema: 130,
        hun: "A Skót Ellenállás",
        swe: "Skotska Motståndet"
    },
    5: {
        img: "old_guadalajara",
        eng: "Old Guadalajara",
        pol: "Stara Guadalajara",
        sch: "古老的瓜达拉哈拉草帽",
        fre: "Guadalajara d'antan",
        bra: "Velha Guadalajara",
        schema: 247,
        hun: "Régi Guadalajara",
        swe: "Gammal Guadalajara"
    },
    6: {
        img: "nappers_respite",
        eng: "Napper's Respite",
        pol: "Szlafmyca śpiocha",
        sch: "小偷的喘息",
        fre: "Neurones au repos",
        bra: "Gorro do Dorminhoco",
        schema: 248,
        hun: "A Szundító Szünideje",
        swe: "Slumrarens Andrum"
    },
    7: {
        img: "name_tag",
        eng: "Name Tag",
        pol: "Etykieta z nazwą",
        sch: "名称标签",
        fre: "Étiquette",
        bra: "Etiqueta de Nome",
        schema: 5020,
        hun: "Névcédula",
        swe: "Namnlapp"
    },
    8: {
        img: "an_extraordinary_abundance_of_tinge",
        eng: "An Extraordinary Abundance of Tinge",
        pol: "Nadzwyczajna obfitość zabarwienia",
        sch: "独特满桶白",
        fre: "Une Touche d'Abondance Extraordinaire",
        bra: "Uma Extraordinária Abundância de Matiz",
        schema: 5039,
        hun: "Különösen Bőséges Színezet",
        swe: "Ett Enastående Överflöd Av Skiftningar"
    },
    9: {
        img: "a_distinctive_lack_of_hue",
        eng: "A Distinctive Lack of Hue",
        pol: "Charakterystyczny brak odcienia",
        sch: "特别无色黑",
        fre: "Un Manque de Camaïeux",
        bra: "Uma Distinta Falta de Coloração",
        schema: 5040,
        hun: "A Színezet Feltűnő Hiánya",
        swe: "En Utpräglad Brist På Nyans"
    },
    10: {
        img: "black_box",
        eng: "Black Box",
        pol: "Czarna skrzynka",
        sch: "黑匣子",
        fre: "La Boîte Noire",
        bra: "A Caixa Preta",
        schema: 228,
        hun: "Fekete Doboz",
        swe: "Svarta Lådan"
    },
    11: {
        img: "shortstop",
        eng: "Shortstop",
        pol: "Wstrzymywacz",
        sch: "游击手",
        fre: "L'Arrêt-court",
        bra: "A Interbases",
        schema: 220,
        hun: "A Megállító",
        ger: "Der Shortstop"
    },
    12: {
        img: "letranger",
        eng: "L'Étranger",
        pol: "L'Étranger",
        sch: "陌生人",
        fre: "L'Étranger",
        bra: "L'Etranger",
        schema: 224,
        hun: "L'Etranger",
        swe: "Främlingen"
    },
    13: {
        img: "sober_stuntman",
        eng: "Sober Stuntman",
        pol: "Trzeźwy kaskader",
        sch: "冷静的特技演员",
        fre: "Cascadeur sobre",
        bra: "Dublê Sóbrio",
        schema: 255,
        hun: "Józan Kaszkadőr",
        ger: "Nüchterner Stuntman"
    },
    14: {
        img: "chieftains_challenge",
        eng: "Chieftain's Challenge",
        pol: "Zew wodza",
        sch: "印第安酋长帽",
        fre: "Défi du chef",
        bra: "Cacife do Cacique",
        schema: 250,
        hun: "A Törzsfő Erőpróbája",
        swe: "Hövdingens Hönsfjäderhatt"
    },
    15: {
        img: "dueling_minigame",
        eng: "Dueling Mini-Game",
        pol: "Minigra - pojedynek",
        sch: "迷你游戏:决斗!",
        fre: "Mini-jeu de duel",
        bra: "Minigame de Duelo",
        schema: 241,
        hun: "Párbaj minijáték",
        swe: "Duelleringsminispel"
    },
    16: {
        img: "a_deep_commitment_to_purple",
        eng: "A Deep Commitment to Purple",
        pol: "Głęboko zaangażowana purpura",
        sch: "誓约之紫",
        fre: "Une Pourpre Imposante",
        bra: "Um Profundo Compromisso com Roxo",
        schema: 5031,
        hun: "Mély Elkötelezettség a Lila Iránt",
        swe: "Ett Djupt Engagemang För Lila"
    },
    17: {
        img: "australium_gold",
        eng: "Australium Gold",
        pol: "Złocistość Australium",
        sch: "澳元素金",
        fre: "Or Australium",
        bra: "Ouro Austrálio",
        schema: 5037,
        hun: "Ausztrálium Arany",
        swe: "Australium-Guld"
    },
    18: {
        img: "bushwacka",
        eng: "Bushwacka",
        pol: "Bandyta z buszu",
        sch: "灌木丛",
        fre: "Le Bushwacka",
        bra: "O Facão do Desbravador",
        schema: 232,
        hun: "A Bozótverő",
        ger: "Der Buschwacka"
    },
    19: {
        img: "critacola",
        eng: "Crit-a-Cola",
        pol: "Kryt-o-Cola",
        sch: "爆击可乐",
        fre: "Crit-a-Cola",
        bra: "Criti-cola",
        schema: 163,
        hun: "Krit-a-Kóla",
        ger: "Krit-a-Cola"
    },
    20: {
        img: "homewrecker",
        eng: "Homewrecker",
        pol: "Burzyciel",
        sch: "拆迁者",
        fre: "Le Briseur de Ménages",
        bra: "A Quebra-casas",
        schema: 153,
        hun: "A Laklerendező",
        swe: "Hemrivaren"
    },
    21: {
        img: "troublemakers_tossle_cap",
        eng: "Troublemaker's Tossle Cap",
        pol: "Czapka rozrabiaki",
        sch: "麻烦制造者的防寒帽",
        fre: "Bonnet de Fauteur de troubles",
        bra: "Touca do Trombadinha",
        schema: 150,
        hun: "Kötött Kötekedő",
        swe: "Bråkstakens Mössa"
    },
    22: {
        img: "bombing_run",
        eng: "Bombing Run",
        pol: "Rajd bombowy",
        sch: "轰炸航路",
        fre: "Frappe aérienne",
        bra: "Bombardeiro",
        schema: 249,
        hun: "Bombatámadás",
        swe: "Bomb-Rush"
    },
    23: {
        img: "stout_shako",
        eng: "Stout Shako",
        pol: "Opasłe czako",
        sch: "斯托特军帽",
        fre: "Fier shako",
        bra: "Shako Robusto",
        schema: 251,
        hun: "Csodás Csákó",
        swe: "Kraftig Kask"
    },
    24: {
        img: "description_tag",
        eng: "Description Tag",
        pol: "Etykieta z opisem",
        sch: "描述标签",
        fre: "Étiquette de Description",
        bra: "Etiqueta de Descrição",
        schema: 5044,
        hun: "Leírás-cédula",
        swe: "Beskrivningslapp"
    },
    25: {
        img: "battalions_backup",
        eng: "Battalion's Backup",
        pol: "Wsparcie batalionu",
        sch: "军队的支援",
        fre: "Le Soutien du Bataillon",
        bra: "O Reforço do Batalhão",
        schema: 226,
        hun: "Az Ezred Erősítése",
        swe: "Bataljonens Förstärkning"
    },
    26: {
        img: "pain_train",
        eng: "Pain Train",
        pol: "Pałka sokisty",
        sch: "钉棍",
        fre: "Le Pain Train",
        bra: "É Pau, É Prego",
        schema: 154,
        hun: "A Szúró Fájdalom",
        swe: "Plågtåget"
    },
    27: {
        img: "your_eternal_reward",
        eng: "Your Eternal Reward",
        pol: "Ostateczna nagroda",
        sch: "永生的回报",
        fre: "Ton Éternelle Récompense",
        bra: "Sua Eterna Recompensa",
        schema: 225,
        hun: "Örökké Tartó Jutalmad",
        swe: "Din Eviga Belöning"
    },
    28: {
        img: "whoopee_cap",
        eng: "Whoopee Cap",
        pol: "Pierdziuszka",
        sch: "狂欢之冠",
        fre: "Toque de Fanfaron",
        bra: "Chapéu Whoopee",
        schema: 174,
        hun: "Az Ihaj-csuhaj",
        swe: "Whoopee-Mössa"
    },
    29: {
        img: "heavy_duty_rag",
        eng: "Heavy Duty Rag",
        pol: "Bandana Grubego",
        sch: "结实的头巾",
        fre: "Bandeau des lourdes obligations",
        bra: "Bandana do Encrenqueiro",
        schema: 185,
        hun: "Strapabíró Fejkendő",
        swe: "Tunga Tåliga Trasan"
    },
    30: {
        img: "handymans_handle",
        eng: "Handyman's Handle",
        pol: "Przepychaczka babci klozetowej",
        sch: "能工巧匠的把手",
        fre: "Poignée multifonction",
        bra: "Desentupidor do Ajudante",
        schema: 253,
        hun: "Ezermester Nyele",
        swe: "Hantverkarens Handtag"
    },
    31: {
        img: "powerjack",
        eng: "Powerjack",
        pol: "Lewarek",
        sch: "强力千斤顶",
        fre: "Le Powerjack",
        bra: "A Ligação Direta",
        schema: 214,
        hun: "Az Életerő-Emelő",
        swe: "Eldomkraften"
    },
    32: {
        img: "gunboats",
        eng: "Gunboats",
        pol: "Kanonierki",
        sch: "炮艇",
        fre: "Les Bottes de sûreté",
        bra: "As Canhoneiras",
        schema: 133,
        hun: "Az Acélbetétes",
        swe: "Kanonbåtarna"
    },
    33: {
        img: "southern_hospitality",
        eng: "Southern Hospitality",
        pol: "Południowa gościnność",
        sch: "南部的款待",
        fre: "L'Hospitalité Sudiste",
        bra: "A Hospitalidade do Sul",
        schema: 155,
        hun: "A Délvidéki Vendégszeretet",
        swe: "Söderns Gästfrihet"
    },
    34: {
        img: "blokes_bucket_hat",
        eng: "Bloke's Bucket Hat",
        pol: "Ziomalska czapka wędkarska",
        sch: "渔夫的水桶帽",
        fre: "Bob de Mec",
        bra: "Chapéu de Pesca",
        schema: 181,
        hun: "Hapek Horgászholmija",
        swe: "Filurens Fiskarhatt"
    },
    35: {
        img: "safensound",
        eng: "Safe'n'Sound",
        pol: "Safe'n'Sound",
        sch: "安全耳套",
        fre: "Son et Sauf",
        bra: "Som e Salvo",
        schema: 178,
        hun: "Ipari Fülvédő",
        swe: "Hörselskydd"
    },
    36: {
        img: "ze_goggles",
        eng: "Ze Goggles",
        pol: "Gógle",
        sch: "护目镜",
        fre: "Les Bézicles",
        bra: "As Ókulos",
        schema: 177,
        hun: "A Szellemző",
        swe: "Das Bildförstärkare"
    },
    37: {
        img: "colorno216190216",
        eng: "Color No. 216-190-216",
        pol: "Kolor nr 216-190-216",
        sch: "颜色编号 216-190-216",
        fre: "Couleur N° 216-190-216",
        bra: "Cor 216-190-216",
        schema: 5030,
        hun: "216-190-216. Számú Szín",
        swe: "Färg Nr. 216-190-216"
    },
    38: {
        img: "jag",
        eng: "Jag",
        pol: "Kieł",
        sch: "尖齿扳手",
        fre: "Le Crochet",
        bra: "A Dentadura",
        schema: 329,
        hun: "A Sürgető",
        swe: "Jacken"
    },
    39: {
        img: "candy_cane",
        eng: "Candy Cane",
        pol: "Cukrowa laska",
        sch: "糖果手杖",
        fre: "Le Sucre d'Orge",
        bra: "A Bengala Doce",
        schema: 317,
        hun: "A Cukorpálca",
        swe: "Polkagrisen"
    },
    40: {
        img: "fists_of_steel",
        eng: "Fists of Steel",
        pol: "Pięści ze stali",
        sch: "钢铁手套",
        fre: "Les Poings d'Acier",
        bra: "Os Punhos de Ferro",
        schema: 331,
        hun: "A Vasöklök",
        swe: "Stålnävarna"
    },
    41: {
        img: "back_scratcher",
        eng: "Back Scratcher",
        pol: "Drapaczka",
        sch: "不求人",
        fre: "Le Gratte-dos",
        bra: "O Coçador de Costas",
        schema: 326,
        hun: "A Hátvakaró",
        swe: "Ryggkliaren"
    },
    42: {
        img: "claidheamh_mor",
        eng: "Claidheamh Mòr",
        pol: "Claidheamh Mòr",
        sch: "摩尔巨剑",
        fre: "La Claidheamh Mòr",
        bra: "A Claidheamh Mòr",
        schema: 327,
        hun: "A Claidheamh Mòr",
        ger: "Das Claidheamh Mòr"
    },
    43: {
        img: "defiant_spartan",
        eng: "Defiant Spartan",
        pol: "Krnąbrny Spartanin",
        sch: "斯巴达战士头盔",
        fre: "Spartiate Provocateur",
        bra: "Espartano Desafiador",
        schema: 340,
        hun: "Dacos Spártai",
        swe: "Trotsig Spartan"
    },
    44: {
        img: "detective_noir",
        eng: "Détective Noir",
        pol: "Detektyw noir",
        sch: "深色侦探帽",
        fre: "Détective Noir",
        bra: "Détective Noir",
        schema: 319,
        swe: "Detektiv Noir"
    },
    45: {
        img: "a_rather_festive_tree",
        eng: "A Rather Festive Tree",
        pol: "Dosyć świąteczna choinka",
        sch: "精美的圣诞树",
        fre: "Un Sapin Assez Festif",
        bra: "Uma Árvore Um Tanto Festiva",
        schema: 341,
        hun: "Egy Eléggé Ünnepélyes Fa",
        swe: "En Ganska Festlig Gran"
    },
    46: {
        img: "exquisite_rack",
        eng: "Exquisite Rack",
        pol: "Pokaźne poroże",
        sch: "精美的鹿角",
        fre: "Support Exquis",
        bra: "Galhada Requintada",
        schema: 339,
        hun: "Kitűnő Trófea",
        swe: "Snygga Ben"
    },
    47: {
        img: "buckaroos_hat",
        eng: "Buckaroo's Hat",
        pol: "Kowbojski kapelusz",
        sch: "西班牙牛仔帽",
        fre: "Chapeau de Buckaroo",
        bra: "Chapéu do Peão",
        schema: 322,
        hun: "Tehenészfiú Tökfedője",
        swe: "Buckaroons Hatt"
    },
    48: {
        img: "prince_tavishs_crown",
        eng: "Prince Tavish's Crown",
        pol: "Korona księcia Tavisha",
        sch: "塔维什王子的皇冠",
        fre: "Couronne du Prince Tavish",
        bra: "Coroa do Príncipe Tavish",
        schema: 342,
        hun: "Tavish Herceg Koronája",
        swe: "Prins Tavish Krona"
    },
    49: {
        img: "coupe_disaster",
        eng: "Coupe D'isaster",
        pol: "Cauchemarny toupéecik",
        sch: "修剪过的假发",
        fre: "Coupe D'Ésastre",
        bra: "Coupe D'isastre",
        schema: 330,
        hun: "L'ecsúszott Műhaj",
        swe: "Katastrofal Tupé"
    },
    50: {
        img: "blighted_beak",
        eng: "Blighted Beak",
        pol: "Morowa maska",
        sch: "勾嘴大夫的面具",
        fre: "Bec Bubonique",
        bra: "Bico Infectado",
        schema: 315,
        hun: "Parádés Pestisdoktor",
        swe: "Fördärvad Näbb"
    },
    51: {
        img: "madame_dixie",
        eng: "Madame Dixie",
        pol: "Pani Dixie",
        sch: "迪克西夫人的花礼帽",
        fre: "Madame Dixie",
        bra: "Madame Dixie",
        schema: 321,
        swe: "Madam Dixie"
    },
    52: {
        img: "german_gonzila",
        eng: "German Gonzila",
        pol: "Niemiecka gonzila",
        sch: "提洛尔圆帽",
        fre: "Gonzila Allemand",
        bra: "Gonzila Alemão",
        schema: 323,
        hun: "Germán Gonzila",
        swe: "Tysk Tyrolerhatt"
    },
    53: {
        img: "pyromancers_mask",
        eng: "Pyromancer's Mask",
        pol: "Maska piromanty",
        sch: "火焰术士的面具",
        fre: "Masque de Pyromancien",
        bra: "Máscara do Piromante",
        schema: 316,
        hun: "A Piromániás Álarca",
        swe: "Eldsjälens Mask"
    },
    54: {
        img: "le_party_phantom",
        eng: "Le Party Phantom",
        pol: "Upiór na przyjęciu",
        sch: "舞会面具",
        fre: "Le Fantôme de la Fête",
        bra: "Le Fantasma da Festa",
        schema: 337,
        hun: "Az Álarcosbál Fantomja",
        swe: "Le Partyfantomen"
    },
    55: {
        img: "industrial_festivizer",
        eng: "Industrial Festivizer",
        pol: "Przemysłowe świecidełka",
        sch: "圣诞彩灯",
        fre: "Festivités Industrielles",
        bra: "Festivizante Industrial",
        schema: 338,
        hun: "Ipari Ünnepiesítő",
        swe: "Industriell Festlighet"
    },
    56: {
        img: "larrikin_robin",
        eng: "Larrikin Robin",
        pol: "Robin z Sherbrooke",
        sch: "绿林好汉之帽",
        fre: "Robin Brigand",
        bra: "Não Robin o meu Chapéu",
        schema: 314,
        hun: "A Bajkeverő Robin",
        swe: "Gäckande Robin"
    },
    57: {
        img: "flipped_trillby",
        eng: "Flipped Trilby",
        pol: "Odwrócony trilby",
        sch: "翻转的圆毡帽",
        fre: "Chapeau de Feutre Retourné",
        bra: "Trilby Invertido",
        schema: 324,
        hun: "Fordított Filckalap",
        swe: "Vikt Trilby"
    },
    58: {
        img: "magnificent_mongolian",
        eng: "Magnificent Mongolian",
        pol: "Mocarny Mongoł",
        sch: "宏伟的蒙古帽",
        fre: "Magnifique Mongol",
        bra: "Mongol Magnífico",
        schema: 313,
        hun: "Méretes Mongol",
        swe: "Magnifik Mongol"
    },
    59: {
        img: "chargin_targe",
        eng: "Chargin' Targe",
        pol: "Tarcza szarży",
        sch: "冲锋盾",
        fre: "Le Bouclier bélier",
        bra: "A Tarja de Investida",
        schema: 131,
        hun: "A Rohamozó Paizs",
        swe: "Anfallsskölden"
    },
    60: {
        img: "direct_hit",
        eng: "Direct Hit",
        pol: "Celny strzał",
        sch: "精确打击者",
        fre: "Le Direct Hit",
        bra: "O Tiro Certo",
        schema: 127,
        hun: "A Telibe Találat",
        swe: "Direkta Träffen"
    },
    61: {
        img: "DUMMY",
        eng: "DUMMY"
    },
    62: {
        img: "demomans_fro",
        eng: "Demoman's Fro",
        pol: "Afro Demomana",
        sch: "爆破手的埃弗罗发型",
        fre: "Coupe afro du Demoman",
        bra: "Afro do Demoman",
        schema: 47,
        hun: "Kirobban-a-fró",
        swe: "Demomans Afro"
    },
    63: {
        img: "drs_dapper_topper",
        eng: "Dr's Dapper Topper",
        pol: "Pasiasty kapelusz Prota",
        sch: "博士的达珀礼帽",
        fre: "Haut-de-forme du docteur",
        bra: "Cartola Listrada do Dr.",
        schema: 252,
        hun: "A Doktor Sraffos Sipkája",
        swe: "Doktorns Höga Hatt"
    },
    64: {
        img: "rimmed_raincatcher",
        eng: "Rimmed Raincatcher",
        pol: "Piracki pieróg",
        sch: "凸缘遮雨帽",
        fre: "Lampion bordé",
        bra: "Tricórnio Cata-chuva",
        schema: 216,
        hun: "Karimás Kalózkalap",
        swe: "Kaptenens Keps"
    },
    65: {
        img: "milkman",
        eng: "Milkman",
        pol: "Mleczarz",
        sch: "牛奶商",
        fre: "Le Laitier",
        bra: "O Leiteiro",
        schema: 219,
        hun: "A Tejesember",
        swe: "Mjölkbudet"
    },
    66: {
        img: "team_spirit",
        eng: "Team Spirit",
        pol: "Duch drużyny",
        sch: "团队精神",
        fre: "Esprit d'Équipe",
        bra: "Espírito de Equipe",
        schema: 5046,
        hun: "Csapatszellem",
        swe: "Laganda"
    },
    67: {
        img: "force_a_nature",
        eng: "Force-A-Nature",
        pol: "Siła natury",
        sch: "自然之力",
        fre: "La Force-de-la-Nature",
        bra: "A Força da Natureza",
        schema: 45,
        hun: "Az Elsöprögető",
        swe: "Hagel-Med-Kraft"
    },
    68: {
        img: "bonk_atomic_punch",
        eng: "Bonk! Atomic Punch",
        pol: "Bonk! Atomowy kop",
        sch: "原子能饮料",
        fre: "Bonk! Atomic Punch",
        bra: "Bonk! Pancada Atômica",
        schema: 46,
        hun: "Poff! Atompuncs",
        ger: "Bonk! Atomenergie-Drink"
    },
    69: {
        img: "huntsman",
        eng: "Huntsman",
        pol: "Łowca",
        sch: "猎人短弓",
        fre: "Le Huntsman",
        bra: "O Caçador",
        schema: 56,
        hun: "A Levadász",
        swe: "Jägaren"
    },
    70: {
        img: "trophy_belt",
        eng: "Trophy Belt",
        pol: "Pas zdobyczy",
        sch: "战利品帽带",
        fre: "Ceinture trophée",
        bra: "Troféus do Caçador",
        schema: 53,
        hun: "Fogas Díszszalag",
        swe: "Trofébälte"
    },
    71: {
        img: "horrific_headsplitter",
        eng: "Horrific Headsplitter",
        pol: "Makabryczny rozłupywacz",
        sch: "可怕的劈头者",
        fre: "Épouvantable Hachoir",
        bra: "Abre-crânios Horripilante",
        schema: 291,
        hun: "Hallatlan Hasogatás",
        swe: "Skrämmande Skallfraktur"
    },
    72: {
        img: "cadavers_cranium",
        eng: "Cadaver's Cranium",
        pol: "Czerep Frankensteina",
        sch: "死尸的头盖骨",
        fre: "Boite crânienne d'un cadavre",
        bra: "Crânio do Cadáver",
        schema: 290,
        hun: "Hulla Homloka",
        swe: "Kadavrets Kranium"
    },
    73: {
        img: "familiar_fez",
        eng: "Familiar Fez",
        pol: "Znajomy fez",
        sch: "熟悉的非斯帽",
        fre: "Fez et gestes",
        bra: "Fez Familiar",
        schema: 223,
        hun: "Feltűnő Fez",
        swe: "Välbekant Fez"
    },
    74: {
        img: "gift_wrap",
        eng: "Gift Wrap",
        pol: "Zestaw do pakowania",
        sch: "礼物包装",
        fre: "Papier Cadeau",
        bra: "Embrulho para Presente",
        schema: 5042,
        hun: "Csomagolópapír",
        rom: "Ambalaj de Cadou",
        swe: "Presentpapper"
    },
    75: {
        img: "i_see_you",
        eng: "Taunt: I See You",
        schema: 1116,
        pol: "Drwina: Widzę cię",
        sch: "嘲讽：看见你了",
        fre: "Raillerie : Je te vois",
        bra: "Provocação: Tô de Olho",
        hun: "Beszólás: Látlak",
        swe: "Hån: Jag ser dig"
    },
    76: {
        img: "buy_a_life",
        eng: "Taunt: Buy A Life",
        schema: 1108,
        pol: "Drwina: Kup sobie życie",
        sch: "嘲讽：强者的施舍",
        fre: "Raillerie : Achète-toi une Vie",
        bra: "Provocação: Compre uma Vida",
        hun: "Beszólás: Vegyél életet",
        swe: "Hån: Köp ett liv"
    },
    77: {
        img: "results_are_in",
        eng: "Taunt: Results Are In",
        schema: 1109,
        pol: "Drwina: Mam twoje wyniki",
        sch: "嘲讽：检查报告",
        fre: "Raillerie : Rien d'anormal",
        bra: "Provocação: Resultado do Exame",
        hun: "Beszólás: Megérkeztek a leletek",
        swe: "Hån: Resultaten är här"
    },
    78: {
        img: "party_trick",
        eng: "Taunt: Party Trick",
        schema: 1112,
        pol: "Drwina: Sztuczka imprezowa",
        sch: "嘲讽：派对礼物",
        fre: "Raillerie : Le petit tour",
        bra: "Provocação: Truque de Festa",
        hun: "Beszólás: Bulitrükk",
        swe: "Hån: Partytrick"
    },
    79: {
        img: "fresh_brewed_victory",
        eng: "Taunt: Fresh Brewed Victory",
        schema: 1113,
        pol: "Drwina: Świeżo zaparzone zwycięstwo",
        sch: "嘲讽：先干为敬",
        fre: "Raillerie : Victoire encore chaude",
        bra: "Provocação: Vitória Recém-Coada",
        hun: "Beszólás: Frissen főzött diadal",
        swe: "Hån: Nybryggd seger"
    },
    80: {
        img: "spent_well_spirits",
        eng: "Taunt: Spent Well Spirits",
        schema: 1114,
        pol: "Drwina: Połykacz ognia",
        sch: "嘲讽：烈酒上头",
        fre: "Raillerie : Cocktail explosif",
        bra: "Provocação: Destilado de Dinamite",
        hun: "Beszólás: Jól használt szesz",
        swe: "Hån: Välspenderad sprit"
    },
    81: {
        img: "rancho_relaxo",
        eng: "Taunt: Rancho Relaxo",
        schema: 1115,
        pol: "Drwina: Relaks na ranczo",
        sch: "嘲讽：悠闲度假",
        fre: "Raillerie : Rancho Relaxo",
        bra: "Provocação: Descanso no Rancho",
        hun: "Beszólás: Rancho Relaxo",
        swe: "Hån: Rancho Relaxo"
    },
    82: {
        img: "battin_a_thousand",
        eng: "Taunt: Battin' a Thousand",
        schema: 1117,
        pol: "Drwina: Perfekcyjny pałkarz",
        sch: "嘲讽：以一敌千",
        fre: "Raillerie : J'dois en être à mille",
        bra: "Provocação: Mestre do Taco",
        hun: "Beszólás: Tökéletes játék",
        swe: "Hån: Slagmanslyckan"
    },
    83: {
        img: "oblooterated",
        eng: "Taunt: Oblooterated",
        schema: 1120,
        pol: "Drwina: Upojony",
        sch: "嘲讽：有酒不给喝",
        fre: "Raillerie : Oblooterated",
        bra: "Provocação: Obebiteração",
        hun: "Beszólás: Fenékig",
        swe: "Hån: Mak-öl-erad"
    },
    84: {
        img: "deep_fried_desire",
        eng: "Taunt: Deep Fried Desire",
        schema: 1119,
        pol: "Drwina: Głęboko smażone pożądanie",
        sch: "嘲讽：有肉不给吃",
        fre: "Raillerie : Le désir consommé",
        bra: "Provocação: Galante Frito",
        hun: "Beszólás: Rántott vágy",
        swe: "Hån: Friterad förförelse"
    },
    85: {
        img: "square_dance",
        eng: "Taunt: Square Dance",
        schema: 1106,
        pol: "Drwina: Oberek",
        sch: "嘲讽：方块舞",
        fre: "Raillerie : Square Dance",
        bra: "Provocação: Quadrilha",
        hun: "Beszólás: Körtánc",
        swe: "Hån: Squaredans"
    },
    86: {
        img: "flippin_awesome",
        eng: "Taunt: Flippin' Awesome",
        schema: 1107,
        pol: "Drwina: Wypasiony fikołek",
        sch: "嘲讽：牛逼闪闪的翻跟头",
        fre: "Raillerie : Absolument renversant",
        bra: "Provocação: Mortal Radical",
        hun: "Beszólás: Durván feldob",
        swe: "Hån: Häftig Bakåtvolt"
    },
    87: {
        img: "rock_paper_scissors",
        eng: "Taunt: Rock, Paper, Scissors",
        schema: 1110,
        pol: "Drwina: Kamień, papier, nożyce",
        sch: "嘲讽：石头剪刀布",
        fre: "Raillerie : Pierre, Papier, Ciseaux",
        bra: "Provocação: Pedra, Papel e Tesoura",
        hun: "Beszólás: Kő, papír, olló",
        swe: "Hån: Sten, sax, påse"
    },
    88: {
        img: "conga",
        eng: "Taunt: Conga",
        schema: 1118,
        pol: "Drwina: Konga",
        sch: "嘲讽：康茄舞",
        fre: "Raillerie : Conga",
        bra: "Provocação: Conga",
        hun: "Beszólás: Konga",
        swe: "Hån: Conga"
    },
    89: {
        img: "skullcracker",
        eng: "Taunt: Skullcracker",
        schema: 1111,
        pol: "Drwina: Kruszenie czaszek",
        sch: "嘲讽：砸烂狗头",
        fre: "Raillerie : Briseur de Crânes",
        bra: "Provocação: Quebra-cuca",
        hun: "Beszólás: Fejtörő",
        swe: "Hån: Skallknäckare"
    },
    90: {
        img: "red_rock_roscoe",
        eng: "Red Rock Roscoe | Pistol",
        pol: "Z Red Rock | Pistolet",
        sch: "红石 手枪",
        bra: "Red Rock Roscoe Pistola",
        fre: "Pistolet Roscoe rouge rouille",
        schema: 15013,
        schema2: 0,
        hun: "Red Rock Roscoe pisztoly"
    },
    91: {
        img: "sand_cannon",
        eng: "Sand Cannon | Rocket Launcher",
        pol: "Działo piaskowe | Wyrzutnia rakiet",
        sch: "沙漠巨炮 火箭发射器",
        bra: "Canhão de Areia Lança-Foguetes",
        fre: "Lance-Roquettes Canon sablé",
        schema: 15014,
        schema2: 1,
        hun: "Sand Cannon rakétavető"
    },
    92: {
        img: "sudden_flurry",
        eng: "Sudden Flurry | Stickybomb Launcher",
        pol: "Nagły podmuch | Wyrzutnia bomb samoprzylepnych",
        sch: "瞬间爆发 黏性炸弹发射器",
        bra: "Enxurrada Inesperada Lança-stickybombs",
        fre: "Lanceur de Bombes collantes Rafale surprise",
        schema: 15009,
        schema2: 8,
        hun: "Sudden Flurry tapadóbomba-vető"
    },
    93: {
        img: "psychedelic_slugger",
        eng: "Psychedelic Slugger | Revolver",
        pol: "Psychodeliczny wycisk | Rewolwer",
        sch: "幻彩 左轮手枪",
        bra: "Batedor Psicodélico Revólver",
        fre: "Revolver psychédélique",
        schema: 15011,
        schema2: 3,
        hun: "Psychedelic Slugger revolver"
    },
    94: {
        img: "purple_range",
        eng: "Purple Range | Sniper Rifle",
        pol: "Zakres fioletu | Karabin snajperski",
        sch: "紫光十射 狙击步枪",
        bra: "Pontaria Púrpura Rifle de Sniper",
        fre: "Fusil de chasse violet",
        schema: 15007,
        schema2: 7,
        hun: "Purple Range mesterlövész puska"
    },
    95: {
        img: "night_terror",
        eng: "Night Terror | Scattergun",
        pol: "Nocny terror | Dubeltówka",
        sch: "夜之恐惧 猎枪",
        bra: "Terror da Meia-noite Espingarda",
        fre: "Fusil à dispersion Terreur nocturne",
        schema: 15002,
        schema2: 11,
        hun: "Night Terror repeszpuska"
    },
    96: {
        img: "carpet_bomber",
        eng: "Carpet Bomber | Stickybomb Launcher",
        pol: "Nalot dywanowy | Wyrzutnia bomb samoprzylepnych",
        sch: "地毯式轰炸 黏性炸弹发射器",
        bra: "Arrasa Quarteirão Lança-stickybombs",
        fre: "Lanceur de Bombes collantes Bombardier tapissé",
        schema: 15012,
        schema2: 4,
        hun: "Carpet Bomber tapadóbomba-vető"
    },
    97: {
        img: "woodland_warrior",
        eng: "Woodland Warrior | Rocket Launcher",
        pol: "Leśny wojownik | Wyrzutnia rakiet",
        sch: "林地战士 火箭发射器",
        bra: "Matador do Matagal Lança-foguetes",
        fre: "Lance-Roquettes Guerrier des bois",
        schema: 15006,
        schema2: 6,
        hun: "Woodland Warrior rakétavető"
    },
    98: {
        img: "wrapped_reviver",
        eng: "Wrapped Reviver | Medi Gun",
        pol: "Spakowany ożywiciel | Medigun",
        sch: "迷彩救援者 医疗枪",
        bra: "Revivedor Embrulhado Arma Médica",
        fre: "Medigun Ressuscitateur emballé",
        schema: 15010,
        schema2: 2,
        hun: "Wrapped Reviver gyógypuska"
    },
    99: {
        img: "forest_fire",
        eng: "Forest Fire | Flame Thrower",
        pol: "Leśny ogień | Miotacz ognia",
        sch: "森林之火 火焰喷射器",
        bra: "Fogo na Floresta Lança-chamas",
        fre: "Lance-flammes Feu de forêt",
        schema: 15005,
        schema2: 9,
        hun: "Forest Fire lángszóró"
    },
    100: {
        img: "night_owl",
        eng: "Night Owl | Sniper Rifle",
        pol: "Nocna sowa | Karabin snajperski",
        sch: "夜枭 狙击步枪",
        bra: "Coruja da Noite Rifle de Sniper",
        fre: "Fusil de sniper Oiseau de nuit",
        schema: 15000,
        schema2: 14,
        hun: "Night Owl mesterlövész puska"
    },
    101: {
        img: "woodsy_widowmaker",
        eng: "Woodsy Widowmaker | SMG",
        pol: "Lesisty owdawiacz | PM",
        sch: "绝后之木 SMG",
        bra: "Viuvadora Verdejante Submetralhadora",
        fre: "Mitraillette Endeuilleur boisé",
        schema: 15001,
        schema2: 13,
        hun: "Woodsy Widowmaker géppisztoly"
    },
    102: {
        img: "backwoods_boomstick",
        eng: "Backwoods Boomstick | Shotgun",
        pol: "Grzmiący kij z puszczy | Strzelba",
        sch: "荒野神兵 霰弹枪",
        bra: "Sertão Escopeta",
        fre: "Fusil à pompe Fusil de derrière les fagots",
        schema: 15003,
        schema2: 12,
        hun: "Backwoods Boomstick sörétes puska"
    },
    103: {
        img: "king_of_the_jungle",
        eng: "King of the Jungle | Minigun",
        pol: "Król dżungli | Minigun",
        sch: "丛林之王 医疗枪",
        bra: "Rei da Selva Metralhadora Giratória",
        fre: "Minigun Roi de la jungle",
        schema: 15004,
        schema2: 10,
        hun: "King of the Jungle gépágyú"
    },
    104: {
        img: "masked_mender",
        eng: "Masked Mender | Medi Gun",
        pol: "Zakamufliowany uzdrowiciel | Medigun",
        sch: "蒙面者 医疗枪",
        bra: "Medicamento Mascarado Arma Médica",
        fre: "Medigun Réparateur masqué",
        schema: 15008,
        schema2: 5,
        hun: "Masked Mender gyógypuska"
    },
    105: {
        img: "pink_as_hell",
        eng: "Pink as Hell",
        pol: "Różowy jak diabli",
        sch: "闪亮的粉红色",
        fre: "Un Rose d'Enfer",
        bra: "Rosa pra Caramba",
        schema: 5051,
        hun: "Pokoli Rózsaszín",
        swe: "Rosa Som In I Helvete"
    },
    106: {
        img: "a_color_similar_to_slate",
        eng: "A Color Similar to Slate",
        pol: "Kolor podobny do łupkowego",
        sch: "石板似的颜色",
        fre: "Une Couleur Similaire à l'Ardoise",
        bra: "Uma Cor Similar à Ardósia",
        schema: 5052,
        hun: "Palához Hasonló Szín",
        swe: "En Färg Som Liknar Griffeltavla"
    },
    107: {
        img: "drably_olive",
        eng: "Drably Olive",
        pol: "Ponura oliwka",
        sch: "平淡榄色",
        fre: "Vert Olive",
        bra: "Oliva Monótono",
        schema: 5053,
        hun: "Zöldesen Barna",
        swe: "Smutsgul Olivfärg"
    },
    108: {
        img: "the_bitter_taste_of_defeat_and_lime",
        eng: "The Bitter Taste of Defeat and Lime",
        pol: "Gorzki smak porażki i limonki",
        sch: "苦涩的青柠绿",
        fre: "Le Goût Amer de la Défaite et du Citron Vert",
        bra: "O Amargo Sabor de Derrota e Limão",
        schema: 5054,
        hun: "A Vereség és Lime Keserű Íze",
        swe: "Den Bittra Smaken Av Förlust Och Lime"
    },
    109: {
        img: "the_color_of_a_gentlemanns_business_pants",
        eng: "The Color of a Gentlemann's Business Pants",
        pol: "Kolor biznesowych spodni dżentelmena",
        sch: "绅士商务裤的颜色",
        fre: "La Couleur du Pantalon d'Affaires d'un Gentlemann",
        bra: "A Cor das Calças de Negócios de um Cavalheiro",
        schema: 5055,
        hun: "Egy Úriember Tárgyalónadrágjának Színe",
        swe: "Färgen Hos En Gentlemanns Affärsbyxor"
    },
    110: {
        img: "dark_salmon_injustice",
        eng: "Dark Salmon Injustice",
        pol: "Niesprawiedliwość ciemnego łososia",
        sch: "不公正的深橙红",
        fre: "Injustice Saumon Foncé",
        bra: "Injustiça Salmão Escuro",
        schema: 5056,
        hun: "Sötét Lazac Igazságtalanság",
        swe: "Laxfärgad Orättvisa"
    },
    111: {
        img: "grenadiers_softcap",
        eng: "Grenadier's Softcap",
        pol: "Patrolówka grenadiera",
        sch: "投弹兵的软帽",
        fre: "Casquette de grenadier",
        bra: "Quepe do Granadeiro",
        schema: 227,
        hun: "Gránátos Terepsapkája",
        swe: "Grenadjärens Keps"
    },
    112: {
        img: "indubitably_green",
        eng: "Indubitably Green",
        pol: "Niezaprzeczalnie zielony",
        sch: "不容置疑的绿色",
        fre: "Indubitablement Vert",
        bra: "Indiscutivelmente Verde",
        schema: 5027,
        hun: "Kétségtelenül Zöld",
        swe: "Otvivelaktigt Grön"
    },
    113: {
        img: "zepheniahs_greed",
        eng: "Zepheniah's Greed",
        pol: "Zachłanność Zefeniasza",
        sch: "贪欲黑暗绿色",
        fre: "L'Avarice de Zepheniah",
        bra: "Ganância do Zepheniah",
        schema: 5028,
        hun: "Zephaniah Kapzsisága",
        swe: "Zepheniahs Girighet"
    },
    114: {
        img: "noble_hatters_violet",
        eng: "Noble Hatter's Violet",
        pol: "Fiolet zacnego kapelusznika",
        sch: "贵族帽商紫色",
        fre: "Violet de l'Aristocrate Chapeauté",
        bra: "Violeta do Nobre Chapeleiro",
        schema: 5029,
        hun: "Nemes Kalaposok Ibolyája",
        swe: "Nobla Hattmakarens Violett"
    },
    115: {
        img: "ullapool_caber",
        eng: "Ullapool Caber",
        pol: "Ullapoolski pal",
        sch: "木柄手榴弹",
        fre: "Le Caber d'Ullapool",
        bra: "A Tora de Ullapool",
        schema: 307,
        hun: "A Kot-Nyeles Gránát",
        swe: "Ullapool-Caber"
    },
    116: {
        img: "lochnload",
        eng: "Loch-n-Load",
        pol: "Loch-n-Load",
        sch: "双管榴弹发射器",
        fre: "Le Loch-n-Load",
        bra: "A Bomba do Lago Ness",
        schema: 308,
        hun: "A Loch-Nesze-Szörny",
        ger: "Der Lochnesser"
    },
    117: {
        img: "scotch_bonnet",
        eng: "Scotch Bonnet",
        pol: "Szkocki kask",
        sch: "破损的防暴头盔",
        fre: "Casque Écossais",
        bra: "Capacete Escocês",
        schema: 306,
        hun: "Skót Sisak",
        swe: "Skotsk Hätta"
    },
    118: {
        img: "glengarry_bonnet",
        eng: "Glengarry Bonnet",
        pol: "Furażerka z Glengarry",
        sch: "苏格兰软帽",
        fre: "Bonnet Glengarry",
        bra: "Boina Glengarry",
        schema: 100,
        hun: "Glengarry Sapka",
        swe: "Glengarry-Hätta"
    },
    119: {
        img: "mann_co_orange",
        eng: "Mann Co. Orange",
        pol: "Pomarańcz firmy Mann Co.",
        sch: "曼恩企业橙",
        fre: "Orange Mann Co.",
        bra: "Laranja Mann Co.",
        schema: 5032,
        hun: "Mann Co. Narancs",
        swe: "Mann Co.-Orange"
    },
    120: {
        img: "crusaders_crossbow",
        eng: "Crusader's Crossbow",
        pol: "Kusza krzyżowca",
        sch: "十字军之弩",
        fre: "L'Arbalète du Croisé",
        bra: "A Besta do Cruzado",
        schema: 305,
        hun: "A Szamaritánus Számszeríja",
        swe: "Korsriddarens Armborst"
    },
    121: {
        img: "amputator",
        eng: "Amputator",
        pol: "Amputator",
        sch: "器官切割器",
        fre: "L'Amputateur",
        bra: "A Amputadora",
        schema: 304,
        hun: "Az Amputáló",
        swe: "Amputeraren"
    },
    122: {
        img: "berliners_bucket_helm",
        eng: "Berliner's Bucket Helm",
        pol: "Hełm garnczkowy Berlińczyka",
        sch: "柏林的桶式头盔",
        fre: "Seau Berlinois",
        bra: "Elmo do Berlinense",
        schema: 303,
        hun: "Berlini Bádogsisak",
        swe: "Tyskens Tunnhjälm"
    },
    123: {
        img: "vintage_tyrolean",
        eng: "Vintage Tyrolean",
        pol: "Klasyczny kapelusz tyrolski",
        sch: "传统提洛帽",
        fre: "Chapeau tyrolien",
        bra: "Tirolês Clássico",
        schema: 101,
        hun: "Öreg Tiroli",
        swe: "Gammal Tyrolerhatt"
    },
    124: {
        img: "prussian_pickelhaube",
        eng: "Prussian Pickelhaube",
        pol: "Pruska pikielhauba",
        sch: "普鲁士头盔",
        fre: "Casque à pointe prussien",
        bra: "Pickelhaube Prussiano",
        schema: 50,
        hun: "Porosz Díszsisak",
        swe: "Preussisk Pickelhuva"
    },
    125: {
        img: "muskelmannbraun",
        eng: "Muskelmannbraun",
        pol: "Muskularny brąz",
        sch: "肌肉男的肤色",
        fre: "Marronmuskelmann",
        bra: "Muskelmannbraun",
        schema: 5033,
        hun: "Izomember-barna"
    },
    126: {
        img: "peculiarly_drab_tincture",
        eng: "Peculiarly Drab Tincture",
        pol: "Osobliwie szarawe zabarwienie",
        sch: "沉闷乏味赭",
        fre: "Teinture Mate Particulière",
        bra: "Tintura Peculiarmente Amarronzada",
        schema: 5034,
        hun: "Különösen Egyhangú Színezet",
        swe: "Underligt Blekgul Färg"
    },
    127: {
        img: "radigan_conagher_brown",
        eng: "Radigan Conagher Brown",
        pol: "Brąz Radigana Conaghera",
        sch: "褐色",
        fre: "Brun Radigan Conagher",
        bra: "Marrom Radigan Conagher",
        schema: 5035,
        hun: "Radigan Conagher Barna",
        swe: "Radigan Conagher-Brun"
    },
    128: {
        img: "brass_beast",
        eng: "Brass Beast",
        pol: "Mosiężna bestia",
        sch: "黄铜猛兽",
        fre: "La Bête de Bronze",
        bra: "A Fera de Bronze",
        schema: 312,
        hun: "A Bronz Bestia",
        swe: "Mässingsmonstret"
    },
    129: {
        img: "buffalo_steak_sandvich",
        eng: "Buffalo Steak Sandvich",
        pol: "Stek z bizona",
        sch: "水牛排三明治",
        fre: "Le Sandvich au Steak de Bison",
        bra: "O Sandviche de Bife de Búfalo",
        schema: 311,
        hun: "A Bivaly Bélszín Szendvics",
        swe: "Buffelbiffsmörgos"
    },
    130: {
        img: "big_chief",
        eng: "Big Chief",
        pol: "Wielki wódz",
        sch: "大酋长",
        fre: "Le Grand Chef",
        bra: "O Grande Cacique",
        schema: 309,
        schema2: 5725,
        hun: "A Nagyfőnök",
        swe: "Den Stora Hövdingen"
    },
    131: {
        img: "officers_ushanka",
        eng: "Officer's Ushanka",
        pol: "Uszanka oficera",
        sch: "官员的红军帽",
        fre: "Ouchanka d'officier",
        bra: "Ushanka do Oficial",
        schema: 96,
        hun: "Tiszti Usanka",
        swe: "Officerens Usjanka"
    },
    132: {
        img: "sydney_sleeper",
        eng: "Sydney Sleeper",
        pol: "Sydnejski usypiacz",
        sch: "悉尼沉睡者",
        fre: "La Sarbacane de Sydney",
        bra: "O Anestesiador de Sydney",
        schema: 230,
        hun: "A Sydney-i Pisi-Puska",
        swe: "Sydney-Sövaren"
    },
    133: {
        img: "ol_snaggletooth",
        eng: "Ol' Snaggletooth",
        pol: "Głowa Forfitera",
        sch: "老鳄鱼的龅牙",
        fre: "Sacré dentier",
        bra: "Velho Dente-torto",
        schema: 229,
        hun: "Vén Csorbafog",
        swe: "Gamle Gadden"
    },
    134: {
        img: "professionals_panama",
        eng: "Professional's Panama",
        pol: "Panama profesjonalisty",
        sch: "专业人士的巴拿马草帽",
        fre: "Panama du professionnel",
        bra: "Panamá do Profissional",
        schema: 109,
        hun: "Profik Panamája",
        swe: "Proffsets Stråhatt"
    },
    135: {
        img: "shooters_sola_topi",
        eng: "Shooter's Sola Topi",
        pol: "Kolonialny hełm strzelca",
        sch: "猎手的索拉托皮",
        fre: "Tireur Colonial",
        bra: "Chapéu de Safári",
        schema: 158,
        hun: "Szafari-kalap",
        swe: "Skjutarens Tropikhjälm"
    },
    136: {
        img: "backbiters_billycock",
        eng: "Backbiter's Billycock",
        pol: "Melonik oszczercy",
        sch: "背后诽谤者的小礼帽",
        fre: "Chapeau melon",
        bra: "Chapéu-coco do Caluniador",
        schema: 108,
        hun: "Hátbaszúrók Keménykalapja",
        swe: "Baktalarens Kubb"
    },
    137: {
        img: "tyrants_helm",
        eng: "Tyrant's Helm",
        pol: "Hełm tyrana",
        sch: "暴君头盔",
        fre: "Casque de viking",
        bra: "Elmo do Tirano",
        schema: 99,
        hun: "Zsarnok-sisak",
        swe: "Tyrannens Hjälm"
    },
    138: {
        img: "mad_milk",
        eng: "Mad Milk",
        pol: "Wściekłe mleko",
        sch: "疯狂的牛奶",
        fre: "Lait frelaté",
        bra: "Leite Louco",
        schema: 222,
        hun: "Kerge Tej",
        swe: "Manisk Mjölk"
    },
    139: {
        img: "degreaser",
        eng: "Degreaser",
        pol: "Odtłuszczacz",
        sch: "脱油剂",
        fre: "Le Dégraisseur",
        bra: "O Desengraxante",
        schema: 215,
        hun: "A Zsíroldó",
        swe: "Avfettaren"
    },
    140: {
        img: "attendant",
        eng: "Attendant",
        pol: "Pomocnik",
        sch: "服务员之帽",
        fre: "Le Préposé",
        bra: "Frentista",
        schema: 213,
        hun: "A Benzinkutas",
        swe: "Personalmössan"
    },
    141: {
        img: "wrangler",
        eng: "Wrangler",
        pol: "Poskramiacz",
        sch: "遥控手柄",
        fre: "Le Dompteur",
        bra: "O Peão",
        schema: 140,
        hun: "Az Idomító",
        swe: "Grälmakaren"
    },
    142: {
        img: "hotrod",
        eng: "Hotrod",
        pol: "Hotrod",
        sch: "电焊面罩",
        fre: "Hotrod",
        bra: "Hotrod",
        schema: 148,
        hun: "Ívadó",
        swe: "Svetsarmask"
    },
    143: {
        img: "professors_peculiarity",
        eng: "Professor's Peculiarity",
        pol: "Profesorska osobliwość",
        sch: "教授的蓬乱白发",
        fre: "Particularité Professorale",
        bra: "Peculiaridade do Professor",
        schema: 384,
        hun: "A Professzor Páratlansága",
        swe: "Professorns Prägel"
    },
    144: {
        img: "grenade_launcher",
        eng: "Grenade Launcher",
        pol: "Granatnik",
        sch: "榴弹发射器",
        fre: "Lance-grenades",
        bra: "Lança-granadas",
        schema: 206,
        hun: "gránátvető",
        swe: "Granatkastare"
    },
    145: {
        img: "stickybomb_launcher",
        eng: "Stickybomb Launcher",
        pol: "Wyrzutnia bomb samoprzylepnych",
        sch: "黏性炸弹发射器",
        fre: "Lanceur de bombes collantes",
        bra: "Lança-stickybombs",
        schema: 207,
        hun: "tapadóbomba-vető",
        swe: "Klisterbombskastare"
    },
    146: {
        img: "eyelander",
        eng: "Eyelander",
        pol: "Wyszczerbiec",
        sch: "苏格兰长剑",
        fre: "L'Eyelander",
        bra: "A Eyelander",
        schema: 132,
        hun: "Az Egyetlen",
        swe: "Ögländaren"
    },
    147: {
        img: "hustlers_hallmark",
        eng: "Hustler's Hallmark",
        pol: "Wizytówka sutenera",
        sch: "皮条客的标志帽",
        fre: "Marque du Débrouillard",
        bra: "Chapéu do Malandro",
        schema: 146,
        hun: "Cinkelő Cégére",
        swe: "Fuskarens Fjäderhatt"
    },
    148: {
        img: "tipplers_tricorne",
        eng: "Tippler's Tricorne",
        pol: "Trikorn pijaka",
        sch: "醉汉的三角帽",
        fre: "Tricorne de l'ivrogne",
        bra: "Tricórnio do Bêbado",
        schema: 179,
        hun: "Tintás Tökfödő",
        swe: "Småsuparens Sjörövarhatt"
    },
    149: {
        img: "schadenfreude",
        eng: "Taunt: The Schadenfreude",
        schema: 463,
        pol: "Drwina: Schadenfreude",
        sch: "嘲讽：幸灾乐祸",
        fre: "Raillerie : La Schadenfreude",
        bra: "Provocação: A Schadenfreude",
        hun: "Beszólás: A káröröm",
        swe: "Hån: Skadeglädjen"
    },
    150: {
        img: "rocket_launcher",
        eng: "Rocket Launcher",
        pol: "Wyrzutnia rakiet",
        sch: "火箭发射器",
        fre: "Lance-roquettes",
        bra: "Lança-foguetes",
        schema: 205,
        hun: "rakétavető",
        swe: "Raketgevär"
    },
    151: {
        img: "shotgun",
        eng: "Shotgun",
        pol: "Strzelba",
        sch: "霰弹枪",
        fre: "Fusil à pompe",
        bra: "Escopeta",
        schema: 199,
        hun: "sörétes puska",
        swe: "Hagelgevär"
    },
    152: {
        img: "killers_kabuto",
        eng: "Killer's Kabuto",
        pol: "Kabuto zabójcy",
        sch: "杀手的武士之胄",
        fre: "Kabuto de tueur",
        bra: "Kabuto do Assassino",
        schema: 152,
        hun: "Gyilkos Kabuto",
        swe: "Mördarens Kabuto"
    },
    153: {
        img: "sergeants_drill_hat",
        eng: "Sergeant's Drill Hat",
        pol: "Kapelusz instruktora musztry",
        sch: "士官的操练帽",
        fre: "Chapeau d'Entraînement du Sergent",
        bra: "Chapelão do Sargento",
        schema: 183,
        hun: "Őrmesteri Kiképzőkalap",
        swe: "Sergeantens Övningshatt"
    },
    154: {
        img: "gloves_of_running_urgently",
        eng: "Gloves of Running Urgently",
        pol: "Gorące rękawice uciekiniera",
        sch: "紧急逃跑手套",
        fre: "Gants de Retraite Urgente",
        bra: "As Geradoras de Rapidez Urgente",
        schema: 1184,
        hun: "A Fürge Sprintelés Bokszkesztyűi",
        swe: "Gorbatjovs Rushande Uppercut"
    },
    155: {
        img: "minigun",
        eng: "Minigun",
        pol: "Minigun",
        sch: "转轮机枪",
        fre: "Minigun",
        bra: "Metralhadora Giratória",
        schema: 202,
        hun: "gépágyú"
    },
    156: {
        img: "natascha",
        eng: "Natascha",
        pol: "Natasza",
        sch: "娜塔莎转轮机枪",
        fre: "Natascha",
        bra: "Natascha",
        schema: 41,
        hun: "Natasa"
    },
    157: {
        img: "hound_dog",
        eng: "Hound Dog",
        pol: "Hound Dog",
        sch: "猎犬",
        fre: "Hound Dog",
        bra: "Cão de Caça",
        schema: 145,
        hun: "A Nagykutya"
    },
    158: {
        img: "football_helmet",
        eng: "Football Helmet",
        pol: "Kask futbolowy",
        sch: "橄榄球头盔",
        fre: "Casque de football",
        bra: "Capacete de Futebol Americano",
        schema: 49,
        hun: "Amerikaifoci-sisak",
        swe: "Amerikansk Fotbollshjälm"
    },
    159: {
        img: "big_steel_jaw_of_summer_fun",
        eng: "Big Steel Jaw of Summer Fun",
        pol: "Wielka stalowa szczęka letniej radochy",
        sch: "夏日狂欢大钢颚",
        fre: "Mâchoire en Acier d'Amusement Estivale",
        bra: "Grande Mandíbula de Aço de Diversão de Verão",
        schema: 485,
        hun: "A Nyári Móka Nagy Acél Állkapcsa",
        swe: "Stora Sommarskoj-Stålkäken"
    },
    160: {
        img: "coppers_hard_top",
        eng: "Copper's Hard Top",
        pol: "Zadaszenie gliniarza",
        sch: "警用机车头盔",
        fre: "Casque de Policier",
        bra: "Capacete do Tira",
        schema: 478,
        hun: "Zsernyák Keményfedele",
        swe: "Konstapelns Knoppskydd"
    },
    161: {
        img: "nessies_nine_iron",
        eng: "Nessie's Nine Iron",
        pol: "Żelazna Nessie",
        sch: "尼斯的九号铁杆",
        fre: "Fer N°9 de Nessie",
        bra: "Ferro Nove do Nessie",
        schema: 482,
        hun: "Nessie Kilences Vasa",
        swe: "Nessies Järnnia"
    },
    162: {
        img: "postal_pummeler",
        eng: "Postal Pummeler",
        pol: "Pocztowy pogromca",
        sch: "明信片打手",
        fre: "Le Poutreur Postal",
        bra: "A Pancada Postal",
        schema: 457,
        hun: "A Postai Püfölő",
        swe: "Postpucklaren"
    },
    163: {
        img: "prairie_heel_biters",
        eng: "Prairie Heel Biters",
        pol: "Preriowe podgryzacze pięt",
        sch: "草原钉刺马靴",
        fre: "Éperons des Plaines",
        bra: "Esporas da Pradaria",
        schema: 484,
        hun: "Bokorugró Sarkantyú",
        swe: "Präriens Hälbitare"
    },
    164: {
        img: "rogues_col_roule",
        eng: "Rogue's Col Roule",
        pol: "Gryzący golf gangstera",
        sch: "盗贼的高领毛衣",
        fre: "Col Roulé du Voleur",
        bra: "Cou Roule do Malandro",
        schema: 483,
        hun: "Gazfickó Garbója",
        swe: "Bedragarens Col Roule"
    },
    165: {
        img: "security_shades",
        eng: "Security Shades",
        pol: "Przyciemniane patrolówki",
        sch: "保安墨镜",
        fre: "Lunettes Sécuritaires",
        bra: "Óculos do Segurança",
        schema: 479,
        hun: "Sötétített Szemtakaró",
        swe: "Snutens Solskydd"
    },
    166: {
        img: "stately_steel_toe",
        eng: "Stately Steel Toe",
        pol: "Dostojne obuwie ochronne",
        sch: "高贵的铁皮鞋",
        fre: "Noble Chaussure de Sécurité",
        bra: "Pontapé Imponente",
        schema: 481,
        hun: "Alighanem Acélorrú",
        swe: "Ståtlig Stålhätta"
    },
    167: {
        img: "tam_o_shanter",
        eng: "Tam O' Shanter",
        pol: "Tam O' Shanter",
        sch: "苏格兰式便帽",
        fre: "Béret Écossais",
        bra: "Tam O' Shanter",
        schema: 480
    },
    168: {
        img: "sandman",
        eng: "Sandman",
        pol: "Piaskun",
        sch: "睡魔",
        fre: "Le marchand de sable",
        bra: "O João Pestana",
        schema: 44,
        hun: "A Testápoló",
        swe: "John Blund"
    },
    169: {
        img: "pistol",
        eng: "Pistol",
        pol: "Pistolet",
        sch: "手枪",
        fre: "Pistolet",
        bra: "Pistola",
        schema: 23,
        hun: "pisztoly",
        ger: "Pistole"
    },
    170: {
        img: "scattergun",
        eng: "Scattergun",
        pol: "Dubeltówka",
        sch: "猎枪",
        fre: "Fusil à dispersion",
        bra: "Espingarda",
        schema: 200,
        hun: "repeszpuska",
        swe: "Hagelbössa"
    },
    171: {
        img: "boston_basher",
        eng: "Boston Basher",
        pol: "Bostoński tłuczek",
        sch: "波士顿狼牙棒",
        fre: "Le Bastonneur Bostonien",
        bra: "O Batedor de Boston",
        schema: 325,
        hun: "A Bostoni Beverő",
        swe: "Bostonbankaren"
    },
    172: {
        img: "ye_olde_baker_boy",
        eng: "Ye Olde Baker Boy",
        pol: "Czapka gazeciarza",
        sch: "老式报童帽",
        fre: "Casquette de gavroche",
        bra: "Padeirinho, ô pá!",
        schema: 107,
        hun: "Rikkancs-sapka",
        swe: "Äppelknyckarkeps"
    },
    173: {
        img: "ambassador",
        eng: "Ambassador",
        pol: "Ambasador",
        sch: "大使手枪",
        fre: "L'Ambassadeur",
        bra: "O Embaixador",
        schema: 61,
        hun: "A Nagykövet",
        swe: "Ambassadören"
    },
    174: {
        img: "connivers_kunai",
        eng: "Conniver's Kunai",
        pol: "Kunai krętacza",
        sch: "密谋者的苦无",
        fre: "Kunaï du Conspirateur",
        bra: "Kunai do Conspirador",
        schema: 356,
        hun: "Kétszínű Kunai",
        swe: "Konspiratörens Kunai"
    },
    175: {
        img: "knife",
        eng: "Knife",
        pol: "Nóż",
        sch: "刀子",
        fre: "Couteau",
        bra: "Faca",
        schema: 194,
        hun: "kés",
        swe: "Kniv"
    },
    176: {
        img: "revolver",
        eng: "Revolver",
        pol: "Rewolwer",
        sch: "左轮手枪",
        fre: "Revolver",
        bra: "Revólver",
        schema: 24
    },
    177: {
        img: "private_eye",
        eng: "Private Eye",
        pol: "Bystrooki detektyw",
        sch: "私家侦探帽",
        fre: "Détective Privé",
        bra: "Detetive Particular",
        schema: 388,
        schema2: 5724,
        hun: "Privát Kopó",
        swe: "Privatdetektiven"
    },
    178: {
        img: "fancy_fedora",
        eng: "Fancy Fedora",
        pol: "Gustowna fedora",
        sch: "花哨软呢帽",
        fre: "Fedora élégant",
        bra: "Fedora Fabuloso",
        schema: 55,
        hun: "Pöpec Puhakalap",
        swe: "Flådig Fedora"
    },
    179: {
        img: "flame_thrower",
        eng: "Flame Thrower",
        pol: "Miotacz ognia",
        sch: "火焰喷射器",
        fre: "Lance-flammes",
        bra: "Lança-chamas",
        schema: 208,
        hun: "lángszóró",
        swe: "Eldkastare"
    },
    180: {
        img: "flare_gun",
        eng: "Flare Gun",
        pol: "Pistolet sygnałowy",
        sch: "信号枪",
        fre: "Pistolet de détresse",
        bra: "Arma Sinalizadora",
        schema: 39,
        hun: "jelzőpisztoly",
        swe: "Signalpistol"
    },
    181: {
        img: "backburner",
        eng: "Backburner",
        pol: "Dupalacz",
        sch: "偷袭烈焰喷射器",
        fre: "Le brûleur arrière",
        bra: "O Queima-costas",
        schema: 40,
        hun: "A Beégető",
        swe: "Ryggbrännaren"
    },
    182: {
        img: "prancers_pride",
        eng: "Prancer's Pride",
        pol: "Duma renifera",
        sch: "驯鹿欢腾的角",
        fre: "Fierté de Prancer",
        bra: "Orgulho da Rena",
        schema: 318,
        hun: "Pompás Büszkesége",
        swe: "Prancers Prydnad"
    },
    183: {
        img: "respectless_rubber_glove",
        eng: "Respectless Rubber Glove",
        pol: "Niepoważna gumowa rękawica",
        sch: "邋遢的橡胶手套",
        fre: "Gant en caoutchouc ridicule",
        bra: "Luva de Borracha Patética",
        schema: 102,
        hun: "Gúnyos Gumikesztyű",
        swe: "Respektlös Gummihandske"
    },
    184: {
        img: "syringe_gun",
        eng: "Syringe Gun",
        pol: "Pistolet strzykawkowy",
        sch: "注射枪",
        fre: "Pistolet à seringues",
        bra: "Arma de Seringas",
        schema: 204,
        hun: "oltópisztoly",
        swe: "Sprutgevär"
    },
    185: {
        img: "bonesaw",
        eng: "Bonesaw",
        pol: "Piła do kości",
        sch: "骨锯",
        fre: "Scie à amputation",
        bra: "Serra de Ossos",
        schema: 198,
        hun: "csontfűrész",
        swe: "Bensåg"
    },
    186: {
        img: "medi_gun",
        eng: "Medi Gun",
        pol: "Medigun",
        sch: "医疗枪",
        fre: "Medigun",
        bra: "Arma Médica",
        schema: 211,
        hun: "gyógypuska",
        swe: "Hälsovapen"
    },
    187: {
        img: "gentlemans_gatsby",
        eng: "Gentleman's Gatsby",
        pol: "Kaszkiet dżentelmena",
        sch: "绅士的盖茨比帽",
        fre: "Gatsby du Gentleman",
        bra: "Boina do Cavalheiro",
        schema: 184,
        hun: "Úri Gatsby",
        swe: "Gentlemannens Golfhatt"
    },
    188: {
        img: "otolaryngologists_mirror",
        eng: "Otolaryngologist's Mirror",
        pol: "Lustro otolaryngologa",
        sch: "耳鼻喉科医师的诊疗镜",
        fre: "Miroir d'oto-rhino-laryngologiste",
        bra: "Espelho do Otorrinolaringologista",
        schema: 104,
        hun: "Otolaringológus-tükör",
        swe: "Otorhinolaryngologikerns Spegel"
    },
    189: {
        img: "sniper_rifle",
        eng: "Sniper Rifle",
        pol: "Karabin snajperski",
        sch: "狙击步枪",
        fre: "Fusil de sniper",
        bra: "Rifle de Sniper",
        schema: 201,
        hun: "mesterlövész puska",
        swe: "Krypskyttegevär"
    },
    190: {
        img: "jarate",
        eng: "Jarate",
        pol: "Sikwondo",
        sch: "瓶手道",
        fre: "Jaraté",
        bra: "Jarratê",
        schema: 58,
        hun: "Vizelharc"
    },
    191: {
        img: "villains_veil",
        eng: "Villain's Veil",
        pol: "Chusta łotrzyka",
        sch: "恶棍的面罩",
        fre: "Voile du Vaurien",
        bra: "Véu do Vilão",
        schema: 393,
        hun: "Gazfickó Kendője",
        swe: "Skurkens Slöja"
    },
    192: {
        img: "frontier_justice",
        eng: "Frontier Justice",
        pol: "Samosąd",
        sch: "边境审判",
        fre: "Que justice soit faite",
        bra: "Justiça com a Própria Sentinela",
        schema: 141,
        hun: "Automatizált bosszú",
        swe: "Västernrättvisa"
    },
    193: {
        img: "wrench",
        eng: "Wrench",
        pol: "Klucz",
        sch: "扳手",
        fre: "Clé",
        bra: "Chave Inglesa",
        schema: 197,
        hun: "franciakulcs",
        swe: "Skiftnyckel"
    },
    194: {
        img: "mining_light",
        eng: "Mining Light",
        pol: "Lampa górnicza",
        sch: "矿灯",
        fre: "Lampe de mineur",
        bra: "Luz de Mineração",
        schema: 48,
        hun: "Bányászlámpa",
        swe: "Gruvlampa"
    },
    195: {
        img: "axtinguisher",
        eng: "Axtinguisher",
        pol: "Poskramiacz płomieni",
        sch: "火焰战斧",
        fre: "L'Hachtincteur",
        bra: "O Queimachado",
        schema: 38,
        hun: "A Fejhasogató",
        swe: "Brandslaktaren"
    },
    196: {
        img: "razorback",
        eng: "Razorback",
        pol: "Antyklinga",
        sch: "电击背脊盾",
        fre: "Le Razorback",
        bra: "O Escudo Antinavalha",
        schema: 57,
        hun: "A Visszavágó",
        ger: "Der Stammesschild"
    },
    197: {
        img: "tough_guys_toque",
        eng: "Tough Guy's Toque",
        pol: "Pomponik twardziela",
        sch: "硬汉的无边帽",
        fre: "Tuque de gros dur",
        bra: "Touca do Valentão",
        schema: 97,
        hun: "A Keményfiú Sapija",
        swe: "Tuffingens Toppluva"
    },
    198: {
        img: "frenchmans_beret",
        eng: "Frenchman's Beret",
        pol: "Beret Francuza",
        sch: "法式贝雷帽",
        fre: "Béret du Français",
        bra: "Boina do Francês",
        schema: 180,
        hun: "Francia Svájcisapka",
        swe: "Fransmannens Basker"
    },
    199: {
        img: "kritzkrieg",
        eng: "Kritzkrieg",
        pol: "Kritzkrieg",
        sch: "闪电战医疗枪",
        fre: "Le Kritzkrieg",
        bra: "A Kritzkrieg",
        schema: 35,
        hun: "A Kritzkrieg",
        ger: "Die Kritzkrieg"
    },
    200: {
        img: "holy_mackerel",
        eng: "Holy Mackerel",
        pol: "Święta makrela",
        sch: "圣灵鲭鱼",
        fre: "Le maquereau sacré",
        bra: "O Santo Carapau",
        schema: 221,
        hun: "A Magasságos Makréla",
        swe: "Fina Fisken"
    },
    201: {
        img: "machina",
        eng: "Machina",
        pol: "Machina",
        sch: "鬼械神狙",
        fre: "La Machina",
        bra: "A Machina",
        schema: 526,
        hun: "A Machina",
        ger: "Die Machina"
    },
    202: {
        img: "half_zatoichi",
        eng: "Half-Zatoichi",
        pol: "Prawie Zatoichi",
        sch: "座头市之刀",
        fre: "Demi-Zatoichi",
        bra: "Quase-Zatoichi",
        schema: 357,
        hun: "Fél-Zatoichi",
        swe: "Halv-Zatoichi"
    },
    203: {
        img: "fists",
        eng: "Fists",
        pol: "Pięści",
        sch: "拳头",
        fre: "Poings",
        bra: "Punhos",
        schema: 195,
        hun: "öklök",
        swe: "Knytnävar"
    },
    204: {
        img: "grimm_hatte",
        eng: "Grimm Hatte",
        pol: "Sępny kłobuk",
        sch: "黑死病医帽",
        fre: "Sinistre Haine",
        bra: "Schapel dos Grimm",
        schema: 383,
        hun: "Zord Sapoka",
        swe: "Grymm Hatt"
    },
    205: {
        img: "ubersaw",
        eng: "Ubersaw",
        pol: "Überpiła",
        sch: "过载充能锯",
        fre: "L'Überscie",
        bra: "A Überserra",
        schema: 37,
        hun: "Az Agyonfűrész",
        swe: "Übersågen"
    },
    206: {
        img: "soda_popper",
        eng: "Soda Popper",
        pol: "Buzownik",
        sch: "苏打炮手",
        fre: "Le Rafraîchisseur",
        bra: "A Refrispingarda",
        schema: 448,
        hun: "Az Üdítő Ürítő",
        swe: "Läskpopparen"
    },
    207: {
        img: "family_business",
        eng: "Family Business",
        pol: "Rodzinny interes",
        sch: "家族运营枪",
        fre: "L'Entreprise Familiale",
        bra: "Os Negócios de Família",
        schema: 425,
        hun: "A Családi Vállalkozás",
        swe: "Familjens Ensak"
    },
    208: {
        img: "reserve_shooter",
        eng: "Reserve Shooter",
        pol: "Rezerwista",
        sch: "预备役射手",
        fre: "Le Tireur Réserviste",
        bra: "O Atirador Reserva",
        schema: 415,
        hun: "A Tartalékos Sörétes",
        swe: "Reservskjutaren"
    },
    209: {
        img: "atomizer",
        eng: "Atomizer",
        pol: "Atomizer",
        sch: "原子能球棒",
        fre: "L'Atomiseur",
        bra: "O Atomizador",
        schema: 450,
        hun: "Az Atomizáló",
        swe: "Atomiseraren"
    },
    210: {
        img: "hard_counter",
        eng: "Hard Counter",
        pol: "Parasolka ochronna",
        sch: "坚固的雨伞帽子",
        fre: "Grands moyens",
        bra: "Para-chuva",
        schema: 254,
        hun: "Erős Ellenérv",
        swe: "Hård Kontring"
    },
    211: {
        img: "western_wear",
        eng: "Western Wear",
        pol: "Zachodni styl",
        sch: "西部圆顶礼帽",
        fre: "Usure de l'Ouest",
        bra: "Veste do Oeste",
        schema: 379,
        hun: "Vadnyugati Viselet",
        swe: "Sheriffens Stolthet"
    },
    212: {
        img: "festive_scattergun",
        eng: "Festive Scattergun",
        pol: "Świąteczna dubeltówka",
        sch: "节日猎枪",
        fre: "Fusil à dispersion festif",
        bra: "Espingarda Festiva",
        schema: 669,
        hun: "Ünnepi Repeszpuska",
        swe: "Festlig Hagelbössa"
    },
    213: {
        img: "festive_bat",
        eng: "Festive Bat",
        pol: "Świąteczny kij",
        sch: "节日球棒",
        fre: "Batte festive",
        bra: "Taco Festivo",
        schema: 660,
        hun: "Ünnepi Ütő",
        swe: "Festligt Slagträ"
    },
    214: {
        img: "festive_rocket_launcher",
        eng: "Festive Rocket Launcher",
        pol: "Świąteczna wyrzutnia rakiet",
        sch: "节日火箭发射器",
        fre: "Lance-roquettes festif",
        bra: "Lança-foguetes Festivo",
        schema: 658,
        hun: "Ünnepi Rakétavető",
        swe: "Festligt Raketgevär"
    },
    215: {
        img: "festive_flame_thrower",
        eng: "Festive Flame Thrower",
        pol: "Świąteczny miotacz ognia",
        sch: "节日火焰喷射器",
        fre: "Lance-flammes festif",
        bra: "Lança-chamas Festivo",
        schema: 659,
        hun: "Ünnepi Lángszóró",
        swe: "Festlig Eldkastare"
    },
    216: {
        img: "festive_stickybomb_launcher",
        eng: "Festive Stickybomb Launcher",
        pol: "Świąteczna wyrzutnia bomb samoprzylepnych",
        sch: "节日黏性炸弹发射器",
        fre: "Lanceur de bombes collantes festif",
        bra: "Lança-stickybombs Festivo",
        schema: 661,
        hun: "Ünnepi Tapadóbomba-vető",
        swe: "Festlig Klisterbombskastare"
    },
    217: {
        img: "festive_minigun",
        eng: "Festive Minigun",
        pol: "Świąteczny minigun",
        sch: "节日转轮机枪",
        fre: "Minigun festif",
        bra: "Metralhadora Giratória Festiva",
        schema: 654,
        hun: "Ünnepi Gépágyú",
        swe: "Festlig Minigun"
    },
    218: {
        img: "festive_wrench",
        eng: "Festive Wrench",
        pol: "Świąteczny klucz",
        sch: "节日扳手",
        fre: "Clé festive",
        bra: "Chave Inglesa Festiva",
        schema: 662,
        hun: "Ünnepi Franciakulcs",
        swe: "Festlig Skiftnyckel"
    },
    219: {
        img: "festive_medi_gun",
        eng: "Festive Medi Gun",
        pol: "Świąteczny medigun",
        sch: "节日医疗枪",
        fre: "Medigun festif",
        bra: "Arma Médica Festiva",
        schema: 663,
        hun: "Ünnepi Gyógypuska",
        swe: "Festligt Hälsovapen"
    },
    220: {
        img: "festive_sniper_rifle",
        eng: "Festive Sniper Rifle",
        pol: "Świąteczny karabin snajperski",
        sch: "节日狙击步枪",
        fre: "Fusil de sniper festif",
        bra: "Rifle de Sniper Festivo",
        schema: 664,
        hun: "Ünnepi Mesterlövész Puska",
        swe: "Festligt Krypskyttegevär"
    },
    221: {
        img: "festive_knife",
        eng: "Festive Knife",
        pol: "Świąteczny nóż",
        sch: "节日刀子",
        fre: "Couteau festif",
        bra: "Faca Festiva",
        schema: 665,
        hun: "Ünnepi Kés",
        swe: "Festlig Kniv"
    },
    222: {
        img: "wrap_assassin",
        eng: "Wrap Assassin",
        pol: "Pakujący zabójca",
        sch: "包装纸杀手",
        fre: "L'assassin emballé",
        bra: "O Assassino do Embrulho",
        schema: 648,
        hun: "A Csomagolós Gyilkos",
        swe: "Omslagsmördaren"
    },
    223: {
        img: "holiday_punch",
        eng: "Holiday Punch",
        pol: "Świąteczny wigor",
        sch: "欢乐拳套",
        fre: "Le Punch des Vacances",
        bra: "O Soco Festivo",
        schema: 656,
        hun: "Az Ünnepi Felütés",
        swe: "Julklappen"
    },
    224: {
        img: "spy_cicle",
        eng: "Spy-cicle",
        pol: "Zamrożony agent",
        sch: "神奇的冰柱",
        fre: "La Spy-lactite",
        bra: "O Spy-lactite",
        schema: 649,
        hun: "A Kém-csap",
        swe: "Smygtappen"
    },
    225: {
        img: "big_elfin_deal",
        eng: "Big Elfin Deal",
        pol: "Wyelfiona mycka",
        sch: "欢乐小精灵的帽子",
        fre: "Une Affaire elftraordinaire",
        bra: "Baita Duen-de Uma Barganha",
        schema: 652,
        hun: "A Mocskosszájú Manó",
        swe: "Tomteloftet"
    },
    226: {
        img: "bootie_time",
        eng: "Bootie Time",
        pol: "Seksowne trzewiczki",
        sch: "欢乐小精灵的鞋子",
        fre: "Le Temps des Bottines",
        bra: "As Botinhas da Hora",
        schema: 653,
        hun: "A Csilingelő Csizma",
        swe: "Stöveldags"
    },
    227: {
        img: "all_father",
        eng: "All-Father",
        pol: "Wszechojciec",
        sch: "雪白的大胡子",
        fre: "Le Père de Tout",
        bra: "Pai de Todos",
        schema: 647,
        hun: "A Mindenek Atyja",
        swe: "Allfadern"
    },
    228: {
        img: "ornament_armament",
        eng: "Ornament Armament",
        pol: "Ozdobny oręż",
        sch: "闪亮的装饰炸弹",
        fre: "L'Armement Ornemental",
        bra: "O Armamento de Ornamento",
        schema: 641,
        hun: "Az Előlgömbölős",
        swe: "Dekoreringsbeväpningen"
    },
    229: {
        img: "kringle_collection",
        eng: "Kringle Collection",
        pol: "Kolekcja Kringle'a",
        sch: "暖和的雪绒外套",
        fre: "La Collection de Kringle",
        bra: "A Coleção do Noel",
        schema: 650,
        hun: "A Téla-Pótló",
        swe: "Tomtekollektionen"
    },
    230: {
        img: "head_warmer",
        eng: "Head Warmer",
        pol: "Ocieplacz głowy",
        sch: "冬季雪绒帽",
        fre: "La Tête Brulée",
        bra: "O Aquecedor de Cabeça",
        schema: 644,
        hun: "A Fejmelengető",
        swe: "Huvudvärmaren"
    },
    231: {
        img: "jingle_belt",
        eng: "Jingle Belt",
        pol: "Obłędnie dzwoniący pas",
        sch: "雪橇响铃",
        fre: "La ceinture de clochettes",
        bra: "Toca o Cinto Pequenino",
        schema: 651,
        hun: "A Lángcsengő",
        swe: "Bjällerbältet"
    },
    232: {
        img: "sandvich_safe",
        eng: "Sandvich Safe",
        pol: "Kanapkowy sejf",
        sch: "午餐盒",
        fre: "Le Coffre à Sandvich",
        bra: "O Cofre de Sandviches",
        schema: 643,
        hun: "A Szendvics-széf",
        swe: "Smörgosskåpet"
    },
    233: {
        img: "stocking_stuffer",
        eng: "Stocking Stuffer",
        pol: "Wypchana skarpeta",
        sch: "鲜红的圣诞袜子",
        fre: "La Chaussette de Noël",
        bra: "A Meia de Natal",
        schema: 670,
        hun: "A Cuccos Zokni",
        swe: "Julsockan"
    },
    234: {
        img: "outback_intellectual",
        eng: "Outback Intellectual",
        pol: "Intelektualista z pustkowi",
        sch: "鲜艳的格子毛绒衫",
        fre: "L'intellectuel de l'arrière-pays",
        bra: "O Intelectual do Interior",
        schema: 645,
        hun: "A Vadon Értelmisége",
        swe: "Australiska Akademikern"
    },
    235: {
        img: "brown_bomber",
        eng: "Brown Bomber",
        pol: "Brązowy bombowiec",
        sch: "棕色雪地帽",
        fre: "La Bombe Brune",
        bra: "O Bombardeiro Marrom",
        schema: 671,
        hun: "A Barna Bombázó",
        swe: "Den Bruna Bombaren"
    },
    236: {
        img: "bazaar_bargain",
        eng: "Bazaar Bargain",
        pol: "Dalekosiężny dżezail",
        sch: "市场还价者",
        fre: "La Pétoire du bazar",
        bra: "A Barganha do Bazar",
        schema: 402,
        hun: "A Bazári Bizsu",
        swe: "Basarfyndet"
    },
    237: {
        img: "big_earner",
        eng: "Big Earner",
        pol: "Gruba ryba",
        sch: "暴利杀手",
        fre: "Le Grand Gagnant",
        bra: "O Grande Ganhador",
        schema: 461,
        hun: "A Nagy Hal",
        swe: "Den Stora Löntagaren"
    },
    238: {
        img: "blutsauger",
        eng: "Blutsauger",
        pol: "Blutsauger",
        sch: "吸血鬼针筒枪",
        fre: "Le Blutsauger",
        bra: "A Blutsauger",
        schema: 36,
        hun: "A Blutsauger",
        ger: "Der Blutsauger"
    },
    239: {
        img: "liberty_launcher",
        eng: "Liberty Launcher",
        pol: "Wyrzutnia wolności",
        sch: "自由推进炮",
        fre: "Le Libérateur",
        bra: "O Lançador da Liberdade",
        schema: 414,
        hun: "A Szabadságvető",
        swe: "Frihetsgeväret"
    },
    240: {
        img: "cosa_nostra_cap",
        eng: "Cosa Nostra Cap",
        pol: "Kapelusz Cosa Nostry",
        sch: "我们的事业之帽",
        fre: "Chapeau de la Cosa Nostra",
        bra: "Chapéu da Cosa Nostra",
        schema: 459,
        hun: "Cosa Nostra Kalap",
        swe: "Cosa Nostra-Hatt"
    },
    241: {
        img: "dead_ringer",
        eng: "Dead Ringer",
        pol: "Dzwon zmarłego",
        sch: "死亡之铃",
        fre: "La Dead Ringer",
        bra: "A Cópia Mortal",
        schema: 59,
        hun: "A Haláli Ketyegő",
        swe: "Dödsringaren"
    },
    242: {
        img: "submachine_gun",
        eng: "SMG",
        pol: "PM",
        sch: "SMG",
        fre: "Mitraillette",
        bra: "Submetralhadora",
        schema: 16,
        hun: "géppisztoly",
        swe: "Kpist"
    },
    243: {
        img: "quick_fix",
        eng: "Quick-Fix",
        pol: "Quick-Fix",
        sch: "快速医疗枪",
        fre: "Le Secou'rapide",
        bra: "A Quebra-galho",
        schema: 411,
        hun: "A Gyorsbeavatkozás",
        swe: "Snabbfixaren"
    },
    244: {
        img: "killing_gloves_of_boxing",
        eng: "Killing Gloves of Boxing",
        pol: "Krytyczne grzmoty bokserskie",
        sch: "杀戮拳击手套",
        fre: "Les Kamarades Gants de Boxe",
        bra: "Os Kríticos Garantidos no Boxe",
        schema: 43,
        hun: "A Kimondottan Galád Bokszkesztyűk",
        swe: "Kalinins Grymma Boxningshandskar"
    },
    245: {
        img: "flamboyant_flamenco",
        eng: "Flamboyant Flamenco",
        pol: "Fatalne flamenco",
        sch: "华丽的弗拉门戈帽",
        fre: "Le Flamenco Flamboyant",
        bra: "O Flamenco Extravagante",
        schema: 627,
        hun: "A Felvágós Flamenco",
        swe: "Den Flambojanta Flamencon"
    },
    246: {
        img: "reggaelator",
        eng: "Reggaelator",
        pol: "Reggaelator",
        sch: "雷鬼发型",
        fre: "Reggaelateur",
        bra: "Reggaeador",
        schema: 390,
        hun: "Reggaeláló"
    },
    247: {
        img: "overdose",
        eng: "Overdose",
        pol: "Końska dawka",
        sch: "过载注射枪",
        fre: "L'Overdose",
        bra: "A Overdose",
        schema: 412,
        hun: "A Túladagoló",
        swe: "Överdoseringen"
    },
    248: {
        img: "a_manns_mint",
        eng: "A Mann's Mint",
        pol: "Mięta Manna",
        sch: "曼恩公司薄荷色",
        fre: "Une menthe Mann",
        bra: "Menta de Macho",
        schema: 5076,
        hun: "Mann Mentája",
        swe: "En Manns Mint"
    },
    249: {
        img: "after_eight",
        eng: "After Eight",
        pol: "Po ósmej",
        sch: "晚八点",
        fre: "After Eight",
        bra: "Depois das Oito",
        schema: 5077,
        swe: "Efter Åtta"
    },
    250: {
        img: "salty_dog",
        eng: "Salty Dog",
        pol: "Wilk morski",
        sch: "水手老兵的帽子",
        fre: "Le Loup de Mer",
        bra: "O Lobo do Mar",
        schema: 611,
        hun: "A Vén Tengerész",
        swe: "Erfarna Seglaren"
    },
    251: {
        img: "widowmaker",
        eng: "Widowmaker",
        pol: "Likwidator",
        sch: "寡妇制造者",
        fre: "Le Faiseur de Veuves",
        bra: "A Viuvadora",
        schema: 527,
        schema2: 5757,
        hun: "A Widowmaker",
        swe: "Änkmakaren"
    },
    252: {
        img: "mantreads",
        eng: "Mantreads",
        pol: "Miażdżyciele",
        sch: "踏步靴",
        fre: "Les Bottes de Combat",
        bra: "Os Coturnos de Macho",
        schema: 444,
        hun: "Az Arctaposó",
        swe: "Manstövlarna"
    },
    253: {
        img: "sapper",
        eng: "Sapper",
        pol: "Saper",
        sch: "电子工兵",
        fre: "Saboteur",
        bra: "Sabotador",
        schema: 736,
        hun: "Mentesítő"
    },
    254: {
        img: "bat",
        eng: "Bat",
        pol: "Kij",
        sch: "短棍",
        fre: "Batte",
        bra: "Taco",
        schema: 190,
        hun: "ütő",
        swe: "Slagträ"
    },
    255: {
        img: "diamondback",
        eng: "Diamondback",
        pol: "Grzechotnik",
        sch: "菱背响尾蛇",
        fre: "Le Diamondback",
        bra: "O Diamante Bruto",
        schema: 525,
        hun: "A Diamondback",
        ger: "Der Diamondback"
    },
    256: {
        img: "strange_part_gib_kills",
        eng: "Strange Part: Gib Kills",
        pol: "Kuriozalna część: Rozczłonkowani wrogowie",
        sch: "奇异武器升级部件：炸碎敌人数",
        fre: "Pièce étrange : Mises en pièce",
        bra: "Peça Estranha: Explosões Fatais",
        schema: 6013,
        hun: "Fura Alkatrész: Cafatokra robbantások",
        swe: "Märklig Del: Fiender sprängda i småbitar"
    },
    257: {
        img: "strange_part_airborne_enemies_killed",
        eng: "Strange Part: Airborne Enemies Killed",
        pol: "Kuriozalna część: Wrogowie zabici w powietrzu",
        sch: "奇异武器升级部件：空中敌人击杀数",
        fre: "Pièce étrange : Ennemis dans les airs tués",
        bra: "Peça Estranha: Inimigos Mortos no Ar",
        schema: 6012,
        hun: "Fura Alkatrész: Megölt repülő ellenfelek",
        swe: "Märklig Del: Luftburna fiender dödade"
    },
    258: {
        img: "strange_part_heavies_killed",
        eng: "Strange Part: Heavies Killed",
        pol: "Kuriozalna część: Zabici Grubi",
        sch: "奇异武器升级部件：机枪手消灭数",
        fre: "Pièce étrange : Heavies tués",
        bra: "Peça Estranha: Heavies Mortos",
        schema: 6000,
        hun: "Fura Alkatrész: Megölt Gépágyúsok",
        swe: "Märklig Del: Tunga Artillerister dödade"
    },
    259: {
        img: "crocodile_smile",
        eng: "Crocodile Smile",
        pol: "Krokodyli uśmiech",
        sch: "鳄鱼牙项链",
        fre: "Le Sourire du Crocodile",
        bra: "As Lágrimas de Crocodilo",
        schema: 618,
        hun: "A Krokodilmosoly",
        swe: "Krokodilsmilet"
    },
    260: {
        img: "large_luchadore",
        eng: "Large Luchadore",
        pol: "Ogromny luchador",
        sch: "摔角手面具",
        fre: "Masque de Luchador",
        bra: "Grande Luchadore",
        schema: 380,
        hun: "A Nagy Luchadore",
        swe: "Stor Skråpuk"
    },
    261: {
        img: "bottle",
        eng: "Bottle",
        pol: "Butelka",
        sch: "瓶子",
        fre: "Bouteille",
        bra: "Garrafa",
        schema: 191,
        hun: "palack",
        swe: "Flaska"
    },
    262: {
        img: "strange_part_buildings_destroyed",
        eng: "Strange Part: Buildings Destroyed",
        pol: "Kuriozalna część: Zniszczone konstrukcje",
        sch: "奇异武器升级部件：建筑摧毁数",
        fre: "Pièce étrange : Bâtiments détruits",
        bra: "Peça Estranha: Construções Destruídas",
        schema: 6009,
        hun: "Fura Alkatrész: Lerombolt építmények",
        swe: "Märklig Del: Byggnader förstörda"
    },
    263: {
        img: "strange_part_projectiles_reflected",
        eng: "Strange Part: Projectiles Reflected",
        pol: "Kuriozalna część: Odbite pociski",
        sch: "奇异武器升级部件：反弹次数",
        fre: "Pièce étrange : Projectiles renvoyés",
        bra: "Peça Estranha: Projéteis Refletidos",
        schema: 6010,
        hun: "Fura Alkatrész: Visszafújt lövedékek",
        swe: "Märklig Del: Projektiler reflekterade"
    },
    264: {
        img: "strange_part_headshot_kills",
        eng: "Strange Part: Headshot Kills",
        pol: "Kuriozalna część: Zabici strzałami w głowę",
        sch: "奇异武器升级部件：爆头数",
        fre: "Pièce étrange : Frags par tir en pleine tête",
        bra: "Peça Estranha: Tiros na Cabeça Fatais",
        schema: 6011,
        hun: "Fura Alkatrész: Fejlövéses ölések",
        swe: "Märklig del: Dödliga huvudskott"
    },
    265: {
        img: "outdoorsman",
        eng: "Outdoorsman",
        pol: "Odludek",
        sch: "伐木工帽子",
        fre: "L'homme d'extérieur",
        bra: "O Lenhador",
        schema: 603,
        hun: "Az Erdőkerülő",
        swe: "Friluftsmänniskan"
    },
    266: {
        img: "ol_geezer",
        eng: "Ol' Geezer",
        pol: "Ol' Geezer",
        sch: "老式牛仔帽",
        fre: "Vieu' Schnock",
        bra: "Ol' Geezer",
        schema: 399,
        hun: "Vén Szivar",
        swe: "Gamle Gubbe"
    },
    267: {
        img: "tribalmans_shiv",
        eng: "Tribalman's Shiv",
        pol: "Plemienne ostrze",
        sch: "部落者刮刀",
        fre: "Le Couteau Aborigène",
        bra: "O Estoque Tribal",
        schema: 171,
        hun: "A Törzsi Bökő",
        swe: "Stamherrens Handgjorda Kniv"
    },
    268: {
        img: "detonator",
        eng: "Detonator",
        pol: "Detonator",
        sch: "起爆者",
        fre: "Le Détonateur",
        bra: "A Detonadora",
        schema: 351,
        hun: "A Detonátor",
        swe: "Detonatorn"
    },
    269: {
        img: "strange_part_allies_extinguished",
        eng: "Strange Part: Teammates Extinguished",
        pol: "Kuriozalna część: Ugaszeni sojusznicy",
        sch: "奇异武器升级部件：队友灭火数",
        fre: "Pièce étrange : coéquipiers en feu éteints",
        bra: "Peça Estranha: Aliados em Chamas Apagados",
        schema: 6020,
        hun: "Fura Alkatrész: Eloltott csapattársak",
        swe: "Märklig Del: Lagkamrater släckta"
    },
    270: {
        img: "strange_part_posthumous_kills",
        eng: "Strange Part: Posthumous Kills",
        pol: "Kuriozalna część: Zabójstwa zza grobu",
        sch: "奇异武器升级部件：死后杀敌数",
        fre: "Pièce étrange : Victimes posthumes",
        bra: "Peça Estranha: Vítimas Póstumas",
        schema: 6019,
        hun: "Fura Alkatrész: Posztumusz ölések",
        swe: "Märklig Del: Dödade efter du dött"
    },
    271: {
        img: "market_gardener",
        eng: "Market Gardener",
        pol: "Ogrodnik z Arnhem",
        sch: "市场花园铁锹",
        fre: "Le Jardinier",
        bra: "O Pá-raquedista",
        schema: 416,
        hun: "A Gerillakertész",
        swe: "Trädgårdsmästaren"
    },
    272: {
        img: "strange_part_critical_kills",
        eng: "Strange Part: Critical Kills",
        pol: "Kuriozalna część: Zabójstwa krytyczne",
        sch: "奇异武器升级部件：爆击杀敌数",
        fre: "Pièce étrange : Victimes par coup critique",
        bra: "Peça Estranha: Críticos Fatais",
        schema: 6021,
        hun: "Fura Alkatrész: Kritikus ölések",
        swe: "Märklig Del: Dödade med Kritiska Träffar"
    },
    273: {
        img: "strange_part_demomen_killed",
        eng: "Strange Part: Demomen Killed",
        pol: "Kuriozalna część: Zabici Demomani",
        sch: "奇异武器升级部件：爆破手消灭数",
        fre: "Pièce étrange : Demomen tués",
        bra: "Peça Estranha: Demomen Mortos",
        schema: 6001,
        hun: "Fura Alkatrész: Megölt Robbantósok",
        swe: "Märklig Del: Demomän dödade"
    },
    274: {
        img: "strange_part_revenge_kills",
        eng: "Strange Part: Revenge Kills",
        pol: "Kuriozalna część: Zabójstwa odwetowe",
        sch: "奇异武器升级部件：复仇数",
        fre: "Pièce étrange : Victimes par vengeance",
        bra: "Peça Estranha: Mortes Vingadoras",
        schema: 6018,
        hun: "Fura Alkatrész: Bosszú ölések",
        swe: "Märklig Del: Hämndmord"
    },
    275: {
        img: "hat_with_no_name",
        eng: "Hat With No Name",
        pol: "Kapelusz bez imienia",
        sch: "无名之帽",
        fre: "Le Chapeau Sans Nom",
        bra: "O Chapéu Sem Nome",
        schema: 631,
        hun: "A Névtelen Kalap",
        swe: "Hatten Utan Namn"
    },
    276: {
        img: "scotsmans_skullcutter",
        eng: "Scotsman's Skullcutter",
        pol: "Czerepołamacz Szkota",
        sch: "苏格兰颅骨切割者",
        fre: "La Guillotine Écossaise",
        bra: "O Corta-crânios Escocês",
        schema: 172,
        hun: "A Skót Fejtörő",
        swe: "Skottens Skallskärare"
    },
    277: {
        img: "persian_persuader",
        eng: "Persian Persuader",
        pol: "Perska perswazja",
        sch: "波斯弯刀",
        fre: "La persuasion persane",
        bra: "O Persuasor Persa",
        schema: 404,
        hun: "A Perzsa Penge",
        swe: "Österländska Övertalaren"
    },
    278: {
        img: "strange_part_soldiers_killed",
        eng: "Strange Part: Soldiers Killed",
        pol: "Kuriozalna część: Zabici Żołnierze",
        sch: "奇异武器升级部件：士兵消灭数",
        fre: "Pièce étrange : Soldiers tués",
        bra: "Peça Estranha: Soldiers Mortos",
        schema: 6002,
        hun: "Fura Alkatrész: Megölt Katonák",
        swe: "Märklig Del: Soldiers dödade"
    },
    279: {
        img: "strange_part_domination_kills",
        eng: "Strange Part: Domination Kills",
        pol: "Kuriozalna część: Zabójstwa dominacyjne",
        sch: "奇异武器升级部件：控制数",
        fre: "Pièce étrange : Victimes dominées",
        bra: "Peça Estranha: Mortes Dominadoras",
        schema: 6016,
        hun: "Fura Alkatrész: Alázó ölések",
        swe: "Märklig Del: Domineringsmord"
    },
    280: {
        img: "strange_part_kills_while_explosive_jumping",
        eng: "Strange Part: Kills While Explosive Jumping",
        pol: "Kuriozalna część: Zabójstwa podczas skoku na eksplozji",
        sch: "奇异武器升级部件：爆炸起跳空中杀敌数",
        fre: "Pièce étrange : Victimes lors de sauts propulsés",
        bra: "Peça Estranha: Saltos Explosivos Fatais",
        schema: 6022,
        hun: "Fura Alkatrész: Ölések robbanó-ugrás közben",
        swe: "Märklig Del: Dödade under Explosionshopp"
    },
    281: {
        img: "tavish_degroot_experience",
        eng: "Tavish DeGroot Experience",
        pol: "Tavish DeGroot Experience",
        sch: "塔维什德格罗特的宽边帽",
        fre: "L'expérience Tavish DeGroot",
        bra: "A Experiência do Tavish DeGroot",
        schema: 604,
        hun: "A Tavish DeGroot-érzés",
        swe: "Tavish DeGroot-Upplevelsen"
    },
    282: {
        img: "lollichop",
        eng: "Lollichop",
        pol: "Lizasiek",
        sch: "锋利的波板糖",
        fre: "La Sussache",
        bra: "O Pyrolito",
        schema: 739,
        hun: "A Szelőke",
        swe: "Slickepinan"
    },
    283: {
        img: "rainblower",
        eng: "Rainblower",
        pol: "Tęczomiot",
        sch: "彩虹喷灯",
        fre: "Le Chasse-nuages",
        bra: "O Lançarco-íris",
        schema: 741,
        hun: "A Szivármány",
        swe: "Regnblåsaren"
    },
    284: {
        img: "apparatchiks_apparel",
        eng: "Apparatchik's Apparel",
        pol: "Koszula komucha",
        sch: "党僚的服饰",
        fre: "Les Vêtements d'Apparatchik",
        bra: "A Vestimenta do Vereador",
        schema: 777,
        hun: "Az Apparátcsik Álma",
        swe: "Byråkratens Bomullsskjorta"
    },
    285: {
        img: "bird_man_of_aberdeen",
        eng: "Bird-Man of Aberdeen",
        pol: "Ptasznik z Aberdeen",
        sch: "阿伯丁的鸟人",
        fre: "L'Homme-Oiseau d'Aberdeen",
        bra: "O Homem de Aberdeen",
        schema: 776,
        hun: "Az Aberdeeni Madárember",
        swe: "Fågelkännaren från Aberdeen"
    },
    286: {
        img: "business_casual",
        eng: "Business Casual",
        pol: "Luźny biznes",
        sch: "商务休闲衫",
        fre: "Le Businessman Détendu",
        bra: "O Traje Casual",
        schema: 782,
        hun: "A Laza Üzlet",
        swe: "Vardagliga Kontoristen"
    },
    287: {
        img: "captains_cocktails",
        eng: "Captain's Cocktails",
        pol: "Koktajle kapitana",
        sch: "丐帮头头的罐头盒",
        fre: "Les Cocktails du Capitaine",
        bra: "Os Coquetéis do Capitão",
        schema: 731,
        hun: "A Kapitány Koktéljai",
        swe: "Kaptenens Konserver"
    },
    288: {
        img: "dillingers_duffel",
        eng: "Dillinger's Duffel",
        pol: "Dorobek Dillingera",
        sch: "狄林杰的粗花呢布袋",
        fre: "Sac de Dillinger",
        bra: "A Mala do Marginal",
        schema: 781,
        hun: "Dillinger Utazótáskája",
        swe: "Vågarens Väska"
    },
    289: {
        img: "fed_fightin_fedora",
        eng: "Fed-Fightin' Fedora",
        pol: "Antyfederalna fedora",
        sch: "联邦探员打败者",
        fre: "Le Fedora du Combat des Fédéraux",
        bra: "O Fedora Furado",
        schema: 780,
        hun: "A Kopókapó Kalap",
        swe: "Federal-fajtande Fedora"
    },
    290: {
        img: "gentlemans_ushanka",
        eng: "Gentleman's Ushanka",
        pol: "Uszanka dżentelmena",
        sch: "绅士的红军帽",
        fre: "L'Ushanka du Gentilhomme",
        bra: "A Ushanka do Cavalheiro",
        schema: 778,
        hun: "Az Úriember Usankája",
        swe: "Gentlemannens Usjanka"
    },
    291: {
        img: "hazmat_headcase",
        eng: "HazMat Headcase",
        pol: "Gorąca głowa",
        sch: "危爆品处理面罩",
        fre: "Le casque Hazmat",
        bra: "O Capacete Contra Coisas Corrosivas",
        schema: 783,
        hun: "A Vegyvédelmis Veszedelem",
        swe: "Skyddsdräktshjälmen"
    },
    292: {
        img: "helmet_without_a_home",
        eng: "Helmet Without a Home",
        pol: "Kask kloszarda",
        sch: "无家可盔",
        fre: "Le Casque Sans Foyer",
        bra: "O Capacete sem Lar",
        schema: 732,
        hun: "A Hajléktalan Homloktakaró",
        swe: "Hemlösa Hjälmen"
    },
    293: {
        img: "idea_tube",
        eng: "Idea Tube",
        pol: "Pomysłowy tubus",
        sch: "思想管",
        fre: "Le Tube à idées",
        bra: "O Porta-ideias",
        schema: 784,
        hun: "Az Ötletcső",
        swe: "Idéröret"
    },
    294: {
        img: "liquidators_lid",
        eng: "Liquidator's Lid",
        pol: "Leon likwidator",
        sch: "清算人的帽子",
        fre: "Le Galurin du Liquidateur",
        bra: "O Exterminador Estiloso",
        schema: 779,
        hun: "A Felszámoló Fejrevalója",
        swe: "Likviderarens Luva"
    },
    295: {
        img: "balloonicorn",
        eng: "Balloonicorn",
        pol: "Balonorożec",
        sch: "气球独角兽",
        fre: "La Ballicorne",
        bra: "O Balãonicórnio",
        schema: 738,
        hun: "A Lufikornis",
        swe: "Ballonghörningen"
    },
    296: {
        img: "infernal_orchestrina",
        eng: "Infernal Orchestrina",
        pol: "Piekielna pozytywka",
        sch: "炼狱管弦乐",
        fre: "Orgue de Barbarie",
        bra: "Orquestrina Infernal",
        schema: 745,
        hun: "Pokoli Zenegép",
        swe: "Infernalisk Pianola"
    },
    297: {
        img: "tomislav",
        eng: "Tomislav",
        pol: "Tomisław",
        sch: "汤姆斯拉夫",
        fre: "Tomislav",
        bra: "Tomislav",
        schema: 424,
        hun: "Tomiszlav"
    },
    298: {
        img: "strange_part_scouts_killed",
        eng: "Strange Part: Scouts Killed",
        pol: "Kuriozalna część: Zabici Skauci",
        sch: "奇异武器升级部件：侦察兵消灭数",
        fre: "Pièce étrange : Scouts tués",
        bra: "Peça Estranha: Scouts Mortos",
        schema: 6003,
        hun: "Fura Alkatrész: Megölt Felderítők",
        swe: "Märklig Del: Scouts dödade"
    },
    299: {
        img: "strange_part_sappers_destroyed",
        eng: "Strange Part: Sappers Destroyed",
        pol: "Kuriozalna część: Zniszczone sapery",
        sch: "奇异武器升级部件：电子工兵摧毁数",
        fre: "Pièce étrange : Saboteurs détruits",
        bra: "Peça Estranha: Sabotadores Removidos",
        schema: 6025,
        hun: "Fura Alkatrész: Elpusztított mentesítők",
        swe: "Märklig Del: Sappers borttagna"
    },
    300: {
        img: "strange_part_cloaked_spies_killed",
        eng: "Strange Part: Cloaked Spies Killed",
        pol: "Kuriozalna część: Zabici niewidzialni Szpiedzy",
        sch: "奇异武器升级部件：隐形间谍消灭数",
        fre: "Pièce étrange : Spies invisibles tués",
        bra: "Peça Estranha: Spies Camuflados Mortos",
        schema: 6024,
        hun: "Fura Alkatrész: Megölt álcázott Kémek",
        swe: "Märklig Del: Dolda Spioner dödade"
    },
    301: {
        img: "backwards_ballcap",
        eng: "Backwards Ballcap",
        pol: "Bejsbolówka buntownika",
        sch: "反戴的棒球帽",
        fre: "La casquette à l'envers",
        bra: "O Boné pra Trás",
        schema: 617,
        hun: "A Lázadó Labdajátékos",
        swe: "Bakåtvända Bollkepsen"
    },
    302: {
        img: "koala_compact",
        eng: "Koala Compact",
        pol: "Kompaktowy koala",
        sch: "口袋考拉",
        fre: "Le Koala Compact",
        bra: "O Coala Compacto",
        schema: 824,
        hun: "A Koalatartó",
        swe: "Den Kompakta Koalan"
    },
    303: {
        img: "soviet_gentleman",
        eng: "Soviet Gentleman",
        pol: "Radziecki dżentelmen",
        sch: "苏维埃绅士",
        fre: "Le Gentleman Soviétique",
        bra: "O Cavalheiro Soviético",
        schema: 821,
        hun: "A Szovjet Úriember",
        swe: "Den Sovjetiska Gentlemannen"
    },
    304: {
        img: "pocket_purrer",
        eng: "Pocket Purrer",
        pol: "Kieszonkowy kiciuś",
        sch: "口袋猫猫",
        fre: "Le Ronronneur de Poche",
        bra: "O Felino Fofinho",
        schema: 823,
        hun: "A Zsebdoromboló",
        swe: "Fickspinnaren"
    },
    305: {
        img: "hat_of_cards",
        eng: "Hat of Cards",
        pol: "Czapka z kart",
        sch: "纸牌骗术师",
        fre: "Chapeau de Cartes",
        bra: "Chapéu de Cartas",
        schema: 825,
        hun: "A Kártyász",
        swe: "Korthatt"
    },
    306: {
        img: "medi_mask",
        eng: "Medi-Mask",
        pol: "Med-maska",
        sch: "防毒面具",
        fre: "Medi-Masque",
        bra: "Medi-máscara",
        schema: 826,
        hun: "Gyógymaszk",
        swe: "Sjukskyddet"
    },
    307: {
        img: "track_terrorizer",
        eng: "Track Terrorizer",
        pol: "Bezecnik z bieżni",
        sch: "跟踪狂",
        fre: "La Terreur des Pistes",
        bra: "O Terror das Pistas",
        schema: 827,
        hun: "A Pálya Ördöge",
        swe: "Löparplågan"
    },
    308: {
        img: "war_pig",
        eng: "War Pig",
        pol: "Świnia wojny",
        sch: "战场豪猪",
        fre: "Le Porc de Guerre",
        bra: "O Porco de Guerra",
        schema: 829,
        hun: "A Harcátlan",
        swe: "Krigsgrisen"
    },
    309: {
        img: "bearded_bombardier",
        eng: "Bearded Bombardier",
        pol: "Brodaty bombardier",
        sch: "炸弹兵的花白胡须",
        fre: "Le Bombardier Barbu",
        bra: "O Bombardeiro Barbudo",
        schema: 830,
        hun: "A Szakállas Tüzér",
        swe: "Den Skäggiga Sprängaren"
    },
    310: {
        img: "pyrotechnic_tote",
        eng: "Pyrotechnic Tote",
        pol: "Torba pirotechnika",
        sch: "烟火背包",
        fre: "Le Fourre-tout Pyrotechnique",
        bra: "A Sacola Pirotécnica",
        schema: 856,
        hun: "A Pirotechnikus Tatyója",
        swe: "Den Pyrotekniska Väskan"
    },
    311: {
        img: "robro_3000",
        eng: "RoBro 3000",
        pol: "RoBro 3000",
        sch: "机器伙伴 3000",
        fre: "Le RoBro 3000",
        bra: "O RoBróder 3000",
        schema: 733,
        hun: "A RoboTesó 3000",
        ger: "Der RoBro 3000"
    },
    312: {
        img: "shovel",
        eng: "Shovel",
        pol: "Saperka",
        sch: "铁锹",
        fre: "Pelle",
        bra: "Pá",
        schema: 196,
        hun: "ásó",
        swe: "Skyffel"
    },
    313: {
        img: "strange_part_engineers_killed",
        eng: "Strange Part: Engineers Killed",
        pol: "Kuriozalna część: Zabici Inżynierowie",
        sch: "奇异武器升级部件：工程师消灭数",
        fre: "Pièce étrange : Engineers tués",
        bra: "Peça Estranha: Engineers Mortos",
        schema: 6004,
        hun: "Fura Alkatrész: Megölt Mérnökök",
        swe: "Märklig Del: Engineers dödade"
    },
    314: {
        img: "strange_part_robots_destroyed",
        eng: "Strange Part: Robots Destroyed",
        pol: "Kuriozalna część: Zniszczone roboty",
        sch: "奇异武器升级部件：机器人消灭数",
        fre: "Pièce étrange : Robots détruits",
        bra: "Peça Estranha: Robôs Destruídos",
        schema: 6026,
        hun: "Fura Alkatrész: Elpusztított robotok",
        swe: "Märklig Del: Robotar förstörda"
    },
    315: {
        img: "strange_part_low_health_kills",
        eng: "Strange Part: Low-Health Kills",
        pol: "Kuriozalna część: Zabójstwa z niskim stanem zdrowia",
        sch: "奇异武器升级部件：残血状态杀敌数",
        fre: "Pièce étrange : Nombre de victimes en ayant une santé faible",
        bra: "Peça Estranha: Vítimas Enquanto com Pouca Vida",
        schema: 6032,
        hun: "Fura Alkatrész: Ölések alacsony életerőnél",
        swe: "Märklig Del: Dödade vid låg hälsa"
    },
    316: {
        img: "surgeons_stahlhelm",
        eng: "Surgeon's Stahlhelm",
        pol: "Stalowy hełm chirurga",
        sch: "外科医生的钢盔",
        fre: "Le Stahlhelm de Chirurgien",
        bra: "Stahlhelm do Cirurgião",
        schema: 616,
        hun: "A Sebészi Sisak",
        swe: "Kirurgens Krigshjälm"
    },
    317: {
        img: "furious_fukaamigasa",
        eng: "Furious Fukaamigasa",
        pol: "Fukaamigasa furiata",
        sch: "愤怒者的斗笠",
        fre: "Furieux Fukaamigasa",
        bra: "Fukaamigasa Furioso",
        schema: 395,
        hun: "Dühös Fukaamigasa",
        swe: "Förbannad Fukaamigasa"
    },
    318: {
        img: "strange_bacon_grease",
        eng: "Strange Bacon Grease",
        pol: "Kuriozalna słonina",
        sch: "奇异猪油",
        fre: "Graisse de bacon étrange",
        bra: "Banha Estranha",
        schema: 5633,
        hun: "Fura Szalonnazsír",
        swe: "Märkligt baconfett"
    },
    319: {
        img: "construction_pda",
        eng: "Construction PDA",
        pol: "PDA budowy",
        sch: "建造工具",
        fre: "PDA de Construction",
        bra: "PDA de Construção",
        schema: 737,
        hun: "Építő PDA",
        swe: "Bygg-PDA"
    },
    320: {
        img: "original",
        eng: "Original",
        pol: "Pierwowzór",
        sch: "正宗雷神之锤火箭发射器",
        fre: "L'Original",
        bra: "O Original",
        schema: 513,
        hun: "Az Eredeti",
        swe: "Originalet"
    },
    321: {
        img: "invis_watch",
        eng: "Invis Watch",
        pol: "Zegarek niewidzialności",
        sch: "隐形手表",
        fre: "Montre d'invisibilité",
        bra: "Relógio de Invisibilidade",
        schema: 212,
        hun: "átlátsz-óra",
        swe: "Osynlighetsklocka"
    },
    322: {
        img: "splendid_screen",
        eng: "Splendid Screen",
        pol: "Prześwietny puklerz",
        sch: "辉煌护盾",
        fre: "Le Mirage Éblouissant",
        bra: "O Escudo Esplêndido",
        schema: 406,
        hun: "A Pompás Pajzs",
        swe: "Det Strålande Skyddet"
    },
    323: {
        img: "sir_hootsalot",
        eng: "Sir Hootsalot",
        pol: "Kapitan Sowa",
        sch: "夜行者爵士",
        fre: "Sire Hululalot",
        bra: "Sr. Corujão",
        schema: 917,
        hun: "Sir Huhogi"
    },
    324: {
        img: "master_mind",
        eng: "Master Mind",
        pol: "Umysł majstra",
        sch: "超级大脑",
        fre: "L'esprit du maître",
        bra: "A Mente Mestra",
        schema: 918,
        hun: "Az Agymester",
        swe: "Geniet"
    },
    325: {
        img: "rump_o_lantern",
        eng: "Rump-o'-Lantern",
        pol: "Halloweenowe wsparcie duchowe",
        sch: "遮臀南瓜灯",
        fre: "La Lantern-o-croupion",
        bra: "O Lampião Traseiro",
        schema: 869,
        hun: "A Tök Alsó",
        swe: "Rumplyktan"
    },
    326: {
        img: "scarecrow",
        eng: "Scarecrow",
        pol: "Strach na wróble",
        sch: "稻草人",
        fre: "L'Épouvantail",
        bra: "O Espantalho",
        schema: 919,
        hun: "A Madárijesztő",
        swe: "Fågelskrämman"
    },
    327: {
        img: "crones_dome",
        eng: "Crone's Dome",
        pol: "Kapelusz wiedźmy",
        sch: "巫婆的尖帽",
        fre: "Le Chapeau de sorcière",
        bra: "O Chapéu do Mausoléu",
        schema: 920,
        hun: "A Banya Búrája",
        swe: "Kärringens Hatt"
    },
    328: {
        img: "executioner",
        eng: "Executioner",
        pol: "Spojrzenie kata",
        sch: "蒙面刽子手",
        fre: "Le Bourreau",
        bra: "O Carrasco",
        schema: 921,
        hun: "A Hóhér",
        swe: "Avrättaren"
    },
    329: {
        img: "bonedolier",
        eng: "Bonedolier",
        pol: "Wybuchowa czacha",
        sch: "骨弹带",
        fre: "L'Osselière",
        bra: "A Cranioleira",
        schema: 922,
        hun: "A Robbanófej",
        swe: "Benbandet"
    },
    330: {
        img: "plutonidome",
        eng: "Plutonidome",
        pol: "Płynny potencjał",
        sch: "诡异脑袋",
        fre: "Le Plutoniheaume",
        bra: "O Plutonidomo",
        schema: 923,
        hun: "A Plutónibúra",
        swe: "Plutonidomen"
    },
    331: {
        img: "spooky_shoes",
        eng: "Spooky Shoes",
        pol: "Trupie trampki",
        sch: "骷髅长靴",
        fre: "Les sinistres chaussures",
        bra: "As Meias Macabras",
        schema: 924,
        hun: "A Rémisztő Cipők",
        swe: "Spökliga Skorna"
    },
    332: {
        img: "spooky_sleeves",
        eng: "Spooky Sleeves",
        pol: "Trupie rękawy",
        sch: "骷髅夹克",
        fre: "Les sinistres manchettes",
        bra: "As Mangas Macabras",
        schema: 925,
        hun: "A Rémisztő Ruhaujjak",
        swe: "Spökliga Ärmarna"
    },
    333: {
        img: "zipperface",
        eng: "Zipperface",
        pol: "Twarz na zamek błyskawiczny",
        sch: "去骨拉链",
        fre: "Le Crâneur",
        bra: "O Cara de Zíper",
        schema: 926,
        hun: "A Cipzárarc",
        swe: "Dragkedjeansiktet"
    },
    334: {
        img: "boo_balloon",
        eng: "Boo Balloon",
        pol: "Złowieszczy balonik",
        sch: "幽灵气球",
        fre: "Le ballon de boo-druche",
        bra: "O Abobalão",
        schema: 927,
        hun: "A Juj-lufi",
        swe: "Bu-Ballongen"
    },
    335: {
        img: "unknown_monkeynaut",
        eng: "Unknown Monkeynaut",
        pol: "Nieznany małponauta",
        sch: "无名的宇航猴",
        fre: "Le singenaute inconnu",
        bra: "O Macaconauta Desconhecido",
        schema: 929,
        hun: "Az Ismeretlen Majomnauta",
        swe: "Den Okända Apanauten"
    },
    336: {
        img: "grand_duchess_tutu",
        eng: "Grand Duchess Tutu",
        pol: "Tutu wielkiej księżnej",
        sch: "大公夫人的芭蕾舞短裙",
        fre: "Le tutu de la Grande Duchesse",
        bra: "O Tutu da Grã-duquesa",
        schema: 930,
        hun: "A Nagyhercegnői Tütü",
        swe: "Storhertiginnans Balettkjol"
    },
    337: {
        img: "grand_duchess_fairy_wings",
        eng: "Grand Duchess Fairy Wings",
        pol: "Baśniowe skrzydła wielkiej księżnej",
        sch: "大公夫人的小仙女翅膀",
        fre: "Les ailes féeriques de la Grande Duchesse",
        bra: "As Asas de Fada da Grã-duquesa",
        schema: 931,
        hun: "A Nagyhercegnői Tündérszárnyak",
        swe: "Storhertiginnans Fevingar"
    },
    338: {
        img: "grand_duchess_tiara",
        eng: "Grand Duchess Tiara",
        pol: "Diadem wielkiej księżnej",
        sch: "大公夫人的宝冠",
        fre: "Le diadème de la Grande Duchesse",
        bra: "A Tiara da Grã-duquesa",
        schema: 932,
        hun: "A Nagyhercegnői Diadém",
        swe: "Storhertiginnans Tiara"
    },
    339: {
        img: "dead_little_buddy",
        eng: "Dead Little Buddy",
        pol: "Mały martwy kumpel",
        sch: "玩伴小幽灵",
        fre: "Le petit pote mort",
        bra: "O Fantasminha Camarada",
        schema: 934,
        hun: "A Kis Halott Haver",
        swe: "Död Liten Kompis"
    },
    340: {
        img: "exorcizor",
        eng: "Exorcizor",
        pol: "Koloratka egzorcysty",
        sch: "驱魔人",
        fre: "L'Exorciseur",
        bra: "O Exorcista",
        schema: 936,
        hun: "Az Ördögiűző",
        swe: "Exercisten"
    },
    341: {
        img: "voodoo_juju_slight_return",
        eng: "Voodoo JuJu (Slight Return)",
        pol: "Juju voodoo (skromny powrót)",
        sch: "巫毒符咒（微小的回报）",
        fre: "Le JuJu vaudou (petit retour)",
        bra: "O Vudu Juju (O Retorno)",
        schema: 935,
        hun: "A Vudu JuJu (Kis visszatérés)",
        rom: "The Voodoo JuJu (Întoarcere aproximativă)",
        swe: "Voodoo JuJu (Igen)"
    },
    342: {
        img: "wraith_wrap",
        eng: "Wraith Wrap",
        pol: "Kaptur krematora",
        sch: "幽灵兜帽",
        fre: "Le Châle Spectral",
        bra: "O Capuz do Espectro",
        schema: 937,
        hun: "A Lidérccsuklya",
        swe: "Vålnadsluvan"
    },
    343: {
        img: "coffin_kit",
        eng: "Coffin Kit",
        pol: "Zestaw trumniarza",
        sch: "求死背包",
        fre: "Le Cercueil à dos",
        bra: "O Zé do Caixão",
        schema: 938,
        hun: "A Koporsó Készlet",
        swe: "Kistkitet"
    },
    344: {
        img: "bat_outta_hell",
        eng: "Bat Outta Hell",
        pol: "Piekielna pałka",
        sch: "恐怖骸骨",
        fre: "La Batte des Enfers",
        bra: "O Tacom Demônio",
        schema: 939,
        hun: "A Pokoli Ütő",
        ger: "Der Schläger aus der Hölle"
    },
    345: {
        img: "festive_holy_mackerel",
        eng: "The Festive Holy Mackerel",
        pol: "Świąteczna Święta makrela",
        sch: "节日圣灵鲭鱼",
        fre: "Maquereau Sacré festif",
        bra: "Santo Carapau Festivo",
        schema: 999,
        hun: "Ünnepi Magasságos Makréla",
        swe: "Festliga Fina Fisken"
    },
    346: {
        img: "festive_axtinguisher",
        eng: "The Festive Axtinguisher",
        pol: "Świąteczny Poskramiacz płomieni",
        sch: "节日火焰战斧",
        fre: "Hachtincteur festif",
        bra: "Queimachado Festivo",
        schema: 1000,
        hun: "Ünnepi Fejhasogató",
        swe: "Festliga Brandslaktaren"
    },
    347: {
        img: "festive_buff_banner",
        eng: "The Festive Buff Banner",
        pol: "Świąteczny Sztandar chwały",
        sch: "节日战旗",
        fre: "Drapeau Buff festif",
        bra: "Estandarte de Encorajamento Festivo",
        schema: 1001,
        hun: "Ünnepi Lázító Lobogó",
        swe: "Festliga Buffbaneret"
    },
    348: {
        img: "festive_sandvich",
        eng: "The Festive Sandvich",
        pol: "Świąteczna kanapka",
        sch: "节日三明治",
        fre: "Sandvich festif",
        bra: "Sandviche Festivo",
        schema: 1002,
        hun: "Ünnepi Szendvics",
        swe: "Festliga Smörgosen"
    },
    349: {
        img: "festive_ubersaw",
        eng: "The Festive Ubersaw",
        pol: "Świąteczna Überpiła",
        sch: "节日过载充能锯",
        fre: "Überscie festive",
        bra: "Überserra Festiva",
        schema: 1003,
        hun: "Ünnepi Agyonfűrész",
        swe: "Festliga Übersågen"
    },
    350: {
        img: "festive_frontier_justice",
        eng: "The Festive Frontier Justice",
        pol: "Świąteczny Samosąd",
        sch: "节日边境裁决者",
        fre: "Justice Frontalière festive",
        bra: "Justiça Vingadora Festiva",
        schema: 1004,
        hun: "Ünnepi Önbíráskodó",
        swe: "Festlig Västernrättvisa"
    },
    351: {
        img: "festive_huntsman",
        eng: "The Festive Huntsman",
        pol: "Świąteczny Łowca",
        sch: "节日猎人短弓",
        fre: "Huntsman festif",
        bra: "Caçador Festivo",
        schema: 1005,
        hun: "Ünnepi Levadász",
        swe: "Festliga Jägaren"
    },
    352: {
        img: "festive_ambassador",
        eng: "The Festive Ambassador",
        pol: "Świąteczny Ambasador",
        sch: "节日大使左轮手枪",
        fre: "Ambassadeur festif",
        bra: "Embaixador Festivo",
        schema: 1006,
        hun: "Ünnepi Nagykövet",
        swe: "Festliga Ambassadören"
    },
    353: {
        img: "festive_grenade_launcher",
        eng: "Festive Grenade Launcher",
        pol: "Świąteczny granatnik",
        sch: "节日榴弹发射器",
        fre: "Lance-grenades festif",
        bra: "Lança-granadas Festivo",
        schema: 1007,
        hun: "Ünnepi Gránátvető",
        swe: "Festliga Granatkastaren"
    },
    354: {
        img: "winter_wonderland_wrap",
        eng: "Winter Wonderland Wrap",
        pol: "Szal śnieżnego szaleństwa",
        sch: "奇幻冬日面罩",
        fre: "L'écharpe du rêve hivernal",
        bra: "O Esquiador Esquentado",
        schema: 976,
        hun: "A Téli Tündérországi Takaró",
        swe: "Vitt vinterland-sjal"
    },
    355: {
        img: "cut_throat_concierge",
        eng: "Cut Throat Concierge",
        pol: "Szumowina Szwajcar",
        sch: "割喉看门人",
        fre: "Le groom assassin",
        bra: "O Porteiro Degolador",
        schema: 977,
        hun: "A Haláli Hotelportás",
        swe: "Den strupskärande portvakten"
    },
    356: {
        img: "der_wintermantel",
        eng: "Der Wintermantel",
        pol: "Der Wintermantel",
        sch: "冬季大衣",
        fre: "Der Wintermantel",
        bra: "Der Wintermantel",
        schema: 978
    },
    357: {
        img: "cool_breeze",
        eng: "Cool Breeze",
        pol: "Zimny zefirek",
        sch: "冷风",
        fre: "La bise",
        bra: "A Brisa Fresca",
        schema: 979,
        hun: "A Hűsítő Szellő",
        swe: "Den Svala Brisen"
    },
    358: {
        img: "soldiers_slope_scopers",
        eng: "Soldier's Slope Scopers",
        pol: "Górskie gogle gwardzisty",
        sch: "士兵的滑雪风镜",
        fre: "Lunettes de ski du Soldier",
        bra: "O Esquiador Estiloso",
        schema: 980,
        hun: "A Katona Lejtőkémlelője",
        swe: "Soldatens skidglasögon"
    },
    359: {
        img: "cold_killer",
        eng: "Cold Killer",
        pol: "Zimnokrwisty zabójca",
        sch: "冷面死神",
        fre: "Le tueur de sang froid",
        bra: "O Sangue Frio",
        schema: 981,
        hun: "A Hidegre-tevő",
        swe: "Den Kallblodiga Mördaren"
    },
    360: {
        img: "docs_holiday",
        eng: "Doc's Holiday",
        pol: "Urlop zdrowotny",
        sch: "医生的假日",
        fre: "Doc Holiday",
        bra: "Doc's Holiday",
        schema: 982,
        hun: "Szabadnapos Doktor",
        swe: "Läkarens semester"
    },
    361: {
        img: "digit_divulger",
        eng: "Digit Divulger",
        pol: "Palczaste pokazywacze",
        sch: "无指手套",
        fre: "Mitaines révélatrices",
        bra: "O Divulgador de Digitais",
        schema: 983,
        hun: "Az Ujjmutató",
        swe: "Fingerräknar-vantar"
    },
    362: {
        img: "tough_stuff_muffs",
        eng: "Tough Stuff Muffs",
        pol: "Najtwardsze nauszniki",
        sch: "高强度耳罩",
        fre: "Cache-oreilles de dur",
        bra: "Ouvidos Aquecidos",
        schema: 984,
        hun: "Durván Fülvédő",
        swe: "Tuffa grabbars öronmuffar"
    },
    363: {
        img: "heavys_hockey_hair",
        eng: "Heavy's Hockey Hair",
        pol: "Hokejowe hery Grubego",
        sch: "机枪手的冰球头",
        fre: "La nuque longue du Heavy hockeyeur",
        bra: "O Mullet do Marrento",
        schema: 985,
        hun: "Ütős Üstök",
        swe: "Tunga Artilleristens Hockeyfrilla"
    },
    364: {
        img: "mutton_mann",
        eng: "Mutton Mann",
        pol: "Faworyty prawdziwego gentlemanna",
        sch: "曼恩式美鬓",
        fre: "Rouflaquettes Mann",
        bra: "As Costeletas do Cavalheiro",
        schema: 986,
        hun: "A Bajos Barkó",
        swe: "Mannliga Polisonger"
    },
    365: {
        img: "mercs_muffler",
        eng: "Merc's Muffler",
        pol: "Arafata najemnika",
        sch: "佣兵的花围巾",
        fre: "Le cache-nez du mercenaire",
        bra: "O Cachecol do Mercenário",
        schema: 987,
        hun: "A Fagyvédett Fejvadász",
        swe: "Legosoldatens halsduk"
    },
    366: {
        img: "barnstormer",
        eng: "Barnstormer",
        pol: "Pilotka z polotem",
        sch: "马戏飞行员",
        fre: "Les lunettes d'aviateur",
        bra: "O Circense Voador",
        schema: 988,
        hun: "A Pajtaütő",
        swe: "Ladugårdsstormaren"
    },
    367: {
        img: "strange_part_snipers_killed",
        eng: "Strange Part: Snipers Killed",
        pol: "Kuriozalna część: Zabici Snajperzy",
        sch: "奇异武器升级部件：狙击手消灭数",
        fre: "Pièce étrange : Snipers tués",
        bra: "Peça Estranha: Snipers Mortos",
        schema: 6005,
        hun: "Fura Alkatrész: Megölt Mesterlövészek",
        swe: "Märklig Del: Snipers dödade"
    },
    368: {
        img: "strange_part_kills_while_ubercharged",
        eng: "Strange Part: Kills While Übercharged",
        pol: "Kuriozalna część: Zabici podczas Übercharge'a",
        sch: "奇异武器升级部件：Ubercharge 中击杀数",
        fre: "Pièce étrange : Victimes pendant une Übercharge",
        bra: "Peça Estranha: Vítimas Durante ÜberCargas",
        schema: 6037,
        hun: "Fura Alkatrész: ÜberTöltés közbeni ölések",
        swe: "Märklig Del: Dödade som Überladdad"
    },
    369: {
        img: "strange_part_underwater_kills",
        eng: "Strange Part: Underwater Kills",
        pol: "Kuriozalna część: Zabici pod wodą",
        sch: "奇异武器升级部件：水下杀人数",
        fre: "Pièce étrange : Victimes se trouvant sous l'eau",
        bra: "Peça Estranha: Inimigos Submersos Mortos",
        schema: 6036,
        hun: "Fura Alkatrész: Víz alatti ölések",
        swe: "Märklig Del: Undervattensmord"
    },
    370: {
        img: "hotties_hoodie",
        eng: "Hottie's Hoodie",
        pol: "Kaptur przystojniaka",
        sch: "火辣的兜帽",
        fre: "Sweat du Canon",
        bra: "Cabeça Quente",
        schema: 377,
        hun: "Piróska Kapucnija",
        swe: "Heta Huvan"
    },
    371: {
        img: "surgeons_side_satchel",
        eng: "Surgeon's Side Satchel",
        pol: "Chlebak chwackiego chirurga",
        sch: "外科医生的随身小包",
        fre: "La Sacoche du Chirurgien",
        bra: "A Pochete do Patologista",
        schema: 770,
        hun: "Az Orvosi Oldaltáska",
        swe: "Kirurgens Kappväska"
    },
    372: {
        img: "baby_faces_blaster",
        eng: "Baby Face's Blaster",
        pol: "Browning Baby Face'a",
        sch: "娃娃脸的冲击波",
        fre: "L'Exploseur de Tête d'Ange",
        bra: "A Destruidora do Degenerado",
        schema: 772,
        hun: "Babaarc Beütője",
        swe: "Barnansiktets Blästrare"
    },
    373: {
        img: "strange_part_pyros_killed",
        eng: "Strange Part: Pyros Killed",
        pol: "Kuriozalna część: Zabici Pyro",
        sch: "奇异武器升级部件：火焰兵消灭数",
        fre: "Pièce étrange : Pyros tués",
        bra: "Peça Estranha: Pyros Mortos",
        schema: 6006,
        hun: "Fura Alkatrész: Megölt Pirók",
        swe: "Märklig Del: Pyros dödade"
    },
    374: {
        img: "strange_part_defender_kills",
        eng: "Strange Part: Defender Kills",
        pol: "Kuriozalna część: Zabójstwa w obronie",
        sch: "奇异武器升级部件：防守击杀数",
        fre: "Pièce étrange : Victimes en tant que défenseur",
        bra: "Peça Estranha: Vítimas para Defesa",
        schema: 6035,
        hun: "Fura Alkatrész: Megölt védők",
        swe: "Märklig Del: Försvarsmord"
    },
    375: {
        img: "counterfeit_billycock",
        eng: "Counterfeit Billycock",
        pol: "Fałszywy melonik",
        sch: "仿冒的小礼帽",
        fre: "Le chapeau melon contrefait",
        bra: "O Chapéu-coco Disfarçado",
        schema: 602,
        hun: "A Kamu Keménykalap",
        swe: "Den Förfalskade Kubben"
    },
    376: {
        img: "desert_marauder",
        eng: "Desert Marauder",
        pol: "Pustynny rabuś",
        sch: "阿拉伯头巾",
        fre: "Le Maraudeur du Désert",
        bra: "Saqueador do Deserto",
        schema: 400,
        hun: "Sivatagi Fosztogató",
        swe: "Ökenbandit"
    },
    377: {
        img: "fire_axe",
        eng: "Fire Axe",
        pol: "Topór strażacki",
        sch: "消防斧",
        fre: "Hache",
        bra: "Machado de Incêndio",
        schema: 192,
        hun: "tűzoltófejsze",
        swe: "Brandyxa"
    },
    378: {
        img: "flying_guillotine",
        eng: "Flying Guillotine",
        pol: "Latająca gilotyna",
        sch: "混混的菜刀",
        fre: "La guillotine volante",
        bra: "A Guilhotina Voadora",
        schema: 812,
        hun: "A Repülő Nyaktiló",
        swe: "Flygande Giljotinen"
    },
    379: {
        img: "beggars_bazooka",
        eng: "Beggar's Bazooka",
        pol: "Bazooka biedaka",
        sch: "乞丐的火箭筒",
        fre: "Bazooka du mendiant",
        bra: "O Lança-foguetes do Lamentável",
        schema: 730,
        hun: "A Kacatvető",
        swe: "Den Ruinerades Raketgevär"
    },
    380: {
        img: "strange_part_medics_killed",
        eng: "Strange Part: Medics Killed",
        pol: "Kuriozalna część: Zabici Medycy",
        sch: "奇异武器升级部件：医生消灭数",
        fre: "Pièce étrange : Medics tués",
        bra: "Peça Estranha: Medics Mortos",
        schema: 6007,
        hun: "Fura Alkatrész: Megölt Szanitécek",
        swe: "Märklig Del: Medics dödade"
    },
    381: {
        img: "strange_part_tanks_destroyed",
        eng: "Strange Part: Tanks Destroyed",
        pol: "Kuriozalna część: Zniszczone czołgi",
        sch: "奇异武器升级部件：坦克摧毁数",
        fre: "Pièce étrange : Tanks détruits",
        bra: "Peça Estranha: Tanques Destruídos",
        schema: 6038,
        hun: "Fura Alkatrész: Megsemmisített tankok",
        swe: "Märklig Del: Pansarvagnar förstörda"
    },
    382: {
        img: "strange_part_long_distance_kills",
        eng: "Strange Part: Long-Distance Kills",
        pol: "Kuriozalna część: Zabójstwa z dużej odległości",
        sch: "奇异武器升级部件：远距离击杀",
        fre: "Pièce étrange : Victimes à longue distance",
        bra: "Peça Estranha: Mortes a Longa Distância",
        schema: 6039,
        hun: "Fura Alkatrész: Ölések nagy távolságból",
        swe: "Märklig del: Dödade på långt avstånd"
    },
    383: {
        img: "modest_pile_of_hat",
        eng: "Modest Pile of Hat",
        pol: "Skromny stos kapelusza",
        sch: "朴素的桩柱帽",
        fre: "Modeste pile de chapeaux",
        bra: "Pilha Modesta de Chapéu",
        schema: 139,
        hun: "Szerény Kalapkupac",
        swe: "Anspråkslös Hög Av Hatt"
    },
    384: {
        img: "neon_annihilator",
        eng: "Neon Annihilator",
        pol: "Neonowy niszczyciel",
        sch: "霓虹杀手",
        fre: "Le Néonhilateur",
        bra: "O Aniquilador Neônico",
        schema: 813,
        hun: "A Neon Megsemmisítő",
        swe: "Neonförintaren"
    },
    385: {
        img: "mann_co_painting_set",
        eng: "Mann Co. Painting Set",
        pol: "Zestaw farb Mann Co.",
        sch: "曼恩公司油漆套装",
        fre: "Coffret de peinture Mann Co.",
        bra: "Kit de Pintura da Mann Co.",
        schema: 1027,
        hun: "Mann Co. Festőkészlet",
        swe: "Mann Co. Målarset"
    },
    386: {
        img: "bolt_boy",
        eng: "Bolt Boy",
        pol: "Bolt Boy",
        sch: "螺栓小子",
        fre: "Le Bolt Boy",
        bra: "O Garoto Robonk",
        schema: 30027,
        hun: "Villámsrác",
        swe: "Bultpojken"
    },
    387: {
        img: "bonk_leadwear",
        eng: "Bonk Leadwear",
        pol: "Ołowiowy Bonk",
        sch: "饮料铅盔",
        fre: "Casque Bonk en Plomb",
        bra: "Energético Bonk",
        schema: 30030,
        hun: "Poff! Villanysisak",
        swe: "Bonk Blykollektion"
    },
    388: {
        img: "bot_dogger",
        eng: "Bot Dogger",
        pol: "Botdoggista",
        sch: "机器热狗帽",
        fre: "Le Bot Dog",
        bra: "O Circuitão Quente",
        schema: 30018,
        hun: "A Bot-Dogos",
        swe: "Korvbotten"
    },
    389: {
        img: "ye_oiled_baker_boy",
        eng: "Ye Oiled Baker Boy",
        pol: "Usmarowana czapka gazeciarza",
        sch: "涂满机油的老式报童帽",
        fre: "Le Mécha-Gavroche Huilé",
        bra: "Padeirinho, bip bop!",
        schema: 30019,
        hun: "Roborikkancs-sapka",
        swe: "Oljeknyckarkeps"
    },
    390: {
        img: "full_metal_drill_hat",
        eng: "Full Metal Drill Hat",
        pol: "Metalowy kapelusz musztry",
        sch: "全金属操练帽",
        fre: "Le Sergent Instructeur 2.0",
        bra: "O Chapelão de Chumbo",
        schema: 30026,
        hun: "Őrmesteri Vaskalap",
        swe: "Helmantlad Övningshatt"
    },
    391: {
        img: "soldiers_sparkplug",
        eng: "Soldier's Sparkplug",
        pol: "Świeca sierżanta",
        sch: "士兵的火花塞",
        fre: "Bougie d'allumage du Soldier",
        bra: "Plugue do Soldier",
        schema: 30033,
        hun: "Katonai Gyújtógyertya",
        swe: "Soldatens Tändstift"
    },
    392: {
        img: "steel_shako",
        eng: "Steel Shako",
        pol: "Stalowe czako",
        sch: "钢制斯托特军帽",
        fre: "Fer shako",
        bra: "Shako Robótico",
        schema: 30017,
        hun: "Acélcsákó",
        swe: "Stålshako"
    },
    393: {
        img: "tyrantium_helmet",
        eng: "Tyrantium Helmet",
        pol: "Hełm z tyrantynium",
        sch: "暴元素头盔",
        fre: "Casque de tyrantium",
        bra: "O Elmo de Tirânio",
        schema: 30014,
        hun: "Zsarnokium Sisak",
        swe: "Tyrantiumhjälm"
    },
    394: {
        img: "bolted_birdcage",
        eng: "Bolted Birdcage",
        pol: "Poskręcana klatka dla ptaków",
        sch: "金属鸟笼",
        fre: "La volière boulonnée",
        bra: "A Gaiola Parafusada",
        schema: 30057,
        hun: "A Szegecselt Kalitka",
        swe: "Fastbultade Fågelburen"
    },
    395: {
        img: "electric_escorter",
        eng: "Electric Escorter",
        pol: "Elektryczny eskorter",
        sch: "指路的灯泡头",
        fre: "L'escorte électrique",
        bra: "A Luz no Fio do Túnel",
        schema: 30025,
        hun: "Az Elektromos Észvilágítás",
        swe: "Elektriska Eskorten"
    },
    396: {
        img: "filamental",
        eng: "Filamental",
        pol: "Żarnikołak",
        sch: "灯泡面罩",
        fre: "Le Filamental",
        bra: "Filamental",
        schema: 30036,
        hun: "Jófényű Jelenés",
        swe: "Själaglöden"
    },
    397: {
        img: "firewall_helmet",
        eng: "Firewall Helmet",
        pol: "Hełm zapory ogniowej",
        sch: "防火墙头盔",
        fre: "Casque pare-feu",
        bra: "O Capacete de Firewall",
        schema: 30038,
        hun: "Tűzfal-sisak",
        swe: "Brandväggshjälmen"
    },
    398: {
        img: "googol_glass_eyes",
        eng: "Googol Glass Eyes",
        pol: "Gogle Googol Glass",
        sch: "谷鸽眼镜",
        fre: "Prothèse pour Vue pixellisée",
        bra: "Cadê?",
        schema: 30053,
        hun: "A Googol Üvegszemek",
        swe: "Googolglasögonen"
    },
    399: {
        img: "metal_slug",
        eng: "Metal Slug",
        pol: "Metaloślimak",
        sch: "合金鼻涕虫",
        fre: "L'Envahisseur Encéphale",
        bra: "A Lesma de Metal",
        schema: 30028,
        hun: "A Fémszívó",
        swe: "Metallsnigeln"
    },
    400: {
        img: "plumbers_pipe",
        eng: "Plumber's Pipe",
        pol: "Rura hydrauliczna",
        sch: "管道工的管子",
        fre: "Tuyau du Plombier",
        bra: "Ofício do Encanador",
        schema: 30022,
        hun: "Pumpás Csőkönyöke",
        swe: "Rörmokarens Rör"
    },
    401: {
        img: "pyros_boron_beanie",
        eng: "Pyro's Boron Beanie",
        pol: "Wolframowy wirniczek Pyro",
        sch: "火焰兵的硼制童帽",
        fre: "Bonnet en Bore du Pyro",
        bra: "O Pyrocóptero de Boro",
        schema: 30040,
        hun: "Piró Platina Propellere",
        swe: "Pyros Bormössa"
    },
    402: {
        img: "respectless_robo_glove",
        eng: "Respectless Robo-Glove",
        pol: "Niepoważna roborękawica",
        sch: "邋遢的机器手套",
        fre: "Gant robotique ridicule",
        bra: "Mão Robótica Patética",
        schema: 30039,
        hun: "Gúnyos Gépkesztyű",
        swe: "Respektlös Robothandske"
    },
    403: {
        img: "rusty_reaper",
        eng: "Rusty Reaper",
        pol: "Rdzawy żniwiarz",
        sch: "铁锈收割者",
        fre: "Le Faucheur Rouillé",
        bra: "O Ceifador Enferrujado",
        schema: 30032,
        hun: "A Rozsdás Kaszás",
        swe: "Den Rostiga Dräparen"
    },
    404: {
        img: "scrap_sack",
        eng: "Scrap Sack",
        pol: "Szmelcowa sakwa",
        sch: "废铁包",
        fre: "Le Sac de Ferraille",
        bra: "A Mochila de Sucata",
        schema: 30020,
        hun: "A Hulladékzsák",
        swe: "Sopsäcken"
    },
    405: {
        img: "bolted_bicorne",
        eng: "Bolted Bicorne",
        pol: "Blaszany bikorn",
        sch: "螺栓海盗的双角帽",
        fre: "Le bicorne boulonné",
        bra: "O Bicórnio Parafusado",
        schema: 30034,
        hun: "A Megrázó Martalóc",
        swe: "Sjörövarens Stålhatt"
    },
    406: {
        img: "bolted_bombardier",
        eng: "Bolted Bombardier",
        pol: "Skuty bombardier",
        sch: "炸弹兵的螺栓花白胡须",
        fre: "Le Bombardier Boulonné",
        bra: "O Bombardeiro Brilhante",
        schema: 30011,
        hun: "A Szegecselt Tüzér",
        swe: "Bultade Sprängaren"
    },
    407: {
        img: "broadband_bonnet",
        eng: "Broadband Bonnet",
        pol: "Szerokopasmowy Szkot",
        sch: "无线宽带软帽",
        fre: "Le Bonnet à Haut Débit",
        bra: "A Boina Parabólica",
        schema: 30029,
        hun: "A Széles Sávú Sapka",
        swe: "Bredbandshättan"
    },
    408: {
        img: "cyborg_stunt_helmet",
        eng: "Cyborg Stunt Helmet",
        pol: "Hełm cyborga-kaskadera",
        sch: "机器人特技头盔",
        fre: "Le Casqu-adeur cyborg",
        bra: "O Capacete do Dublê Ciborgue",
        schema: 30024,
        hun: "A Kiborgkaszkadőr-sisak",
        swe: "Cyborgens Stunthjälm"
    },
    409: {
        img: "fr_0",
        eng: "FR-0",
        pol: "FR-0",
        sch: "0式爆炸头",
        fre: "La FR-0",
        bra: "O AFR-0",
        schema: 30016,
        hun: "Az A-FR0",
        swe: "AFR-0"
    },
    410: {
        img: "hdmi_patch",
        eng: "HDMI Patch",
        pol: "Przepaska HDMI",
        sch: "高画质独眼照",
        fre: "Le cache HDMI",
        bra: "O Tapa-olho HDMI",
        schema: 30010,
        hun: "A HDMI Szemkötő",
        swe: "HDMI-uppdateringen"
    },
    411: {
        img: "pure_tin_capotain",
        eng: "Pure Tin Capotain",
        pol: "Pirytowy purytanin",
        sch: "纯锡猎巫帽",
        fre: "L'Inquisiteur en aluminium pur",
        bra: "A Cartola de Latão",
        schema: 30021,
        hun: "A Villamos Telepes",
        swe: "Ren Tennkapott"
    },
    412: {
        img: "scrumpy_strongbox",
        eng: "Scrumpy Strongbox",
        pol: "Sejf pełen szkockiej",
        sch: "苹果烈酒保险箱",
        fre: "Le Coffre-fort de Scrumpy",
        bra: "A Caixa-forte de Cidra",
        schema: 30055,
        hun: "A Kódolt Kincsesláda",
        swe: "Spritkassaskåpet"
    },
    413: {
        img: "strontium_stove_pipe",
        eng: "Strontium Stove Pipe",
        pol: "Strontowy szapoklak",
        sch: "锶元素大礼帽",
        fre: "Le Haut-de-forme en Strontium",
        bra: "A Cartola de Estrôncio",
        schema: 30037,
        hun: "A Cink Cilinder",
        swe: "Skottens Strontiumhatt"
    },
    414: {
        img: "bunsen_brave",
        eng: "Bunsen Brave",
        pol: "Bunsenimo",
        sch: "火焰大酋长",
        fre: "Le brave Bunsen",
        bra: "O Cacique das Chamas",
        schema: 30054,
        hun: "A Lángfőnök",
        swe: "Bunsenkrigaren"
    },
    415: {
        img: "gridiron_guardian",
        eng: "Gridiron Guardian",
        pol: "Oksydowany obrońca",
        sch: "球场保卫者",
        fre: "Le Gardien du Stade",
        bra: "O Zagueiro de Ferro",
        schema: 30013,
        hun: "Az Acélos Arcvédő",
        swe: "Amerikansk Järnhjälm"
    },
    416: {
        img: "titanium_towel",
        eng: "Titanium Towel",
        pol: "Ferromagnetyczny ręcznik",
        sch: "钛制毛巾",
        fre: "La Serviette en Titane",
        bra: "A Toalha Antioxidante",
        schema: 30012,
        hun: "A Titán Törölköző",
        swe: "Titanhandduken"
    },
    417: {
        img: "tungsten_toque",
        eng: "Tungsten Toque",
        pol: "Platynowy pomponik",
        sch: "钨制无边帽",
        fre: "La tuque de tungstène",
        bra: "A Touca de Tungstênio",
        schema: 30049,
        hun: "A Keményrobot Mecha-sapija",
        swe: "Tuffingens Volframluva"
    },
    418: {
        img: "data_mining_light",
        eng: "Data Mining Light",
        pol: "Lampa drążenia danych",
        sch: "数据挖掘灯",
        fre: "La Lampe d'explorateur de Données",
        bra: "A Luz de Mineração de Dados",
        schema: 30051,
        hun: "Adatbányász-lámpa",
        swe: "Datautvinningslampan"
    },
    419: {
        img: "dual_core_devil_doll",
        eng: "Dual-Core Devil Doll",
        pol: "Dwurdzeniowa diabelska lalka",
        sch: "双核恶魔娃娃",
        fre: "La Poupée Double-cœur Démoniaque",
        bra: "O Boneco Demoníaco Dual-Core",
        schema: 30056,
        hun: "A Kétmagos Kémbábu",
        swe: "Dubbelkärnig Djävulsdocka"
    },
    420: {
        img: "plug_in_prospector",
        eng: "Plug-In Prospector",
        pol: "Poszukiwacz wtyczek",
        sch: "插件式牛仔帽",
        fre: "Le prospecteur connecté",
        bra: "O Garimpeiro de Gadgets",
        schema: 30031,
        hun: "A Fém Szivar",
        swe: "Prospektörens Plåthätta"
    },
    421: {
        img: "teddy_robobelt",
        eng: "Teddy Robobelt",
        pol: "Teddy Robobelt",
        sch: "泰迪机器熊",
        fre: "Enginounours robotique",
        bra: "Ursinho de Plutônio",
        schema: 30023,
        swe: "Teddy Robobälte"
    },
    422: {
        img: "texas_tin_gallon",
        eng: "Texas Tin-Gallon",
        pol: "Tytanowy teksański kapelusz",
        sch: "德州锡制牛仔帽",
        fre: "Chapeau de cowboy en étain",
        bra: "O Caubói de Latão",
        schema: 30044,
        hun: "Texasi Bádogkalap",
        swe: "Texas Tennboy"
    },
    423: {
        img: "timeless_topper",
        eng: "Timeless Topper",
        pol: "Ponadczasowy cylinder",
        sch: "永恒的礼帽",
        fre: "Le Haut-de-forme Intemporel",
        bra: "A Cartola Atemporal",
        schema: 30035,
        hun: "Az Időálló Viselet",
        swe: "Sheriffens Stålthet"
    },
    424: {
        img: "byted_beak",
        eng: "Byte'd Beak",
        pol: "Megabajtowa maska",
        sch: "字节大夫的面具",
        fre: "Le Bec à Octet",
        bra: "O Bico Booleano",
        schema: 30052,
        hun: "A Polírozott Pestisdoktor",
        swe: "Fördärvad Plåtnäbb"
    },
    425: {
        img: "halogen_head_lamp",
        eng: "Halogen Head Lamp",
        pol: "Halogenowa lampa czołowa",
        sch: "头戴式卤素灯",
        fre: "Lampe Halogène Frontale",
        bra: "Lâmpada de Halogênio",
        schema: 30041,
        hun: "Halogén Fejlámpa",
        swe: "Halogen-pannlampa"
    },
    426: {
        img: "mecha_medes",
        eng: "Mecha-Medes",
        pol: "Mechamedes",
        sch: "阿机米德",
        fre: "Archi-Mech",
        bra: "Meca-medes",
        schema: 30048,
        hun: "Mecha-médész"
    },
    427: {
        img: "platinum_pickelhaube",
        eng: "Platinum Pickelhaube",
        pol: "Platynowa pikielhauba",
        sch: "铂金尖顶盔",
        fre: "Casque à pointe en platine",
        bra: "O Pickelhaube Platinado",
        schema: 30042,
        hun: "Platina Díszsisak",
        swe: "Platinapickelhaube"
    },
    428: {
        img: "practitioners_processing_mask",
        eng: "Practitioner's Processing Mask",
        pol: "Mechaniczna maska internisty",
        sch: "医生的数据调控口罩",
        fre: "Masque de traitement du praticien",
        bra: "Máscara de Processamento do Médico",
        schema: 30046,
        hun: "Saválló Sebészeti Maszk",
        swe: "Läkarens Robotmunskydd"
    },
    429: {
        img: "steam_pipe",
        eng: "Steam Pipe",
        pol: "Fajka parowa",
        sch: "蒸汽管的大问题",
        fre: "La pipe à vapeur",
        bra: "A Engenhoca de Nove Tragadas",
        schema: 30050,
        hun: "A Gőzpipás Feladat",
        swe: "Ångpipan"
    },
    430: {
        img: "titanium_tyrolean",
        eng: "Titanium Tyrolean",
        pol: "Tytanowy kapelusz tyrolski",
        sch: "钛制提洛帽",
        fre: "Chapeau tyrolien en titane",
        bra: "Tirolês de Titânio",
        schema: 30045,
        hun: "Titán Tiroli",
        swe: "Gammal Titanhatt"
    },
    431: {
        img: "virus_doctor",
        eng: "Virus Doctor",
        pol: "Antywirus",
        sch: "病毒医帽",
        fre: "Le Docteur Viral",
        bra: "O Doutor Antivírus",
        schema: 30043,
        hun: "A Vírusdoktor",
        swe: "Virusdoktorn"
    },
    432: {
        img: "letchs_led",
        eng: "Letch's LED",
        pol: "LEDy lubieżnika",
        sch: "好色之徒的 LED 眼镜",
        fre: "Galurin à LED licencieuses",
        bra: "O Exterminador Elétrico",
        schema: 30002,
        hun: "Letch LED-je",
        swe: "Snuskets Nattsyn"
    },
    433: {
        img: "shooters_tin_topi",
        eng: "Shooter's Tin Topi",
        pol: "Chromowany hełm strzelca",
        sch: "射手的锡制遮阳帽",
        fre: "Tireur Colonial d'Étain",
        bra: "Chapéu de Safari Laminado",
        schema: 30005,
        hun: "Bádog Szafari-kalap",
        swe: "Skyttens Tin Topi"
    },
    434: {
        img: "soldered_sensei",
        eng: "Soldered Sensei",
        pol: "Zespawany sensei",
        sch: "大师的铁带",
        fre: "Sensei soudé",
        bra: "O Sensei da Solda",
        schema: 30004,
        hun: "Forrasztott Szenszei",
        swe: "Metallmästaren"
    },
    435: {
        img: "base_metal_billycock",
        eng: "Base Metal Billycock",
        pol: "Bezecny metalowy melonik",
        sch: "碱金属小圆礼帽",
        fre: "Chapeau Boulon",
        bra: "Chapéu-coco de Cobre",
        schema: 30007,
        hun: "Egyszerű Fém Keménykalap",
        swe: "Kubb av grundmetall"
    },
    436: {
        img: "bootleg_base_metal_billycock",
        eng: "Bootleg Base Metal Billycock",
        pol: "Podrobiony bezecny metalowy melonik",
        sch: "仿冒的金属小礼帽",
        fre: "Chapeau Boulon de contrebande",
        bra: "Chapéu-coco de Contrabanda Metálica",
        schema: 30047,
        hun: "Krómozott Keménykalap-kópia",
        swe: "Oädla Metallkubben"
    },
    437: {
        img: "megapixel_beard",
        eng: "Megapixel Beard",
        pol: "Megapikselowa broda",
        sch: "千万像素级大胡子相机",
        fre: "La Barbe Mégapixel",
        bra: "A Barba Megapixel",
        schema: 30009,
        hun: "A Megapixel Szakáll",
        swe: "Megapixel-skägget"
    },
    438: {
        img: "galvanized_gibus",
        eng: "Galvanized Gibus",
        pol: "Cynkowany cylinder",
        sch: "镀锌折叠礼帽",
        fre: "Le Gibus Galvanisé",
        bra: "A Cartola Metálica",
        schema: 30003,
        hun: "A Cinkezett Cilinder",
        swe: "Den Galvaniserade Stormhatten"
    },
    439: {
        img: "modest_metal_pile_of_scrap",
        eng: "Modest Metal Pile of Scrap",
        pol: "Skromny stalowy stos złomu",
        sch: "朴素的废铁桩柱帽",
        fre: "Modeste pile de ferraille",
        bra: "Bateria Modesta de Chapéus",
        schema: 30001,
        hun: "Szerény Hulladékfém-kupac",
        swe: "Hyfsad hög med Metallskrot"
    },
    440: {
        img: "noble_nickel_amassment_of_hats",
        eng: "Noble Nickel Amassment of Hats",
        pol: "Zacna kadmowana miara kapeluszy",
        sch: "高贵的镍制层叠帽",
        fre: "Pile nobiliaire de couvre-chefs en nickel",
        bra: "Conjunto Niquelado de Chapéus",
        schema: 30006,
        hun: "Nemes Kalapok Nikkelezett Gyűjteménye",
        swe: "Ståtlig Hattansamling i Nickel"
    },
    441: {
        img: "towering_titanium_pillar_of_hats",
        eng: "Towering Titanium Pillar of Hats",
        pol: "Niebotyczny tytanowy słup kapeluszy",
        sch: "高耸的钛制塔柱帽",
        fre: "Imposante pile de chapeaux en titane",
        bra: "Pilar Monumental de Chapéus de Titânio",
        schema: 30008,
        hun: "Magasan Tornyosuló Titán Kalap-oszlop",
        swe: "Reslig Pelare av Titanhattar"
    },
    442: {
        img: "battery_canteens",
        eng: "Battery Canteens",
        pol: "Mannierki-baterie",
        sch: "机器水壶",
        fre: "Gourdes Acides",
        bra: "Cantil de Baterias",
        schema: 30015,
        hun: "Akkumulátor Kulacsok",
        swe: "Batterikantiner"
    },
    443: {
        img: "kukri",
        eng: "Kukri",
        pol: "Kukri",
        sch: "反曲刀",
        fre: "Kukri",
        bra: "Kukri",
        schema: 193
    },
    444: {
        img: "huo_long_heater",
        eng: "Huo-Long Heater",
        pol: "Wejście smoka",
        sch: "火龙转轮机枪",
        fre: "Le Radiateur de Huo Long",
        bra: "As Chamas de Huo Long",
        schema: 811,
        hun: "A Huo-Long Bemelegítő",
        swe: "Elddraksvärmaren"
    },
    445: {
        img: "enforcer",
        eng: "Enforcer",
        pol: "Egzekutor",
        sch: "执法者",
        fre: "L'Exécutant",
        bra: "O Capanga",
        schema: 460,
        hun: "A Behajtó",
        swe: "Verkställaren"
    },
    446: {
        img: "strange_part_medics_killed_that_have_full_ubercharge",
        eng: "Strange Part: Medics Killed That Have Full ÜberCharge",
        pol: "Kuriozalna część: Zabici Medycy z pełnym ÜberCharge",
        sch: "奇异武器升级部件：满 Ubercharge 医生消灭数",
        fre: "Pièce étrange : Medics tués ayant une Übercharge complète",
        bra: "Peça Estranha: Medics com ÜberCarga Pronta Mortos",
        schema: 6023,
        hun: "Fura alkatrész: Teljes ÜberTöltéssel megölt Szanitécek",
        swe: "Märklig Del: Dödade Medics som hade full ÜberLaddning"
    },
    447: {
        img: "strange_part_giant_robots_destroyed",
        eng: "Strange Part: Giant Robots Destroyed",
        pol: "Kuriozalna część: Zniszczone gigantyczne roboty",
        sch: "奇异武器升级部件：巨型机器人摧毁数",
        fre: "Pièce étrange : Robots géants détruits",
        bra: "Peça Estranha: Robôs Gigantes Destruídos",
        schema: 6028,
        hun: "Fura Alkatrész: Elpusztított óriás-robotok",
        swe: "Märklig Del: Förstörda jätterobotar"
    },
    448: {
        img: "fast_learner",
        eng: "Fast Learner",
        pol: "Błysk geniuszu",
        sch: "速学者",
        fre: "L'éclair de génie",
        bra: "O Aluno Veloz",
        schema: 722,
        hun: "A Gyorsan Tanuló",
        swe: "Den Lättlärde"
    },
    449: {
        img: "disciplinary_action",
        eng: "Disciplinary Action",
        pol: "Postępowanie dyscyplinarne",
        sch: "指挥官的军鞭",
        fre: "L'Action Disciplinaire",
        bra: "A Ação Disciplinar",
        schema: 447,
        hun: "A Fegyelmi Eljárás",
        swe: "Disciplinåtgärden"
    },
    450: {
        img: "loose_cannon",
        eng: "Loose Cannon",
        pol: "Działo nawalone",
        sch: "脱缰巨炮",
        fre: "Danger public",
        bra: "Pavio Curto",
        schema: 996,
        hun: "Elszabadult Ágyú",
        swe: "Laddad Kanon"
    },
    451: {
        img: "fan_o_war",
        eng: "Fan O'War",
        pol: "Wachlarz wojenny",
        sch: "军配扇",
        fre: "L'évent-taille",
        bra: "Leque d'Guerra",
        schema: 355,
        hun: "Haláli Legyező",
        swe: "Krigsfjäder"
    },
    452: {
        img: "strangifier",
        eng: "#ITEM# Strangifier",
        pol: "Kuriozonator: #ITEM#",
        sch: "#ITEM# 奇异转化药剂",
        fre: "Étrangifiant: #ITEM#",
        bra: "#ITEM# Estranhificador",
        schema: 6522,
        hun: "#ITEM# Furásító",
        swe: "#ITEM# Märkligifierare"
    },
    453: {
        img: "strange_part_kills_with_a_taunt_attack",
        eng: "Strange Part: Kills with a Taunt Attack",
        pol: "Kuriozalna część: Zabójstwa drwiną",
        sch: "奇异武器升级部件：嘲讽杀敌数",
        fre: "Pièce étrange : Victimes d'attaques par raillerie",
        bra: "Peça Estranha: Vítimas de Provocação",
        schema: 6051,
        hun: "Fura Alkatrész: Beszólás-támadással megölt ellenfelek",
        swe: "Märklig Del: Dödade med hånattacker"
    },
    454: {
        img: "brotherhood_of_arms",
        eng: "Brotherhood of Arms",
        pol: "Braterstwo broni",
        sch: "兄弟会兜帽",
        fre: "Le frère d'armes",
        bra: "A Fraternidade das Ruas",
        schema: 30066,
        hun: "A Csökönyös Csuklya",
        swe: "Broderluvan"
    },
    455: {
        img: "well_rounded_rifleman",
        eng: "Well-Rounded Rifleman",
        pol: "Satysfakcjonujący strzelec",
        sch: "狙击手圆帽",
        fre: "Le fusilier accompli",
        bra: "O Atirador Completo",
        schema: 30067,
        hun: "A Felkészült Fejlövész",
        swe: "Den heltäckande infanteristen"
    },
    456: {
        img: "breakneck_baggies",
        eng: "Breakneck Baggies",
        pol: "Karkołomne szarawary",
        sch: "巡逻者长裤",
        fre: "Les baggies à grande vitesse",
        bra: "O Esportista Expresso",
        schema: 30068,
        hun: "A Nyaktörő Mackóalsó",
        swe: "Nackbrytarbyxor"
    },
    457: {
        img: "powdered_practitioner",
        eng: "Powdered Practitioner",
        pol: "Przypudrowany konsyliarz",
        sch: "绣花三角帽",
        fre: "Le Praticien Emperruqué",
        bra: "O Médico Empoado",
        schema: 30069,
        hun: "A Kipúderezett Kezelőorvos",
        swe: "Den Pudrade Praktikern"
    },
    458: {
        img: "pocket_pyro",
        eng: "Pocket Pyro",
        pol: "Kieszonkowy Pyro",
        sch: "口袋火焰兵",
        fre: "Le Pyro de poche",
        bra: "Pyro de Bolso",
        schema: 30070,
        hun: "A Zsebpiró",
        swe: "Fickpyron"
    },
    459: {
        img: "cloud_crasher",
        eng: "Cloud Crasher",
        pol: "Postrach przestworzy",
        sch: "裂云头盔",
        fre: "Le fendeur de nuages",
        bra: "O Arranhador de Céus",
        schema: 30071,
        hun: "A Felhőfejelő",
        swe: "Molnkrossaren"
    },
    460: {
        img: "pom_pommed_provocateur",
        eng: "Pom-Pommed Provocateur",
        pol: "Pomponowy prowokator",
        sch: "绒球特工",
        fre: "Le provocateur à pompon",
        bra: "O Pompom Provocador",
        schema: 30072,
        hun: "A Pomponos Provokátor",
        swe: "Pompom-provokatören"
    },
    461: {
        img: "dark_age_defender",
        eng: "Dark Age Defender",
        pol: "Średniowieczny śmiałek",
        sch: "暗黑护甲",
        fre: "Le Défenseur des Temps Obscurs",
        bra: "O Protetor da Idade das Trevas",
        schema: 30073,
        hun: "A Vasbordájú Várvédő",
        swe: "Den mörka tidens försvarare"
    },
    462: {
        img: "tyurtlenek",
        eng: "Tyurtlenek",
        pol: "Gyolfik",
        sch: "高领毛衣",
        fre: "Le Kol Roulé",
        bra: "A Golya Alta",
        schema: 30074,
        hun: "A Gjarbó",
        swe: "Polojtrödjan"
    },
    463: {
        img: "mair_mask",
        eng: "Mair Mask",
        pol: "Maska MO2",
        sch: "曼尔面具",
        fre: "Le Masque à Mair",
        bra: "A Máscara de SAM",
        schema: 30075,
        hun: "A Mégzőmaszk",
        swe: "Mluftmasken"
    },
    464: {
        img: "stormin_norman",
        eng: "Stormin' Norman",
        pol: "Szalejący Norman",
        sch: "进击的诺曼人",
        fre: "L'Assaillant Normand",
        bra: "O Comando Normando",
        schema: 30112,
        hun: "A Rohamozó Normann",
        swe: "Stormande Norrmannen"
    },
    465: {
        img: "das_gutenkutteharen",
        eng: "Das Gutenkutteharen",
        pol: "Das Dobren Fryzuren",
        sch: "佼佼者",
        fre: "Das Gutenkutteharen",
        bra: "Das Toppetchen",
        schema: 30127,
        hun: "Das Gutenkuttehaj"
    },
    466: {
        img: "blood_banker",
        eng: "Blood Banker",
        pol: "Krwawy bankier",
        sch: "嗜血银行家",
        fre: "Le Banquier Sanglant",
        bra: "O Banqueiro de Sangue",
        schema: 30132,
        hun: "A Vérbankos",
        swe: "Blodbanken"
    },
    467: {
        img: "pardners_pompadour",
        eng: "Pardner's Pompadour",
        pol: "Preriowy pompadour",
        sch: "搭档的庞帕多发型",
        fre: "Le Pompadour du Compagnon",
        bra: "A Peruca do Parceiro",
        schema: 30099,
        hun: "A Pórias Pompadúr",
        swe: "Partnerns pompadourfrisyr"
    },
    468: {
        img: "heavy_weight_champ",
        eng: "Heavy-Weight Champ",
        pol: "Mistrz wagi ciężkiej",
        sch: "重量级拳击冠军",
        fre: "Le Champion Poids Lourd",
        bra: "O Campeão Peso-pesado",
        schema: 30080,
        hun: "A Nehézsúlyú Bajnok",
        swe: "Tungviktsmästaren"
    },
    469: {
        img: "backpack_broiler",
        eng: "Backpack Broiler",
        pol: "Plecakowy opiekacz",
        sch: "烤肉背包",
        fre: "Le Sac à Dos Grill",
        bra: "O Churrasco de Lombo",
        schema: 30090,
        hun: "A Grillbatyu",
        swe: "Ryggstekaren"
    },
    470: {
        img: "virtual_viewfinder",
        eng: "Virtual Viewfinder",
        pol: "Wirtualny wizjer",
        sch: "虚拟现实取景器",
        fre: "Le Viseur Virtuel",
        bra: "O Visor Virtual",
        schema: 30140,
        hun: "A Virtuális Világlátó",
        swe: "Virtuell Bildsökare"
    },
    471: {
        img: "federal_casemaker",
        eng: "Federal Casemaker",
        pol: "Profesjonalny agent federalny",
        sch: "联邦闹事者",
        fre: "L'inspecteur Fédéral",
        bra: "O Investigador Federal",
        schema: 30119,
        hun: "A Szövetségi Ügyelő",
        swe: "Federala Fedoran"
    },
    472: {
        img: "wet_works",
        eng: "Wet Works",
        pol: "Mokra robota",
        sch: "暗杀雨帽",
        fre: "Oeuvres Sanglantes",
        bra: "O Golpe Sujo",
        schema: 30135,
        hun: "Mocskos Meló",
        swe: "Sydvästern"
    },
    473: {
        img: "dry_gulch_gulp",
        eng: "Dry Gulch Gulp",
        pol: "Haust z suchego kanionu",
        sch: "快餐店饮料杯",
        fre: "La Gorge Sèche",
        bra: "O Mata-sede",
        schema: 30087,
        hun: "A Szárazsági Szomjoltó",
        swe: "Törstsläckaren"
    },
    474: {
        img: "borscht_belt",
        eng: "Borscht Belt",
        pol: "Czerwony pas",
        sch: "罗宋腰带",
        fre: "Gros plein de bortch",
        bra: "Cinturão de Borscht",
        schema: 30108,
        hun: "Ólomsúly",
        rom: "Cureaua de Borş",
        swe: "Borsjtj-Bälte"
    },
    475: {
        img: "valley_forge",
        eng: "Valley Forge",
        pol: "Valley Forge",
        sch: "福吉谷总统帽",
        fre: "Le Tricorne de Valley Forge",
        bra: "O Vale Forge",
        schema: 30114,
        hun: "A Valley Forge",
        ger: "Der Valley Forge"
    },
    476: {
        img: "shoguns_shoulder_guard",
        eng: "Shogun's Shoulder Guard",
        pol: "Niewzruszone ramię szoguna",
        sch: "幕府将军的肩甲",
        fre: "L'Épaulière du Shogun",
        bra: "A Ombreira do Xogum",
        schema: 30126,
        hun: "A Sógun Vállvédője",
        swe: "Shogunens Axelvakt"
    },
    477: {
        img: "gaelic_golf_bag",
        eng: "Gaelic Golf Bag",
        pol: "Gaelicka torba golfowa",
        sch: "盖尔人的高尔夫球袋",
        fre: "Le Sac de Golf Gaélique",
        bra: "A Mochila de Golfe Galesa",
        schema: 30107,
        hun: "A Gael Golftáska",
        swe: "Galliska Golfbagen"
    },
    478: {
        img: "das_feelinbeterbager",
        eng: "Das Feelinbeterbager",
        pol: "Das Już Mi Lepiej Torben",
        sch: "感觉良好背包",
        fre: "Das Feelinbeterbager",
        bra: "Das Poschetedecurativos",
        schema: 30096,
        hun: "Das Jobahnleßeltöhle",
        swe: "Das Måbesserbag"
    },
    479: {
        img: "belgian_detective",
        eng: "Belgian Detective",
        pol: "Belgijski detektyw",
        sch: "比利时侦探",
        fre: "Le détective belge",
        bra: "Hercule Traidot",
        schema: 30128,
        hun: "A Belga Detektív",
        swe: "Den Belgiska Detektiven"
    },
    480: {
        img: "caribbean_conqueror",
        eng: "Caribbean Conqueror",
        pol: "Karaibski konkwistador",
        sch: "加勒比征服者",
        fre: "Le conquérant des Caraïbes",
        bra: "O Conquistador do Caribe",
        schema: 30116,
        hun: "A Karibi Kapitány",
        swe: "Den Karibiska Erövraren"
    },
    481: {
        img: "katyusha",
        eng: "Katyusha",
        pol: "Katiusza",
        sch: "喀秋莎",
        fre: "Le Katyusha",
        bra: "A Katyusha",
        schema: 30094,
        hun: "A Katyusa",
        swe: "Katiusjan"
    },
    482: {
        img: "das_hazmattenhatten",
        eng: "Das Hazmattenhatten",
        pol: "Das Hazmatten Kapelusz",
        sch: "镜面防护帽",
        fre: "Das Hazmattenhatten",
        bra: "Das Schapelantihadioatividaden",
        schema: 30095,
        hun: "Das Wiedwiedelemschapek"
    },
    483: {
        img: "pampered_pyro",
        eng: "Pampered Pyro",
        pol: "Wypieszczony Pyro",
        sch: "骄奢火焰兵头巾",
        fre: "Le Pyro Pomponné",
        bra: "O Pyro Privilegiado",
        schema: 30139,
        hun: "A Kicicomázott Piró",
        swe: "Bortskämda Pyro"
    },
    484: {
        img: "rogues_brogues",
        eng: "Rogue's Brogues",
        pol: "Bandyckie brogsy",
        sch: "恶棍的粗革皮鞋",
        fre: "Les Claquettes du Coquin",
        bra: "O Mocassim do Assassim",
        schema: 30125,
        hun: "A Lator Lábbelije",
        swe: "Skurkens Skodon"
    },
    485: {
        img: "hornblower",
        eng: "Hornblower",
        pol: "Pan Hornblower",
        sch: "霍恩布洛尔的海军服",
        fre: "Le Hornblower",
        bra: "O Napoleônico Naval",
        schema: 30129,
        swe: "Hornblåsaren"
    },
    486: {
        img: "delinquents_down_vest",
        eng: "Delinquent's Down Vest",
        pol: "Bezrękawnik delikwenta",
        sch: "少年犯的羽绒背心",
        fre: "La Doudoune de Délinquant",
        bra: "O Colete do Criminoso",
        schema: 30134,
        hun: "A Tettleges Mellény",
        swe: "Brottslingens Dunväst"
    },
    487: {
        img: "glasgow_great_helm",
        eng: "Glasgow Great Helm",
        pol: "Hełm garnczkowy z Glasgow",
        sch: "格拉斯哥卓越头盔",
        fre: "Le Grand Heaume de Glasgow",
        bra: "O Grande Elmo de Glasgow",
        schema: 30082,
        hun: "A Glasgow-i Nagy Sisak",
        swe: "Glasgows Storhjälm"
    },
    488: {
        img: "rebel_rouser",
        eng: "Rebel Rouser",
        pol: "Skonfundowany konfederat",
        sch: "叛乱煽动者",
        fre: "L'agitateur rebelle",
        bra: "O Agitador Adornado",
        schema: 30120,
        hun: "A Lázadító",
        swe: "Rebelleggaren"
    },
    489: {
        img: "el_muchacho",
        eng: "El Muchacho",
        pol: "El muchacho",
        sch: "少年的羊毛斗篷",
        fre: "El Muchacho",
        bra: "El Muchacho",
        schema: 30089
    },
    490: {
        img: "das_ubersternmann",
        eng: "Das Ubersternmann",
        pol: "Das Uber Srogien Człowiekien",
        sch: "名医帽",
        fre: "Das Ubersternmann",
        bra: "Das Überausteromann",
        schema: 30097,
        hun: "Das Übermorzosmann",
        swe: "Das Ubersträngmann"
    },
    491: {
        img: "bolshevik_biker",
        eng: "Bolshevik Biker",
        pol: "Bolszewicki motocyklista",
        sch: "布尔什维克骑手",
        fre: "Le Biker Bolchevik",
        bra: "O Motoqueiro Marxista",
        schema: 30138,
        hun: "A Marxista Motoros",
        swe: "Bolsjevikknutten"
    },
    492: {
        img: "tartan_spartan",
        eng: "Tartan Spartan",
        pol: "Demolka w kratę",
        sch: "炸弹专家",
        fre: "Spartiate Écossais",
        bra: "Tartan Espartano",
        schema: 30106,
        hun: "Skótkockás spártai",
        rom: "Spartan Tartan",
        swe: "Sprängande Spartan"
    },
    493: {
        img: "cobber_chameleon",
        eng: "Cobber Chameleon",
        pol: "Koleżka kameleon",
        sch: "宠物变色龙",
        fre: "Le Pote Caméléon",
        bra: "O Colega Camaleão",
        schema: 30101,
        hun: "A Kaméleon-kedvenc",
        swe: "Kamratliga kameleonten"
    },
    494: {
        img: "graybanns",
        eng: "Graybanns",
        pol: "Graybanny",
        sch: "灰朋太阳镜",
        fre: "Graybanns",
        bra: "Graybanns",
        schema: 30104
    },
    495: {
        img: "after_dark",
        eng: "After Dark",
        pol: "Po zmroku",
        sch: "夜生活",
        fre: "La Fin de Soirée",
        bra: "Altas Horas",
        schema: 30133,
        hun: "A Kései Vendég",
        swe: "Efter Mörkret"
    },
    496: {
        img: "hive_minder",
        eng: "Hive Minder",
        pol: "Opiekun roju",
        sch: "养蜂人之帽",
        fre: "L'apiculteur",
        bra: "O Abelhudo",
        schema: 30093,
        hun: "A Kas-lató",
        swe: "Bikupekupolen"
    },
    497: {
        img: "das_metalmeatencasen",
        eng: "Das Metalmeatencasen",
        pol: "Das Metal Ochrona fur Brust",
        sch: "金属裹肉甲",
        fre: "Das Metalmeatencasen",
        bra: "Das Protettordepeittodemetalen",
        schema: 30098,
        hun: "Das Metalhuschtok",
        swe: "Das Metallköttpanzer"
    },
    498: {
        img: "gaelic_garb",
        eng: "Gaelic Garb",
        pol: "Celtyckie odzienie",
        sch: "高卢式外套",
        fre: "Les Habits Gaéliques",
        bra: "O Galês Gabado",
        schema: 30124,
        hun: "A Kelta Kollekció",
        swe: "Gaeliska Skruden"
    },
    499: {
        img: "half_pipe_hurdler",
        eng: "Half-Pipe Hurdler",
        pol: "Ramposkoczek",
        sch: "邦克滑板",
        fre: "Le Coureur des Rampes",
        bra: "O Rapaz do Rolê",
        schema: 30084,
        hun: "A Félcső-futó",
        swe: "Halfpipe-häcklöparen"
    },
    500: {
        img: "birdman_of_australiacatraz",
        eng: "Birdman of Australiacatraz",
        pol: "Ptasznik z Australiacatraz",
        sch: "澳魔岛驯鸟者",
        fre: "Homme-Oiseau d'Australcatraz",
        bra: "O Homem de Australiacatraz",
        schema: 30100,
        hun: "Ausztrálialcatraz Madárembere",
        swe: "Fågelmannen från Australcatraz."
    },
    501: {
        img: "tsarboosh",
        eng: "Tsarboosh",
        pol: "Carbusz",
        sch: "沙皇毡帽",
        fre: "Le Tsarboosh",
        bra: "O Tsarboosh",
        schema: 30081,
        hun: "A Cárbus",
        ger: "Der Zarbusch"
    },
    502: {
        img: "macho_mann",
        eng: "Macho Mann",
        pol: "Męski mężczyzna",
        sch: "猛男曼恩",
        fre: "Le Macho Mann",
        bra: "O Macho Mann",
        schema: 30085,
        hun: "A Macsó Mannus",
        ger: "Der Macho-Mann"
    },
    503: {
        img: "harmburg",
        eng: "Harmburg",
        pol: "Harmburg",
        sch: "邪恶汉堡帽",
        fre: "Le Harmburg",
        bra: "O Assassinomburg",
        schema: 30123,
        hun: "A Nempuha-kalap",
        swe: "Homburgern"
    },
    504: {
        img: "das_maddendoktor",
        eng: "Das Maddendoktor",
        pol: "Der Szalonenlekarzen",
        sch: "疯狂的医生",
        fre: "Das Maddendoktor",
        bra: "Das Doktormaluco",
        schema: 30121,
        hun: "Das Öhrültdoktor",
        swe: "Das Vansinnesdoktor"
    },
    505: {
        img: "red_army_robin",
        eng: "Red Army Robin",
        pol: "Rudzik Armii Czerwonej",
        sch: "红军战士的知更鸟",
        fre: "Le Rouge-gorge de l'Armée Rouge",
        bra: "O Pisco do Exército Vermelho",
        schema: 30079,
        hun: "A Vörös Begy",
        swe: "Röda arméns Robin"
    },
    506: {
        img: "greased_lightning",
        eng: "Greased Lightning",
        pol: "Natłuszczona błyskawica",
        sch: "风驰电掣",
        fre: "Éclair Gominé",
        bra: "Tempo da Brilhantina",
        schema: 30078,
        hun: "Olajozott Villám",
        swe: "Oljad Blixt"
    },
    507: {
        img: "whiskey_bib",
        eng: "Whiskey Bib",
        pol: "Wyświechtany śliniak do whisky",
        sch: "威士忌餐巾布",
        fre: "Le Bavoir à Whisky",
        bra: "Os Babadores de Uísque",
        schema: 30110,
        hun: "A Whiskey-előke",
        swe: "Whiskey-haklappen"
    },
    508: {
        img: "cool_cat_cardigan",
        eng: "Cool Cat Cardigan",
        pol: "Prze gość",
        sch: "酷猫羊毛衫",
        fre: "Le Cardigan du Mec Cool",
        bra: "O Cardigã do Carismático",
        schema: 30077,
        hun: "A Király Kardigán",
        swe: "Coola Cardigan"
    },
    509: {
        img: "colonial_clogs",
        eng: "Colonial Clogs",
        pol: "Kolonialne chodaki",
        sch: "殖民木屐",
        fre: "Les Sabots Coloniaux",
        bra: "Os Calçados Coloniais",
        schema: 30117,
        hun: "A Kolonista Klumpái",
        swe: "Traditionella Träskor"
    },
    510: {
        img: "burning_bandana",
        eng: "Burning Bandana",
        pol: "Płonąca bandana",
        sch: "烈焰头巾",
        fre: "Le Bandana Ardent",
        bra: "A Bandana em Brasas",
        schema: 30091,
        hun: "A Lobogó Kendő",
        swe: "Heta huvudduken"
    },
    511: {
        img: "falconer",
        eng: "Falconer",
        pol: "Sokolnik",
        sch: "驯鹰手套",
        fre: "Le Fauconnier",
        bra: "O Falcoeiro",
        schema: 30103,
        hun: "A Solymász",
        swe: "Falkeneraren"
    },
    512: {
        img: "black_watch",
        eng: "Black Watch",
        pol: "Czarna straż",
        sch: "黑卫部队军帽",
        fre: "La Sentinelle Noire",
        bra: "A Guarda Negra",
        schema: 30105,
        hun: "A Fekete Sereg",
        swe: "Svarta Vakten"
    },
    513: {
        img: "flared_frontiersman",
        eng: "Flared Frontiersman",
        pol: "Dzwoniasty pionier",
        sch: "拓荒者的喇叭裤",
        fre: "Les Pattes d'eph du Pionnier",
        bra: "A Boca de Sino do Arraiano",
        schema: 30113,
        hun: "A Tartós Trapéznadrág",
        swe: "Prydliga Pionjären"
    },
    514: {
        img: "compatriot",
        eng: "Compatriot",
        pol: "Kompatriota",
        sch: "同胞",
        fre: "Le compatriote",
        bra: "A Compatriota",
        schema: 30115,
        hun: "A Patriótárs",
        swe: "Landsmannen"
    },
    515: {
        img: "das_fantzipantzen",
        eng: "Das Fantzipantzen",
        pol: "Das Krzykliwy Mantel",
        sch: "花哨衬衫",
        fre: "Das Fantzipantzen",
        bra: "Das Calssaschiczen",
        schema: 30137,
        hun: "Das Tschiniepantallo"
    },
    516: {
        img: "bear_necessities",
        eng: "Bear Necessities",
        pol: "Niedźwiedzie potrzeby",
        sch: "巨熊必需品",
        fre: "Il en faut peu pour être un Ours",
        bra: "O Abraço de Urso",
        schema: 30122,
        hun: "A Medve Akarta",
        swe: "Björntjänsten"
    },
    517: {
        img: "bigg_mann_on_campus",
        eng: "Bigg Mann on Campus",
        pol: "Szycha z akademika",
        sch: "校园大咖",
        fre: "La Star du Campus",
        bra: "O Dono do Pedaço",
        schema: 30076,
        hun: "Az Egyetem Fenemannusa",
        swe: "Viktig Mann på Campus"
    },
    518: {
        img: "lieutenant_bites",
        eng: "Lieutenant Bites",
        pol: "Porucznik Gryzak",
        sch: "咬咬中尉",
        fre: "Lieutenant Mordant",
        bra: "Tenente Mordida",
        schema: 30130,
        hun: "Harap Hadnagy",
        swe: "Löjtnant Bett"
    },
    519: {
        img: "brawling_buccaneer",
        eng: "Brawling Buccaneer",
        pol: "Bitny bukanier",
        sch: "喧闹海盗外套",
        fre: "Le Boucanier Bagarreur",
        bra: "O Bucaneiro Briguento",
        schema: 30131,
        hun: "Az Ütleg Kapitánya",
        swe: "Den Gormande Sjörövaren"
    },
    520: {
        img: "founding_father",
        eng: "Founding Father",
        pol: "Ojciec założyciel",
        sch: "开国元勋",
        fre: "Le père fondateur",
        bra: "O Pai Fundador",
        schema: 30142,
        hun: "Az Alapító Atya",
        swe: "Grundaren"
    },
    521: {
        img: "caffeine_cooler",
        eng: "Caffeine Cooler",
        pol: "Chłodna kofeina",
        sch: "原子饮料冰镇箱",
        fre: "La glacière à caféine",
        bra: "O Refrigerador de Refrescos",
        schema: 30083,
        hun: "A Koffeines Táska",
        swe: "Koffeinkylaren"
    },
    522: {
        img: "gabe_glasses",
        eng: "Gabe Glasses",
        pol: "Okulary Gabe'a",
        sch: "Gabe 式眼镜",
        fre: "Les lunettes de Gabe",
        bra: "Os Óculos do Gabe",
        schema: 30141,
        hun: "A Gabe Szemüveg",
        swe: "Gabes Glasögon"
    },
    523: {
        img: "trash_toter",
        eng: "Trash Toter",
        pol: "Kopertówka klamociarza",
        sch: "废物挎包",
        fre: "La Sacoche à Déchets",
        bra: "A Sacola Reciclável",
        schema: 30086,
        hun: "A Szemétszatyor",
        swe: "Reservdelsväskan"
    },
    524: {
        img: "baron_von_havenaplane",
        eng: "Baron von Havenaplane",
        pol: "Baron von Awiatoren",
        sch: "天堂战机男爵",
        fre: "Baron von Havenaplane",
        bra: "Barão von Tonoavião",
        schema: 30136,
        hun: "Baron von Röpuelohmvarn"
    },
    525: {
        img: "whirly_warrior",
        eng: "Whirly Warrior",
        pol: "Zakręcony wojownik",
        sch: "旋翼战士",
        fre: "L'Hélico-Guerrier",
        bra: "O Voador Violento",
        schema: 30118,
        hun: "A 'Kopter Kapitánya",
        swe: "Virvelkrigaren"
    },
    526: {
        img: "das_naggenvatcher",
        eng: "Das Naggenvatcher",
        pol: "Das Ochrona Fur Głowen",
        sch: "兄弟钢盔",
        fre: "Das Naggenvatcher",
        bra: "Das Antirraschakucka",
        schema: 30109,
        hun: "Das Kobahkverdo"
    },
    527: {
        img: "soot_suit",
        eng: "Soot Suit",
        pol: "Gorący garniak",
        sch: "烟尘西装",
        fre: "La Tenue de Sooter",
        bra: "O Zoot Suit do Zombador",
        schema: 30092,
        hun: "A Koromzakó",
        swe: "Stiliga Sotarn"
    },
    528: {
        img: "hitmans_heatmaker",
        eng: "Hitman's Heatmaker",
        pol: "Zabawka zawodowca",
        sch: "职业杀手的手感",
        fre: "Le Brûleur à Gages",
        bra: "O Assassino de Aluguel",
        schema: 752,
        hun: "A Bérgyilkos Bemelegítője",
        swe: "Torpedens Trogna"
    },
    529: {
        img: "strange_part_kills_during_victory_time",
        eng: "Strange Part: Kills During Victory Time",
        pol: "Kuriozalna część: Zabójstwa po zakończeniu rundy",
        sch: "奇异武器升级部件：胜利后逃兵追杀数",
        fre: "Pièce étrange : Victimes après victoire d'un round",
        bra: "Peça Estranha: Vítimas Após Vencer a Rodada",
        schema: 6041,
        hun: "Fura Alkatrész: Győzelmi idő alatti ölések",
        swe: "Märklig Del: Dödade under segertid"
    },
    530: {
        img: "strange_part_robot_spies_destroyed",
        eng: "Strange Part: Robot Spies Destroyed",
        pol: "Kuriozalna część: Zniszczeni Robo-Szpiedzy",
        sch: "奇异武器升级部件：机器间谍摧毁数",
        fre: "Pièce étrange : Spies Robots détruits",
        bra: "Peça Estranha: Spies Robôs Destruídos",
        schema: 6048,
        hun: "Fura Alkatrész: Elpusztított robot Kémek",
        swe: "Märklig Del: Robotspioner förstörda"
    },
    531: {
        img: "viking_braider",
        eng: "Viking Braider",
        pol: "Warkoczyki wikinga",
        sch: "维京孖辫胡",
        fre: "Les tresses vikings",
        bra: "As Tranças Vikings",
        schema: 30164,
        hun: "A Vikingszakáll",
        swe: "Vikingflätan"
    },
    532: {
        img: "flapjack",
        eng: "Flapjack",
        pol: "Klapota",
        sch: "东京奔跑者",
        fre: "Le Flapjack",
        bra: "A Jaquetsuo",
        schema: 30185,
        hun: "A Hajtókás",
        swe: "Uppknäppta knappjackan"
    },
    533: {
        img: "gold_digger",
        eng: "Gold Digger",
        pol: "Poszukiwacz złota",
        sch: "淘金者",
        fre: "Le Chercheur d'or",
        bra: "O Garimpeiro",
        schema: 30172,
        hun: "Az Aranyásó",
        swe: "Guldgrävaren"
    },
    534: {
        img: "weight_room_warmer",
        eng: "Weight Room Warmer",
        pol: "Docieplający dres",
        sch: "健身房棉袄",
        fre: "Chauffeur de salle de sport",
        bra: "Blusão de Musculação",
        schema: 30178,
        hun: "Edzőterem Melegítő",
        swe: "Skrotlyftaroverall"
    },
    535: {
        img: "special_eyes",
        eng: "Special Eyes",
        pol: "Wyjątkowe oczy",
        sch: "独特之眼",
        fre: "Le Regard spécial",
        bra: "Os Olhos Especiais",
        schema: 30168,
        hun: "A Különleges Szemek",
        swe: "De Speciella Ögonen"
    },
    536: {
        img: "chronomancer",
        eng: "Chronomancer",
        pol: "Chronomanta",
        sch: "时空之主",
        fre: "Le Chronomancien",
        bra: "O Cronomante",
        schema: 30170,
        hun: "Az Időző",
        swe: "Kronomantikern"
    },
    537: {
        img: "pirate_bandana",
        eng: "Pirate Bandana",
        pol: "Piracka chusta",
        sch: "海盗头巾",
        fre: "Bandana de pirate",
        bra: "Bandana de Pirata",
        schema: 30180,
        hun: "Kalózkendő",
        swe: "Piratbandana"
    },
    538: {
        img: "brim_full_of_bullets",
        eng: "Brim-Full Of Bullets",
        pol: "W kulach po brzegi",
        sch: "大嫖客的牛仔帽",
        fre: "Pour une poignée de balles",
        bra: "Por um Punhado de Balas",
        schema: 30173,
        hun: "A Kalapos Felkészülés",
        swe: "Hatten full med kulor"
    },
    539: {
        img: "lil_snaggletooth",
        eng: "Li'l Snaggletooth",
        pol: "Mały Forfiter",
        sch: "小鳄鱼的龅牙",
        fre: "Le P'tit Snaggletooth",
        bra: "Filhote Dente-torto",
        schema: 30181,
        hun: "Kis Csorbafog",
        swe: "Lille Gadden"
    },
    540: {
        img: "escapist",
        eng: "Escapist",
        pol: "Ucieczkowicz",
        sch: "逃犯",
        fre: "L'évadé",
        bra: "Fugitivo",
        schema: 30183,
        hun: "Szabadulóművész",
        swe: "Eskapist"
    },
    541: {
        img: "lhomme_burglerre",
        eng: "L'homme Burglerre",
        pol: "L'homme Burglerre",
        sch: "古堡神偷",
        fre: "L'homme Burglerre",
        bra: "Le Papa Burglerre",
        schema: 30182,
        swe: "Spionenne L'homme"
    },
    542: {
        img: "ward",
        eng: "Ward",
        pol: "Oddziałowy",
        sch: "病房大衣",
        fre: "Le Dispensaire",
        bra: "O Oficial",
        schema: 30190,
        hun: "A Test-őr",
        swe: "Härbärgaren"
    },
    543: {
        img: "beep_boy",
        eng: "Beep Boy",
        pol: "Bip Boy",
        sch: "BB 游戏机",
        fre: "La Beep Boy",
        bra: "O Bip Boy",
        schema: 30167,
        hun: "A Füttyös Fiú",
        ger: "Der Beep Boy"
    },
    544: {
        img: "cuban_bristle_crisis",
        eng: "Cuban Bristle Crisis",
        pol: "Kubański kryzys szczecinowy",
        sch: "古巴大胡子危机",
        fre: "La Barbe de crise cubaine",
        bra: "A Crise de Charutos de Cuba",
        schema: 30165,
        hun: "A Kubai Sörteválság",
        swe: "Kubastilen"
    },
    545: {
        img: "a_brush_with_death",
        eng: "A Brush with Death",
        pol: "Konował z brodą",
        sch: "致死胡髭",
        fre: "En brosse avec la Mort",
        bra: "Por um Fio",
        schema: 30186,
        hun: "Szőrszál Híján",
        swe: "Se skägget med vitögat"
    },
    546: {
        img: "tricksters_turnout_gear",
        eng: "Trickster's Turnout Gear",
        pol: "Żaroodporne wdzianko żartownisia",
        sch: "欺诈大师的消防战斗服",
        fre: "Le faux pompier",
        bra: "Casaco Antifogo do Vigarista",
        schema: 30169,
        hun: "A Trükkös Tűzoltó-felszerelés",
        swe: "Busens Utryckningsutrustning"
    },
    547: {
        img: "hong_kong_cone",
        eng: "Hong Kong Cone",
        pol: "Słomiany stożek",
        sch: "港式斗笠",
        fre: "Cône de Hong Kong",
        bra: "Cone de Hong Kong",
        schema: 30177,
        hun: "Hongkong Kúp",
        swe: "Hong Kong-kon"
    },
    548: {
        img: "hurt_locher",
        eng: "Hurt Locher",
        pol: "Wybuchowe wdzianko",
        sch: "拆弹部队",
        fre: "Le Démineur",
        bra: "A Guerra ao Pudor",
        schema: 30179,
        hun: "A Bambák Földjén",
        swe: "Lochande bombdräkt"
    },
    549: {
        img: "bone_dome",
        eng: "Bone Dome",
        pol: "Koścista kopuła",
        sch: "去骨圆帽",
        fre: "Le Casque crânien",
        bra: "A Proteção de Cabeção",
        schema: 30162,
        hun: "Csontkupola",
        swe: "Benskallen"
    },
    550: {
        img: "air_raider",
        eng: "Air Raider",
        pol: "Kierowca bombowca",
        sch: "空袭者",
        fre: "Le Pilleur d'air",
        bra: "O Combatente Aéreo",
        schema: 30163,
        hun: "A Légi Támadó",
        swe: "Luftbanditen"
    },
    551: {
        img: "medical_mystery",
        eng: "Medical Mystery",
        pol: "Tajemnica lekarska",
        sch: "医学之谜",
        fre: "Le Mystère médical",
        bra: "O Mistério Médico",
        schema: 30171,
        hun: "Az Orvosi Rejtély",
        swe: "Det Medicinska Mysteriet"
    },
    552: {
        img: "cotton_head",
        eng: "Cotton Head",
        pol: "Dziadkowe trilby",
        sch: "棉制顶帽",
        fre: "Le Cotton Head",
        bra: "A Cabeça de Algodão",
        schema: 30175,
        hun: "A Pamutfej",
        swe: "Bomullstoppen"
    },
    553: {
        img: "slick_cut",
        eng: "Slick Cut",
        pol: "Dziarski fryz",
        sch: "手术头巾",
        fre: "La Coupe futée",
        bra: "O Corte Malandro",
        schema: 30187,
        hun: "A Sima Vágás",
        swe: "Klipska klippet"
    },
    554: {
        img: "frenchmans_formals",
        eng: "Frenchman's Formals",
        pol: "Elegancja-Francja",
        sch: "法式礼服",
        fre: "Le Français classique",
        bra: "As Formalidades do Francês",
        schema: 30189,
        hun: "A Francia Formaruha",
        swe: "Fransmannens Högtidsdräkt"
    },
    555: {
        img: "halloweiner",
        eng: "Halloweiner",
        pol: "Paróween",
        sch: "万圣热狗",
        fre: "Le Hante-dog",
        bra: "O Malsichão",
        schema: 30248,
        hun: "A Halloweersli",
        swe: "Hallowienerkorven"
    },
    556: {
        img: "horrific_head_of_hare",
        eng: "Horrific Head of Hare",
        pol: "Koszmarna królicza kiepeła",
        sch: "野兔的惊惧头套",
        fre: "L'affreuse tête de lièvre",
        bra: "A Cabeça de Coelho do Capeta",
        schema: 30211,
        hun: "A Hapsifüles",
        swe: "Hemska harhuvudet"
    },
    557: {
        img: "hounds_hood",
        eng: "Hound's Hood",
        pol: "Kejterski kaptur",
        sch: "猎犬头套",
        fre: "La Capuche du Chien",
        bra: "O Gorro de Cachorro",
        schema: 30207,
        hun: "A Kopó Kapucnija",
        swe: "Hundhuvan"
    },
    558: {
        img: "face_plante",
        eng: "Face Plante",
        pol: "Twarzoplask",
        sch: "普兰特面具",
        fre: "La Face de Plante",
        bra: "A Máscara de Hóquei",
        schema: 30231,
        hun: "A Plante Arc",
        swe: "Fredagsmasken"
    },
    559: {
        img: "faun_feet",
        eng: "Faun Feet",
        pol: "Faunie stópki",
        sch: "地狱信差",
        fre: "Pattes du faune",
        bra: "Pés de Fauno",
        schema: 30247,
        hun: "Faunláb",
        swe: "Faunfötter"
    },
    560: {
        img: "sprinting_cephalopod",
        eng: "Sprinting Cephalopod",
        pol: "Głowonogi goniec",
        sch: "疾跑的触须",
        fre: "Les Céphalopodes de course",
        bra: "O Corredor Cefalópode",
        schema: 30253,
        hun: "A Sprintelő Cephalopod",
        swe: "Snabba sugkoppar"
    },
    561: {
        img: "terrier_trousers",
        eng: "Terrier Trousers",
        pol: "Trzewiki teriera",
        sch: "梗犬长裤",
        fre: "Le pantalon pattes de terrier",
        bra: "As Calças Caninas",
        schema: 30208,
        hun: "Terrier Térdnadrág",
        swe: "Terrierbyxorna"
    },
    562: {
        img: "cadavers_capper",
        eng: "Cadaver's Capper",
        pol: "Mycka martwiaka",
        sch: "僵尸的符咒帽",
        fre: "La Toque du vampire",
        bra: "A Marca do Morto-vivo",
        schema: 30251,
        hun: "A Kínai Kísértő",
        swe: "Kadavrets keps"
    },
    563: {
        img: "freedom_feathers",
        eng: "Freedom Feathers",
        pol: "Pióra wolności",
        sch: "自由之羽",
        fre: "L'Aigle de la liberté",
        bra: "As Asas da Liberdade",
        schema: 30239,
        hun: "A Szállás Szabadsága",
        swe: "Frihetsfjädrarna"
    },
    564: {
        img: "hardium_helm",
        eng: "Hardium Helm",
        pol: "Hardy hełm",
        sch: "硬派头盔",
        fre: "Le Casque de hardium",
        bra: "O Capacete de Coisadúrio",
        schema: 30264,
        hun: "A Hardium Sisak",
        swe: "Hårdiumhjälmen"
    },
    565: {
        img: "hidden_dragon",
        eng: "Hidden Dragon",
        pol: "Ukryty smok",
        sch: "藏龙",
        fre: "Le Dragon tigré",
        bra: "O Dragão Oculto",
        schema: 30228,
        hun: "A Rejtett Sárkány",
        swe: "Den Dolda Draken"
    },
    566: {
        img: "larval_lid",
        eng: "Larval Lid",
        pol: "Glizdogłowa",
        sch: "威蛆先生",
        fre: "Le Casque ver",
        bra: "A Minhoca dos Miolos",
        schema: 30294,
        hun: "A Kalapkukac",
        swe: "Larviga larvhjälmen"
    },
    567: {
        img: "spellbinders_bonnet",
        eng: "Spellbinder's Bonnet",
        pol: "Gandalf Szarobrewy",
        sch: "巫师的软帽",
        fre: "Le Chapeau de sorcier",
        bra: "A Fantasia de Feiticeiro",
        schema: 30268,
        hun: "A Varázslatos Süveg",
        swe: "Trollkarlens topphatt"
    },
    568: {
        img: "faux_manchu",
        eng: "Faux Manchu",
        pol: "Fałszywy Mandżur",
        sch: "人造满洲胡",
        fre: "Le Faux Manchu",
        bra: "O Faux Manchu",
        schema: 30227,
        hun: "A Faux Manchu",
        ger: "Der Faux Man Chu"
    },
    569: {
        img: "grub_grenades",
        eng: "Grub Grenades",
        pol: "Granaty robalowe",
        sch: "蛆形手雷",
        fre: "Larves explosives",
        bra: "Granadas Gosmentas",
        schema: 30221,
        hun: "Lárvagránát",
        swe: "Larvgranater"
    },
    570: {
        img: "jupiter_jumpers",
        eng: "Jupiter Jumpers",
        pol: "Saturnowe skoczki",
        sch: "木星飞跃军靴",
        fre: "Les Bottes de Jupiter",
        bra: "Os Propulsores de Plutão",
        schema: 30265,
        hun: "A Jupiter-ugrók",
        swe: "Jupiters jetstövlar"
    },
    571: {
        img: "lieutenant_bites_the_dust",
        eng: "Lieutenant Bites the Dust",
        pol: "Porucznik Gryzie Piach",
        sch: "暴毙的咬咬中尉",
        fre: "Lieutenant Mord-la-poussière",
        bra: "Tenente Mortinho da Silva",
        schema: 30276,
        hun: "Fűbeharap Hadnagy",
        swe: "Löjtnant Betts sista bett"
    },
    572: {
        img: "shaolin_sash",
        eng: "Shaolin Sash",
        pol: "Szaolińska szarfa",
        sch: "少林腰带",
        fre: "L'Écharpe shaolin",
        bra: "A Faixa de Shaolin",
        schema: 30281,
        hun: "A Saolin Selyemöv",
        swe: "Shaolin-bältet"
    },
    573: {
        img: "space_bracers",
        eng: "Space Bracers",
        pol: "Galaktyczne karwasze",
        sch: "太空护腕",
        fre: "Les Brassards spatiaux",
        bra: "As Braçadeiras Espaciais",
        schema: 30266,
        hun: "Az Űrkarvért",
        swe: "Astronomiska armskydd"
    },
    574: {
        img: "bozos_bouffant",
        eng: "Bozo's Bouffant",
        pol: "Czupryna pajaca",
        sch: "笨蛋的蓬松发型",
        fre: "Tronche de clown",
        bra: "O Cabelo do Comediante",
        schema: 30245,
        hun: "Bohócbozont",
        swe: "Bozos bouffant"
    },
    575: {
        img: "burnys_boney_bonnet",
        eng: "Burny's Boney Bonnet",
        pol: "Kolczasty kapturek Denżara",
        sch: "硝博士的邦尼帽",
        fre: "Le Bonnet cornu de Burny",
        bra: "O Capuz de Chifres Chamuscados",
        schema: 30203,
        hun: "A Tüzes Tülkös Tökfödője",
        swe: "Brendas beniga bahytt"
    },
    576: {
        img: "corpsemopolitan",
        eng: "Corpsemopolitan",
        pol: "Zwłokopolitan",
        sch: "大都会果汁",
        fre: "Le Corpsmopolite",
        bra: "O Dry Mortini",
        schema: 30285,
        hun: "A Hullapolitán",
        swe: "Kroppsmopolitan"
    },
    577: {
        img: "crispy_golden_locks",
        eng: "Crispy Golden Locks",
        pol: "Chrupiąco złote loki",
        sch: "易碎的金假发",
        fre: "Les Boucles d'or frites",
        bra: "Os Cachinhos Bem Dourados",
        schema: 30204,
        hun: "A Ropogós Arany Fürtök",
        swe: "Svedda guldlockar"
    },
    578: {
        img: "gothic_guise",
        eng: "Gothic Guise",
        pol: "Gotycka garderoba",
        sch: "哥特式伪装",
        fre: "La Gargouille gothique",
        bra: "A Gárgula Gótica",
        schema: 30222,
        hun: "A Tűzköpő Vízköpő",
        swe: "Gotiska hotet"
    },
    579: {
        img: "macabre_mask",
        eng: "Macabre Mask",
        pol: "Makabryczna maska",
        sch: "骇人面具",
        fre: "Le masque macabre",
        bra: "A Máscara Macabra",
        schema: 30269,
        hun: "Marcona Maszk",
        swe: "Makabra masken"
    },
    580: {
        img: "mucous_membrain",
        eng: "Mucous Membrain",
        pol: "Membrana śluzowa",
        sch: "粘膜脑浆",
        fre: "Le Mucus de la membrane crânienne",
        bra: "O Miolo Mucoso",
        schema: 30235,
        hun: "A Nyálkás Agybrán",
        swe: "Slemmiga slemhinnan"
    },
    581: {
        img: "ravens_visage",
        eng: "Raven's Visage",
        pol: "Krucze oblicze",
        sch: "巨大的乌鸦头骨",
        fre: "Le Crâne du corbeau",
        bra: "O Crânio de Corvo",
        schema: 30298,
        hun: "A Holló Tekintete",
        swe: "Korpgluggarna"
    },
    582: {
        img: "spectralnaut",
        eng: "Spectralnaut",
        pol: "Widmonauta",
        sch: "古怪的宇航头盔",
        fre: "L'Explorateur spectral",
        bra: "O Espectronauta",
        schema: 30194,
        hun: "Az Űrkísértet",
        swe: "Spökonauten"
    },
    583: {
        img: "abhorrent_appendages",
        eng: "Abhorrent Appendages",
        pol: "Abominacyjne akcesoria",
        sch: "可憎的四肢",
        fre: "Les Appendices répugnants",
        bra: "Os Membros do Monstro",
        schema: 30303,
        hun: "Vérfagyasztó Végtagok",
        swe: "Läskiga lemmar"
    },
    584: {
        img: "beast_from_below",
        eng: "Beast From Below",
        pol: "Groza z głębin",
        sch: "下界恶兽",
        fre: "La Bête des profondeurs",
        bra: "A Criatura das Profundezas",
        schema: 30191,
        hun: "A Mélységes Bestia",
        swe: "Odjuret från det undre"
    },
    585: {
        img: "carrion_companion",
        eng: "Carrion Companion",
        pol: "Trupi towarzysz",
        sch: "腐肉同伴",
        fre: "La Charogne de compagnie",
        bra: "A Carniça Companheira",
        schema: 30288,
        hun: "Kimúlt Kishaver",
        swe: "Kamratliga kadavret"
    },
    586: {
        img: "cauterizers_caudal_appendage",
        eng: "Cauterizer's Caudal Appendage",
        pol: "Ognisty ogon przyżegacza",
        sch: "烧灼之尾",
        fre: "L’Appendice caudal cautérisé",
        bra: "O Complemento Caudal do Cauterizador",
        schema: 30225,
        hun: "A Tüzes Tompor-tartozéka",
        swe: "Heta drakstjärten"
    },
    587: {
        img: "creature_from_the_heap",
        eng: "Creature From The Heap",
        pol: "Bestia ze śmieciowiska",
        sch: "隐藏在垃圾桶内的生物",
        fre: "La Créature des poubelles",
        bra: "A Criatura das Impurezas",
        schema: 30296,
        hun: "A Rém a Rakásból",
        swe: "Sopmonstret"
    },
    588: {
        img: "death_support_pack",
        eng: "Death Support Pack",
        pol: "Zestaw pierwszej pożogi",
        sch: "死亡补给包",
        fre: "Le Pack de survie",
        bra: "O Protetor do Psicopata",
        schema: 30257,
        hun: "A Halálfenntartó Egység",
        swe: "Dödshjälpen"
    },
    589: {
        img: "external_organ",
        eng: "External Organ",
        pol: "Organ zewnętrzny",
        sch: "黑色管弦乐",
        fre: "L'Orgue externe",
        bra: "O Órgão Externo",
        schema: 30216,
        hun: "Az Orgonizátor",
        swe: "Det Utvändiga Organet"
    },
    590: {
        img: "glob",
        eng: "Glob",
        pol: "Glut",
        sch: "幽浮小精灵",
        fre: "Le Glob",
        bra: "A Gosma",
        schema: 30286,
        hun: "A Glob",
        swe: "Blobben"
    },
    591: {
        img: "grisly_gumbo",
        eng: "Grisly Gumbo",
        pol: "Grobowa grochówa",
        sch: "恐怖的浓汤",
        fre: "Le Gombo humain",
        bra: "A Sopa Misantropa",
        schema: 30277,
        hun: "A Borzalmas Bogrács",
        swe: "Grisiga grytan"
    },
    592: {
        img: "handhunter",
        eng: "Handhunter",
        pol: "Palcościnacz",
        sch: "斩手铡刀",
        fre: "La Guillotine à mains",
        bra: "A Decepadora de Mãos",
        schema: 30267,
        hun: "A Kézvadász",
        swe: "Handjägaren"
    },
    593: {
        img: "hard_headed_hardware",
        eng: "Hard-Headed Hardware",
        pol: "Twardogłowy osprzęt",
        sch: "硬头硬脑",
        fre: "Tête de pioche infernale",
        bra: "A Máscara de Metal Maquiavélica",
        schema: 30192,
        hun: "Keményfejű Hardver",
        swe: "Hårdhudad Hårdvara"
    },
    594: {
        img: "hollowhead",
        eng: "Hollowhead",
        pol: "Pustogłowy",
        sch: "万圣罩",
        fre: "La tête vide",
        bra: "A Cabeça de Vento",
        schema: 30220,
        hun: "Az Üresfejű",
        swe: "Hålhuvudet"
    },
    595: {
        img: "maniacs_manacles",
        eng: "Maniac's Manacles",
        pol: "Okowy opętanego",
        sch: "疯子的手铐",
        fre: "Les Menottes du maniaque",
        bra: "As Algemas do Alucinado",
        schema: 30196,
        hun: "Az Elszabadult Eszelős",
        swe: "Blådårens bojor"
    },
    596: {
        img: "monsters_stompers",
        eng: "Monster's Stompers",
        pol: "Potworne przygniatacze",
        sch: "怪兽的践踏爪靴",
        fre: "Les Pattes de monstre",
        bra: "As Patas Paleontológicas",
        schema: 30259,
        hun: "A Szörnyű Topogók",
        swe: "Monstermockasiner"
    },
    597: {
        img: "py_40_incinibot",
        eng: "PY-40 Incinibot",
        pol: "Spopielobot PY-40",
        sch: "火焰机器人 40 号",
        fre: "Incinérobot PY-40",
        bra: "Incinerabô PY-40",
        schema: 30290,
        swe: "PY-40 brandbot"
    },
    598: {
        img: "rugged_respirator",
        eng: "Rugged Respirator",
        pol: "Rdzewiejący respirator",
        sch: "坚固耐用的呼吸器",
        fre: "Le respirateur robuste",
        bra: "O Respirador Rústico",
        schema: 30218,
        hun: "A Gonosz Gázálarc",
        swe: "Robust respirator"
    },
    599: {
        img: "scorched_skirt",
        eng: "Scorched Skirt",
        pol: "Spalona spódnica",
        sch: "烧焦的围裙",
        fre: "La Jupe carbonisée",
        bra: "A Saia Chamuscada",
        schema: 30205,
        hun: "A Szenes Szurkoló",
        swe: "Brända kjolen"
    },
    600: {
        img: "up_pyroscopes",
        eng: "Up Pyroscopes",
        pol: "Pyroskopy",
        sch: "火焰兵的蛙人之魂",
        fre: "Les Pyroscopes",
        bra: "Pyroscópios à Vista",
        schema: 30213,
        hun: "Piroszkópot Fel",
        swe: "Pyroskop"
    },
    601: {
        img: "vicious_visage",
        eng: "Vicious Visage",
        pol: "Fatalna facjata",
        sch: "恶毒面罩",
        fre: "Le Visage vicieux",
        bra: "A Máscara do Monstro",
        schema: 30273,
        hun: "Az Ádáz Ábrázat",
        swe: "Griniga gasmasken"
    },
    602: {
        img: "headtakers_hood",
        eng: "Headtaker's Hood",
        pol: "Kaptur kosigłowy",
        sch: "行刑者的面罩",
        fre: "La Cagoule du décapiteur",
        bra: "O Gorro da Guilhotina",
        schema: 30240,
        hun: "A Fejvevő Fejfedő",
        swe: "Halshuggarens huva"
    },
    603: {
        img: "mann_bird_of_aberdeen",
        eng: "Mann-Bird of Aberdeen",
        pol: "Ptakoczłek z Aberdeen",
        sch: "阿伯丁的鸟头人",
        fre: "L'Oiseau d'Aberdeen",
        bra: "O Pássaro de Aberdeen",
        schema: 30282,
        hun: "Az Aberdeeni Madárember",
        swe: "Fågelmann från Aberdeen"
    },
    604: {
        img: "squids_lid",
        eng: "Squid's Lid",
        pol: "Postrach owoców morza",
        sch: "独眼乌贼",
        fre: "Le Chapeau de la Méduse",
        bra: "O Pirata Es-tentacular",
        schema: 30219,
        hun: "A Tintahalas Fejfedő",
        swe: "Bläckfiskens Lock"
    },
    605: {
        img: "transylvania_top",
        eng: "Transylvania Top",
        pol: "Transylwańska czupryna",
        sch: "吸血鬼之祖",
        fre: "La Coupe de Transylvanie",
        bra: "O Topete da Transilvânia",
        schema: 30241,
        hun: "Az Erdélyi Viselet",
        swe: "Transsylvanknoppen"
    },
    606: {
        img: "capn_calamari",
        eng: "Cap'n Calamari",
        pol: "Kapitan Kalmar",
        sch: "鱿鱼船长",
        fre: "Cap'taine Calamar",
        bra: "Capitão Calamari",
        schema: 30193,
        hun: "Kalmár Kapitány",
        swe: "Kapten Calamari"
    },
    607: {
        img: "horsemanns_hand_me_down",
        eng: "Horsemann's Hand-Me-Down",
        pol: "Dziedzictwo Bezkonnego Jeźdźca",
        sch: "无头骑士的传家之宝",
        fre: "La Guenille du Cavalier sans Tête",
        bra: "A Capa do Cavaleiro Carente de Cavalo e Cabeça",
        schema: 30243,
        hun: "A Lovas Levetett Leple",
        swe: "Horsemanns gamla kappa"
    },
    608: {
        img: "lordly_lapels",
        eng: "Lordly Lapels",
        pol: "Książęcy kołnierz",
        sch: "贵族衣领",
        fre: "Le Col comtois",
        bra: "As Lapelas do Lorde",
        schema: 30249,
        hun: "A Gróf Gallérja",
        swe: "Grevens krage"
    },
    609: {
        img: "parasight",
        eng: "Parasight",
        pol: "Paso-wid",
        sch: "寄生之眼",
        fre: "L'oeil indésirable",
        bra: "O Paravista",
        schema: 30292,
        hun: "A Paralátvány",
        swe: "Parasikte-n"
    },
    610: {
        img: "polly_putrid",
        eng: "Polly Putrid",
        pol: "Plugawa Polly",
        sch: "腐烂的鹦鹉",
        fre: "Perroquet putride",
        bra: "Papagaio Putrefato",
        schema: 30226,
        hun: "Purcant Polly",
        swe: "Polly Pest"
    },
    611: {
        img: "chicken_kiev",
        eng: "Chicken Kiev",
        pol: "Kotlet de volaille",
        sch: "鸡辅猛禽",
        fre: "Le Poulet à la Kiev",
        bra: "O Frango à Kiev",
        schema: 30238,
        hun: "A Kijevi Csirke",
        swe: "Kievkycklingen"
    },
    612: {
        img: "horned_honcho",
        eng: "Horned Honcho",
        pol: "Piekielny przełożony",
        sch: "双角队长",
        fre: "Le Honcho à cornes",
        bra: "O Chefão dos Chifres",
        schema: 30275,
        hun: "A Szarvas Szivar",
        swe: "Hårdhudad huvudklädnad"
    },
    613: {
        img: "ivan_the_inedible",
        eng: "Ivan The Inedible",
        pol: "Iwan Niejadalny",
        sch: "恐怖的伊凡三明治",
        fre: "Ivan l'immangeable",
        bra: "Ivan, o Incomestível",
        schema: 30217,
        hun: "Iván, az Ehetetlen",
        swe: "Ivan den oätliga"
    },
    614: {
        img: "last_bite",
        eng: "Last Bite",
        pol: "Ostatni kęs",
        sch: "最后一口",
        fre: "L'Ultime bouchée",
        bra: "A Última Mordida",
        schema: 30199,
        hun: "Az Utolsó Harapás",
        swe: "Det Sista Bettet"
    },
    615: {
        img: "monstrous_mandible",
        eng: "Monstrous Mandible",
        pol: "Sztraszna szczena",
        sch: "巨大的下颚",
        fre: "La mandibule monstrueuse",
        bra: "A Mandíbula Monstruosa",
        schema: 30280,
        hun: "Az Állati Állkapocs",
        swe: "Monsterkäken"
    },
    616: {
        img: "grease_monkey",
        eng: "Grease Monkey",
        pol: "Małpi mechanik",
        sch: "油猢狲",
        fre: "Le Mécanosinge",
        bra: "O Macaco de Oficina",
        schema: 30223,
        hun: "A Szaki Maki",
        swe: "Makakmekanikern"
    },
    617: {
        img: "alternative_medicine_mann",
        eng: "Alternative Medicine Mann",
        pol: "Szaman na perypetiach",
        sch: "非传统医学医师",
        fre: "Le Médecin alternatif",
        bra: "O Homem da Medicina Alternativa",
        schema: 30224,
        hun: "Az Alternatív Sebészmaszk",
        swe: "Alternativa medicinmannen"
    },
    618: {
        img: "das_blutliebhaber",
        eng: "Das Blutliebhaber",
        pol: "Das Blutliebhaber",
        sch: "嗜血狂魔",
        fre: "Das Blutliebhaber",
        bra: "Das Blutliebhaber",
        schema: 30232
    },
    619: {
        img: "medimedes",
        eng: "Medimedes",
        pol: "Medymedes",
        sch: "麦基米德",
        fre: "Medimède",
        bra: "Medimedes",
        schema: 30237,
        hun: "Szanimédész"
    },
    620: {
        img: "shamans_skull",
        eng: "Shaman's Skull",
        pol: "Czaszka szamana",
        sch: "萨满的头骨",
        fre: "Le Crâne pour shaman",
        bra: "O Crânio do Xamã",
        schema: 30270,
        hun: "A Sámán Koponyája",
        swe: "Shamanskallen"
    },
    621: {
        img: "teutonkahmun",
        eng: "Teutonkahmun",
        pol: "Teutonchamon",
        sch: "图坦卡蒙的诅咒",
        fre: "Teutonkahmon",
        bra: "Teutocâmon",
        schema: 30293,
        hun: "Teutonhamon",
        ger: "Teutonchamun"
    },
    622: {
        img: "trepanabotomizer",
        eng: "Trepanabotomizer",
        pol: "Trepanabotomizer",
        sch: "滥医充数",
        fre: "Le trépanolobotomiseur",
        bra: "O Trepanalobotomizador",
        schema: 30233,
        hun: "A Trepanabotomizáló",
        swe: "Trepalobotomeraren"
    },
    623: {
        img: "archimedes_the_undying",
        eng: "Archimedes the Undying",
        pol: "Archimedes Z",
        sch: "阿僵米德",
        fre: "Archimède zombie",
        bra: "Arquimedes, o Imortal",
        schema: 30279,
        hun: "Arkhimédész, az élőholt",
        swe: "Arkimedes den odödliga"
    },
    624: {
        img: "lo_grav_loafers",
        eng: "Lo-Grav Loafers",
        pol: "Księżycowe kamasze",
        sch: "低重力懒汉鞋",
        fre: "Les Bottes anti-gravité",
        bra: "As Botas de Baixa Gravidade",
        schema: 30229,
        hun: "A Holdpapucs",
        swe: "Tyngdlösa loafers"
    },
    625: {
        img: "ramses_regalia",
        eng: "Ramses' Regalia",
        pol: "Regalia Ramzesa",
        sch: "拉美西斯的皇家遗物",
        fre: "Le Costume de Ramsès",
        bra: "Regalias de Ramsés",
        schema: 30299,
        hun: "Ramszesz Rongyai",
        swe: "Ramses regalier"
    },
    626: {
        img: "second_opinion",
        eng: "Second Opinion",
        pol: "Druga opinia",
        sch: "第二选择",
        fre: "Second diagnostic",
        bra: "Segunda Opinião",
        schema: 30197,
        hun: "Másodvélemény",
        rom: "O a Doua Opinie",
        swe: "Andra konsultation"
    },
    627: {
        img: "surgeons_space_suit",
        eng: "Surgeon's Space Suit",
        pol: "Skafander kosmiczny doktora",
        sch: "外科医生的太空服",
        fre: "La combinaison d'astro-chirurgien",
        bra: "O Traje Espacial do Cirurgião",
        schema: 30230,
        hun: "A Sebész Űrruhája",
        swe: "Kirurgens Rymddräkt"
    },
    628: {
        img: "vicars_vestments",
        eng: "Vicar's Vestments",
        pol: "Szata wikarego",
        sch: "教堂牧师的圣衣",
        fre: "Les Vêtements du vicaire",
        bra: "As Vestimentas do Vigário",
        schema: 30263,
        hun: "A Magasztos Mellény",
        swe: "Pastorns paltor"
    },
    629: {
        img: "hallowed_headcase",
        eng: "Hallowed Headcase",
        pol: "Sakralny świr",
        sch: "万圣狂人",
        fre: "Le Saint masque",
        bra: "A Máscara Mística",
        schema: 30287,
        hun: "A Megszentelt Maszk",
        swe: "Avskyvärda ansiktsmasken"
    },
    630: {
        img: "sir_shootsalot",
        eng: "Sir Shootsalot",
        pol: "Pan Dużostrzel",
        sch: "夜行者爵士帽",
        fre: "Sire Tirealot",
        bra: "Olho de Coruja",
        schema: 30284,
        hun: "Sir Lelövi"
    },
    631: {
        img: "carious_chameleon",
        eng: "Carious Chameleon",
        pol: "Nadgniły kameleon",
        sch: "腐烂的变色龙",
        fre: "Le Caméléon décharné",
        bra: "O Camaleão Cariado",
        schema: 30258,
        hun: "Kinyiffant Kaméleon",
        swe: "Karieskameleont"
    },
    632: {
        img: "hyperbaric_bowler",
        eng: "Hyperbaric Bowler",
        pol: "Melonik mózgowca",
        sch: "高压式硬顶圆礼帽",
        fre: "Le mental sous pression",
        bra: "O Chapéu-coco Cerebral",
        schema: 30256,
        hun: "A Túlnyomásos Tökfödő",
        swe: "Hyperbariska hatten"
    },
    633: {
        img: "candymans_cap",
        eng: "Candyman's Cap",
        pol: "Czapka cukierasa",
        sch: "甜心宝贝的帽子",
        fre: "Le Chapeau de Candyman",
        bra: "A Cartola do Confeiteiro",
        schema: 30261,
        hun: "A Cukorkás Kalap",
        swe: "Godisgubbens go'a hatt"
    },
    634: {
        img: "bountiful_bow",
        eng: "Bountiful Bow",
        pol: "Miłosierna muszka",
        sch: "慷慨的蝴蝶结",
        fre: "Le Costume du prodigue",
        bra: "A Gravata Generosa",
        schema: 30260,
        hun: "A Csinos Csokor",
        swe: "Fina flugan"
    },
    635: {
        img: "bozos_brogues",
        eng: "Bozo's Brogues",
        pol: "Buty Bozo",
        sch: "笨蛋的土皮鞋",
        fre: "Croquenots du nigaud",
        bra: "Os Calçados do Comediante",
        schema: 30301,
        hun: "Bolondos Bakancsok",
        swe: "Bozos brogues"
    },
    636: {
        img: "foul_cowl",
        eng: "Foul Cowl",
        pol: "Plugawa peleryna",
        sch: "污秽的斗篷",
        fre: "La Cape du vampire",
        bra: "O Conde von Piro",
        schema: 30283,
        hun: "Az Aljas Köpeny",
        swe: "Kusliga kåpan"
    },
    637: {
        img: "baphomet_trotters",
        eng: "Baphomet Trotters",
        pol: "Kopyta Bafometa",
        sch: "巴风特羊蹄",
        fre: "Les Baphomet trotteurs",
        bra: "Os Pés de Bafomé",
        schema: 30200,
        hun: "A Baphomet Csülkök",
        swe: "Baphomets kusliga klövar"
    },
    638: {
        img: "candleer",
        eng: "Candleer",
        pol: "Świecznik",
        sch: "蜡烛手雷",
        fre: "La Bandoulière de bougies",
        bra: "O Candeeiro Humano",
        schema: 30242,
        hun: "A Gyertyás",
        swe: "Stearinljuskronan"
    },
    639: {
        img: "pin_pals",
        eng: "Pin Pals",
        pol: "Nierozłączki",
        sch: "针友",
        fre: "Potes épinglés",
        bra: "Amigos do Peito",
        schema: 30236,
        hun: "Kitűzött Kisemberek",
        swe: "Nåldynor"
    },
    640: {
        img: "snaggletoothed_stetson",
        eng: "Snaggletoothed Stetson",
        pol: "Szczerbaty stetson",
        sch: "牙齿不齐的斯泰森毡帽",
        fre: "Le Stetson denté",
        bra: "O Chapéu de Caubói Carnívoro",
        schema: 30212,
        hun: "A Csorbafogas Puhakalap",
        swe: "Huggtandad stetsonhatt"
    },
    641: {
        img: "ethereal_hood",
        eng: "Ethereal Hood",
        pol: "Eteryczny kaptur",
        sch: "空灵风帽",
        fre: "Capuche éthérée",
        bra: "O Capuz Espectral",
        schema: 30195,
        hun: "Éteri Csuklya",
        swe: "Hemska huvan"
    },
    642: {
        img: "birdie_bonnet",
        eng: "Birdie Bonnet",
        pol: "Kurczakaptur",
        sch: "小鸟邦尼特",
        fre: "Le Bonnet de poulet",
        bra: "O Gorro Galináceo",
        schema: 30215,
        hun: "A Kakas Kalap",
        swe: "Tuppkammen"
    },
    643: {
        img: "dark_helm",
        eng: "Dark Helm",
        pol: "Mroczny hełm",
        sch: "黑暗头盔",
        fre: "Le Heaume noir",
        bra: "O Elmo Sombrio",
        schema: 30278,
        hun: "A Sötét Sisak",
        swe: "Svarta hjälmen"
    },
    644: {
        img: "haunted_hat",
        eng: "Haunted Hat",
        pol: "Nawiedzona czapka",
        sch: "闹鬼的折叠礼帽",
        fre: "Le Chapeau Hanté",
        bra: "O Chapéu Assombrado",
        schema: 30300,
        hun: "Kísérteties Kalap",
        swe: "Den Hemsökta Hatten"
    },
    645: {
        img: "magical_mercenary",
        eng: "Magical Mercenary",
        pol: "Magiczny najemnik",
        sch: "魔法佣兵",
        fre: "Le Mercenaire magique",
        bra: "O Mercenário Mágico",
        schema: 30297,
        hun: "A Bűbájos Bérgyilkos",
        swe: "Magiska manen"
    },
    646: {
        img: "manneater",
        eng: "Manneater",
        pol: "Ludożerca",
        sch: "曼恩头骨",
        fre: "Le Crannibale",
        bra: "O Manntropófago",
        schema: 30295,
        hun: "Az Emberevő",
        swe: "Mannslukaren"
    },
    647: {
        img: "one_way_ticket",
        eng: "One-Way Ticket",
        pol: "Bilet w jedną stronę",
        sch: "死朋友",
        fre: "L'Aller simple",
        bra: "O Bilhete Só de Ida",
        schema: 30214,
        hun: "A Vonaljegy",
        swe: "Enkelbiljetten"
    },
    648: {
        img: "tuque_or_treat",
        eng: "Tuque or Treat",
        pol: "Czapka albo psikus",
        sch: "不给南瓜就捣蛋",
        fre: "La Tuque citrouille",
        bra: "Touca ou Travessuras",
        schema: 30274,
        hun: "A Tök-födő",
        swe: "Hotfull halloweenmössa"
    },
    649: {
        img: "accursed_apparition",
        eng: "Accursed Apparition",
        pol: "Wyklęte widmo",
        sch: "受诅咒的亡魂",
        fre: "L'Apparition maudite",
        bra: "A Aparição Amaldiçoada",
        schema: 30206,
        hun: "A Kísértő Kísérő",
        swe: "Dömda dödskallen"
    },
    650: {
        img: "beacon_from_beyond",
        eng: "Beacon from Beyond",
        pol: "Latarnia z zaświatów",
        sch: "启蒙之灯",
        fre: "La Lanterne de l'au-delà",
        bra: "A Lanterna do Além",
        schema: 30255,
        hun: "A Vérfagyasztó Viharlámpa",
        swe: "Lyktgubben"
    },
    651: {
        img: "cryptic_keepsake",
        eng: "Cryptic Keepsake",
        pol: "Straszliwy suwenir",
        sch: "神秘的骨链",
        fre: "La Relique cryptique",
        bra: "A Lembrancinha da Lápide",
        schema: 30302,
        hun: "A Rejtélyes Emléktárgy",
        swe: "Dödshäftigt dödskallesmycke"
    },
    652: {
        img: "guano",
        eng: "Guano",
        pol: "Guano",
        sch: "火蝠",
        fre: "Guano",
        bra: "Guano",
        schema: 30252
    },
    653: {
        img: "pocket_horsemann",
        eng: "Pocket Horsemann",
        pol: "Kieszonkowy Jeździec",
        sch: "口袋骑士",
        fre: "Le Cavalier sans Tête de poche",
        bra: "O Cavaleiro de Bolso",
        schema: 30198,
        hun: "A Zseb-Lovas",
        swe: "Horsemann i fickan"
    },
    654: {
        img: "quoth",
        eng: "Quoth",
        pol: "Miodrzekł",
        sch: "乌鸦",
        fre: "Quoth",
        bra: "Quoth",
        schema: 30289,
        hun: "Szólta",
        ger: "Sprach"
    },
    655: {
        img: "sackcloth_spook",
        eng: "Sackcloth Spook",
        pol: "Parciany potworak",
        sch: "麻布幽灵",
        fre: "Le Sac de toile hanté",
        bra: "O Espantalho de Estimação",
        schema: 30234,
        hun: "A Zsákvászon Kísértet",
        swe: "Spöket i säcken"
    },
    656: {
        img: "unidentified_following_object",
        eng: "Unidentified Following Object",
        pol: "Niezidentyfikowany obiekt podążający",
        sch: "不明跟踪物",
        fre: "Objet Visqueux Non Identifié",
        bra: "Observador Voador Não Identificado",
        schema: 30254,
        hun: "Azonosítatlan Ragaszkodó Tárgy",
        swe: "Oidentifierat Förföljande Föremål"
    },
    657: {
        img: "solemn_vow",
        eng: "Solemn Vow",
        pol: "Uroczysta przysięga",
        sch: "希波克拉底的塑像",
        fre: "Le Voeu Solennel",
        bra: "O Voto Solene",
        schema: 413,
        hun: "Az Ünnepi Fogadalom",
        swe: "Det Högtidliga Löftet"
    },
    658: {
        img: "strange_part_unusual_wearing_player_kills",
        eng: "Strange Part: Unusual-Wearing Player Kills",
        pol: "Kuriozalna część: Zabitych wrogów noszących nietypowy okaz",
        sch: "奇异武器升级部件：佩戴稀有装饰玩家消灭数",
        fre: "Pièce étrange : Victimes portant un objet inhabituel",
        bra: "Peça Estranha: Vítimas com Itens Incomuns",
        schema: 6052,
        hun: "Fura Alkatrész: Megölt Rendkívülit viselő játékosok",
        swe: "Märklig Del: Ovanligt klädda spelare döda"
    },
    659: {
        img: "strange_part_spies_killed",
        eng: "Strange Part: Spies Killed",
        pol: "Kuriozalna część: Zabici Szpiedzy",
        sch: "奇异武器升级部件：间谍消灭数",
        fre: "Pièce étrange : Spies tués",
        bra: "Peça Estranha: Spies Mortos",
        schema: 6008,
        hun: "Fura Alkatrész: Megölt Kémek",
        swe: "Märklig Del: Spioner dödade"
    },
    660: {
        img: "strange_part_burning_enemy_kills",
        eng: "Strange Part: Burning Enemy Kills",
        pol: "Kuriozalna część: Zabici podpaleni wrogowie",
        sch: "奇异武器升级部件：着火敌人消灭数",
        fre: "Pièce étrange : Victimes enflammée",
        bra: "Peça Estranha: Vítimas em Chamas",
        schema: 6053,
        hun: "Fura Alkatrész: Megölt égő ellenségek",
        swe: "Märklig Del: Brinnande fiender dödade"
    },
    661: {
        img: "concheror",
        eng: "Concheror",
        pol: "Sashimono straceńca",
        sch: "征服者战旗",
        fre: "Conque-érant",
        bra: "Conchistador",
        schema: 354,
        hun: "Kürt-ölő",
        swe: "Trumpetsnäcka"
    },
    662: {
        img: "eviction_notice",
        eng: "Eviction Notice",
        pol: "Nakaz eksmisji",
        sch: "驱逐警告",
        fre: "L'Avis d'Expulsion",
        bra: "O Aviso de Despejo",
        schema: 426,
        hun: "A Kilakoltatási Felszólítás",
        swe: "Vräkningsbeskedet"
    },
    663: {
        img: "strange_part_killstreaks_ended",
        eng: "Strange Part: Killstreaks Ended",
        pol: "Kuriozalna część: Przerwane serie zabójstw",
        sch: "奇异武器升级部件：连杀终结数",
        fre: "Pièce étrange : Fin d'un killstreak",
        bra: "Peça Estranha: Combos Interrompidos",
        schema: 6054,
        hun: "Fura Alkatrész: Megállított ámokfutások",
        swe: "Märklig Del: Mördarserier avbrutna"
    },
    664: {
        img: "strange_cosmetic_part_freezecam_taunt_appearances",
        eng: "Strange Cosmetic Part: Freezecam Taunt Appearances",
        pol: "Kuriozalna część dla przedmiotów ozdobnych: Drwiny uwiecznione w migawce",
        sch: "奇异部件：杀敌后嘲讽数",
        fre: "Pièce cosmétique étrange : Apparition en faisant une raillerie pendant un arrêt sur image",
        bra: "Peça Estranha para Cosméticos: Provocações na Câmera de Morte",
        schema: 6055,
        hun: "Fura Díszalkatrész: Pillanatképen rögzített beszólások",
        swe: "Märklig Kosmetisk Del: Frysbildshån"
    },
    665: {
        img: "strange_part_damage_dealt",
        eng: "Strange Part: Damage Dealt",
        pol: "Kuriozalna część: Zadane obrażenia",
        sch: "奇异武器升级部件：伤害输出量",
        fre: "Pièce étrange : Dégâts infligés",
        bra: "Peça Estranha: Dano Causado",
        schema: 6056,
        hun: "Fura Alkatrész: Okozott sebzés",
        swe: "Märklig del: Vållad skada"
    },
    666: {
        img: "front_runner",
        eng: "Front Runner",
        pol: "Przodownik",
        sch: "领跑者",
        fre: "Le Coureur Frontal",
        bra: "A Ação Silvestre",
        schema: 760,
        hun: "A Befutó",
        swe: "Frontlöparen"
    },
    667: {
        img: "rescue_ranger",
        eng: "Rescue Ranger",
        pol: "Ryzykowny ratunek",
        sch: "火线救兵",
        fre: "Ranger du risque",
        bra: "Telescopeta",
        schema: 997,
        hun: "A Mentőlövész",
        swe: "Räddaren i Nöden"
    },
    668: {
        img: "scorch_shot",
        eng: "Scorch Shot",
        pol: "Piekąca palba",
        sch: "焦化枪",
        fre: "La Torche rousse",
        bra: "A Queima-roupa",
        schema: 740,
        hun: "Az Odapörkölő",
        swe: "Svedskottet"
    },
    669: {
        img: "strange_cosmetic_part_fires_survived",
        eng: "Strange Cosmetic Part: Fires Survived",
        pol: "Kuriozalna część dla przedmiotów ozdobnych: Przetrwane podpalenia",
        sch: "奇异部件：被点燃后存活次数",
        fre: "Pièce de cosmétique étrange : Incendies survécus",
        bra: "Peça Estranha para Cosméticos: Fogos Sobrevividos",
        schema: 6057,
        hun: "Fura Díszalkatrész: Túlélt égések",
        swe: "Märklig Kosmetisk Del: Eldar överlevda"
    },
    670: {
        img: "strange_part_allied_healing_done",
        eng: "Strange Part: Allied Healing Done",
        pol: "Kuriozalna część: Uleczone punkty zdrowia drużyny",
        sch: "奇异武器升级部件：队友生命值回复量",
        fre: "Pièce étrange : Soins apportés aux alliés",
        bra: "Peça Estranha: Vida de Aliados Curada",
        schema: 6058,
        hun: "Fura Alkatrész: Szövetségeseken végzett gyógyítás",
        swe: "Märklig Del: Helande på allierade"
    },
    671: {
        img: "strange_part_point_blank_kills",
        eng: "Strange Part: Point-Blank Kills",
        pol: "Kuriozalna część: Zabójstwa z bliska",
        sch: "奇异武器升级部件：超近距离击杀数",
        fre: "Pièce étrange : Victimes à bout portant",
        bra: "Peça Estranha: Vítimas à Queima-roupa",
        schema: 6059,
        hun: "Fura Alkatrész: Közvetlen közeli ölések",
        swe: "Märklig Del: Dödade på nära håll"
    },
    672: {
        img: "builders_blueprints",
        eng: "Builder's Blueprints",
        pol: "Plany budowniczego",
        sch: "建筑师的蓝图",
        fre: "Les Plans du Constructeur",
        bra: "Os Projetos do Construtor",
        schema: 606,
        hun: "A Technikus Tervei",
        swe: "Byggarens Ritningar"
    },
    673: {
        img: "festive_force_a_nature",
        eng: "Festive Force-A-Nature",
        pol: "Świąteczna Siła natury",
        sch: "节日自然之力",
        fre: "Force-de-la-Nature festive",
        bra: "Força da Natureza Festiva",
        schema: 1078,
        hun: "Ünnepi Elsöprögető",
        swe: "Festlig Hagel-Med-Kraft"
    },
    674: {
        img: "festive_sapper",
        eng: "Festive Sapper",
        pol: "Świąteczny saper",
        sch: "节日电子工兵",
        fre: "Saboteur festif",
        bra: "Sabotador Festivo",
        schema: 1080,
        hun: "Ünnepi Mentesítő",
        swe: "Festlig Elektrosapparen"
    },
    675: {
        img: "festive_crusaders_crossbow",
        eng: "Festive Crusader's Crossbow",
        pol: "Świąteczna Kusza krzyżowca",
        sch: "节日十字军之弩",
        fre: "Arbalète du Croisé festive",
        bra: "Besta do Cruzado Festiva",
        schema: 1079,
        hun: "Ünnepi Szamaritánus Számszeríja",
        swe: "Festlig Korsriddarens Armborst"
    },
    676: {
        img: "festive_flare_gun",
        eng: "Festive Flare Gun",
        pol: "Świąteczny pistolet sygnałowy",
        sch: "节日信号枪",
        fre: "Pistolet de détresse festif",
        bra: "Arma Sinalizadora Festiva",
        schema: 1081,
        hun: "Ünnepi Jelzőpisztoly",
        swe: "Festlig Signalpistol"
    },
    677: {
        img: "festive_eyelander",
        eng: "Festive Eyelander",
        pol: "Świąteczny Wyszczerbiec",
        sch: "节日苏格兰长剑",
        fre: "Eyelander festif",
        bra: "Eyelander Festiva",
        schema: 1082,
        hun: "Ünnepi Egyetlen",
        swe: "Festlig Ögländaren"
    },
    678: {
        img: "festive_jarate",
        eng: "Festive Jarate",
        pol: "Świąteczne Sikwondo",
        sch: "节日瓶手道",
        fre: "Jaraté festif",
        bra: "Jarratê Festivo",
        schema: 1083,
        hun: "Ünnepi Vizelharc",
        swe: "Festlig Jarate"
    },
    679: {
        img: "festive_gloves_of_running_urgently",
        eng: "Festive Gloves of Running Urgently",
        pol: "Świąteczne Gorące rękawice uciekiniera",
        sch: "节日紧急逃跑手套",
        fre: "Gants de Retraite Urgente festifs",
        bra: "Geradoras de Rapidez Urgente Festivas",
        schema: 1084,
        hun: "Ünnepi Fürge Sprintelés Bokszkesztyűi",
        swe: "Festlig Gorbatjovs Rushande Uppercut"
    },
    680: {
        img: "festive_black_box",
        eng: "Festive Black Box",
        pol: "Świąteczna Czarna skrzynka",
        sch: "节日黑匣子",
        fre: "Boîte Noire festive",
        bra: "Caixa Preta Festiva",
        schema: 1085,
        hun: "Ünnepi Fekete Doboz",
        swe: "Festlig Svarta Lådan"
    },
    681: {
        img: "festive_wrangler",
        eng: "Festive Wrangler",
        pol: "Świąteczny Poskramiacz",
        sch: "节日遥控手柄",
        fre: "Dompteur festif",
        bra: "Peão Festivo",
        schema: 1086,
        hun: "Ünnepi Idomító",
        swe: "Festlig Grälmakare"
    },
    682: {
        img: "blizzard_breather",
        eng: "Blizzard Breather",
        pol: "Arktyczny oddech",
        sch: "暴风雪呼吸面具",
        fre: "Le respirateur du blizzard",
        bra: "O Respirador Resfriado",
        schema: 30304,
        hun: "Hóvihar-maszk",
        swe: "Snöstorms-syrgasmasken"
    },
    683: {
        img: "sub_zero_suit",
        eng: "Sub Zero Suit",
        pol: "Azbestowy anorak",
        sch: "极地防寒服",
        fre: "La combinaison polaire",
        bra: "O Traje Abaixo de Zero",
        schema: 30305,
        hun: "A Fagyos Felöltő",
        swe: "Nollpunktsdräkten"
    },
    684: {
        img: "dictator",
        eng: "Dictator",
        pol: "Dyktator",
        sch: "独裁者之须",
        fre: "Dictateur",
        bra: "O Ditador",
        schema: 30306,
        hun: "Diktátor",
        swe: "Diktatorn"
    },
    685: {
        img: "neckwear_headwear",
        eng: "Neckwear Headwear",
        pol: "Szalikapelusz",
        sch: "戴围巾的帽子",
        fre: "L'écharpe de chapeau",
        bra: "A Cartola com Cachecol",
        schema: 30307,
        hun: "Fejfedő Nyakfedő",
        swe: "Huvudhalsduken"
    },
    686: {
        img: "trail_blazer",
        eng: "Trail-Blazer",
        pol: "Płomienne płozy",
        sch: "开路先锋",
        fre: "Le pionnier des pistes",
        bra: "O Trenó Flamejante",
        schema: 30308,
        hun: "A Szakadt Szánkó",
        swe: "Spårljuset"
    },
    687: {
        img: "dead_of_night",
        eng: "Dead of Night",
        pol: "Śmierć nadejdzie w nocy",
        sch: "冬夜夹克",
        fre: "Cœur de la nuit",
        bra: "Noite Infeliz",
        schema: 30309,
        hun: "Éji Halál",
        swe: "Nattens skugga"
    },
    688: {
        img: "snow_scoper",
        eng: "Snow Scoper",
        pol: "Wiatrówka",
        sch: "雪地瞭望者",
        fre: "Le Snow Scoper",
        bra: "O Agasalho do Atirador",
        schema: 30310,
        hun: "A Havas Lövész",
        swe: "Snösiktaren"
    },
    689: {
        img: "nunhood",
        eng: "Nunhood",
        pol: "Kapłański kaptur",
        sch: "修女披风",
        fre: "La capuche de l'ordre",
        bra: "A Santa Paciência",
        schema: 30311,
        hun: "Az Apóca",
        swe: "Nunneluvan"
    },
    690: {
        img: "angel_of_death",
        eng: "Angel of Death",
        pol: "Anioł śmierci",
        sch: "死亡天使的长袍",
        fre: "L'ange de la mort",
        bra: "O Anjo da Morte",
        schema: 30312,
        hun: "A Halál Angyala",
        swe: "Dödsängeln"
    },
    691: {
        img: "kiss_king",
        eng: "Kiss King",
        pol: "Cesarz całusków",
        sch: "亲吻之王",
        fre: "Le roi du baiser",
        bra: "O Barão dos Beijos",
        schema: 30313,
        hun: "A Csókkirály",
        swe: "Pussprinsen"
    },
    692: {
        img: "slo_poke",
        eng: "Slo-Poke",
        pol: "Kowbojskie zapędy",
        sch: "警长的牛仔帽",
        fre: "Le Par-Essieux",
        bra: "O Marechal Mandriado",
        schema: 30314,
        hun: "A Nyugis",
        swe: "Slöfocken"
    },
    693: {
        img: "minnesota_slick",
        eng: "Minnesota Slick",
        pol: "Minesocki fryz",
        sch: "明州的老练杀手",
        fre: "L'assassin du Minnesota",
        bra: "Mauricinho de Minnesota",
        schema: 30315,
        hun: "Dörzsölt Minnesotai",
        swe: "Minnesota-stilen"
    },
    694: {
        img: "toy_soldier",
        eng: "Toy Soldier",
        pol: "Zabawkowy żołnierzyk",
        sch: "锡兵的军帽",
        fre: "Le soldat de plomb",
        bra: "O Soldadinho de Chumbo",
        schema: 30316,
        hun: "A Játékkatona",
        swe: "Leksakssoldaten"
    },
    695: {
        img: "five_month_shadow",
        eng: "Five-Month Shadow",
        pol: "Sześciomiesięczna szczecina",
        sch: "怪形客胡子",
        fre: "La barbe de cinq mois",
        bra: "A Barba de Cinco Meses",
        schema: 30317,
        hun: "Az Öt Hónapos Árnyék",
        swe: "Femmånadersskuggan"
    },
    696: {
        img: "mann_of_reason",
        eng: "Mann of Reason",
        pol: "Głos rozsądku",
        sch: "随从之帽",
        fre: "L'homme de raison",
        bra: "O Servo Germânnico",
        schema: 30318,
        hun: "Az Ész-szerű",
        swe: "En Mann av Resonemang"
    },
    697: {
        img: "mann_of_the_house",
        eng: "Mann of the House",
        pol: "Pan domu",
        sch: "一家之主",
        fre: "L'Homme de la Maison",
        bra: "O Homann da Casa",
        schema: 30319,
        hun: "A Ház Ura",
        swe: "Mann-en i huset"
    },
    698: {
        img: "chucklenuts",
        eng: "Chucklenuts",
        pol: "Druhna Basia",
        sch: "宝来鼠",
        fre: "Chucklenuts",
        bra: "Bochechudo",
        schema: 30320,
        hun: "Mókamókus",
        swe: "Korren"
    },
    699: {
        img: "tiny_timber",
        eng: "Tiny Timber",
        pol: "Podpałka",
        sch: "小小柴火堆",
        fre: "Petit bois",
        bra: "O Rei de Paus",
        schema: 30321,
        hun: "Tartalék Tűzifa",
        swe: "Liten Timmerstock"
    },
    700: {
        img: "face_full_of_festive",
        eng: "Face Full of Festive",
        pol: "Oświecone oblicze Śniąt",
        sch: "满带节日气息的大胡子",
        fre: "Barbe festive",
        bra: "Rosto Festivo",
        schema: 30322,
        hun: "Teljesen Ünnepi Arc",
        swe: "Det Festliga Fejset"
    },
    701: {
        img: "ruffled_ruprecht",
        eng: "Ruffled Ruprecht",
        pol: "Brodaty Bawarczyk",
        sch: "随从之胡",
        fre: "Le père froissé",
        bra: "O Ruprecht Ruço",
        schema: 30323,
        hun: "Borzas Ruprecht",
        swe: "Ruggiga Ruprecht"
    },
    702: {
        img: "golden_garment",
        eng: "Golden Garment",
        pol: "Ozdobne odzienie",
        sch: "金丝大衣",
        fre: "L'habit doré",
        bra: "As Dragonas Douradas",
        schema: 30324,
        hun: "Az Aranyzubbony",
        swe: "Det Gyllene Plagget"
    },
    703: {
        img: "little_drummer_mann",
        eng: "Little Drummer Mann",
        pol: "Maleńki dobosz",
        sch: "小小鼓手",
        fre: "Le petit homme au tambour",
        bra: "O Pequeno Percussionista",
        schema: 30325,
        hun: "A Kis Dobos",
        swe: "Den Lille Trumslagaren"
    },
    704: {
        img: "scout_shako",
        eng: "Scout Shako",
        pol: "Skautowe czako",
        sch: "小兵的斯托特军帽",
        fre: "Le frêle shako",
        bra: "O Shako do Scout",
        schema: 30326,
        hun: "A Cserkész Csákó",
        swe: "Scouts Schakå"
    },
    705: {
        img: "toy_tailor",
        eng: "Toy Tailor",
        pol: "Starszy elf podpalacz",
        sch: "玩具裁缝",
        fre: "Le lutin des jouets",
        bra: "O Elfaiate de Brinquedos",
        schema: 30327,
        hun: "A Játékkészítő",
        swe: "Leksaksskräddaren"
    },
    706: {
        img: "extra_layer",
        eng: "Extra Layer",
        pol: "Dodatkowa warstwa",
        sch: "厚实的夹克",
        fre: "La couche de plus",
        bra: "A Camada Extra",
        schema: 30328,
        hun: "Az Extra Réteg",
        swe: "Det Extra Lagret"
    },
    707: {
        img: "polar_pullover",
        eng: "Polar Pullover",
        pol: "Kozacka kominiara",
        sch: "极地头套",
        fre: "Le pullover polaire",
        bra: "O Pulôver Polar",
        schema: 30329,
        hun: "Sarkvidéki Sityak",
        swe: "Polovern"
    },
    708: {
        img: "dogfighter",
        eng: "Dogfighter",
        pol: "As przestworzy",
        sch: "飞行员的夹克衫",
        fre: "Le baroudeur",
        bra: "O Piloto Pelejador",
        schema: 30330,
        hun: "A Légiharcos",
        swe: "Kurvstridaren"
    },
    709: {
        img: "antarctic_parka",
        eng: "Antarctic Parka",
        pol: "Antarktyczna parka",
        sch: "南极御寒大衣",
        fre: "Parka antarctique",
        bra: "Parka Antártica",
        schema: 30331,
        hun: "Antarktika Parka",
        swe: "Antarktisk parka"
    },
    710: {
        img: "runners_warm_up",
        eng: "Runner's Warm-Up",
        pol: "Zimowa rozgrzewka",
        sch: "跑垒者的护耳帽",
        fre: "Réchauffement du coureur",
        bra: "Aquecedor do Corredor",
        schema: 30332,
        hun: "A Futó Bemelegítője",
        swe: "Springarens Uppvärmning"
    },
    711: {
        img: "highland_high_heels",
        eng: "Highland High Heels",
        pol: "Taternicze tartanowe trapery",
        sch: "高地人的高跟靴",
        fre: "Talons des Highlands",
        bra: "Botas Altas das Terras Altas",
        schema: 30333,
        hun: "Magaslati Magas Szárú",
        swe: "Höglandskängor"
    },
    712: {
        img: "tartan_tyrolean",
        eng: "Tartan Tyrolean",
        pol: "Tyrolski tartan",
        sch: "苏格兰方格花纹提洛帽",
        fre: "Tyrolien écossais",
        bra: "Tirolês Tartan",
        schema: 30334,
        hun: "Tartán Tiroli",
        swe: "Tartantyrolern"
    },
    713: {
        img: "marshalls_mutton_chops",
        eng: "Marshall's Mutton Chops",
        pol: "Pekaesy pułkownika",
        sch: "警长的白花胡子",
        fre: "Favoris du Marshall",
        bra: "Costeletas do Comandante",
        schema: 30335,
        hun: "A Tábornagy Pofaszakálla",
        swe: "Marshallens Kejsarskägg"
    },
    714: {
        img: "ticket_boy",
        eng: "Ticket Boy",
        pol: "Bileter",
        sch: "查票小哥",
        fre: "Le Tickettier",
        bra: "O Bilheteiro",
        schema: 30376,
        hun: "A Jegyszedőfiú",
        swe: "Biljettpojken"
    },
    715: {
        img: "ground_control",
        eng: "Ground Control",
        pol: "Halo, tu wieża",
        sch: "地面控制",
        fre: "Contrôleur terrestre",
        bra: "Controlador Terrestre",
        schema: 30338,
        hun: "Földi Irányítás",
        swe: "Markkontroll"
    },
    716: {
        img: "killers_kit",
        eng: "Killer's Kit",
        pol: "Zestaw zabijaki",
        sch: "杀手的工具包",
        fre: "Le kit du tueur",
        bra: "Os Aparatos do Assassino",
        schema: 30339,
        hun: "A Gyilkos Fazon",
        swe: "Soldatens Ställ"
    },
    717: {
        img: "cute_suit",
        eng: "Cute Suit",
        pol: "Ziomek błyskawiczny",
        sch: "超萌外套",
        fre: "La jolie combi",
        bra: "O Traje Tímido",
        schema: 30367,
        hun: "A Cuki Ruci",
        swe: "Den söta dräkten"
    },
    718: {
        img: "sole_mate",
        eng: "Sole Mate",
        pol: "Bratnia gorbusza",
        sch: "大嘴情侣",
        fre: "Sole partenaire",
        bra: "Bacalhalma Gêmea",
        schema: 30355,
        hun: "Lelki Társhal",
        swe: "Plattfiskkompisen"
    },
    719: {
        img: "bushi_dou",
        eng: "Bushi-Dou",
        pol: "Bushi-Dou",
        sch: "武士道",
        fre: "Bushi-Dou",
        bra: "Bushi-Dou",
        schema: 30348
    },
    720: {
        img: "dark_falkirk_helm",
        eng: "Dark Falkirk Helm",
        pol: "Mroczna przyłbica",
        sch: "黑暗的福尔柯克头盔",
        fre: "Le sombre casque de Falkirk",
        bra: "O Elmo Sombrio de Falkirk",
        schema: 30357,
        hun: "A Sötét Falkirk Sisak",
        swe: "Mörka Falkirkhjälmen"
    },
    721: {
        img: "juggernaut_jacket",
        eng: "Juggernaut Jacket",
        pol: "Kozacki kevlar",
        sch: "霸王夹克衫",
        fre: "Tenue anti-explosifs",
        bra: "O Colete Colossal",
        schema: 30363,
        hun: "A Zúzós Zubbony",
        swe: "Juggernautjackan"
    },
    722: {
        img: "sangu_sleeves",
        eng: "Sangu Sleeves",
        pol: "Samurajskie sangu",
        sch: "甲胄护腕",
        fre: "Les brassards Sangu",
        bra: "A Kobertura Kote",
        schema: 30366,
        hun: "A Sangu Karvért",
        swe: "Sangu-Ärmarna"
    },
    723: {
        img: "sole_saviors",
        eng: "Sole Saviors",
        pol: "Nikczemne nagolennice",
        sch: "脚丫救世主",
        fre: "Le Sauveur de semelles",
        bra: "As Solas do Salvador",
        schema: 30358,
        hun: "A Talpalávalók",
        swe: "De ensamma överlevarna"
    },
    724: {
        img: "stylish_degroot",
        eng: "Stylish DeGroot",
        pol: "Stylowy DeGroot",
        sch: "时髦的头带",
        fre: "Élégant DeGroot",
        bra: "DeGroot Descolado",
        schema: 30340,
        hun: "Stílusos DeGroot",
        swe: "Stiliga DeGroot"
    },
    725: {
        img: "bullet_buzz",
        eng: "Bullet Buzz",
        pol: "Jak kula do jeża",
        sch: "子弹配平头",
        fre: "Balle règlementaire",
        bra: "Corte Comando",
        schema: 30344,
        hun: "Golyózápor",
        swe: "Kulsurret"
    },
    726: {
        img: "combat_slacks",
        eng: "Combat Slacks",
        pol: "Bitewne bambetle",
        sch: "格斗便裤",
        fre: "Tenue de combat",
        bra: "As Calças de Combate",
        schema: 30372,
        hun: "Csatacucc",
        swe: "Stridsbyxor"
    },
    727: {
        img: "eliminators_safeguard",
        eng: "Eliminator's Safeguard",
        pol: "Ochrona likwidatora",
        sch: "歼灭者的防暴头盔",
        fre: "La protection du destructeur",
        bra: "A Proteção do Eliminador",
        schema: 30369,
        hun: "A Tömegoszlatók Védelmezője",
        swe: "Eliminerarens skyddshjälm"
    },
    728: {
        img: "gone_commando",
        eng: "Gone Commando",
        pol: "Kolosalny komandos",
        sch: "剥光的突击兵",
        fre: "Passé commando",
        bra: "Calção Antipredatório",
        schema: 30343,
        hun: "Kommandós Stílus",
        swe: "Kommandören"
    },
    729: {
        img: "heavy_lifter",
        eng: "Heavy Lifter",
        pol: "Potężny paker",
        sch: "健硕的举重运动员",
        fre: "L'haltérophile",
        bra: "O Heavy Halterofilista",
        schema: 30342,
        hun: "A Súlyemelő",
        swe: "Tyngdlyftaren"
    },
    730: {
        img: "leftover_trap",
        eng: "Leftover Trap",
        pol: "Pułapka na okruchy",
        sch: "大胡子陷阱",
        fre: "Le piège à miettes",
        bra: "A Sujeira das Sobras",
        schema: 30345,
        hun: "A Morzsafogó",
        swe: "Smulfällan"
    },
    731: {
        img: "rat_stompers",
        eng: "Rat Stompers",
        pol: "Kamasze kloszarda",
        sch: "耗子践踏者",
        fre: "Les Bottes Anti-rat",
        bra: "As Botas Mata-ratos",
        schema: 30354,
        hun: "A Patkánytaposó",
        swe: "Råttstamparna"
    },
    732: {
        img: "sammy_cap",
        eng: "Sammy Cap",
        pol: "Kanapułapka",
        sch: "三明治之帽",
        fre: "L'attrape Sammy",
        bra: "A Vara de 'Viche",
        schema: 30374,
        hun: "A Szendó-sapka",
        swe: "Smörgåshatten"
    },
    733: {
        img: "trash_man",
        eng: "Trash Man",
        pol: "Mycka menela",
        sch: "拾荒者之帽",
        fre: "Éboueur Man",
        bra: "O Homem do Lixo",
        schema: 30346,
        hun: "A Kukás",
        swe: "Sopgubben"
    },
    734: {
        img: "war_goggles",
        eng: "War Goggles",
        pol: "Gogle wojenne",
        sch: "战争护目镜",
        fre: "Les lunettes de guerre",
        bra: "Os Óculos de Guerra",
        schema: 30368,
        hun: "A Hadi Kukker",
        swe: "Krigsglasögonen"
    },
    735: {
        img: "warmth_preserver",
        eng: "Warmth Preserver",
        pol: "Ocieplana kamizela",
        sch: "保暖的无袖羽绒",
        fre: "Le Conservateur de chaleur",
        bra: "O Conservador de Calor",
        schema: 30364,
        hun: "A Melegen Tartó",
        swe: "Värmehållaren"
    },
    736: {
        img: "antarctic_researcher",
        eng: "Antarctic Researcher",
        pol: "Arktyczny odkrywca",
        sch: "南极研究员",
        fre: "Le chercheur polaire",
        bra: "O Pesquisador Antártico",
        schema: 30377,
        hun: "A Sarkkutató",
        swe: "Den Antarktiska Utforskaren"
    },
    737: {
        img: "ein",
        eng: "Ein",
        pol: "Ein",
        sch: "矿工的金丝雀",
        fre: "Ein",
        bra: "Ein",
        schema: 30341
    },
    738: {
        img: "scotch_saver",
        eng: "Scotch Saver",
        pol: "Łapacz whisky",
        sch: "苏格兰节俭者",
        fre: "Le sauveur de scotch",
        bra: "A Barba Bêbada",
        schema: 30347,
        hun: "A Skót Megmentő",
        swe: "Whiskyspararen"
    },
    739: {
        img: "trenchers_topper",
        eng: "Trencher's Topper",
        pol: "Okopowa osłona",
        sch: "战壕兵的礼帽",
        fre: "Le couvre-chef des tranchées",
        bra: "A Tranqueira da Trincheira",
        schema: 30336,
        hun: "A Lövészárokásó Fejfedője",
        swe: "Skyttegrävarens Hatt"
    },
    740: {
        img: "trenchers_tunic",
        eng: "Trencher's Tunic",
        pol: "Okopowe odzienie",
        sch: "战壕兵的束腰外衣",
        fre: "La tunique des tranchées",
        bra: "A Túnica das Trincheiras",
        schema: 30337,
        hun: "A Lövészárokásó Kabátja",
        swe: "Skyttegrävarens Skjorta"
    },
    741: {
        img: "colonels_coat",
        eng: "Colonel's Coat",
        pol: "Odzienie oficera",
        sch: "陆军上校的大衣",
        fre: "La veste du colonel",
        bra: "O Casaco do Coronel",
        schema: 30361,
        hun: "Ezredesi Kabát",
        swe: "Överstens Rock"
    },
    742: {
        img: "dough_puncher",
        eng: "Dough Puncher",
        pol: "Krawat kuchcika",
        sch: "揉面大厨的工作服",
        fre: "Le batteur de pâte",
        bra: "O Amassador de Massas",
        schema: 30350,
        hun: "A Tésztagyúró",
        swe: "Degknådaren"
    },
    743: {
        img: "fashionable_megalomaniac",
        eng: "Fashionable Megalomaniac",
        pol: "Modny megaloman",
        sch: "时髦的自大狂",
        fre: "La mode du mégalomane",
        bra: "O Megalomaníaco Magnífico",
        schema: 30349,
        hun: "A Divatos Megalomániás",
        swe: "Den Moderiktiga Megalomanisten"
    },
    744: {
        img: "gaiter_guards",
        eng: "Gaiter Guards",
        pol: "Germańskie gamasze",
        sch: "绑腿守卫",
        fre: "Le protecteur des guêtres",
        bra: "As Galochas do Galeno",
        schema: 30379,
        hun: "Kamáslik",
        swe: "Damaskskydden"
    },
    745: {
        img: "heat_of_winter",
        eng: "Heat of Winter",
        pol: "Zimna wojna",
        sch: "冬天里的一把热",
        fre: "La Chaleur de l'hiver",
        bra: "O Calor do Inverno",
        schema: 30356,
        hun: "A Tél Melege",
        swe: "Vinterns hetta"
    },
    746: {
        img: "heers_helmet",
        eng: "Heer's Helmet",
        pol: "Hełm Heeru",
        sch: "德国陆军的头盔",
        fre: "Le Casque de la Heer",
        bra: "Stahlhelm da Heer",
        schema: 30378,
        hun: "Heer Sisak",
        swe: "Heers hjälm"
    },
    747: {
        img: "mustachioed_mann",
        eng: "Mustachioed Mann",
        pol: "Wąsaty wodzirej",
        sch: "长胡子曼恩",
        fre: "Le moustachu",
        bra: "O Semblante do Surrealista",
        schema: 30352,
        hun: "Mannő Bajusz",
        swe: "Den mustacherade mannen"
    },
    748: {
        img: "smock_surgeon",
        eng: "Smock Surgeon",
        pol: "Fartuch felczera",
        sch: "外科医生的工作服",
        fre: "Le tablier de chirurgien",
        bra: "A Bata de Biópsia",
        schema: 30365,
        hun: "A Sebészkötény",
        swe: "Kirurgens Skyddsrock"
    },
    749: {
        img: "teutonic_toque",
        eng: "Teutonic Toque",
        pol: "Kapelusz kuchcika",
        sch: "日耳曼大厨帽",
        fre: "La toque teutonique",
        bra: "O Toque Teutônico",
        schema: 30351,
        hun: "A Teuton Toque",
        swe: "Tyska Kockmössan"
    },
    750: {
        img: "archers_groudings",
        eng: "Archer's Groundings",
        pol: "Trzewiki łucznika",
        sch: "射手之靴",
        fre: "Bottines de l'archer",
        bra: "Os Apoios do Arqueiro",
        schema: 30371,
        hun: "Az Íjászat Alapjai",
        swe: "Bågskyttens stövlar"
    },
    751: {
        img: "huntsmans_essentials",
        eng: "Huntsman's Essentials",
        pol: "Niezbędnik łowcy",
        sch: "猎手的必需品",
        fre: "Les indispensables du Huntsman",
        bra: "O Equipamento do Caçador",
        schema: 30359,
        hun: "A Levadász-kellék",
        swe: "Jägarens nödvändigheter"
    },
    752: {
        img: "toowoomba_tunic",
        eng: "Toowoomba Tunic",
        pol: "Tunika z Toowoomba",
        sch: "图文巴束腰外衣",
        fre: "La Tunique de Toowoomba",
        bra: "A Túnica de Toowoomba",
        schema: 30373,
        hun: "A Toowoombai Tunika",
        swe: "Toowombatröjan"
    },
    753: {
        img: "backstabbers_boomslang",
        eng: "Backstabber's Boomslang",
        pol: "Zdradziecki zaskroniec",
        sch: "背刺者之蛇",
        fre: "Le Boomslang du poignardage",
        bra: "A Peçonha do Pérfido",
        schema: 30353,
        hun: "Az Alattomos Kígyó",
        swe: "Rygghuggarens Huggorm"
    },
    754: {
        img: "napoleon_complex",
        eng: "Napoleon Complex",
        pol: "Napoleon Szedł-w-Zaparte",
        sch: "拿破仑情结",
        fre: "Le Complexe de Napoléon",
        bra: "O Complexo de Napoleão",
        schema: 30360,
        hun: "A Napóleon-komplexus",
        swe: "Napoleonkomplexet"
    },
    755: {
        img: "deep_cover_operator",
        eng: "Deep Cover Operator",
        pol: "Zabili go i uciekł",
        sch: "潜行大师",
        fre: "L'agent sous couverture",
        bra: "A Faixa Furtiva",
        schema: 30375,
        hun: "A Nagyon Beépült Ügynök",
        swe: "Dolda Agenten"
    },
    756: {
        img: "law",
        eng: "Law",
        pol: "Dzielnicowy",
        sch: "警官大檐帽",
        fre: "La loi",
        bra: "A Lei",
        schema: 30362,
        hun: "A Törvény",
        swe: "Lagen"
    },
    757: {
        img: "pretty_boys_pocket_pistol",
        eng: "Pretty Boy's Pocket Pistol",
        pol: "Poręczny pistolet Pretty Boya",
        sch: "帅气男孩的袖珍手枪",
        fre: "Le Pistolet de Poche du Beau Gosse",
        bra: "A Pistola Portátil do Peralta",
        schema: 773,
        schema2: 5721,
        hun: "Szépfiús Zsebpisztoly",
        swe: "Snyggingens Pangpistol"
    },
    758: {
        img: "phlogistinator",
        eng: "Phlogistinator",
        pol: "Flogistynator",
        sch: "燃素喷射器",
        fre: "Le Phlogistinateur",
        bra: "O Flogistinador",
        schema: 594,
        schema2: 5722,
        hun: "A Flogisztonizáló",
        swe: "Flogistoneraren"
    },
    759: {
        img: "cleaners_carbine",
        eng: "Cleaner's Carbine",
        pol: "Spluwa sprzątacza",
        sch: "扫荡者卡宾",
        fre: "La Carabine du Nettoyeur",
        bra: "A Carabina do Carrasco",
        schema: 751,
        schema2: 5723,
        hun: "A Karbantartó Karabély",
        swe: "Undanröjarens Umgänge"
    },
    760: {
        img: "strange_cosmetic_part_kills",
        eng: "Strange Cosmetic Part: Kills",
        pol: "Kuriozalna część dla przedmiotów ozdobnych: Zabici",
        sch: "奇异饰品部件：杀敌数",
        fre: "Pièce cosmétique étrange : Victimes",
        bra: "Peça Estranha para Cosméticos: Vítimas",
        schema: 6060,
        hun: "Fura Díszítő Alkatrész: Ölések",
        swe: "Märklig Kosmetisk Del: Dödade"
    },
    761: {
        img: "strange_part_full_health_kills",
        eng: "Strange Part: Full Health Kills",
        pol: "Kuriozalna część: Zabójstw z pełnym zdrowiem",
        sch: "奇异武器升级部件：满血杀敌数",
        fre: "Victimes lorsque santé est au maximum",
        bra: "Peça Estranha: Vítimas Enquanto com Vida Cheia",
        schema: 6061,
        hun: "Fura Alkatrész: Teljes életerejű ölések",
        swe: "Märklig Del: Dödade vid full hälsa"
    },
    762: {
        img: "giftapult",
        eng: "Giftapult",
        pol: "Daropulta",
        fre: "Cadeaupulte",
        sch: "礼物发射器",
        bra: "Presentapulta",
        schema: 5083,
        hun: "Ajándékkatapult",
        swe: "Presentkastare"
    },
    763: {
        img: "strange_count_transfer_tool",
        eng: "Strange Count Transfer Tool",
        pol: "Narzędzie przekazujące statystykę kuriozum",
        fre: "Outil de transfert de compteur étrange",
        sch: "奇异计数转移工具",
        bra: "Ferramenta de Transferência de Contagens Estranhas",
        schema: 5818,
        hun: "Fura Pont-átvivő Eszköz",
        swe: "Verktyg för överföring av märklig räknare"
    },
    764: {
        img: "killstreak_kit",
        eng: "Killstreak #ITEM# Kit",
        pol: "Zestaw serii zabójstw: #ITEM#",
        fre: "Kit: #ITEM# Killstreak",
        sch: "连杀记录式 #ITEM# 工具包",
        bra: "Kit de Combo para #ITEM#",
        schema: 6527,
        hun: "Ámokfutás-készlet #ITEM#",
        swe: "Mördarserie #ITEM# Kit"
    },
    765: {
        img: "specialized_killstreak_kit",
        eng: "Specialized Killstreak #ITEM# Kit",
        pol: "Zestaw wyspecjalizowanej serii zabójstw: #ITEM#",
        fre: "Kit: #ITEM# Killstreak spécialisé",
        sch: "高级连杀记录式 #ITEM# 工具包",
        bra: "Kit de Combo Especializado para #ITEM#",
        schema: 6523,
        hun: "Specializált Ámokfutás-készlet #ITEM#",
        swe: "Specialiserad mördarserie #ITEM# Kit"
    },
    766: {
        img: "professional_killstreak_kit",
        eng: "Professional Killstreak #ITEM# Kit",
        pol: "Zestaw profesjonalnej serii zabójstw: #ITEM#",
        fre: "Kit: #ITEM# Killstreak professionnel",
        sch: "职业连杀记录式 #ITEM# 工具包",
        bra: "Kit de Combo Profissional para #ITEM#",
        schema: 6526,
        hun: "Hivatásos Ámokfutás-készlet #ITEM#",
        swe: "Professionell mördarserie #ITEM# Kit"
    },
    767: {
        img: "tour_of_duty_ticket",
        eng: "Tour of Duty Ticket",
        pol: "Bilet służby wojskowej",
        fre: "Ticket de Tour of Duty",
        sch: "使命之旅入场券",
        bra: "Ingresso da Campanha",
        schema: 725,
        hun: "Szolgálati Turnus Jegy",
        swe: "Tjänstgöringsbiljett"
    },
    768: {
        img: "backpack_expander",
        eng: "Backpack Expander",
        pol: "Rozszerzacz plecaka",
        fre: "Agrandisseur d'Inventaire",
        sch: "背包扩充工具",
        bra: "Expansor de Mochila",
        schema: 5050,
        hun: "Hátizsákbővítő",
        swe: "Ryggsäcksutökare"
    },
    769: {
        img: "decal_tool",
        eng: "Decal Tool",
        pol: "Naklejka",
        fre: "Outil de décalcomanie",
        sch: "贴纸工具",
        bra: "Ferramenta de Decalque",
        schema: 5026,
        hun: "Matrica eszköz",
        swe: "Dekalverktyg"
    },
    770: {
        img: "unusualifier",
        eng: "#ITEM# Unusualifier",
        pol: "Uniezwyklacz: #ITEM#",
        fre: "Inhabituellisateur: #ITEM#",
        sch: "#ITEM# 稀有转化魔法",
        bra: "#ITEM# Incomunizador",
        schema: 9258,
        hun: "#ITEM# Rendkívüliesítő",
        swe: "#ITEM# Ovanligifierare"
    },
    771: {
        img: "aged_moustache_grey",
        eng: "Aged Moustache Grey",
        pol: "Szarość wiekowych wąsów",
        fre: "Gris Moustache Grisonnante",
        sch: "老年胡须灰",
        bra: "Cinza Bigode Envelhecido",
        schema: 5038,
        hun: "Őszülő Bajusz Szürke",
        swe: "Åldrad Mustasch-Grå"
    },
    772: {
        img: "mutated_milk",
        eng: "Mutated Milk",
        pol: "Zmutowane mleko",
        sch: "突变牛奶",
        fre: "Lait mutant",
        bra: "A Larva do Leite",
        schema: 1121,
        hun: "Mutáns Tej",
        swe: "Muterad mjölk"
    },
    773: {
        img: "bread_bite",
        eng: "Bread Bite",
        pol: "Chlebogryzarka",
        sch: "咬人的面包",
        fre: "La Morsure du pain",
        bra: "O Pão de Munição",
        schema: 1100,
        hun: "A Kenyérfalat",
        swe: "Brödbettet"
    },
    774: {
        img: "self_aware_beauty_mark",
        eng: "Self-Aware Beauty Mark",
        pol: "Samoświadomy pieprzyk",
        sch: "迷人的生命体",
        fre: "Le grain de beauté conscient",
        bra: "A Bolota Senciente",
        schema: 1105,
        hun: "Az Öntudatra Ébredt Anyajegy",
        swe: "Den självmedvetna skönhetsfläcken"
    },
    775: {
        img: "snack_attack",
        eng: "Snack Attack",
        pol: "Atak przekąską",
        sch: "进击的零食",
        fre: "L'Attaque du snack",
        bra: "A Revanche do Lanche",
        schema: 1102,
        hun: "A Falatozó Falat",
        swe: "Mellanmålsmördaren"
    },
    776: {
        img: "argyle_ace",
        eng: "Argyle Ace",
        pol: "Kraciasty krok",
        sch: "时髦鞋袜",
        fre: "Les Chaussettes à carreaux",
        bra: "A Meia de Meia-idade",
        schema: 30427,
        hun: "A Kockás Király",
        swe: "Argyleässet"
    },
    777: {
        img: "frickin_sweet_ninja_hood",
        eng: "Frickin' Sweet Ninja Hood",
        pol: "Przesłodki kaptur ninja",
        sch: "忍者小斗篷",
        fre: "La Capuche ninja carrément cool",
        bra: "O Capuz do Ninja Maloqueiro",
        schema: 30394,
        hun: "A Durva Menő Nindzsacsuklya",
        swe: "Den jäkligt snygga ninjamasken"
    },
    778: {
        img: "paisley_pro",
        eng: "Paisley Pro",
        pol: "Paislejowe polo",
        sch: "佩斯利花边衬衫",
        fre: "Le Paisley du pro",
        bra: "O Profissional de Paisley",
        schema: 30426,
        hun: "A Paisley Profi",
        swe: "Paisleyproffset"
    },
    779: {
        img: "pomade_prince",
        eng: "Pomade Prince",
        pol: "Pan pomada",
        sch: "王子的发油头",
        fre: "Le Prince du gel",
        bra: "A Pomada do Príncipe",
        schema: 30428,
        hun: "A Hajzselés Herceg",
        swe: "Pomadaprinsen"
    },
    780: {
        img: "red_socks",
        eng: "Red Socks",
        pol: "Czerwone skarpety",
        sch: "红袜子",
        fre: "Les Chaussettes Rouges",
        bra: "As Meias Vermelhas",
        schema: 30396,
        hun: "A Piros Zoknik",
        swe: "De Röda Sockorna"
    },
    781: {
        img: "southie_shinobi",
        eng: "Southie Shinobi",
        pol: "Ninja spod Bostonu",
        sch: "南方小忍者",
        fre: "Le Shinobi de Boston",
        bra: "O Shinobi do Sul",
        schema: 30395,
        hun: "A Déli Shinobi",
        swe: "Söderns Shinobi"
    },
    782: {
        img: "classified_coif",
        eng: "Classified Coif",
        pol: "Marynarka agenta",
        sch: "公务员的风衣",
        fre: "Le Col Blanc",
        bra: "O Casaco Confidencial",
        schema: 30388,
        hun: "A Titkos Télikabát",
        swe: "Den Klassificerade Coifen"
    },
    783: {
        img: "man_in_slacks",
        eng: "Man in Slacks",
        pol: "Facet w spodniach",
        sch: "黑裤人",
        fre: "L'Homme de Goût",
        bra: "A Calça que Sabia Demais",
        schema: 30392,
        hun: "A Laza Naci",
        swe: "Man i Slacks"
    },
    784: {
        img: "spook_specs",
        eng: "Spook Specs",
        pol: "Okulary tajniaka",
        sch: "秘密探员墨镜",
        fre: "Les Lunettes Secrètes",
        bra: "O Detetive Destemido",
        schema: 30390,
        hun: "A Kémleső",
        swe: "Spionage-solglasögonen"
    },
    785: {
        img: "combustible_kabuto",
        eng: "Combustible Kabuto",
        pol: "Łatwopalne kabuto",
        sch: "易燃武士盔",
        fre: "Le Kabuto Inflammable",
        bra: "O Kabuto Kombustível",
        schema: 30418,
        hun: "A Gyúlékony Kabuto",
        swe: "Den Brännbara Kabuton"
    },
    786: {
        img: "employee_of_the_mmmph",
        eng: "Employee of the Mmmph",
        pol: "Pracownik mmmphca",
        sch: "麦呜唔快餐员工",
        fre: "Employé du Mmmph",
        bra: "Empregado do Mmmph",
        schema: 30416,
        hun: "A Hmmpf Dolgozója",
        swe: "Mmmphnadens anställd"
    },
    787: {
        img: "frymaster",
        eng: "Frymaster",
        pol: "Mistrz kuchni",
        sch: "油炸活人",
        fre: "Le Maître ès Frites",
        bra: "O Mestre da Fritura",
        schema: 30417,
        hun: "A Süt-ő-mester",
        swe: "Fritermästaren"
    },
    788: {
        img: "gas_guzzler",
        eng: "Gas Guzzler",
        pol: "Pożeracz paliwa",
        sch: "跑车排气管",
        fre: "L'Usine à Gaz",
        bra: "O Gasólotra",
        schema: 30398,
        hun: "A Benzinzabáló",
        swe: "Bensinslukaren"
    },
    789: {
        img: "lunatics_leathers",
        eng: "Lunatic's Leathers",
        pol: "Skórzany szaleniec",
        sch: "疯狂车手的皮衣",
        fre: "Le Cuir du Dérangé",
        bra: "Couro Chamuscado",
        schema: 30400,
        hun: "A Bolond Bőrkabátja",
        swe: "Psykfallets Läderpaj"
    },
    790: {
        img: "sengoku_scorcher",
        eng: "Sengoku Scorcher",
        pol: "Podpalacz z Sengoku",
        sch: "本能寺纵火者",
        fre: "Fougue Sengoku",
        bra: "Xogum Chamuscado",
        schema: 30391,
        hun: "Sengoku Perzselő",
        swe: "Sengoku-sotaren"
    },
    791: {
        img: "smoking_skid_lid",
        eng: "Smoking Skid Lid",
        pol: "Apokaliptyczny kask",
        sch: "冒烟尖顶盔",
        fre: "Le Casque à pointe du flambeur",
        bra: "O Capacete de Fumaça",
        schema: 30399,
        hun: "A Füstös Fejfedő",
        swe: "Den Pyromaniska Pickelhuvan"
    },
    792: {
        img: "allbrero",
        eng: "Allbrero",
        pol: "Bylibrero",
        sch: "墨西哥草帽",
        fre: "Le sombre héros",
        bra: "O Solbrero",
        schema: 30429,
        hun: "A Mindbrero",
        swe: "Allbreron"
    },
    793: {
        img: "frontier_djustice",
        eng: "Frontier Djustice",
        pol: "Dsamosąd",
        sch: "墨西哥边疆正义",
        fre: "La Djustice Frontalière",
        bra: "O Djusticeiro",
        schema: 30421,
        hun: "Az Elszabadult Önbíráskodó",
        swe: "Frontens Rättvisa"
    },
    794: {
        img: "razor_cut",
        eng: "Razor Cut",
        pol: "Ostry fryz",
        sch: "剃刀发型",
        fre: "La Coupe au rasoir",
        bra: "O Corte à Navalha",
        schema: 30393,
        hun: "Az Éles Vágás",
        swe: "Rakbladsklippningen"
    },
    795: {
        img: "seeing_double",
        eng: "Seeing Double",
        pol: "Widzenie zdwojone",
        sch: "醉汉太阳镜",
        fre: "Double vision",
        bra: "Visão Dobrada",
        schema: 30430,
        hun: "Duplán Látó",
        swe: "Ser dubbelt"
    },
    796: {
        img: "six_pack_abs",
        eng: "Six Pack Abs",
        pol: "Sześciopak",
        sch: "六瓶装啤酒",
        fre: "Les tablettes de chocolat",
        bra: "O Abdome Alcoólico",
        schema: 30431,
        hun: "Kockás Sörhas",
        swe: "Sexpacket"
    },
    797: {
        img: "yuris_revenge",
        eng: "Yuri's Revenge",
        pol: "Zemsta Jurija",
        sch: "尤里的复仇",
        fre: "Revanche de Yuri",
        bra: "A Vingança do Yuri",
        schema: 30401,
        hun: "Yuri Bosszúja",
        swe: "Yuris Hämnd"
    },
    798: {
        img: "danger",
        eng: "Danger",
        pol: "Zagrożenie",
        sch: "绝命毒工",
        fre: "Le Danger",
        bra: "O Perigo",
        schema: 30420,
        hun: "A Veszély",
        swe: "Faran"
    },
    799: {
        img: "eggheads_overalls",
        eng: "Egghead's Overalls",
        pol: "Okrągłości jajogłowego",
        sch: "书呆子衬衫",
        fre: "La blouse de l'intello",
        bra: "O Barrigão do Cabeção",
        schema: 30408,
        hun: "Az Okostojás Kardigánja",
        swe: "Ägghuvudets Overaller"
    },
    800: {
        img: "endothermic_exowear",
        eng: "Endothermic Exowear",
        pol: "Endotermiczne egzoubranie",
        sch: "吸热外套",
        fre: "L'Exo-combi Endothermique",
        bra: "A Exovestimenta Endotérmica",
        schema: 30412,
        hun: "A Hőelnyelő Melegítő",
        swe: "De Endotermiska Exokläderna"
    },
    801: {
        img: "joe_on_the_go",
        eng: "Joe-on-the-Go",
        pol: "Przenośny inżynier",
        sch: "偷运宇航猴",
        fre: "Le Joe-Sac-à-Dos",
        bra: "Macaco pra Toda Obra",
        schema: 30403,
        hun: "A Menetkész Mérnök",
        swe: "Portabel Joe"
    },
    802: {
        img: "level_three_chin",
        eng: "Level Three Chin",
        pol: "Potrójny podbródek",
        sch: "肉肉的下巴",
        fre: "Le Triple Menton",
        bra: "O Queixo Nível Três",
        schema: 30407,
        hun: "A Hármas Szintű Toka",
        swe: "Haka Nivå Tre"
    },
    803: {
        img: "lonesome_loafers",
        eng: "Lonesome Loafers",
        pol: "Łapcie łamagi",
        sch: "孤独一生的凉鞋",
        fre: "Les Mocassins du solitaire",
        bra: "As Meias Melancólicas",
        schema: 30409,
        hun: "A Magányos Mamusz",
        swe: "De Solitära Sandalerna"
    },
    804: {
        img: "peaceniks_ponytail",
        eng: "Peacenik's Ponytail",
        pol: "Kucyk pacyfisty",
        sch: "反战分子的马尾头",
        fre: "La Queue de Cheval du Pacifiste",
        bra: "O Rabo de Cavalo do Revolucionário",
        schema: 30406,
        hun: "A Békeharcos Hajfonata",
        swe: "Hippiens Hästsvans"
    },
    805: {
        img: "tools_of_the_trade",
        eng: "Tools of the Trade",
        pol: "Narzędzia fachu",
        sch: "工具背心",
        fre: "Les Bons Outils",
        bra: "Os Ossos do Ofício",
        schema: 30402,
        hun: "A Munka Eszközei",
        swe: "Branchens Verktyg"
    },
    806: {
        img: "chronoscarf",
        eng: "Chronoscarf",
        pol: "Chronoszalik",
        sch: "时空围巾",
        fre: "L'Écharpe temporelle",
        bra: "O Cronocachecol",
        schema: 30419,
        hun: "Az Idősál",
        swe: "Krono-scarfen"
    },
    807: {
        img: "medicine_manpurse",
        eng: "Medicine Manpurse",
        pol: "Torba medyczna",
        sch: "装药的男式手袋",
        fre: "La sacoche médicinale",
        bra: "A Mala Médica",
        schema: 30415,
        hun: "A Gyógyszeres Erszény",
        swe: "Den Medicinska Mansväskan"
    },
    808: {
        img: "ze_ubermensch",
        eng: "Ze Übermensch",
        pol: "Ze Übermensch",
        sch: "尼采的超人胡子",
        fre: "Ze Übermensch",
        bra: "Ze Übermensch",
        schema: 30410,
        hun: "A Felschőbbrendű",
        ger: "Der Übermensch"
    },
    809: {
        img: "scopers_smoke",
        eng: "Scoper's Smoke",
        pol: "Fajka teleskopowa",
        sch: "烟枪狙击手",
        fre: "La Cigarette du Scoper",
        bra: "O Fumo do Franco-atirador",
        schema: 30423,
        hun: "A Távcsöves Füstje",
        swe: "Siktarens Rök"
    },
    810: {
        img: "triggermans_tacticals",
        eng: "Triggerman's Tacticals",
        pol: "Spodnie strzelca",
        sch: "射手的战术长裤",
        fre: "Le Bouffant du braqueur",
        bra: "As Calças do Carabineiro",
        schema: 30424,
        hun: "A Taktikai Lövésznadrág",
        swe: "Gevärsmannens Taktiska"
    },
    811: {
        img: "au_courant_assassin",
        eng: "Au Courant Assassin",
        pol: "Zabójca au courant",
        sch: "熟练杀手",
        fre: "L'assassin au courant",
        bra: "O Assassino Antenado",
        schema: 30411,
        hun: "A Penge Bérgyilkos",
        swe: "Den Dekorerade Dräparen"
    },
    812: {
        img: "aviator_assassin",
        eng: "Aviator Assassin",
        pol: "Podniebny zabójca",
        sch: "空中刺客",
        fre: "L'Aviateur Malfaiteur",
        bra: "O Assassino Aviador",
        schema: 30404,
        hun: "A Repülős Merénylő",
        swe: "Flygarmördaren"
    },
    813: {
        img: "rogues_robe",
        eng: "Rogue's Robe",
        pol: "Szlafrok łotra",
        sch: "盗贼长袍",
        fre: "Le Kimono du voyou",
        bra: "A Bata do Bandido",
        schema: 30389,
        hun: "A Zsivány Köntöse",
        swe: "Rövarens Rock"
    },
    814: {
        img: "sky_captain",
        eng: "Sky Captain",
        pol: "Podniebny kapitan",
        sch: "机长制服",
        fre: "Le Commandant de bord",
        bra: "O Capitão dos Céus",
        schema: 30405,
        hun: "Az Égi Kapitány",
        swe: "Himmelskaptenen"
    },
    815: {
        img: "mercs_mohawk",
        eng: "Merc's Mohawk",
        pol: "Irokez najemnika",
        sch: "佣兵的莫西干发型",
        fre: "Le Mohawk de mercenaire",
        bra: "O Moicano do Mercenário",
        schema: 30413,
        hun: "A Zsoldos Taraja",
        swe: "Legosoldatens Tuppkam"
    },
    816: {
        img: "bruisers_bandanna",
        eng: "Bruiser's Bandanna",
        pol: "Bandana pięściarza",
        sch: "彪形大汉面罩",
        fre: "Le Bandana du Bandito",
        bra: "A Bandana do Brutamontes",
        schema: 30397,
        hun: "A Kiütős Kendő",
        swe: "Bråkstakens Bandana"
    },
    817: {
        img: "eye_catcher",
        eng: "Eye-Catcher",
        pol: "Oczko w głowie",
        sch: "独眼龙",
        fre: "Le Tape-à-l'Oeil",
        bra: "O Papa-olho",
        schema: 30414,
        hun: "A Szembeötlő",
        swe: "Blickfångaren"
    },
    818: {
        img: "tipped_lid",
        eng: "Tipped Lid",
        pol: "Przechylony dekiel",
        sch: "卷边小礼帽",
        fre: "Le Chapeau bas",
        bra: "Chapéu-coco Caridoso",
        schema: 30425,
        hun: "Megbillentett Fejfedő",
        swe: "Tippat Lock"
    },
    819: {
        img: "vive_la_france",
        eng: "Vive La France",
        pol: "Wiwat Fhąsja",
        sch: "法兰西万岁",
        fre: "Vive La France",
        bra: "Vive La France",
        schema: 30422
    },
    820: {
        img: "ye_olde_rustic_colour",
        eng: "Ye Olde Rustic Colour",
        pol: "Odcień zaiste rdzawy",
        fre: "La Bonne Vieille Couleur des Familles",
        sch: "老式乡村啡色",
        bra: "Vossa Velha Cor Rústica",
        schema: 5036,
        hun: "Jó Öreg Rusztikus Színezet",
        swe: "Gammal Lantlig Färg"
    },
    821: {
        img: "an_air_of_debonair",
        eng: "An Air of Debonair",
        pol: "Powiew elegancji",
        fre: "Un Air de Débonnaire",
        sch: "优雅的姿态",
        bra: "Um Ar de Debochar",
        schema: 5063,
        hun: "Egy Árnyalatnyi Ámulat",
        swe: "En doft av charm"
    },
    822: {
        img: "balaclavas_are_forever",
        eng: "Balaclavas Are Forever",
        pol: "Kominiarki są wieczne",
        fre: "Les Cagoules Sont Éternelles",
        sch: "不朽的巴拉克拉瓦",
        bra: "As Balaclavas são Eternas",
        schema: 5062,
        hun: "A Símaszk Örök",
        swe: "Balaklavas För Alltid"
    },
    823: {
        img: "cream_spirit",
        eng: "Cream Spirit",
        pol: "Duch kremówki",
        fre: "Esprit Crème",
        sch: "奶油精神",
        bra: "Cremespírito de Equipe",
        schema: 5065,
        hun: "Krémek Krémje",
        swe: "Krämanda"
    },
    824: {
        img: "operators_overalls",
        eng: "Operator's Overalls",
        pol: "Ogrodniczki operatora",
        fre: "Salopette de l'Engineer",
        sch: "技工的工作服",
        bra: "Macacão do Operário",
        schema: 5060,
        hun: "A Gépész Szerelőruhája",
        swe: "Operatörens Overaller"
    },
    825: {
        img: "the_value_of_teamwork",
        eng: "The Value of Teamwork",
        pol: "Wartość współpracy",
        fre: "L'Importance du Travail d'Équipe",
        sch: "团队之力",
        bra: "O Valor do Trabalho em Equipe",
        schema: 5064,
        hun: "A Csapatmunka Értéke",
        swe: "Värdet av Lagarbete"
    },
    826: {
        img: "waterlogged_lab_coat",
        eng: "Waterlogged Lab Coat",
        pol: "Przemoknięty kitel laboratoryjny",
        fre: "Blouse de Labo Détrempée",
        sch: "浸满水的实验工作服",
        bra: "Jaleco Encharcado",
        schema: 5061,
        hun: "A Vizenyős Köpeny",
        swe: "Vattendränkt Labbrock"
    },
    827: {
        img: "aerobatics_demonstrator",
        eng: "Aerobatics Demonstrator",
        pol: "Lot pokazowy",
        fre: "Démonstrateur de voltige aérienne",
        sch: "醉酒驾机",
        bra: "Demonstrador de Acrobacias Aéreas",
        schema: 30793,
        hun: "Bombasztikus Légi Akrobata",
        swe: "Konstflyguppvisare"
    },
    828: {
        img: "final_frontier_freighter",
        eng: "Final Frontier Freighter",
        pol: "Końcowe odliczanie",
        fre: "Transporteur de l'ultime frontière",
        sch: "航地飞机",
        bra: "Cargueiro da Fronteira Final",
        schema: 30794,
        hun: "Az Orbitális Mérnök",
        swe: "Gränslandsfraktaren"
    },
    829: {
        img: "hovering_hotshot",
        eng: "Hovering Hotshot",
        pol: "Helikopter w ogniu",
        fre: "Super hélice",
        sch: "人肉直升机",
        bra: "Ares Ardentes",
        schema: 30795,
        hun: "Lebegő Lánglovag",
        swe: "Hovrande Höjdare"
    },
    830: {
        img: "classic",
        eng: "Classic",
        pol: "Klasyk",
        sch: "经典狙击步枪",
        fre: "Le Classique",
        bra: "O Clássico",
        schema: 1098,
        schema2: 5754,
        hun: "A Klasszikus",
        swe: "Klassikern"
    },
    831: {
        img: "manmelter",
        eng: "Manmelter",
        pol: "Człekoroztapiacz",
        sch: "熔人枪",
        fre: "Le Fissionneur",
        bra: "A Derretedora de Homens",
        schema: 595,
        schema2: 5755,
        hun: "Az Emberolvasztó",
        swe: "Människosmältaren"
    },
    832: {
        img: "crocodile_mun_dee",
        eng: "Crocodile Mun-Dee",
        pol: "Krokodyl Mun-Dee",
        fre: "Crocodile Mun-Dee",
        sch: "全员鳄人",
        bra: "Crocodilo Mun-Dee",
        schema: 31009,
        hun: "Krokodil Mun-Dee",
        swe: "Krokodil Mun-Dee"
    },
    833: {
        img: "strange_part_robot_scouts_destroyed",
        eng: "Strange Part: Robot Scouts Destroyed",
        pol: "Kuriozalna część: Zniszczeni Robo-Skauci",
        sch: "奇异武器升级部件：机器人侦察兵消灭数",
        fre: "Pièce étrange : Scouts Robots détruits",
        bra: "Peça Estranha: Scouts Robôs Destruídos",
        schema: 6042,
        hun: "Fura Alkatrész: Elpusztított Robot-felderítők",
        swe: "Märklig Del: Robotspanare förstörda"
    },
    834: {
        img: "strange_part_taunting_player_kills",
        eng: "Strange Part: Taunting Player Kills",
        pol: "Kuriozalna część: Zabici drwiący wrogowie",
        sch: "奇异武器升级部件：消灭正在嘲讽玩家数",
        fre: "Pièce étrange : Victimes effectuant une raillerie",
        bra: "Peça Estranha: Vítimas Provocadoras",
        schema: 6062,
        hun: "Fura Alkatrész: Megölt beszóló játékosok",
        swe: "Märklig Del: Hånande spelare dödade"
    },
    835: {
        img: "scopers_scales",
        eng: "Scoper's Scales",
        pol: "Łuskowate łapska",
        fre: "Reptile réputé",
        sch: "鳄人鳞甲",
        bra: "Escamas do Franco-atirador",
        schema: 31005,
        hun: "Hidegvérű Mesterlövész",
        swe: "Skyttens Skinn"
    },
    836: {
        img: "dell_in_the_shell",
        eng: "Dell in the Shell",
        pol: "Oczytany owodniowiec",
        fre: "Dell sous Carapace",
        sch: "龟工",
        bra: "Conagher no Casco",
        schema: 30995,
        hun: "Dell a Páncélban",
        ger: "Schildkrötenschrauber"
    },
    837: {
        img: "a_shell_of_a_mann",
        eng: "A Shell of a Mann",
        pol: "Karapaks konstruktora",
        fre: "La Carapace d'un Homme",
        sch: "曼恩鳖甲",
        bra: "O Casco de um Homem",
        schema: 30994,
        hun: "Férfias Páncélok",
        swe: "Skalmann"
    },
    838: {
        img: "nugget_noggin",
        eng: "Nugget Noggin",
        pol: "Ptasi móżdżek",
        sch: "鸡块大王",
        fre: "La Tronche d'Escalope Panée",
        bra: "O Galo Galante",
        schema: 30491,
        hun: "A Csirkekobak",
        swe: "Hönshuvud"
    },
    839: {
        img: "fowl_fists",
        eng: "Fowl Fists",
        pol: "Drobiowe dłonie",
        sch: "小鸡快跑",
        fre: "Les Poings Volatiles",
        bra: "Os Punhos Penosos",
        schema: 30492,
        hun: "A Szárnyas Öklök",
        swe: "Hönshänder"
    },
    840: {
        img: "talon_trotters",
        eng: "Talon Trotters",
        pol: "Kamasze kurczaka",
        sch: "劲脆鸡爪",
        fre: "Les Serres Trotteuses",
        bra: "As Garras Galináceas",
        schema: 30493,
        hun: "A Karmos Csülkök",
        swe: "Kycklingklor"
    },
    841: {
        img: "head_hunter",
        eng: "Head Hunter",
        pol: "Głowca",
        sch: "Hunter 的头",
        fre: "Le Chasseur de Têtes",
        bra: "O Caçador de Cabeças",
        schema: 30494,
        hun: "A Vadászfő",
        swe: "Huvudjägare"
    },
    842: {
        img: "claws_and_infect",
        eng: "Claws And Infect",
        pol: "Splugawione szpony",
        sch: "感染之爪",
        fre: "Les Griffes Infectées",
        bra: "Os Dedos Doentios",
        schema: 30495,
        hun: "A Karom és Fertőzés",
        swe: "Onådiga naglar"
    },
    843: {
        img: "crazy_legs",
        eng: "Crazy Legs",
        pol: "Szalone nogi",
        sch: "腐烂之足",
        fre: "Les Crazy Legs",
        bra: "As Pernas Loucas",
        schema: 30496,
        hun: "Az Eszelős Lábak",
        swe: "Galna ben"
    },
    844: {
        img: "battle_bird",
        eng: "Battle Bird",
        pol: "Ptak wojny",
        sch: "天鹰战士",
        fre: "L'Oiseau de bataille",
        bra: "O Bicador de Batalha",
        schema: 30524,
        hun: "A Harci Sas",
        swe: "Frihetskämparfågel"
    },
    845: {
        img: "ghoul_gibbin_gear",
        eng: "Ghoul Gibbin' Gear",
        pol: "Ekwipunek egzorcysty",
        sch: "尖头十字架",
        fre: "L'Équipement du Massacreur de Goules",
        bra: "O Equipamento Explode-espíritos",
        schema: 30520,
        hun: "A Halvajáró-nyuvasztó Holmi",
        swe: "Vampyrjägarens vapenarsenal"
    },
    846: {
        img: "hellhunters_headpiece",
        eng: "Hellhunter's Headpiece",
        pol: "Łowca demonów",
        sch: "地狱猎手",
        fre: "Le Chapeau du Purificateur",
        bra: "O Chapéu do Caçador Infernal",
        schema: 30521,
        hun: "A Pokoljáró Kalapja",
        swe: "Helvetisk jägarhatt"
    },
    847: {
        img: "supernatural_stalker",
        eng: "Supernatural Stalker",
        pol: "Upiorna chusta",
        sch: "超自然行者",
        fre: "Le Traqueur Surnaturel",
        bra: "O Perseguidor Paranormal",
        schema: 30522,
        hun: "A Lidérces Lidércvadász",
        swe: "Övernaturlig övervakare"
    },
    848: {
        img: "kritz_or_treat_canteen",
        eng: "Kritz or Treat Canteen",
        pol: "Mannierka smakołyk albo krytyk",
        sch: "“不放爆击就捣蛋”水壶",
        fre: "Gourde Kritz ou Friandises",
        bra: "Cantil de Doces e Travessuras",
        schema: 30535,
        hun: "A Csokit vagy Kritelek Kulacs",
        swe: "Krut eller godis-kantin"
    },
    849: {
        img: "creatures_grin",
        eng: "Creature's Grin",
        pol: "Upiorny uśmiech",
        sch: "裂面者",
        fre: "Le Sourire Monstrueux",
        bra: "A Boca Bem Aberta",
        schema: 30525,
        hun: "A Lény Vigyora",
        swe: "Monstruöst flin"
    },
    850: {
        img: "arsonist_apparatus",
        eng: "Arsonist Apparatus",
        pol: "Przyrządy podpalacza",
        sch: "纵火者之臂",
        fre: "L'Appareil Incendiaire",
        bra: "A Prótese Piromaníaca",
        schema: 30526,
        hun: "A Gyújtogatókészülék",
        swe: "Nedbrännarens näve"
    },
    851: {
        img: "moccasin_machinery",
        eng: "Moccasin Machinery",
        pol: "Mokasynowa maszyneria",
        sch: "莫卡辛机器足",
        fre: "Le Mocassin Mécanique",
        bra: "O Mocassim Mecatrônico",
        schema: 30527,
        hun: "A Lábgép",
        swe: "Mockasinmaskineri"
    },
    852: {
        img: "lollichop_licker",
        eng: "Lollichop Licker",
        pol: "Lizacz Lizaśka",
        sch: "糖上舔血",
        fre: "Le Sussache Lover",
        bra: "O Lambedor de Pyrolito",
        schema: 30528,
        hun: "A Nyalakvó",
        swe: "Slickepinansiktet"
    },
    853: {
        img: "mr_juice",
        eng: "Mr. Juice",
        pol: "Sokopyrówka",
        sch: "果汁先生",
        fre: "M. Juteux",
        bra: "Sr. Suco",
        schema: 30529,
        hun: "Lé Úr",
        swe: "Fruktlös fruktsallad"
    },
    854: {
        img: "vampyro",
        eng: "Vampyro",
        pol: "Wampyro",
        sch: "火焰吸血鬼",
        fre: "Le Vampyro",
        bra: "Vampyro",
        schema: 30530,
        hun: "A Vámpiró",
        ger: "Der Vampyro"
    },
    855: {
        img: "forgotten_kings_restless_head",
        eng: "Forgotten King's Restless Head",
        pol: "Nieznająca spoczynku głowa Zapomnianego Króla",
        sch: "鬼王的脑袋",
        fre: "La Tête Délétère du Roi Oublié",
        bra: "O Crânio Inquieto do Rei Esquecido",
        schema: 30516,
        hun: "Az Elfeledett Király Nyughatatlan Feje",
        swe: "Förgätna kungens rastlösa huvud"
    },
    856: {
        img: "forgotten_kings_pauldrons",
        eng: "Forgotten King's Pauldrons",
        pol: "Naramienniki Zapomnianego Króla",
        sch: "鬼王的肩甲",
        fre: "Les Épaulières du Roi Oublié",
        bra: "As Ombreiras do Rei Esquecido",
        schema: 30517,
        hun: "Az Elfeledett Király Vállvértje",
        swe: "Förgätna kungens axelskydd"
    },
    857: {
        img: "eyeborg",
        eng: "Eyeborg",
        pol: "Cyklopoborg",
        sch: "机器义眼",
        fre: "L'Oeilborg",
        bra: "O Ciclopeborgue",
        schema: 30518,
        hun: "A Szemborg",
        swe: "Enögde mannens önskan"
    },
    858: {
        img: "mannhattan_project",
        eng: "Mannhattan Project",
        pol: "Projekt Mannhattan",
        sch: "头戴式蘑菇云",
        fre: "Le Projet Manhattan",
        bra: "O Projeto Mannhattan",
        schema: 30519,
        hun: "A Mannhattan Terv",
        swe: "Mannhattanprojektet"
    },
    859: {
        img: "lil_dutchman",
        eng: "Li'l Dutchman",
        pol: "Maluśki Holender",
        sch: "诅咒之舟",
        fre: "Le P'tit Hollandais",
        bra: "O Pequeno Holandês Voador",
        schema: 30536,
        hun: "A Kis Hollandi",
        swe: "Flytande holländaren"
    },
    860: {
        img: "bone_cut_belt",
        eng: "Bone-Cut Belt",
        pol: "Najeżony naramiennik",
        sch: "剔骨护肩",
        fre: "La Ceinture Coupe-Os",
        bra: "O Cinto Chifrudo",
        schema: 30531,
        hun: "A Csontöv",
        swe: "Aggets axelrem"
    },
    861: {
        img: "bull_locks",
        eng: "Bull Locks",
        pol: "Bycza broda",
        sch: "公牛毛发",
        fre: "Les Dreadlocks de Taureau",
        bra: "A Barba de Touro",
        schema: 30532,
        hun: "A Bikaszakáll",
        swe: "Tjuriga skägglockar"
    },
    862: {
        img: "minsk_beef",
        eng: "Minsk Beef",
        pol: "Wół z Mińska",
        sch: "明斯克公牛",
        fre: "L' É-Minsk-é de Bœuf",
        bra: "O Bife de Minsk",
        schema: 30533,
        hun: "A Minszki Bika",
        swe: "Tjurskallen"
    },
    863: {
        img: "immobile_suit",
        eng: "Immobile Suit",
        pol: "Pancerz niemobilny",
        sch: "重型胸甲",
        fre: "La Combi-mmobile",
        bra: "O Traje Imóvel",
        schema: 30534,
        hun: "Az Immobil Erő",
        swe: "Immobil rustning"
    },
    864: {
        img: "hooded_haunter",
        eng: "Hooded Haunter",
        pol: "Zakapturzona zjawa",
        sch: "披风幽灵",
        fre: "L'Esprit Capuchonné",
        bra: "O Espectro Encapuzado",
        schema: 30498,
        hun: "A Kapucnis Kísértő",
        swe: "Hemsökande huvan"
    },
    865: {
        img: "iron_fist",
        eng: "Iron Fist",
        pol: "Żelazna pięść",
        sch: "铁臂",
        fre: "Le Poing de Fer",
        bra: "O Punho de Ferro",
        schema: 30508,
        hun: "A Vasököl",
        swe: "Järnnäven"
    },
    866: {
        img: "beep_man",
        eng: "Beep Man",
        pol: "Człowiek-konsola",
        sch: "巨型游戏机",
        fre: "Le Beep Man",
        bra: "O Bip Man",
        schema: 30509,
        hun: "A Füttyös Férfi",
        ger: "Der Beep Man"
    },
    867: {
        img: "soul_of_spensers_past",
        eng: "Soul of 'Spensers Past",
        pol: "Duch minionego zasobnika",
        sch: "布.基齐先生的灵魂",
        fre: "Le Fantôme des Distributeurs Défunts",
        bra: "O Fantasma de Fornecedores Falecidos",
        schema: 30510,
        hun: "Régvolt Adagolók Lelke",
        swe: "Utvalda utmatarens själ"
    },
    868: {
        img: "garden_bristles",
        eng: "Garden Bristles",
        pol: "Krasnal obrodowy",
        sch: "花园矮人须",
        fre: "La Barbe de Jardin",
        bra: "A Barba de Jardim",
        schema: 30523,
        hun: "A Kerti Fazon",
        swe: "Trädgårdstomteskägg"
    },
    869: {
        img: "tiny_texan",
        eng: "Tiny Texan",
        pol: "Tyci Teksańczyk",
        sch: "微型德州人",
        fre: "Le Petit Texan",
        bra: "A Cabeça Controladora",
        schema: 30511,
        hun: "A Törpe Texasi",
        swe: "Lillvilliga ingenjören"
    },
    870: {
        img: "herzensbrecher",
        eng: "Herzensbrecher",
        pol: "Herzensbrecher",
        sch: "医界良心",
        fre: "Le Herzensbrecher",
        bra: "O Herzensbrecher",
        schema: 30486,
        hun: "A Herzensbrecher",
        ger: "Der Herzensbrecher"
    },
    871: {
        img: "hundkopf",
        eng: "Hundkopf",
        pol: "Hundkopf",
        sch: "死狗头",
        fre: "Le Hundkopf",
        bra: "O Hundkopf",
        schema: 30487,
        hun: "A Hundkopf",
        ger: "Der Hundekopf"
    },
    872: {
        img: "kriegsmaschine_9000",
        eng: "Kriegsmaschine-9000",
        pol: "Kriegsmaschine-9000",
        sch: "爆击机器人 9000 型",
        fre: "La Kriegsmaschine-9000",
        bra: "A Kriegsmaschine-9000",
        schema: 30488,
        hun: "A Kriegsmaschine-9000",
        ger: "Die Kriegsmaschine-9000"
    },
    873: {
        img: "vampire_makeover",
        eng: "Vampire Makeover",
        pol: "Wampirzy wizaż",
        sch: "血站总管的面容",
        fre: "Le Relooking Transylvanien",
        bra: "A Maquiagem Vampiresca",
        schema: 30489,
        hun: "A Vámpírszerkó",
        swe: "Vacker vampyr"
    },
    874: {
        img: "vampiric_vesture",
        eng: "Vampiric Vesture",
        pol: "Wampirze wdzianko",
        sch: "血站总管的大衣",
        fre: "L'Habit Vampirique",
        bra: "As Vestimentas Vampíricas",
        schema: 30490,
        hun: "A Vámpíros Viselet",
        swe: "Vampyrisk utstyrsel"
    },
    875: {
        img: "templars_spirit",
        eng: "Templar's Spirit",
        pol: "Duch templariusza",
        sch: "圣殿骑士之灵",
        fre: "L’Âme du Templier",
        bra: "O Espírito do Templário",
        schema: 30514,
        hun: "A Templomos Szelleme",
        swe: "Tempelriddarens själ"
    },
    876: {
        img: "wings_of_purity",
        eng: "Wings of Purity",
        pol: "Skrzydła cnoty",
        sch: "净化之翼",
        fre: "Les Ailes de Pureté",
        bra: "As Asas da Pureza",
        schema: 30515,
        hun: "A Tisztaság Szárnyai",
        swe: "Renhetens vingar"
    },
    877: {
        img: "cranial_conspiracy",
        eng: "Cranial Conspiracy",
        pol: "Czaszkowa zmowa",
        sch: "蜥蜴脑袋",
        fre: "La Conspiration Crânienne",
        bra: "A Conspiração Craniana",
        schema: 30499,
        hun: "A Koponyális Összeesküvés",
        swe: "Kraniell konspiration"
    },
    878: {
        img: "scaly_scrapers",
        eng: "Scaly Scrapers",
        pol: "Łuskowe łapsko",
        sch: "蜥蜴爪子",
        fre: "La Démangeaison d’Écailles",
        bra: "O Esfolador Escamoso",
        schema: 30500,
        hun: "A Pikkelyes Kaparók",
        swe: "Fjällfingrar"
    },
    879: {
        img: "mr_mundees_wild_ride",
        eng: "Mr. Mundee's Wild Ride",
        pol: "Strusia jazda bez trzymanki",
        sch: "穆迪先生的狂野座驾",
        fre: "La Chevauchée Sauvage de M. Mundee",
        bra: "As Aventuras do Mundee Animal",
        schema: 30513,
        hun: "A Mr. Mundee Vadfogata",
        swe: "Struttande struts"
    },
    880: {
        img: "marsupial_man",
        eng: "Marsupial Man",
        pol: "Człowiek-torbacz",
        sch: "袋鼠耳朵",
        fre: "L'Homme Marsupial",
        bra: "O Marsupial Maluco",
        schema: 30501,
        hun: "Az Erszényes Ember",
        swe: "Pungdjursmannen"
    },
    881: {
        img: "kanga_kickers",
        eng: "Kanga Kickers",
        pol: "Kangurze kopyta",
        sch: "袋鼠尾巴",
        fre: "Les Kangous Kickers",
        bra: "Os Cangu-calçados",
        schema: 30502,
        hun: "A Kengur-ugró",
        swe: "Kängurukängor"
    },
    882: {
        img: "roo_rippers",
        eng: "Roo Rippers",
        pol: "Kangurze kończyny",
        sch: "袋鼠爪子",
        fre: "Le Kangourou Éventreur",
        bra: "Garras de 'Guru",
        schema: 30503,
        hun: "A Kengurukarmok",
        swe: "Känguruklor"
    },
    883: {
        img: "marsupial_muzzle",
        eng: "Marsupial Muzzle",
        pol: "Twarz torbacza",
        sch: "袋鼠脑袋",
        fre: "Le Museau Marsupial",
        bra: "O Focinho Marsupial",
        schema: 30504,
        hun: "Az Orrszényes",
        swe: "Kängurukäke"
    },
    884: {
        img: "shadowmans_shade",
        eng: "Shadowman's Shade",
        pol: "Cień cichociemnego",
        sch: "暗影之人的礼帽",
        fre: "L'Ombre du Shadowman",
        bra: "O Visual do Outro Lado",
        schema: 30505,
        hun: "Az Árnyékember Árnyékolója",
        swe: "Skuggmannens skugga"
    },
    885: {
        img: "nightmare_hunter",
        eng: "Nightmare Hunter",
        pol: "Wiązowy koszmar",
        sch: "梦魇猎手",
        fre: "Le Chasseur Cauchemardesque",
        bra: "O Caçador da Meia-noite",
        schema: 30506,
        hun: "A Rémálomvadász",
        swe: "Mardrömsjägaren"
    },
    886: {
        img: "rogues_rabbit",
        eng: "Rogue's Rabbit",
        pol: "Królik krętacza",
        sch: "魔术师的兔子帽",
        fre: "Le Lapin du Voyou",
        bra: "O Truque do Trapaceiro",
        schema: 30507,
        hun: "A Gazember Nyula",
        swe: "Knivmanskanin"
    },
    887: {
        img: "facepeeler",
        eng: "Facepeeler",
        pol: "Zdzieracz twarzy",
        sch: "画皮",
        fre: "L’Éplucheur de Visages",
        bra: "O Descarado",
        schema: 30512,
        hun: "Az Archámozó",
        swe: "Avskalat ansikte"
    },
    888: {
        img: "ghost_of_spies_checked_past",
        eng: "Ghost of Spies Checked Past",
        pol: "Długie ramię niesprawiedliwości",
        sch: "死不瞑目的间谍鬼魂",
        fre: "Le Fantôme des Défunts Spies Repérés",
        bra: "O Fantasma de Spies Passados",
        schema: 30497,
        hun: "A Lebukott Kémek Szelleme",
        swe: "Påkomna Spionens spöke"
    },
    889: {
        img: "mishap_mercenary",
        eng: "Mishap Mercenary",
        pol: "Niefortunny najemnik",
        sch: "倒霉的雇佣兵",
        fre: "Le Mercenaire malchanceux",
        bra: "O Mercenário Azarado",
        schema: 30475,
        hun: "A Katasztrófa-zsoldos",
        swe: "Kemikaliekämpen"
    },
    890: {
        img: "lady_killer",
        eng: "Lady Killer",
        pol: "Pożeracz damskich serc",
        sch: "少妇杀手",
        fre: "Le Tombeur de ces Dames",
        bra: "O Espião Estripador",
        schema: 30476,
        hun: "Hölgyek Veszedelme",
        swe: "Ladykiller"
    },
    891: {
        img: "lone_survivor",
        eng: "Lone Survivor",
        pol: "Jedyny ocalały",
        sch: "孤独的幸存者",
        fre: "Le Survivant Solitaire",
        bra: "O Sobrevivente",
        schema: 30477,
        hun: "A Magányos Túlélő",
        swe: "Den Ensamma Överlevaren"
    },
    892: {
        img: "poachers_safari_jacket",
        eng: "Poacher's Safari Jacket",
        pol: "Kurtka safari kłusownika",
        sch: "偷猎者的夹克",
        fre: "Veste safari du braconnier",
        bra: "A Jaqueta do Larápio de Cabeças",
        schema: 30478,
        hun: "Vadorzók Szafarikabátja",
        swe: "Tjuvskyttens Safarijacka"
    },
    893: {
        img: "thirst_blood",
        eng: "Thirst Blood",
        pol: "Pożądana krew",
        sch: "嗜血头带",
        fre: "Pas ma Guerre",
        bra: "Sede de Sangue",
        schema: 30479,
        hun: "Vérszomjúság",
        swe: "Blodtörst"
    },
    894: {
        img: "mann_of_the_seven_sees",
        eng: "Mann of the Seven Sees",
        pol: "Chojrak siedmiu wynurzeń",
        sch: "纵横七海",
        fre: "Le Conquérant des Sept Mers",
        bra: "O Demolidor dos Sete Mares",
        schema: 30480,
        hun: "Hét Tenger Legénye",
        swe: "Mannen av de Sju Haven"
    },
    895: {
        img: "hillbilly_speed_bump",
        eng: "Hillbilly Speed-Bump",
        pol: "Prowincjonalny garbek",
        sch: "口袋犰狳",
        fre: "Bosse de péquenaud",
        bra: "Tatu do Ben",
        schema: 30481,
        hun: "Mucsai Fekvőrendőr",
        swe: "Lantisens Farthinder"
    },
    896: {
        img: "unshaved_bear",
        eng: "Unshaved Bear",
        pol: "Nieogolony niedźwiedź",
        sch: "长毛熊怪",
        fre: "L'Ours mal rasé",
        bra: "O Urso Barbudo",
        schema: 30482,
        hun: "A Borotválatlan Medve",
        swe: "Den Orakade Björnen"
    },
    897: {
        img: "pocket_heavy",
        eng: "Pocket Heavy",
        pol: "Kieszonkowy Gruby",
        sch: "口袋机枪手",
        fre: "Heavy de poche",
        bra: "Heavy de Bolso",
        schema: 30483,
        hun: "Zsebgépágyús",
        swe: "Fickartillerist"
    },
    898: {
        img: "wartime_warmth",
        eng: "Wartime Warmth",
        pol: "Wojenne ciepełko",
        sch: "战时保暖面罩",
        fre: "Cagoule de guerre",
        bra: "Calor do Combate",
        schema: 30538,
        hun: "Háborús Hőség",
        swe: "Gasmaskvärmaren"
    },
    899: {
        img: "insulated_inventor",
        eng: "Insulated Inventor",
        pol: "Watowany wynalazca",
        sch: "保暖发明家",
        fre: "Inventeur isolé",
        bra: "Inventor Insulado",
        schema: 30539,
        hun: "Szigetelt Szakember",
        swe: "Isolerad Innovatör"
    },
    900: {
        img: "brooklyn_booties",
        eng: "Brooklyn Booties",
        pol: "Brooklyńskie buciki",
        sch: "布鲁克林短靴",
        fre: "Bottines de Brooklyn",
        bra: "Botinas de Brooklyn",
        schema: 30540,
        hun: "Brooklyni Bakancs",
        swe: "Brooklynkängor"
    },
    901: {
        img: "double_dynamite",
        eng: "Double Dynamite",
        pol: "Sześciopak Nobla",
        sch: "两捆炸药",
        fre: "Double Dynamite",
        bra: "Dupla Dinamite",
        schema: 30541,
        hun: "Dupla Dinamit",
        swe: "Dubbeldynamit"
    },
    902: {
        img: "coldsnap_cap",
        eng: "Coldsnap Cap",
        pol: "Polarny pompon",
        sch: "寒潮针织帽",
        fre: "Bonnet de vague de froid",
        bra: "Forro de Frente Fria",
        schema: 30542,
        hun: "Fagyvédő Fejfedő",
        swe: "Köldknäppsmössa"
    },
    903: {
        img: "snow_stompers",
        eng: "Snow Stompers",
        pol: "Termiczne trepy",
        sch: "雪地践踏者",
        fre: "Grosses bottes de neige",
        bra: "Galochas de Gelo",
        schema: 30543,
        hun: "Hetyke Hótaposó",
        swe: "Snöpulsare"
    },
    904: {
        img: "north_polar_fleece",
        eng: "North Polar Fleece",
        pol: "Polarny polar",
        sch: "北极羊毛衣",
        fre: "Toison polaire",
        bra: "Pulôver do Polo Norte",
        schema: 30544,
        hun: "Kötött Pulcsi",
        swe: "Nordpolsfleece"
    },
    905: {
        img: "fur_lined_fighter",
        eng: "Fur-lined Fighter",
        pol: "Uszanka emigranta",
        sch: "毛皮战士帽",
        fre: "Guerrier de fourrure",
        bra: "Protetores Peludos",
        schema: 30545,
        hun: "Prémes Pofozó",
        swe: "Pälsfodrad Bjässe"
    },
    906: {
        img: "boxcar_bomber",
        eng: "Boxcar Bomber",
        pol: "Pilot pociągu-pułapki",
        sch: "棚车轰炸员",
        fre: "Fourvoyeur de fourgon",
        bra: "Vigilante do Vagão",
        schema: 30546,
        hun: "Vagon Vagány",
        swe: "Pälsmössa"
    },
    907: {
        img: "bombers_bucket_hat",
        eng: "Bomber's Bucket Hat",
        pol: "Zamachowiec-amator",
        sch: "轰炸员的礼帽",
        fre: "Bob du bombardier",
        bra: "Barrete do Bombardeiro",
        schema: 30547,
        hun: "Bombázó Halászsapka",
        swe: "Sprängarens Fiskehatt"
    },
    908: {
        img: "screamin_eagle",
        eng: "Screamin' Eagle",
        pol: "As przestworzy",
        sch: "呼啸雄鹰",
        fre: "Piqué du faucon",
        bra: "Esquadrão Gavião",
        schema: 30548,
        hun: "A sas lecsap",
        rom: "Strigătul Vulturului",
        swe: "Skriande örnen"
    },
    909: {
        img: "winter_woodsman",
        eng: "Winter Woodsman",
        pol: "Dogrzany drwal",
        sch: "冬季伐木人",
        fre: "Bûcheron boréal",
        bra: "Lenhador de Lisburne",
        schema: 30549,
        hun: "Téli Favágó",
        swe: "Vinterskogsarbetaren"
    },
    910: {
        img: "snow_sleeves",
        eng: "Snow Sleeves",
        pol: "Rękawy śnieżne",
        sch: "雪地防寒袖",
        fre: "Manches hivernales",
        bra: "Abomináveis Mangas da Neve",
        schema: 30550,
        hun: "Hószegély",
        swe: "Vinterjacka"
    },
    911: {
        img: "flashdance_footies",
        eng: "Flashdance Footies",
        pol: "Froty z Flashdance",
        sch: "闪舞冬靴",
        fre: "Façon Flashdance",
        bra: "Polaina do Polo",
        schema: 30551,
        hun: "Villámcsukák",
        swe: "Flashdanceskor"
    },
    912: {
        img: "thermal_tracker",
        eng: "Thermal Tracker",
        pol: "Docieplony długodystansowiec",
        sch: "热能追踪者",
        fre: "Traqueur thermique",
        bra: "Camisa Térmica do Corredor",
        schema: 30552,
        hun: "Hőkereső",
        swe: "Spårarens Värmetröja"
    },
    913: {
        img: "condor_cap",
        eng: "Condor Cap",
        pol: "Dokerka Condor",
        sch: "秃鹰之帽",
        fre: "Bonnet de condor",
        bra: "Chapéu de Condor",
        schema: 30553,
        hun: "Keselyűsapka",
        swe: "Kondorens Mössa"
    },
    914: {
        img: "mistaken_movember",
        eng: "Mistaken Movember",
        pol: "Spóźniony Movember",
        sch: "胡子节的谬误",
        fre: "Mauvais Movember",
        bra: "Barbudo sem Bigode",
        schema: 30554,
        hun: "Elnézett Movember",
        swe: "Misstagen Movember"
    },
    915: {
        img: "double_dog_dare_demo_pants",
        eng: "Double Dog Dare Demo Pants",
        pol: "Bombowe bojówki",
        sch: "无惧之爆破手冬裤",
        fre: "Pantalon à double défi du Demoman",
        bra: "Calças de Carga do Ciclope",
        schema: 30555,
        hun: "Zsebesnek Áll a Világ",
        swe: "Dubbel Dos Djärv Demo Cargobyxor"
    },
    916: {
        img: "sleeveless_in_siberia",
        eng: "Sleeveless in Siberia",
        pol: "Syberyjski bezrękawnik",
        sch: "西伯利亚无袖衣",
        fre: "Débardeur sibérien",
        bra: "Jaqueta de Geada",
        schema: 30556,
        hun: "Szibériai Ujjatlan",
        swe: "Ärmlös i Sibirien"
    },
    917: {
        img: "hunter_heavy",
        eng: "Hunter Heavy",
        pol: "Łowca grubego zwierza",
        sch: "机枪猎手",
        fre: "Heavy chasseur",
        bra: "Caçador Camuflado",
        schema: 30557,
        hun: "Nehéz Vadász",
        swe: "Tung Jägare"
    },
    918: {
        img: "coldfront_curbstompers",
        eng: "Coldfront Curbstompers",
        pol: "Zimowe zgniatacze",
        sch: "冷锋践踏者",
        fre: "Mange-trottoir de Coldfront",
        bra: "Frente Quente",
        schema: 30558,
        hun: "Hidegfront-harcos",
        swe: "Kallfronts Pansarvantar"
    },
    919: {
        img: "crossing_guard",
        eng: "Crossing Guard",
        pol: "Zawiadowca",
        sch: "铁轨管制员",
        fre: "Le Passage à niveau",
        bra: "A Cruz de Santo André",
        schema: 1127,
        hun: "A Bakter",
        swe: "Övergångsvakten"
    },
    920: {
        img: "pool_party",
        eng: "Taunt: Pool Party",
        schema: 30570,
        pol: "Drwina: Impreza na basenie",
        sch: "嘲讽：泳池派对",
        fre: "Raillerie : Piscine festive",
        bra: "Provocação: Festa na Piscina",
        hun: "Beszólás: Medencés buli",
        swe: "Hån: Poolparty"
    },
    921: {
        img: "cold_snap_coat",
        eng: "Cold Snap Coat",
        pol: "Polarna parka",
        sch: "寒潮外套",
        fre: "Blouson Coupe Vent",
        bra: "Casaco Cala-frio",
        schema: 30601,
        hun: "Hidegroham-kabát",
        swe: "Köldknäppskappa"
    },
    922: {
        img: "festive_bonk_atomic_punch",
        eng: "Festive Bonk! Atomic Punch",
        pol: "Świąteczny Bonk! Atomowy kop",
        sch: "节日原子能饮料",
        fre: "Bonk! Atomic Punch festif",
        bra: "Bonk! Pancada Atômica Festiva",
        schema: 1145,
        hun: "Ünnepi Poff! Atompuncs",
        swe: "Festlig Bonk! Atomic Punch"
    },
    923: {
        img: "festive_backburner",
        eng: "Festive Backburner",
        pol: "Świąteczny Dupalacz",
        sch: "节日偷袭烈焰喷射器",
        fre: "Brûleur arrière festif",
        bra: "Queima-costas Festivo",
        schema: 1146,
        hun: "Ünnepi Beégető",
        swe: "Festlig Ryggbrännare"
    },
    924: {
        img: "festive_chargin_targe",
        eng: "Festive Chargin' Targe",
        pol: "Świąteczna Tarcza szarży",
        sch: "节日冲锋盾",
        fre: "Bouclier bélier festif",
        bra: "Tarja de Investida Festiva",
        schema: 1144,
        hun: "Ünnepi Rohamozó Paizs",
        swe: "Festlig Anfallssköld"
    },
    925: {
        img: "festive_bonesaw",
        eng: "Festive Bonesaw",
        pol: "Świąteczna piła do kości",
        sch: "节日骨锯",
        fre: "Scie à amputation festive",
        bra: "Serra de Ossos Festiva",
        schema: 1143,
        hun: "Ünnepi Csontfűrész",
        swe: "Festlig Bensåg"
    },
    926: {
        img: "festive_smg",
        eng: "Festive SMG",
        pol: "Świąteczny PM",
        sch: "节日 SMG",
        fre: "Mitraillette festive",
        bra: "Submetralhadora Festiva",
        schema: 1149,
        hun: "Ünnepi Géppisztoly",
        swe: "Festlig SMG"
    },
    927: {
        img: "festive_revolver",
        eng: "Festive Revolver",
        pol: "Świąteczny rewolwer",
        sch: "节日左轮手枪",
        fre: "Revolver festif",
        bra: "Revólver Festivo",
        schema: 1142,
        hun: "Ünnepi Revolver",
        swe: "Festlig Revolver"
    },
    928: {
        img: "festive_shotgun",
        eng: "Festive Shotgun",
        pol: "Świąteczna strzelba",
        sch: "节日霰弹枪",
        fre: "Fusil à pompe festif",
        bra: "Escopeta Festiva",
        schema: 1141,
        hun: "Ünnepi Sörétespuska",
        swe: "Festlig Shotgun"
    },
    929: {
        img: "co_pilot",
        eng: "Co-Pilot",
        pol: "Drugi pilot",
        sch: "副驾驶头盔",
        fre: "Co-Pilote",
        bra: "Copiloto",
        schema: 30576,
        hun: "Másodpilóta",
        swe: "Andrepilot"
    },
    930: {
        img: "courtiers_collar",
        eng: "Courtier's Collar",
        pol: "Kołnierzyk dworzanina",
        sch: "谄媚者的衣领",
        fre: "Col du Courtisan",
        bra: "Colarinho do Cortesão",
        schema: 30574,
        hun: "Az Udvaronc Öltözéke",
        swe: "Hovmannens Krage"
    },
    931: {
        img: "harlequins_hooves",
        eng: "Harlequin's Hooves",
        pol: "Błazeńskie buciki",
        sch: "小丑靴子",
        fre: "Souliers d'Arlequin",
        bra: "Andar do Arlequim",
        schema: 30575,
        hun: "Harlekin-paták",
        swe: "Narrens Hovar"
    },
    932: {
        img: "mountebanks_masque",
        eng: "Mountebank's Masque",
        pol: "Maska szarlatana",
        sch: "行骗者的面具",
        fre: "Masque de Charlatant",
        bra: "Símbolo do Saltimbanco",
        schema: 30573,
        hun: "Mountebank Maszkja",
        swe: "Kvacksalvarens Maskspel"
    },
    933: {
        img: "skullcap",
        eng: "Skullcap",
        pol: "Beret z czaszką",
        sch: "骷髅贝雷帽",
        fre: "Le Béret d'Outre-Tombe",
        bra: "Comando Caveira",
        schema: 30578,
        hun: "Koponyasapka",
        swe: "Skallmössa"
    },
    934: {
        img: "black_knights_bascinet",
        eng: "Black Knight's Bascinet",
        pol: "Psi pysk czarnego rycerza",
        sch: "黑骑士面罩头盔",
        fre: "Bassinet du chevalier noir",
        bra: "Bacinete da Baixa Idade Média",
        schema: 30582,
        hun: "A Fekete Lovag Fejvédője",
        swe: "Svarta Riddarens Bascinet"
    },
    935: {
        img: "charred_chainmail",
        eng: "Charred Chainmail",
        pol: "Wypalana kolczuga",
        sch: "焦黑鳞甲",
        fre: "Cotte de maille carbonisée",
        bra: "Cota de Malha Chamuscada",
        schema: 30584,
        hun: "Lángmarta Láncing",
        swe: "Förkolnad Ringbrynjeskjorta"
    },
    936: {
        img: "pyromancers_hood",
        eng: "Pyromancer's Hood",
        pol: "Kaptur piromanty",
        sch: "火焰术士的兜帽",
        fre: "Capuche de Pyromancien",
        bra: "Capuz de Piromante",
        schema: 30580,
        hun: "A Tűzgyújtó Csuklyája",
        swe: "Pyromanens Huva"
    },
    937: {
        img: "pyromancers_raiments",
        eng: "Pyromancer's Raiments",
        pol: "Szaty piromanty",
        sch: "纵火者的制服",
        fre: "Robe de Pyromancien",
        bra: "Vestes de Piromante",
        schema: 30581,
        hun: "A Tűzgyújtó Öltözéke",
        swe: "Pyromanens Skrud"
    },
    938: {
        img: "torchers_tabard",
        eng: "Torcher's Tabard",
        pol: "Odzienie giermka",
        sch: "火炬手的战袍",
        fre: "Tabard de l'incendiaire",
        bra: "Tabardo do Toca-fogo",
        schema: 30583,
        hun: "A Gyújtogató Tunikája",
        swe: "Brännarens Tabert"
    },
    939: {
        img: "scot_bonnet",
        eng: "Scot Bonnet",
        pol: "Szkocka mycka",
        sch: "苏格兰毛线帽",
        fre: "Bonnet du Scot",
        bra: "Boina Escocesa",
        schema: 30604,
        hun: "Skót Sapka",
        swe: "Skotthuva"
    },
    940: {
        img: "storm_stompers",
        eng: "Storm Stompers",
        pol: "Burzowe buciory",
        sch: "风暴战靴",
        fre: "L'Ecraseur de Flaques",
        bra: "Galochas Germânicas",
        schema: 30587,
        hun: "Vihartipró",
        swe: "Snökängorna"
    },
    941: {
        img: "valhalla_helm",
        eng: "Valhalla Helm",
        pol: "Hełm Walhalli",
        sch: "瓦尔哈拉头盔",
        fre: "Casque du Valhalla",
        bra: "Elmo dos Einherjar",
        schema: 30586,
        hun: "Valhallai Sisak",
        swe: "Valhallahjälmen"
    },
    942: {
        img: "old_man_frost",
        eng: "Old Man Frost",
        pol: "Dziadek Mróz",
        sch: "森林爷爷",
        fre: "Le vieil homme de Noël",
        bra: "A Malha de Morozko",
        schema: 30589,
        hun: "Az Öreg Fagy",
        swe: "Farfar Frost"
    },
    943: {
        img: "siberian_facehugger",
        eng: "Siberian Facehugger",
        pol: "Syberyjski ocieplacz głowy",
        sch: "西伯利亚裹脸帽",
        fre: "Face-Hugger de Sibérie",
        bra: "Capuz Calorento",
        schema: 30588,
        hun: "Szibériai Arcölelő",
        swe: "Sibirisk Ansiktskramare"
    },
    944: {
        img: "clubsy_the_seal",
        eng: "Clubsy The Seal",
        pol: "Foczka buławka",
        sch: "海豹棒棒",
        fre: "Clubsy le Phoque",
        bra: "Parceiro Polar",
        schema: 30593,
        hun: "Zsebi A Fóka",
        swe: "Sälen Klubby"
    },
    945: {
        img: "conaghers_combover",
        eng: "Conagher's Combover",
        pol: "Zaczes Conaghera",
        sch: "秃顶牛仔的装束",
        fre: "Conagher Dégarni",
        bra: "Calvície Coberta do Conagher",
        schema: 30592,
        hun: "Jólfésült Conagher",
        swe: "Conaghers Överkamning"
    },
    946: {
        img: "cop_caller",
        eng: "Cop Caller",
        pol: "Kapuś",
        sch: "报警员",
        fre: "Demande de Renforts",
        bra: "Transceptor do Tira",
        schema: 30591,
        hun: "Zsaruhívó",
        swe: "Polisradio"
    },
    947: {
        img: "holstered_heaters",
        eng: "Holstered Heaters",
        pol: "Kaburowe ogrzewacze",
        sch: "检测者的保暖大衣",
        fre: "Étuis Chauffants",
        bra: "Aquecedores Armados",
        schema: 30590,
        hun: "Hónaljtokos Hidegretevő",
        swe: "Hölstrade Värmaren"
    },
    948: {
        img: "thermal_insulation_layer",
        eng: "Thermal Insulation Layer",
        pol: "Warstwa izolacji termicznej",
        sch: "绝热层",
        fre: "Couche d'isolation thermique",
        bra: "Camada de Isolante Térmico",
        schema: 30605,
        hun: "Hőszigetelő Réteg",
        swe: "Värmeisolerat Lager"
    },
    949: {
        img: "surgeons_shako",
        eng: "Surgeon's Shako",
        pol: "Czako chirurga",
        sch: "外科医师的行军帽",
        fre: "Shako du chirurgien",
        bra: "Shako do Cirurgião",
        schema: 30596,
        hun: "Sebészcsákó",
        swe: "Kirurgens Shako"
    },
    950: {
        img: "unknown_mann",
        eng: "Unknown Mann",
        pol: "Nieznany Mann",
        sch: "无名之人",
        fre: "Mann Inconnu",
        bra: "Mann Não Identificado",
        schema: 30595,
        hun: "Az Ismeretlen Mannus",
        swe: "Okänd Mann"
    },
    951: {
        img: "bushmans_bristles",
        eng: "Bushman's Bristles",
        pol: "Buszmeńska szczecina",
        sch: "丛林一族的长须",
        fre: "Bacchantes du Bushman",
        bra: "Barba do Australiano",
        schema: 30597,
        hun: "Busman Bozont",
        swe: "Bushmannens Borst"
    },
    952: {
        img: "marksmans_mohair",
        eng: "Marksman's Mohair",
        pol: "Kożuch wyborowy",
        sch: "神射手的马海毛大衣",
        fre: "Petite Laine du Tireur d'Élite",
        bra: "Agasalho do Atirador",
        schema: 30599,
        hun: "A Mesterlövész Szőrmellénye",
        swe: "Prickskyttens Pläd"
    },
    953: {
        img: "professionals_ushanka",
        eng: "Professional's Ushanka",
        pol: "Uszanka profesjonalisty",
        sch: "职业杀手的俄国军帽",
        fre: "Ouchanka du professionnel",
        bra: "Ushanka do Profissional",
        schema: 30598,
        hun: "A Hivatásos Usankája",
        swe: "Proffsets Usjanka"
    },
    954: {
        img: "wally_pocket",
        eng: "Wally Pocket",
        pol: "Kieszonkowy kangurek",
        sch: "可爱的袋鼠袋",
        fre: "Wally de poche",
        bra: "Bolsa de Canguru",
        schema: 30600,
        hun: "Erszény Wally",
        swe: "Kängrufickan"
    },
    955: {
        img: "pocket_momma",
        eng: "Pocket Momma",
        pol: "Kieszonkowa mamusia",
        sch: "袖珍妈妈",
        fre: "Momma de poche",
        bra: "Mamãe de Bolso",
        schema: 30606,
        hun: "Zsebanyuci",
        swe: "Fickmamma"
    },
    956: {
        img: "puffy_provocateur",
        eng: "Puffy Provocateur",
        pol: "Puszysta prowokatorka",
        sch: "蓬蓬密探",
        fre: "Le Provocateur matelassé",
        bra: "A Parka Provocateur",
        schema: 30602,
        hun: "A Pufidzsekis Provokátor",
        swe: "Puffad Provokatör"
    },
    957: {
        img: "stealthy_scarf",
        eng: "Stealthy Scarf",
        pol: "Szal szalbierza",
        sch: "隐秘围巾",
        fre: "Écharpe énigmatique",
        bra: "Cachecol Camuflado",
        schema: 30603,
        hun: "Surranó Sál",
        swe: "Smygande Halsduken"
    },
    958: {
        img: "cow_mangler_5000",
        eng: "Cow Mangler 5000",
        pol: "Wyżymaczka krów 5000",
        sch: "绞牛机 5000",
        fre: "Le Broyeur Bovin 5000",
        bra: "O Avacalhador 5000",
        schema: 441,
        schema2: 5783,
        hun: "A Tehéncsonkító 5000",
        swe: "Komanglaren 5000"
    },
    959: {
        img: "third_degree",
        eng: "Third Degree",
        pol: "Trzeci stopień",
        sch: "三度烧伤",
        fre: "Le Troisième Degré",
        bra: "O Terceiro Grau",
        schema: 593,
        schema2: 5784,
        hun: "A Harmadfokú",
        swe: "Tredje Graden"
    },
    960: {
        img: "winger",
        eng: "Winger",
        pol: "Skrzydłowy",
        sch: "边锋球员",
        fre: "L'Ailier",
        bra: "Ala",
        schema: 449,
        hun: "A Szárny-segéd",
        swe: "Yttern"
    },
    961: {
        img: "strange_part_not_crit_nor_minicrit_kills",
        eng: "Strange Part: Not Crit nor MiniCrit Kills",
        pol: "Kuriozalna część: Zabójstwa niekrytyczne oraz nieminikrytyczne",
        sch: "奇异武器升级部件：非爆击/迷你爆击杀敌数",
        fre: "Pièce étrange : Victimes sans coup critique ou mini-crit",
        bra: "Peça Estranha: Vítimas sem Críticos ou Minicrits",
        schema: 6063,
        hun: "Fura Alkatrész: Nem krites vagy mini-krites ölések",
        swe: "Märklig del: Varken kritiskt eller minikritiskt dödade"
    },
    962: {
        img: "strange_part_player_hits",
        eng: "Strange Part: Player Hits",
        pol: "Kuriozalna część: Trafieni wrogowie",
        sch: "奇异武器升级部件：击中玩家数",
        fre: "Pièce étrange : Joueurs touchés",
        bra: "Peça Estranha: Jogadores Acertados",
        schema: 6064,
        hun: "Fura Alkatrész: Eltalált játékosok",
        swe: "Märklig del: Spelare träffade"
    },
    963: {
        img: "strange_cosmetic_part_assists",
        eng: "Strange Cosmetic Part: Assists",
        pol: "Kuriozalna część dla przedmiotów ozdobnych: Asysty",
        sch: "奇异饰品部件：助攻数",
        fre: "Pièce de cosmétique étrange : Coopérations",
        bra: "Peça Estranha para Cosméticos: Assistências",
        schema: 6065,
        hun: "Fura Díszítő Alkatrész: Segítések",
        swe: "Märklig kosmetisk del: Assister"
    },
    964: {
        img: "texas_ten_gallon",
        eng: "Texas Ten Gallon",
        pol: "Teksański kapelusz",
        sch: "德州牛仔帽",
        fre: "Chapeau de cowboy",
        bra: "Caubói Texano",
        schema: 94,
        hun: "Texasi Cowboykalap",
        swe: "Texas Cowboy"
    },
    965: {
        img: "awper_hand",
        eng: "AWPer Hand",
        pol: "Ale wielka pukawka",
        sch: "高手的 AWP",
        fre: "L'AWPantage",
        bra: "A AWPer Capita",
        schema: 851,
        hun: "Az AWPró Előny",
        swe: "AWPekaren"
    },
    966: {
        img: "war_blunder",
        eng: "War Blunder",
        pol: "Bitewny blamaż",
        fre: "Bavure de guerre",
        sch: "战争挺累",
        bra: "Esquadrilha da Desgraça",
        schema: 31137,
        hun: "Haditákolmány",
        swe: "Testpilot"
    },
    967: {
        img: "grounded_flyboy",
        eng: "Grounded Flyboy",
        pol: "Uziemiony lotnik",
        fre: "Pilote terrestre",
        sch: "禁止升空",
        bra: "Olha o Aviãozinho",
        schema: 31138,
        hun: "Gyalogrepülő",
        swe: "Jordnära flygaräss"
    },
    968: {
        img: "rolfe_copter",
        eng: "Rolfe Copter",
        pol: "Rolfokopter",
        fre: "Médicoptère",
        sch: "医疗直升机",
        bra: "Rolfe-cóptero",
        schema: 31139,
        hun: "Mentőhelikopáter",
        swe: "Medikopter"
    },
    969: {
        img: "pug_mug",
        eng: "Pug Mug",
        pol: "Mopsowa mordka",
        fre: "Bouille de bouledogue",
        sch: "哈巴工",
        schema: 31140,
        bra: "Pugenheiro",
        hun: "Kutyapofa",
        swe: "Mopsnylle"
    },
    970: {
        img: "treehugger",
        eng: "Treehugger",
        pol: "Drzewolub",
        fre: "Souche sèche",
        sch: "抱树狂人",
        bra: "Cara de Pau",
        schema: 31141,
        hun: "Faölelgető",
        swe: "Träskalle"
    },
    971: {
        img: "mannvich",
        eng: "Mannvich",
        pol: "Kanapkoczłek",
        fre: "Mannvich",
        sch: "巨型三明治",
        bra: "Mannviche",
        schema: 31142,
        hun: "Szendvicsember"
    },
    972: {
        img: "festivizer",
        eng: "The Festivizer",
        pol: "Dekorator",
        fre: "Enguirlandeur",
        sch: "彩灯",
        bra: "Festivizador",
        schema: 5839,
        hun: "Ünnepiesítő",
        swe: "Pyntare"
    },
    973: {
        img: "taunt_boston_breakdance",
        eng: "Taunt: The Boston Breakdance",
        schema: 30572,
        pol: "Drwina: Bostoński breakdance",
        fre: "Raillerie : La breakdance de Boston",
        sch: "嘲讽：波士顿霹雳舞",
        bra: "Provocação: O Break de Boston",
        hun: "Beszólás: A bostoni breaktánc",
        swe: "Hån: Boston Breakdance"
    },
    974: {
        img: "taunt_bunnyhopper",
        eng: "Taunt: The Bunnyhopper",
        schema: 30920,
        pol: "Drwina: Pogoskoczek",
        fre: "Raillerie : Le coup du lapin",
        sch: "嘲讽：跳跳兔",
        bra: "Provocação: O Pula-Pula",
        hun: "Beszólás: A nyusziugró",
        swe: "Hån: Kaninhopparen"
    },
    975: {
        img: "dalokohs_bar",
        eng: "Dalokohs Bar",
        pol: "Tabliczka Ladyczeko",
        sch: "达勒克斯巧克力块",
        fre: "La Barre de Dalokohs",
        bra: "A Barra de Dalokohs",
        schema: 159,
        hun: "A Dalokohs Szelet",
        swe: "Dalokohs-Kakan"
    },
    976: {
        img: "warriors_spirit",
        eng: "Warrior's Spirit",
        pol: "Dusza wojownika",
        sch: "勇士之魂",
        fre: "L'Esprit du Guerrier",
        bra: "O Espírito do Guerreiro",
        schema: 310,
        hun: "A Harci Szellem",
        swe: "Krigarens Anda"
    },
    977: {
        img: "apoco_fists",
        eng: "Apoco-Fists",
        pol: "Piąchokalipsa",
        sch: "天灾之拳",
        fre: "A-poing-calypses",
        bra: "O Punhocalipse",
        schema: 587,
        hun: "Végütélet",
        swe: "Apoka-Nävar"
    },
    978: {
        img: "red_tape_recorder",
        eng: "Red-Tape Recorder",
        pol: "Magnetofon urzędasa",
        sch: "官僚式录音机",
        fre: "L'enregistreur de formalités",
        bra: "O Rebobinador",
        schema: 810,
        hun: "A Vörös Propaganda",
        swe: "Avvecklaren"
    },
    979: {
        img: "shahanshah",
        eng: "Shahanshah",
        pol: "Szachinszach",
        sch: "诸王之王",
        fre: "Le Shahanshah",
        bra: "A Shahanshah",
        schema: 401,
        hun: "A Sahinsah",
        swe: "Shahanshahen"
    },
    980: {
        img: "cloak_and_dagger",
        eng: "Cloak and Dagger",
        pol: "Peleryna nożownika",
        sch: "隐形刺客",
        fre: "La Cloak and Dagger",
        bra: "O Manto e Adaga",
        schema: 60,
        hun: "Az Állvaálca",
        swe: "Dolken Och Manteln"
    },
    981: {
        img: "buff_banner",
        eng: "Buff Banner",
        pol: "Sztandar chwały",
        sch: "战旗",
        fre: "Le Drapeau Buff",
        bra: "O Estandarte de Encorajamento",
        schema: 129,
        hun: "A Lázító Lobogó",
        swe: "Buffbaneret"
    },
    982: {
        img: "righteous_bison",
        eng: "Righteous Bison",
        pol: "Prawy bizon",
        sch: "正义野牛",
        fre: "Le Bison Vertueux",
        bra: "O Bisão Justiceiro",
        schema: 442,
        schema2: 5804,
        hun: "Az Igaz Bölény",
        swe: "Rättskaffens Bison"
    },
    983: {
        img: "turbine_torcher",
        eng: "Turbine Torcher | Flame Thrower",
        pol: "Palnik turbinowy | Miotacz ognia",
        sch: "涡轮喷灯 火焰喷射器",
        bra: "Tocha Turbinada Lança-chamas",
        fre: "Lance-flammes Torcheur à turbine",
        schema: 15054,
        schema2: 54,
        hun: "Turbine Torcher lángszóró"
    },
    984: {
        img: "low_profile",
        eng: "Low Profile | SMG",
        pol: "Czysta dyskrecja | PM",
        sch: "不露圭角 SMG",
        bra: "Disparador Discreto Submetralhadora",
        fre: "Mitraillette Profil bas",
        schema: 15058,
        schema2: 58,
        hun: "Low Profile géppisztoly"
    },
    985: {
        img: "aqua_marine",
        eng: "Aqua Marine | Rocket Launcher",
        pol: "Akwamarynarz | Wyrzutnia rakiet",
        sch: "海军陆战队 火箭发射器",
        bra: "Matador Marinho Lança-foguetes",
        fre: "Lance-Roquettes Aigue-marine",
        schema: 15057,
        schema2: 57,
        hun: "Aqua Marine rakétavető"
    },
    986: {
        img: "brick_house",
        eng: "Brick House | Minigun",
        pol: "Domek z cegieł | Minigun",
        sch: "砖墙 转轮机枪",
        bra: "Casa Muito Metralhada Metralhadora Giratória",
        fre: "Minigun Maison en brique",
        schema: 15055,
        schema2: 55,
        hun: "Brick House gépágyú"
    },
    987: {
        img: "lightning_rod",
        eng: "Lightning Rod | Shotgun",
        pol: "Piorunochron | Strzelba",
        sch: "引雷针 霰弹枪",
        bra: "Para-raios Escopeta",
        fre: "Fusil à pompe Paratonnerre",
        schema: 15047,
        schema2: 47,
        hun: "Lightning Rod sörétes puska"
    },
    988: {
        img: "sandstone_special",
        eng: "Sandstone Special | Pistol",
        pol: "Piaskowcowy specjał | Pistolet",
        sch: "砂岩特制 手枪",
        bra: "Arsenal de Arenito Pistola",
        fre: "Pistolet Spéciale grès",
        schema: 15056,
        schema2: 56,
        hun: "Sandstone Special pisztoly"
    },
    989: {
        img: "black_dahlia",
        eng: "Black Dahlia | Pistol",
        pol: "Czarna dalia | Pistolet",
        sch: "黑色大丽花 手枪",
        bra: "Dália Negra Pistola",
        fre: "Pistolet Dahlia noir",
        schema: 15046,
        schema2: 46,
        hun: "Black Dahlia pisztoly"
    },
    990: {
        img: "dead_reckoner",
        eng: "Dead Reckoner | Revolver",
        pol: "Trupi zwiad | Rewolwer",
        sch: "死亡判官 左轮手枪",
        bra: "Cadáver Calculista Revólver",
        fre: "Revolver Cible de la mort",
        schema: 15051,
        schema2: 51,
        hun: "Dead Reckoner revolver"
    },
    991: {
        img: "spark_of_life",
        eng: "Spark of Life | Medi Gun",
        pol: "Iskra życia | Medigun",
        sch: "生命之光 医疗枪",
        bra: "Sinal de Vida Arma Médica",
        fre: "Medigun Étincelle de vie",
        schema: 15050,
        schema2: 50,
        hun: "Spark of Life gyógypuska"
    },
    992: {
        img: "flash_fryer",
        eng: "Flash Fryer | Flame Thrower",
        pol: "Szybki spalacz | Miotacz ognia",
        sch: "闪电烧烤 火焰喷射器",
        bra: "Flama Fulminante Lança-chamas",
        fre: "Lance-flammes Friteuse éclair",
        schema: 15049,
        schema2: 49,
        hun: "Flash Fryer lángszóró"
    },
    993: {
        img: "pink_elephant",
        eng: "Pink Elephant | Stickybomb Launcher",
        pol: "Różowy słoń | Wyrzutnia bomb samoprzylepnych",
        sch: "粉红象 黏性炸弹发射器",
        bra: "Elefante Rosa Lança-stickybombs",
        fre: "Lanceur de Bombes collantes Éléphant rose",
        schema: 15048,
        schema2: 48,
        hun: "Pink Elephant tapadóbomba-vető"
    },
    994: {
        img: "current_event",
        eng: "Current Event | Scattergun",
        pol: "Aktualne wydarzenie | Dubeltówka",
        sch: "紫电 猎枪",
        bra: "Acontecimento Chocante Espingarda",
        fre: "Fusil à dispersion Événement actuel",
        schema: 15053,
        schema2: 53,
        hun: "Current Event repeszpuska"
    },
    995: {
        img: "shell_shocker",
        eng: "Shell Shocker | Rocket Launcher",
        pol: "Traumatyczne wspomnienie | Wyrzutnia rakiet",
        sch: "震荡弹 火箭发射器",
        bra: "Ligadão Lança-foguetes",
        fre: "Lance-Roquettes Horrible obus",
        schema: 15052,
        schema2: 52,
        hun: "Shell Shocker rakétavető"
    },
    996: {
        img: "liquid_asset",
        eng: "Liquid Asset | Stickybomb Launcher",
        pol: "Płynny kapitał | Wyrzutnia bomb samoprzylepnych",
        sch: "液态炸药 黏性炸弹发射器",
        bra: "Lucro Líquido Lança-stickybombs",
        fre: "Lanceur de Bombes collantes Liquidateurs d'actifs",
        schema: 15045,
        schema2: 45,
        hun: "Liquid Asset tapadóbomba-vető"
    },
    997: {
        img: "thunderbolt",
        eng: "Thunderbolt | Sniper Rifle",
        pol: "Piorun | Karabin snajperski",
        sch: "雷霆打击 狙击步枪",
        bra: "Relâmpago Rifle de Sniper",
        fre: "Fusil de sniper Coup de foudre",
        schema: 15059,
        schema2: 59,
        hun: "Thunderbolt mesterlövész puska"
    },
    998: {
        img: "commissars_coat",
        eng: "Commissar's Coat",
        pol: "Płaszcz komisarza",
        sch: "人民委员大衣",
        fre: "Manteau du commissaire",
        bra: "Casaco do Comissário",
        schema: 30633,
        hun: "A Komisszár Kabátja",
        swe: "Kommissariens Kappa"
    },
    999: {
        img: "flak_jack",
        eng: "Flak Jack",
        pol: "Dezaprobata policjanta",
        sch: "防弹背心",
        fre: "Flak Jack",
        bra: "Colete do Corredor",
        schema: 30637,
        hun: "Muníciós Mellény",
        ger: "Flak-Jacke"
    },
    1000: {
        img: "lurkers_leathers",
        eng: "Lurker's Leathers",
        pol: "Odzienie opryszka",
        sch: "潜行者的皮衣裤",
        fre: "Le cuir du limier",
        bra: "A Jaqueta Secreta",
        schema: 30631,
        hun: "A Bujkáló Bőrkabátja",
        swe: "Smygarens skinndräkt"
    },
    1001: {
        img: "rotation_sensation",
        eng: "Rotation Sensation",
        pol: "Odwrócona furora",
        sch: "旋转感应帽",
        fre: "La rotation sensass",
        bra: "A Sensação da Rotação",
        schema: 30623,
        hun: "A Laza Létérzés",
        swe: "Roterande sensationen"
    },
    1002: {
        img: "support_spurs",
        eng: "Support Spurs",
        pol: "Ostrogi oparcia",
        sch: "马刺靴",
        fre: "Supporte-éperons",
        bra: "Esporas de Suporte",
        schema: 30629,
        hun: "Segítő Sarkantyúk",
        swe: "Stödsporrar"
    },
    1003: {
        img: "vascular_vestment",
        eng: "Vascular Vestment",
        pol: "Naczyniowy napierśnik",
        sch: "医生的经典胸甲",
        fre: "Le gilet vasculaire",
        bra: "A Vestimenta Vascular",
        schema: 30626,
        hun: "A Szakorvosi Szerelés",
        swe: "Vaskulära Västen"
    },
    1004: {
        img: "wild_west_waistcoat",
        eng: "Wild West Waistcoat",
        pol: "Kamizelka z Dzikiego Zachodu",
        sch: "西部狂野马甲",
        fre: "Manteau du shérif de l'ouest",
        bra: "O Colete do Caubói",
        schema: 30635,
        hun: "Vadnyugati Viselet",
        swe: "Vilda västern-väst"
    },
    1005: {
        img: "fortunate_son",
        eng: "Fortunate Son",
        pol: "Szczęściarz",
        sch: "走运的新兵",
        fre: "Fils chanceux",
        bra: "Filho Afortunado",
        schema: 30636
    },
    1006: {
        img: "outta_sight",
        eng: "Outta' Sight",
        pol: "Ślepak",
        sch: "眼不见心不烦",
        fre: "Hyper loin",
        bra: "Visual Vistoso",
        schema: 30628,
        hun: "Tutkeráj"
    },
    1007: {
        img: "physicians_protector",
        eng: "Physician's Protector",
        pol: "Ochraniacz medyczny",
        sch: "内科医生的钢盔",
        fre: "La protection du physicien",
        bra: "O Protetor do Doutor",
        schema: 30625,
        hun: "A Felcser Fejvédője",
        swe: "Doktorns Protektor"
    },
    1008: {
        img: "white_russian",
        eng: "White Russian",
        pol: "Biały Rosjanin",
        sch: "白色俄罗斯",
        fre: "Le Caucasien",
        bra: "Russo Branco",
        schema: 30644
    },
    1009: {
        img: "bruces_bonnet",
        eng: "Bruce's Bonnet",
        pol: "Rycerskość Roberta",
        sch: "苏格兰王布鲁斯之盔",
        fre: "Cagoule de maille",
        bra: "Malha do Monarca",
        schema: 30627,
        hun: "Fenséges Fejfedő",
        swe: "Bruces Bonnet"
    },
    1010: {
        img: "el_duderino",
        eng: "El Duderino",
        pol: "El kolesino",
        sch: "大佬勒布斯基",
        fre: "Le Duc",
        bra: "El Duderino",
        schema: 30645,
        hun: "A Tökös Tekés"
    },
    1011: {
        img: "sheriffs_stetson",
        eng: "Sheriff's Stetson",
        pol: "Stetson szeryfa",
        sch: "警长的宽檐帽",
        fre: "Chapeau de shérif",
        bra: "Chapéu do Xerife",
        schema: 30634,
        hun: "A Seriffkalap",
        swe: "Sheriffens Stetson"
    },
    1012: {
        img: "captain_cardbeard_cutthroat",
        eng: "Captain Cardbeard Cutthroat",
        pol: "Kapitan Okrutny Kartonobrody",
        sch: "纸板海盗帽",
        fre: "Capitaine Barbenkarton",
        bra: "Pirata de Barbelão",
        schema: 30640,
        hun: "Kaszaboló Kartonszakáll Kapitány",
        swe: "Kapten Kartongskägg Knivhals"
    },
    1013: {
        img: "potassium_bonnett",
        eng: "Potassium Bonnet",
        pol: "Czapeczka potasowa",
        sch: "获元钾",
        fre: "Cagoule au potassium",
        bra: "A Banana do Banana",
        schema: 30643,
        hun: "Kálium Kalap",
        swe: "Kaliumbonnett"
    },
    1014: {
        img: "universal_translator",
        eng: "Universal Translator",
        pol: "Uniwersalny tłumacz",
        sch: "星际翻译官",
        fre: "Traducteur universel",
        bra: "Tradutor Universal",
        schema: 30658,
        hun: "Univerzális Fordító",
        swe: "Universell översättare"
    },
    1015: {
        img: "phobos_filter",
        eng: "Phobos Filter",
        pol: "Filtr z Fobosa",
        sch: "火卫一过滤面罩",
        fre: "Filtre de Phobos",
        bra: "Filtro de Fobus",
        schema: 30652,
        hun: "Phobosz-légszűrő",
        swe: "Phobos-filter"
    },
    1016: {
        img: "final_frontiersman",
        eng: "Final Frontiersman",
        pol: "Ostatni pogranicznik",
        sch: "最后的拓荒者",
        fre: "L'habitant de la frontière finale",
        bra: "O Homem da Última Fronteira",
        schema: 30649,
        hun: "A Legvégső Határvidéki",
        ger: "Letzter Grenzgänger"
    },
    1017: {
        img: "life_support_system",
        eng: "Life Support System",
        pol: "System podtrzymywania życia",
        sch: "生命支持系统",
        fre: "Système de support vital",
        bra: "Sistema de Suporte Vital",
        schema: 30654,
        hun: "Létfenntartó Rendszer",
        swe: "Livsuppehållande apparat"
    },
    1018: {
        img: "rocket_operator",
        eng: "Rocket Operator",
        pol: "Operator rakiety",
        sch: "火箭操作员",
        fre: "Opérateur de roquettes",
        bra: "Operador de Foguetes",
        schema: 30655,
        hun: "Rakétatechnikus",
        swe: "Raketmekanikern"
    },
    1019: {
        img: "captain_space_mann",
        eng: "Captain Space Mann",
        pol: "Kapitan kosmannauta",
        sch: "太空队长曼恩",
        fre: "Capitaine Space Mann",
        bra: "Capitão Cosmannauta",
        schema: 30646,
        hun: "Űrmannus kapitány",
        swe: "Kapten Rymdmann"
    },
    1020: {
        img: "shooting_star",
        eng: "Shooting Star",
        pol: "Strzelająca gwiazda",
        sch: "流星狙击者",
        fre: "Étoile filante",
        bra: "Estrela Cadente",
        schema: 30665,
        hun: "Hullacsillag",
        swe: "Stjärnskott"
    },
    1021: {
        img: "giger_counter",
        eng: "Giger Counter",
        pol: "Licznik Gigera",
        sch: "异形计数器",
        fre: "Le compteur geiger",
        bra: "O Contador Giger",
        schema: 30668,
        hun: "A Giger-számláló",
        swe: "Giger-räknaren"
    },
    1022: {
        img: "starduster",
        eng: "Starduster",
        pol: "Gwiezdny łach",
        sch: "星尘披风",
        fre: "Nébuleuse",
        bra: "Espanador das Estrelas",
        schema: 30650,
        hun: "Csillagporos",
        ger: "Sternwanderer"
    },
    1023: {
        img: "corona_australis",
        eng: "Corona Australis",
        pol: "Korona południowa",
        sch: "澳冕",
        fre: "Couronne australe",
        bra: "Corona Australis",
        schema: 30648
    },
    1024: {
        img: "sucker_slug",
        eng: "Sucker Slug",
        pol: "Ssący glut",
        sch: "吸脑鼻涕虫",
        fre: "Limace suceuse",
        bra: "Sanguessuga Sideral",
        schema: 30653,
        hun: "Fejszívó Féreg",
        swe: "Stolle-snigeln"
    },
    1025: {
        img: "graylien",
        eng: "Graylien",
        pol: "Szarmita",
        sch: "小灰人",
        fre: "Le Petit-Gris",
        bra: "O Alienígena Apunhalador",
        schema: 30651,
        hun: "A Zűrlény",
        swe: "Grålien"
    },
    1026: {
        img: "cadet_visor",
        eng: "Cadet Visor",
        pol: "Wizjer kadeta",
        sch: "学院护目镜",
        fre: "Visière du cadet",
        bra: "Visor do Cadete",
        schema: 30661,
        hun: "A Kadét Szemellenzője",
        swe: "Kadettvisir"
    },
    1027: {
        img: "space_diver",
        eng: "Space Diver",
        pol: "Kosmonurek",
        sch: "太空跳伞员",
        fre: "Plongée spatiale",
        bra: "O Mergulhador Espacial",
        schema: 30664,
        hun: "Az Űrbuvár",
        swe: "Rymddykaren"
    },
    1028: {
        img: "a_head_full_of_hot_air",
        eng: "A Head Full of Hot Air",
        pol: "Głowa pełna gorącego powietrza",
        sch: "热气头盔",
        fre: "Une tête pleine d'air chaud",
        bra: "Cabeça de Ar Quente",
        schema: 30662,
        hun: "Forrófejű",
        swe: "Ett huvud fyllt med varm luft"
    },
    1029: {
        img: "capper",
        eng: "C.A.P.P.E.R",
        pol: "C.A.P.P.E.R",
        sch: "高能电磁脉冲粒子枪",
        fre: "Le C.A.P.P.E.R",
        bra: "O C.A.U.B.O.I.",
        schema: 30666,
        hun: "A F.É.Z.E.R.",
        ger: "Die C.A.P.P.E.R"
    },
    1030: {
        img: "phononaut",
        eng: "Phononaut",
        pol: "Telenauta",
        sch: "太空头盔",
        fre: "Casque astronomique",
        bra: "Fononauta",
        schema: 30647,
        hun: "Hangonauta"
    },
    1031: {
        img: "jupiter_jetpack",
        eng: "Jupiter Jetpack",
        pol: "Plutoński plecak",
        sch: "木星飞行背包",
        fre: "Jetpack jupitérien",
        bra: "Mochila a Jato de Júpiter",
        schema: 30663,
        hun: "Jupiteri Röptáska",
        ger: "Jupiter-Jetpack"
    },
    1032: {
        img: "space_hamster_hammy",
        eng: "Space Hamster Hammy",
        pol: "Hammy - kosmiczny chomik",
        sch: "太空仓鼠哈米",
        fre: "Hamster de l'espace au goût de jambon",
        bra: "Hammy, o Hamster Espacial",
        schema: 30669,
        hun: "Hammy, az Űrhörcsög",
        swe: "Rymdhamstern Hammy"
    },
    1033: {
        img: "burstchester",
        eng: "Taunt: Burstchester",
        schema: 30621,
        pol: "Drwina: Burstchester",
        sch: "嘲讽：裂胸者",
        fre: "Raillerie : Ami de cœur",
        bra: "Provocação: Amigo do Peito",
        hun: "Beszólás: Kikelesztő",
        swe: "Hån: Burstchester"
    },
    1034: {
        img: "batsaber",
        eng: "Batsaber",
        pol: "Kij świetlny",
        sch: "光剑球棒",
        fre: "Batsabre",
        bra: "Taco de Luz",
        schema: 30667,
        hun: "Fényütő",
        swe: "Ljus-slagträ"
    },
    1035: {
        img: "face_of_mercy",
        eng: "Face of Mercy",
        pol: "Twarz przebaczenia",
        sch: "仁慈者的面具",
        fre: "Visage de la miséricorde",
        bra: "A Face da Misericórdia",
        schema: 30676,
        hun: "Az Irgalom Arca",
        swe: "Nådens Ansikte"
    },
    1036: {
        img: "el_patron",
        eng: "El Patron",
        pol: "El patron",
        sch: "大老板",
        fre: "El Patron",
        bra: "El Patrón",
        schema: 30681
    },
    1037: {
        img: "smokey_sombrero",
        eng: "Smokey Sombrero",
        pol: "Czadowe sombrero",
        sch: "烟熏墨西哥帽",
        fre: "Sombrero fumé",
        bra: "Chapéu de Charuto",
        schema: 30682,
        hun: "Füstös Sombrero",
        swe: "Stilig Sombrero"
    },
    1038: {
        img: "thrilling_tracksuit",
        eng: "Thrilling Tracksuit",
        pol: "Dreszczowiec",
        sch: "赛车手外套",
        fre: "Survêtement phénoménal",
        bra: "Agasalho Assustador",
        schema: 30685,
        hun: "Vérfagyasztó Versenyruha",
        swe: "Racerförarens Overall"
    },
    1039: {
        img: "baaarrgh_n_britches",
        eng: "B'aaarrgh-n-Britches",
        pol: "D-aaarrrmowe bryczesy",
        sch: "廉价海盗裤",
        fre: "Haaauts-de-chaaausses",
        bra: "Calças da Pechincha Pirata",
        schema: 30719,
        hun: "Koszlott Kalóznadrág",
        swe: "Piratbyxor"
    },
    1040: {
        img: "prehistoric_pullover",
        eng: "Prehistoric Pullover",
        pol: "Prehistoryczny pulower",
        sch: "史前爬行动物兜帽",
        fre: "Pullover Préhistorique",
        bra: "Capuz Pré-histórico",
        schema: 30704,
        hun: "Őskori Pulóver",
        swe: "Dinohuva"
    },
    1041: {
        img: "roboot",
        eng: "Roboot",
        pol: "Robut",
        sch: "枪炮工之足",
        fre: "Robotte",
        bra: "Robota",
        schema: 30675,
        hun: "Ro-botlábú",
        swe: "Robuut"
    },
    1042: {
        img: "duck_billed_hatypus",
        eng: "Duck Billed Hatypus",
        pol: "Kacza czapa",
        sch: "真.鸭舌帽",
        fre: "Coincoinsquette",
        bra: "Boné-torrinco",
        schema: 30700,
        hun: "Kacsacsőrű Sapka",
        swe: "Näbbkeps"
    },
    1043: {
        img: "iron_lung",
        eng: "Iron Lung",
        pol: "Żelazne płuco",
        sch: "铁肺",
        fre: "Poumon d'acier",
        bra: "Pulmão de Ferro",
        schema: 30698,
        hun: "Vastüdő",
        swe: "Järnlunga"
    },
    1044: {
        img: "neptunes_nightmare",
        eng: "Neptune's Nightmare",
        pol: "Koszmar Neptuna",
        sch: "海王之魇",
        fre: "Cauchemar de Neptune",
        bra: "Pesadelo de Netuno",
        schema: 30684,
        hun: "Neptunusz Rémálma",
        swe: "Neptunus Mardröm"
    },
    1045: {
        img: "el_caballero",
        eng: "El Caballero",
        pol: "El caballero",
        sch: "西班牙绅士",
        fre: "El Caballero",
        bra: "El Caballero",
        schema: 30680
    },
    1046: {
        img: "baaarrgh_n_bicorne",
        eng: "B'aaarrgh-n-Bicorne",
        pol: "D-aaarrrrmowy bikorn",
        sch: "廉价海盗帽",
        fre: "Bicorrrne",
        bra: "Bicórnio da Pechincha Pirata",
        schema: 30718,
        hun: "Koszlott Kalózkalap",
        swe: "Pirathatt"
    },
    1047: {
        img: "death_racers_helmet",
        eng: "Death Racer's Helmet",
        pol: "Hełm death racera",
        sch: "绝命赛车手",
        fre: "Casque du pilote de la mort",
        bra: "Capacete do Corredor da Morte",
        schema: 30686,
        hun: "Halálfutam-sisak",
        swe: "Racerförarens Hjälm"
    },
    1048: {
        img: "grim_tweeter",
        eng: "Grim Tweeter",
        pol: "Ponury ćwierkacz",
        sch: "报亡之雀",
        fre: "Le titi faucheur",
        bra: "Ceifador Depenado",
        schema: 30693,
        hun: "A Kis Kaszás",
        swe: "Liefågeln"
    },
    1049: {
        img: "crusaders_getup",
        eng: "Crusader's Getup",
        pol: "Kubrak krzyżowca",
        sch: "十字军战服",
        fre: "Combinaison du défenseur",
        bra: "Uniforme do Cruzado",
        schema: 30716,
        hun: "Keresztes Hacuka",
        swe: "Lysmaskens Stil"
    },
    1050: {
        img: "deader_alive",
        eng: "Dead'er Alive",
        pol: "Żywy lub martwy",
        sch: "要塞威龙",
        fre: "Engicop",
        bra: "Vivo ou Morto",
        schema: 30707,
        hun: "Élve Holtabb",
        swe: "Död eller levande"
    },
    1051: {
        img: "arthropods_aspect",
        eng: "Arthropod's Aspect",
        pol: "Mrówcze oblicze",
        sch: "纵火狂蚁",
        fre: "Le look arthropode",
        bra: "Aspecto do Artrópode",
        schema: 30717,
        hun: "Rovarpofa",
        swe: "Lysmaskens Anlete"
    },
    1052: {
        img: "catastrophic_companions",
        eng: "Catastrophic Companions",
        pol: "Katastroficzni towarzysze",
        sch: "大规模杀伤性小伙伴",
        fre: "Compagnons catastrophiques",
        bra: "Companheiros Gatastróficos",
        schema: 30706,
        hun: "Katasztrofális Kísérők",
        swe: "Kattastrofiska Kompanjoner"
    },
    1053: {
        img: "hellmet",
        eng: "Hellmet",
        pol: "Piekłohełm",
        sch: "地狱骨盔",
        fre: "Le Purgacasque",
        bra: "O Capacete Infernal",
        schema: 30708,
        hun: "A Pokolsisak",
        swe: "Helvetshjälmen"
    },
    1054: {
        img: "flower_power_shotgun",
        eng: "Flower Power | Shotgun",
        pol: "Strzelba | Dzieci kwiaty",
        sch: "鲜花之力霰弹枪",
        fre: "Le fusil à pompe Flower Power",
        bra: "Escopeta | Campo Florido",
        schema: 15109,
        schema2: 72,
        hun: "A Virágerő Sörétes Puska",
        swe: "Hagelgevär | Flower Power"
    },
    1055: {
        img: "flower_power_scattergun",
        eng: "Flower Power | Scattergun",
        pol: "Dubeltówka | Dzieci kwiaty",
        sch: "鲜花之力猎枪",
        fre: "Le fusil à dispersion Flower Power",
        bra: "Espingarda | Campo Florido",
        schema: 15107,
        schema2: 72,
        hun: "A Virágerő Repeszpuska",
        swe: "Hagelbössa | Flower Power"
    },
    1056: {
        img: "flower_power_revolver",
        eng: "Flower Power | Revolver",
        pol: "Rewolwer | Dzieci kwiaty",
        sch: "鲜花之力左轮手枪",
        fre: "Le revolver Flower Power",
        bra: "Revólver | Campo Florido",
        schema: 15103,
        schema2: 72,
        hun: "A Virágerő Revolver",
        swe: "Revolver | Flower Power"
    },
    1057: {
        img: "flower_power_medi_gun",
        eng: "Flower Power | Medi Gun",
        pol: "Medigun | Dzieci kwiaty",
        sch: "鲜花之力医疗枪",
        fre: "Le medigun Flower Power",
        bra: "Arma Médica | Campo Florido",
        schema: 15097,
        schema2: 72,
        hun: "A Virágerő Gyógypuska",
        swe: "Hälsovapen | Flower Power"
    },
    1058: {
        img: "brain_candy_rocket_launcher",
        eng: "Brain Candy | Rocket Launcher",
        pol: "Wyrzutnia rakiet | Cukieraski",
        sch: "脑浆糖果火箭发射器",
        fre: "Le lance-roquettes sucette",
        bra: "Lança-foguetes | Doce Mental",
        schema: 15105,
        schema2: 70,
        hun: "Az Agynyalóka Rakétavető",
        swe: "Raketgevär | Sockersöt"
    },
    1059: {
        img: "brain_candy_pistol",
        eng: "Brain Candy | Pistol",
        pol: "Pistolet | Cukieraski",
        sch: "脑浆糖果手枪",
        fre: "Le pistolet sucette",
        bra: "Pistola | Doce Mental",
        schema: 15101,
        schema2: 70,
        hun: "Az Agynyalóka Pisztoly",
        swe: "Pistol | Sockersöt"
    },
    1060: {
        img: "brain_candy_minigun",
        eng: "Brain Candy | Minigun",
        pol: "Minigun | Cukieraski",
        sch: "脑浆糖果转轮机枪",
        fre: "Le minigun sucette",
        bra: "Metralhadora Giratória | Doce Mental",
        schema: 15098,
        schema2: 70,
        hun: "Az Agynyalóka Gépágyú",
        swe: "Minigun | Sockersöt"
    },
    1061: {
        img: "brain_candy_knife",
        eng: "Brain Candy | Knife",
        pol: "Nóż | Cukieraski",
        sch: "脑浆糖果刀子",
        fre: "Le couteau sucette",
        bra: "Faca | Doce Mental",
        schema: 15095,
        schema2: 70,
        hun: "Az Agynyalóka Kés",
        swe: "Kniv | Sockersöt"
    },
    1062: {
        img: "shot_to_hell_pistol",
        eng: "Shot to Hell | Pistol",
        pol: "Pistolet | Z piekła rodem",
        sch: "射到死手枪",
        fre: "Le pistolet infernal",
        bra: "Pistola | Balas Infernais",
        schema: 15102,
        schema2: 74,
        hun: "A Pokolra Lőtt Pisztoly",
        swe: "Pistol | Helvetesbränd"
    },
    1063: {
        img: "stabbed_to_hell_knife",
        eng: "Stabbed to Hell | Knife",
        pol: "Nóż | Z piekła rodem",
        sch: "背刺到死刀子",
        fre: "Le couteau infernal",
        bra: "Faca | Facadas Infernais",
        schema: 15096,
        schema2: 71,
        hun: "A Pokolra Szúrt Kés",
        swe: "Kniv | Knivad åt helvete"
    },
    1064: {
        img: "blue_mew_smg",
        eng: "Blue Mew | SMG",
        pol: "PM | Koteł",
        sch: "蓝喵 SMG",
        fre: "La mitraillette miaou",
        bra: "Submetralhadora | Céu de Gatinhos",
        schema: 15110,
        schema2: 69,
        hun: "A Kék Miau Géppisztoly",
        swe: "Kpist | Blått jam"
    },
    1065: {
        img: "torqued_to_hell_wrench",
        eng: "Torqued to Hell | Wrench",
        pol: "Klucz | Z piekła rodem",
        sch: "扭曲到死扳手",
        fre: "La clé infernale",
        bra: "Chave Inglesa | Torcidas Infernais",
        schema: 15114,
        schema2: 75,
        hun: "A Pokolra Tekert Franciakulcs",
        swe: "Skiftnyckel | Helvetesbränd"
    },
    1066: {
        img: "shot_to_hell_scattergun",
        eng: "Shot to Hell | Scattergun",
        pol: "Dubeltówka | Z piekła rodem",
        sch: "射到死猎枪",
        fre: "Le fusil à dispersion infernal",
        bra: "Espingarda | Balas Infernais",
        schema: 15108,
        schema2: 74,
        hun: "A Pokolra Lőtt Repeszpuska",
        swe: "Hagelbössa | Helvetesbränd"
    },
    1067: {
        img: "blue_mew_scattergun",
        eng: "Blue Mew | Scattergun",
        pol: "Dubeltówka | Koteł",
        sch: "蓝喵猎枪",
        fre: "Le fusil à dispersion miaou",
        bra: "Espingarda | Céu de Gatinhos",
        schema: 15106,
        schema2: 69,
        hun: "A Kék Miau Repeszpuska",
        swe: "Hagelbössa | Blått jam"
    },
    1068: {
        img: "blue_mew_rocket_launcher",
        eng: "Blue Mew | Rocket Launcher",
        pol: "Wyrzutnia rakiet | Koteł",
        sch: "蓝喵火箭发射器",
        fre: "Le lance-roquettes miaou",
        bra: "Lança-foguetes | Céu de Gatinhos",
        schema: 15104,
        schema2: 69,
        hun: "A Kék Miau Rakétavető",
        swe: "Raketgevär | Blått jam"
    },
    1069: {
        img: "blue_mew_pistol",
        eng: "Blue Mew | Pistol",
        pol: "Pistolet | Koteł",
        sch: "蓝喵手枪",
        fre: "Le pistolet miaou",
        bra: "Pistola | Céu de Gatinhos",
        schema: 15100,
        schema2: 69,
        hun: "A Kék Miau Pisztoly",
        swe: "Pistol | Blått jam"
    },
    1070: {
        img: "blue_mew_knife",
        eng: "Blue Mew | Knife",
        pol: "Nóż | Koteł",
        sch: "蓝喵刀子",
        fre: "Le couteau miaou",
        bra: "Faca | Céu de Gatinhos",
        schema: 15094,
        schema2: 69,
        hun: "A Kék Miau Kés",
        swe: "Kniv | Blått jam"
    },
    1071: {
        img: "mister_cuddles_minigun",
        eng: "Mister Cuddles | Minigun",
        pol: "Pan Przytulaśny | Minigun",
        sch: "抱抱先生 转轮机枪",
        bra: "Senhor Fofinho Metralhadora Giratória",
        fre: "Le Minigun Monsieur Câlin",
        schema: 15099,
        schema2: 73,
        hun: "Mister Cuddles gépágyú"
    },
    1072: {
        img: "balloonicorn_flame_thrower",
        eng: "Balloonicorn | Flame Thrower",
        pol: "Miotacz ognia | Balonorożec",
        sch: "气球独角兽火焰喷射器",
        fre: "Le lance-flammes Ballicorne",
        bra: "Lança-chamas | Balãonicórnio",
        schema: 15089,
        schema2: 66,
        hun: "A Lufikornis Lángszóró",
        swe: "Eldkastare | Ballonghörning"
    },
    1073: {
        img: "sweet_dreams_stickybomb_launcher",
        eng: "Sweet Dreams | Stickybomb Launcher",
        pol: "Wyrzutnia bomb samoprzylepnych | Słodkich snów",
        sch: "甜蜜梦境黏性炸弹发射器",
        fre: "Le lanceur de bombes collantes des beaux rêves",
        bra: "Lança-stickybombs | Bons Sonhos",
        schema: 15113,
        hun: "Az Édes Álom Tapadóbomba-vető",
        swe: "Klisterbombskastare | Söta drömmar"
    },
    1074: {
        img: "sweet_dreams_grenade_launcher",
        eng: "Sweet Dreams | Grenade Launcher",
        pol: "Granatnik | Słodkich snów",
        sch: "甜蜜梦境榴弹发射器",
        fre: "Le lance-grenades des beaux rêves",
        bra: "Lança-granadas | Bons Sonhos",
        schema: 15092,
        schema2: 68,
        hun: "Az Édes Álom Gránátvető",
        swe: "Granatkastare | Söta drömmar"
    },
    1075: {
        img: "balloonicorn_sniper_rifle",
        eng: "Balloonicorn | Sniper Rifle",
        pol: "Karabin snajperski | Balonorożec",
        sch: "气球独角兽狙击步枪",
        fre: "Le fusil de sniper Ballicorne",
        bra: "Rifle de Sniper | Balãonicórnio",
        schema: 15111,
        schema2: 66,
        hun: "A Lufikornis Mesterlövész Puska",
        swe: "Krypskyttegevär | Ballonghörning"
    },
    1076: {
        img: "rainbow_flame_thrower",
        eng: "Rainbow | Flame Thrower",
        pol: "Miotacz ognia | Tęcza",
        sch: "彩虹火焰喷射器",
        fre: "Le lance-flammes arc-en-ciel",
        bra: "Lança-chamas | Arco-íris",
        schema: 15090,
        schema2: 67,
        hun: "A Szivárvány Lángszóró",
        swe: "Eldkastare | Regnbåge"
    },
    1077: {
        img: "rainbow_sniper_rifle",
        eng: "Rainbow | Sniper Rifle",
        pol: "Karabin snajperski | Tęcza",
        sch: "彩虹狙击步枪",
        fre: "Le fusil de sniper arc-en-ciel",
        bra: "Rifle de Sniper | Arco-íris",
        schema: 15112,
        schema2: 67,
        hun: "A Szivárvány Mesterlövész Puska",
        swe: "Krypskyttegevär | Regnbåge"
    },
    1078: {
        img: "rainbow_grenade_launcher",
        eng: "Rainbow | Grenade Launcher",
        pol: "Granatnik | Tęcza",
        sch: "彩虹榴弹发射器",
        fre: "Le lance-grenades arc-en-ciel",
        bra: "Lança-granadas | Arco-íris",
        schema: 15091,
        schema2: 67,
        hun: "A Szivárvány Gránátvető",
        swe: "Granatkastare | Regnbåge"
    },
    1079: {
        img: "blitzkrieg_knife",
        eng: "Blitzkrieg | Knife",
        pol: "Nóż | Blitzkrieg",
        sch: "雷霆闪击刀子",
        fre: "Le couteau Blitzkrieg",
        bra: "Faca | Blitzkrieg",
        schema: 15143,
        schema2: 81,
        hun: "A Villámháború Kés",
        swe: "Kniv | Blitzkrieg"
    },
    1080: {
        img: "butcher_bird_grenade_launcher",
        eng: "Butcher Bird | Grenade Launcher",
        pol: "Granatnik | Srokacz",
        sch: "伯劳鸟榴弹发射器",
        fre: "Le lance-grenades rapace",
        bra: "Lança-granadas | Gralha",
        schema: 15158,
        schema2: 84,
        hun: "A Mészármadár Gránátvető",
        swe: "Granatkastare | Stridsflygplan"
    },
    1081: {
        img: "corsair_scattergun",
        eng: "Corsair | Scattergun",
        pol: "Dubeltówka | Korsarz",
        sch: "海盗猎枪",
        fre: "Le fusil à dispersion pirate",
        bra: "Espingarda | Corsário",
        schema: 15157,
        schema2: 83,
        hun: "A Kalóz Repeszpuska",
        swe: "Hagelbössa | Korseld"
    },
    1082: {
        img: "airwolf_wrench",
        eng: "Airwolf | Wrench",
        pol: "Klucz | Airwolf",
        sch: "飞狼扳手",
        fre: "La clé Airwolf",
        bra: "Chave Inglesa | Lobo dos Ares",
        schema: 15156,
        schema2: 82,
        hun: "A Légifarkas Franciakulcs",
        swe: "Skiftnyckel | Airwolf"
    },
    1083: {
        img: "blitzkrieg_smg",
        eng: "Blitzkrieg | SMG",
        pol: "PM | Blitzkrieg",
        sch: "雷霆闪击 SMG",
        fre: "La mitraillette Blitzkrieg",
        bra: "Submetralhadora | Blitzkrieg",
        schema: 15153,
        schema2: 81,
        hun: "A Villámháború Géppisztoly",
        swe: "Kpist | Blitzkrieg"
    },
    1084: {
        img: "blitzkrieg_revolver",
        eng: "Blitzkrieg | Revolver",
        pol: "Rewolwer | Blitzkrieg",
        sch: "雷霆闪击左轮手枪",
        fre: "Le revolver Blitzkrieg",
        bra: "Revólver | Blitzkrieg",
        schema: 15149,
        schema2: 81,
        hun: "A Villámháború Revolver",
        swe: "Revolver | Blitzkrieg"
    },
    1085: {
        img: "blitzkrieg_pistol",
        eng: "Blitzkrieg | Pistol",
        pol: "Pistolet | Blitzkrieg",
        sch: "雷霆闪击手枪",
        fre: "Le pistolet Blitzkrieg",
        bra: "Pistola | Blitzkrieg",
        schema: 15148,
        schema2: 81,
        hun: "A Villámháború Pisztoly",
        swe: "Pistol | Blitzkrieg"
    },
    1086: {
        img: "blitzkrieg_medi_gun",
        eng: "Blitzkrieg | Medi Gun",
        pol: "Medigun | Blitzkrieg",
        sch: "雷霆闪击医疗枪",
        fre: "Le medigun Blitzkrieg",
        bra: "Arma Médica | Blitzkrieg",
        schema: 15145,
        schema2: 81,
        hun: "A Villámháború Gyógypuska",
        swe: "Hälsovapen | Blitzkrieg"
    },
    1087: {
        img: "airwolf_knife",
        eng: "Airwolf | Knife",
        pol: "Nóż | Airwolf",
        sch: "飞狼刀子",
        fre: "Le couteau Airwolf",
        bra: "Faca | Lobo dos Ares",
        schema: 15144,
        schema2: 82,
        hun: "A Légifarkas Kés",
        swe: "Kniv | Airwolf"
    },
    1088: {
        img: "corsair_medi_gun",
        eng: "Corsair | Medi Gun",
        pol: "Medigun | Korsarz",
        sch: "海盗医疗枪",
        fre: "Le medigun pirate",
        bra: "Arma Médica | Corsário",
        schema: 15146,
        schema2: 83,
        hun: "A Kalóz Gyógypuska",
        swe: "Hälsovapen | Korseld"
    },
    1089: {
        img: "blitzkrieg_stickybomb_launcher",
        eng: "Blitzkrieg | Stickybomb Launcher",
        pol: "Wyrzutnia bomb samoprzylepnych | Blitzkrieg",
        sch: "雷霆闪击黏性炸弹发射器",
        fre: "Le lanceur de bombes collantes Blitzkrieg",
        bra: "Lança-stickybombs | Blitzkrieg",
        schema: 15155,
        schema2: 81,
        hun: "A Villámháború Tapadóbomba-vető",
        swe: "Klisterbombskastare | Blitzkrieg"
    },
    1090: {
        img: "airwolf_sniper_rifle",
        eng: "Airwolf | Sniper Rifle",
        pol: "Karabin snajperski | Airwolf",
        sch: "飞狼狙击步枪",
        fre: "Le fusil de sniper Airwolf",
        bra: "Rifle de Sniper | Lobo dos Ares",
        schema: 15154,
        schema2: 82,
        hun: "A Légifarkas Mesterlövész Puska",
        swe: "Krypskyttegevär | Airwolf"
    },
    1091: {
        img: "butcher_bird_minigun",
        eng: "Butcher Bird | Minigun",
        pol: "Minigun | Srokacz",
        sch: "伯劳鸟转轮机枪",
        fre: "Le minigun rapace",
        bra: "Metralhadora Giratória | Gralha",
        schema: 15147,
        schema2: 84,
        hun: "A Mészármadár Gépágyú",
        swe: "Minigun | Stridsflygplan"
    },
    1092: {
        img: "warhawk_flame_thrower",
        eng: "Warhawk | Flame Thrower",
        pol: "Miotacz ognia | Warhawk",
        sch: "鹰派火焰喷射器",
        fre: "Le lance-flammes du faucon guerrier",
        bra: "Lança-chamas | Gavião de Guerra",
        schema: 15141,
        schema2: 80,
        hun: "A Harci Héja Lángszóró",
        swe: "Eldkastare | Krigshök"
    },
    1093: {
        img: "red_bear_shotgun",
        eng: "Red Bear | Shotgun",
        pol: "Strzelba | Czerwony niedźwiedź",
        sch: "赤红之熊霰弹枪",
        fre: "Le fusil à pompe de l'ours rouge",
        bra: "Escopeta | Ursoviético",
        schema: 15152,
        schema2: 86,
        hun: "A Vörös Medve Sörétes Puska",
        swe: "Hagelgevär | Röd björn"
    },
    1094: {
        img: "warhawk_grenade_launcher",
        eng: "Warhawk | Grenade Launcher",
        pol: "Granatnik | Warhawk",
        sch: "鹰派榴弹发射器",
        fre: "Le lance-grenades du faucon guerrier",
        bra: "Lança-granadas | Gavião de Guerra",
        schema: 15142,
        schema2: 80,
        hun: "A Harci Héja Gránátvető",
        swe: "Granatkastare | Krigshök"
    },
    1095: {
        img: "warhawk_rocket_launcher",
        eng: "Warhawk | Rocket Launcher",
        pol: "Wyrzutnia rakiet | Warhawk",
        sch: "鹰派火箭发射器",
        fre: "Le lance-roquettes du faucon guerrier",
        bra: "Lança-foguetes | Gavião de Guerra",
        schema: 15150,
        schema2: 80,
        hun: "A Harci Héja Rakétavető",
        swe: "Raketgevär | Krigshök"
    },
    1096: {
        img: "killer_bee_scattergun",
        eng: "Killer Bee | Scattergun",
        pol: "Dubeltówka | Zabójcza pszczoła",
        sch: "杀人蜂猎枪",
        fre: "Le fusil à dispersion ruche",
        bra: "Espingarda | Abelha Assassina",
        schema: 15151,
        schema2: 85,
        hun: "A Gyilkos Méh Repeszpuska",
        swe: "Hagelbössa | Mördarbi"
    },
    1097: {
        img: "patriot_peak",
        eng: "Patriot Peak",
        pol: "Rogatywka patrioty",
        sch: "爱国之鹰",
        fre: "L'apogée patriotique",
        bra: "O Pico Patriota",
        schema: 30743,
        hun: "A Csúcshazafi",
        swe: "Patriotens prydnad"
    },
    1098: {
        img: "berlin_brain_bowl",
        eng: "Berlin Brain Bowl",
        pol: "Berliński hełm sanitariusza",
        sch: "柏林脑盔",
        fre: "Protection berlinoise",
        bra: "Batalhador de Berlim",
        schema: 30755,
        hun: "Berlini Búra-búra",
        swe: "Berlinsk hjärnhjälm"
    },
    1099: {
        img: "hot_heels",
        eng: "Hot Heels",
        pol: "Mattelowe trampki",
        sch: "热力跑鞋",
        fre: "Talons chauts",
        bra: "Calçados Chama-tivos",
        schema: 30754,
        hun: "Lángoló Lábbeli",
        swe: "Heta hälar"
    },
    1100: {
        img: "a_hat_to_kill_for",
        eng: "A Hat to Kill For",
        pol: "Kapelusz wart zabójstwa",
        sch: "谋帽害命",
        fre: "Un chapeau à en mourir",
        bra: "Um Chapéu de Matar",
        schema: 30753,
        hun: "Életedet Egy Kalapért",
        swe: "En hatt att döda för"
    },
    1101: {
        img: "shin_shredders",
        eng: "Shin Shredders",
        pol: "Samurajskie haidate",
        sch: "武士钢甲",
        fre: "Protège-tibias du conquérant",
        bra: "Caneleiras do Conquistador",
        schema: 30742,
        hun: "Lábszártépők",
        swe: "Smalbensskydd"
    },
    1102: {
        img: "medical_monarch",
        eng: "Medical Monarch",
        pol: "Monarcha medycyny",
        sch: "医疗之王",
        fre: "Monarque médical",
        bra: "Monarca da Medicina",
        schema: 30750,
        hun: "Szanitéckirály",
        swe: "Medicinsk monark"
    },
    1103: {
        img: "siberian_sweater",
        eng: "Siberian Sweater",
        pol: "Syberyjski sweter",
        sch: "西伯利亚小毛衣",
        fre: "Pull sibérien",
        bra: "Suéter Siberiano",
        schema: 30745,
        hun: "Szibériai Szvetter",
        swe: "Siberiska stickade tröjan"
    },
    1104: {
        img: "chicago_overcoat",
        eng: "Chicago Overcoat",
        pol: "Chicagowski płaszcz",
        sch: "芝加哥外套",
        fre: "Pardessus de Chicago",
        bra: "O Casacão de Chicago",
        schema: 30752,
        hun: "Chicagói Felöltő",
        swe: "Chicagorocken"
    },
    1105: {
        img: "diplomat",
        eng: "Diplomat",
        pol: "Dyplomata",
        sch: "驻外武官",
        fre: "Le diplomate",
        bra: "O Diplomata",
        schema: 30744,
        hun: "A Diplomata",
        swe: "Diplomaten"
    },
    1106: {
        img: "bunnyhoppers_ballistics_vest",
        eng: "Bunnyhopper's Ballistics Vest",
        pol: "Kamizelka kuloodporna skoczka",
        sch: "跳跳兔的弹道学背心",
        fre: "Pare-balle du Bunnyhopper",
        bra: "Veste Balística",
        schema: 30756,
        hun: "A Bakugró Ballisztikus Mellénye",
        swe: "Bunnyhopparens Ballistikväst"
    },
    1107: {
        img: "winter_backup",
        eng: "Winter Backup",
        pol: "Zimowe wsparcie",
        sch: "冬日背包",
        fre: "Provisions hivernales",
        bra: "Ferramentas do Frio",
        schema: 30749,
        hun: "Téli Támogatás",
        swe: "Vinterreserven"
    },
    1108: {
        img: "bonk_batters_backup",
        eng: "Bonk Batter's Backup",
        pol: "Sprzęt pałkarza Bonka",
        sch: "原子能棒球手的必备物品",
        fre: "Sac de baseball de Bonk",
        bra: "A Bolsa do Batedor Bonk",
        schema: 30751,
        hun: "Az Ütős Felszerelés",
        swe: "Slagmannens reserv"
    },
    1109: {
        img: "gift_bringer",
        eng: "Gift Bringer",
        pol: "Śnięty Mikołaj",
        sch: "圣诞老人外套",
        fre: "Le père Soldier",
        bra: "O Papai dos Presentes",
        schema: 30747,
        hun: "Az Ajándékhozó",
        swe: "Gåvogivaren"
    },
    1110: {
        img: "chill_chullo",
        eng: "Chill Chullo",
        pol: "Zmrożone chullo",
        sch: "御寒毛绒帽",
        fre: "Chill Chullo",
        bra: "O Gorro Glacial",
        schema: 30748,
        hun: "A Fagyos Fültakarós",
        swe: "Kyliga chullon"
    },
    1111: {
        img: "a_well_wrapped_hat",
        eng: "A Well Wrapped Hat",
        pol: "Dobrze zapakowany cylinder",
        sch: "精美礼物包装帽",
        fre: "Un chapeau bien emballé",
        bra: "Um Chapéu Bem Embrulhado",
        schema: 30746,
        hun: "Rendesen Becsomagolt Kalap",
        swe: "En väl inslagen hatt"
    },
    1112: {
        img: "courtly_cuirass",
        eng: "Courtly Cuirass",
        pol: "Dworski kirys",
        sch: "典雅的臂甲",
        fre: "Protège-bras moyenâgeux",
        bra: "A Proteção Platinada",
        schema: 30770,
        hun: "A Pompás Páncél",
        swe: "Hövisk Harnesk"
    },
    1113: {
        img: "demos_dustcatcher",
        eng: "Demo's Dustcatcher",
        pol: "Peleryna z prerii",
        sch: "爆破手的遮风围巾",
        fre: "Plumeau du Demo",
        bra: "O Poncho do Demolidor",
        schema: 30788,
        hun: "A Robbantós Porfogója",
        swe: "Dammsamlaren"
    },
    1114: {
        img: "surgical_survivalist",
        eng: "Surgical Survivalist",
        pol: "Sanitariusz frontowy",
        sch: "外科学护膝",
        fre: "Chirurgien survivaliste",
        bra: "O Sobrevivencialista Cirúrgico",
        schema: 30773,
        hun: "A Sebészeti Túlélő",
        swe: "Kirurgens Kamouflage"
    },
    1115: {
        img: "squires_sabatons",
        eng: "Squire's Sabatons",
        pol: "Trzewiki giermka",
        sch: "侍从的铠靴",
        fre: "Soleret de l'écuyer",
        bra: "Os Calcantes do Cavaleiro",
        schema: 30771,
        hun: "A Pajzshordó Páncélcsizmái",
        swe: "Knektens Kängor"
    },
    1116: {
        img: "airdog",
        eng: "Airdog",
        pol: "Awiator",
        sch: "天狗滑雪盔",
        fre: "Pro de la glisse",
        bra: "O Esqui-ardor",
        schema: 30767,
        hun: "A Légikutya",
        swe: "Skidhjälmen"
    },
    1117: {
        img: "scoped_spartan",
        eng: "Scoped Spartan",
        pol: "Wcelowany Spartanin",
        sch: "斯巴达披风",
        fre: "Cape spartiate",
        bra: "A Capa do Espartano",
        schema: 30789,
        hun: "A Távcsöves Spártai",
        swe: "Siktande Spartanen"
    },
    1118: {
        img: "heralds_helm",
        eng: "Herald's Helm",
        pol: "Hełm herolda",
        sch: "先锋之盔",
        fre: "Casque de héraut",
        bra: "O Elmo do Mensageiro",
        schema: 30769,
        hun: "A Hírnök Sisakja",
        swe: "Häroldens Hjälm"
    },
    1119: {
        img: "gauzed_gaze",
        eng: "Gauzed Gaze",
        pol: "Gazowe gogle",
        sch: "蒙面神医",
        fre: "Masque chirurgical",
        bra: "O Olhar do Cirurgião",
        schema: 30786,
        hun: "A Fáslis Fürkész",
        swe: "Narkosläkarens Nylle"
    },
    1120: {
        img: "lurking_legionnaire",
        eng: "Lurking Legionnaire",
        pol: "Czyhający legionista",
        sch: "外籍军团的军装",
        fre: "Tenue du légionnaire",
        bra: "O Legionário Ladino",
        schema: 30777,
        hun: "A Lopakodó Légiós",
        swe: "Lurande Legionären"
    },
    1121: {
        img: "patriots_pouches",
        eng: "Patriot's Pouches",
        pol: "Ładownica patrioty",
        sch: "爱国者的弹药袋",
        fre: "Équipement du patriote",
        bra: "Os Bolsos do Patriota",
        schema: 30780,
        hun: "A Hazafias Hordtáskák",
        swe: "Fosterlandsvännens Fickor"
    },
    1122: {
        img: "dad_duds",
        eng: "Dad Duds",
        pol: "Ojcowski ciuch",
        sch: "工程老爹的格纹衬衫",
        fre: "Chemise à carreaux",
        bra: "A Camisa do Paizão",
        schema: 30785,
        hun: "Az Apuka-hacuka",
        swe: "Pappans Paltor"
    },
    1123: {
        img: "dayjogger",
        eng: "Dayjogger",
        pol: "Wieczny biegacz",
        sch: "日行者",
        fre: "Dayjogger",
        bra: "O Caçador de Cabeças",
        schema: 30779,
        hun: "A Fénybenkocogó",
        swe: "Dagjoggaren"
    },
    1124: {
        img: "dead_head",
        eng: "Dead Head",
        pol: "Twarzoczaszka",
        sch: "无面之人",
        fre: "Masque de mort",
        bra: "A Cabeça Cadavérica",
        schema: 30775,
        hun: "A Holtfej",
        swe: "Dödskallen"
    },
    1125: {
        img: "bedouin_bandana",
        eng: "Bedouin Bandana",
        pol: "Bandana Beduina",
        sch: "贝督因蒙面头巾",
        fre: "Bandana bédouin",
        bra: "A Bandana do Beduíno",
        schema: 30768,
        hun: "A Beduin Fejkendő",
        swe: "Beduinsk Bandana"
    },
    1126: {
        img: "sandvich",
        eng: "Sandvich",
        pol: "Kanapka",
        sch: "三明治",
        fre: "Le Sandvich",
        bra: "O Sandviche",
        schema: 42,
        hun: "A Szendvics",
        swe: "Smörgosen"
    },
    1127: {
        img: "quickiebomb_launcher",
        eng: "Quickiebomb Launcher",
        pol: "Wyrzutnik szybkobomb",
        sch: "快速黏弹发射器",
        fre: "Le Lanceur de Bombes rapides",
        bra: "O Lança-rápibombs",
        schema: 1150,
        hun: "A Gyorsbomba-vető",
        swe: "Kvickbombsskjutaren"
    },
    1128: {
        img: "back_scatter",
        eng: "Back Scatter",
        pol: "Dupeltówka",
        sch: "偷袭之力",
        fre: "Le Back Scatter",
        bra: "A Fuziladora de Costas",
        schema: 1103,
        hun: "A Hátrepesztő",
        swe: "Ryggsplittraren"
    },
    1129: {
        img: "panic_attack",
        eng: "Panic Attack",
        pol: "Atak paniki",
        sch: "恐慌打击",
        fre: "La Crise de Panique",
        bra: "O Ataque de Pânico",
        schema: 1153,
        hun: "A Pánikroham",
        swe: "Panikattacken"
    },
    1130: {
        img: "iron_bomber",
        eng: "Iron Bomber",
        pol: "Żelazny bombardier",
        sch: "铁弹发射器",
        fre: "Le Canonneur",
        bra: "O Bombardeiro de Ferro",
        schema: 1151,
        hun: "A Vas Bombázó",
        swe: "Järnbombaren"
    },
    1131: {
        img: "showstopper",
        eng: "Showstopper",
        pol: "fajerwerki",
        sch: "烟花表演",
        fre: "Clou du spectacle",
        bra: "Fim de show",
        schema: 30797,
        hun: "Ünneprontó",
        swe: "Showstoppare"
    },
    1132: {
        img: "mad_mask",
        eng: "Mad Mask",
        pol: "Mad maska",
        sch: "疯狂面具",
        fre: "Mad Mask",
        bra: "Máscara da Loucura",
        schema: 30815,
        hun: "Mad Maszk"
    },
    1133: {
        img: "surgeons_sidearms",
        eng: "Surgeon's Sidearms",
        pol: "Przybornik chirurga",
        sch: "外科医生的小工具",
        fre: "Armes de poing du chirurgien",
        bra: "As Ferramentas do Cirurgião",
        schema: 30813,
        hun: "Az Orvos Oldalfegyverei",
        swe: "Sjukvårdarens Sidovapen"
    },
    1134: {
        img: "nasty_norsemann",
        eng: "Nasty Norsemann",
        pol: "Groźny nord",
        sch: "北欧牛角盔",
        fre: "Vil viking",
        bra: "Nórdico Nefasto",
        schema: 30810,
        hun: "Galád Normann",
        swe: "Nedsmutsad Nordmann"
    },
    1135: {
        img: "colossal_cranium",
        eng: "Colossal Cranium",
        pol: "Kolosalna łysina",
        sch: "巨型光头",
        fre: "Boîte crânienne colossale",
        bra: "Crânio Colossal",
        schema: 30792,
        hun: "Kolosszális Koponya",
        swe: "Kolossalt Kranium"
    },
    1136: {
        img: "el_paso_poncho",
        eng: "El Paso Poncho",
        pol: "Ponczo el paso",
        sch: "帕索的毛衣",
        fre: "Le El Paso Poncho",
        bra: "El Paso Poncho",
        schema: 30804,
        hun: "Az El Paso Poncsó",
        swe: "El Paso-ponchon"
    },
    1137: {
        img: "spooktacles",
        eng: "Spooktacles",
        pol: "Strasznokulary",
        sch: "骷髅墨镜",
        fre: "Luneffrayantes",
        bra: "Horripilóculos",
        schema: 30801,
        hun: "Rémüveg",
        swe: "Skräckglasögon"
    },
    1138: {
        img: "wide_brimmed_bandito",
        eng: "Wide-Brimmed Bandito",
        pol: "Bandyckie sombrero",
        sch: "宽边墨西哥帽",
        fre: "Le Bandito à larges bords",
        bra: "O Bandido de Aba Larga",
        schema: 30805,
        hun: "A Széles Sombrero",
        swe: "Bredbrättad Bandito"
    },
    1139: {
        img: "cranial_carcharodon",
        eng: "Cranial Carcharodon",
        pol: "Czaszkowy megalodon",
        sch: "鲨鱼头套",
        fre: "Le carcharodon crânien",
        bra: "O Carcharodon Craniano",
        schema: 30800,
        hun: "A Fejcápa",
        swe: "Huvudhajen"
    },
    1140: {
        img: "wing_mann",
        eng: "Wing Mann",
        pol: "Skrzydełkowy",
        sch: "鸡翅大侠",
        fre: "Le Mann ailé",
        bra: "A Asinha de Frango",
        schema: 30809,
        hun: "A Comb-boss",
        swe: "Vingmannen"
    },
    1141: {
        img: "toadstool_topper",
        eng: "Toadstool Topper",
        pol: "Muchomor czapkogłowy",
        sch: "小佣兵采蘑菇",
        fre: "Chapignon",
        bra: "O Chapéu de Cogumelo",
        schema: 30796,
        hun: "A Galócafedő",
        swe: "Svamprikets Servitörshatt"
    },
    1142: {
        img: "spirit_of_the_bombing_past",
        eng: "Spirit of the Bombing Past",
        pol: "Duch wybuchowej przeszłości",
        sch: "爆破狂魔",
        fre: "Esprit des anciennes explosions",
        bra: "Espirito das Explosões Passadas",
        schema: 30807,
        hun: "Rémséges Robbantós Régmúlt",
        swe: "Anden av förflutna bombningar"
    },
    1143: {
        img: "corpus_christi_cranium",
        eng: "Corpus Christi Cranium",
        pol: "Corpus Christi cranium",
        sch: "圣体之颅",
        fre: "Le crâne de Corpus Christi",
        bra: "O Crânio de Corpus Christi",
        schema: 30806,
        hun: "A Corpus Christi Koponya",
        swe: "Kristi Lekamens Kranium"
    },
    1144: {
        img: "heavy_tourism",
        eng: "Heavy Tourism",
        pol: "Grube wakacje",
        sch: "超重型游客",
        fre: "Chemise à fleurs",
        bra: "Turismo Pesado",
        schema: 30803,
        hun: "Turistaosztály-harcos",
        swe: "Tung Turism"
    },
    1145: {
        img: "big_topper",
        eng: "Big Topper",
        pol: "Wielka szycha",
        sch: "魔术师的高帽子",
        fre: "Haut-de-plume",
        bra: "Cartolão Circense",
        schema: 30798,
        hun: "Kapitális Kalap",
        swe: "Hög hatt"
    },
    1146: {
        img: "lil_bitey",
        eng: "Lil' Bitey",
        pol: "Gacuś",
        sch: "吸血蝙蝠帽",
        fre: "P'tite morsure",
        bra: "Mordidinha",
        schema: 30814,
        hun: "Kis Harapós",
        swe: "Lill-bitarn"
    },
    1147: {
        img: "mohorn",
        eng: "Mo'Horn",
        pol: "Mo-czub",
        sch: "火焰发型",
        fre: "Mo'Horn",
        bra: "Moi'chifre",
        schema: 30812,
        hun: "Kakastaréj",
        swe: "Tjurkam"
    },
    1148: {
        img: "pestering_jester",
        eng: "Pestering Jester",
        pol: "Wnerwiający błazen",
        sch: "弄臣之帽",
        fre: "Bouffon badin",
        bra: "Bobo da Corte Importuno",
        schema: 30811,
        hun: "Bosszantó Bolond",
        swe: "Störande Skojare"
    },
    1149: {
        img: "burly_beast",
        eng: "Burly Beast",
        pol: "Muskularna bestia",
        sch: "胸毛怪兽",
        fre: "Brute baraquée",
        bra: "Besta Corpulenta",
        schema: 30817,
        hun: "Dögös Dögész",
        swe: "Väldig Vilde"
    },
    1150: {
        img: "class_crown",
        eng: "Class Crown",
        pol: "Korona z klasą",
        sch: "佣兵之冠",
        fre: "Couronne royale",
        bra: "Coroa de Classe",
        schema: 30808,
        hun: "Királyi Korona",
        swe: "Klasskronan"
    },
    1151: {
        img: "combustible_cutie",
        eng: "Combustible Cutie",
        pol: "Łatwopalny słodziak",
        sch: "火爆萌物",
        fre: "Beauté combustible",
        bra: "Fofura Combustível",
        schema: 30799,
        hun: "Lobbanékony Cukipofa",
        swe: "Lättantändlig Ljuvhet"
    },
    1152: {
        img: "handy_canes",
        eng: "Handy Canes",
        pol: "Poręczne laski",
        sch: "拐杖糖",
        fre: "Canne à bombe",
        bra: "Doces Explosivos",
        schema: 30822,
        hun: "Édes Lőszer",
        swe: "Polkaprydnader"
    },
    1153: {
        img: "readers_choice",
        eng: "Reader's Choice",
        pol: "Nagroda czytelników",
        sch: "睹者之选",
        fre: "Choix des lecteurs",
        bra: "Escolha do Leitor",
        schema: 30831,
        hun: "Olvasók Választása",
        swe: "Läsarnas val"
    },
    1154: {
        img: "brain_warming_wear",
        eng: "Brain-Warming Wear",
        pol: "Mózgogrzewacz",
        sch: "暖脑毛绒帽",
        fre: "Couvre-cerveau",
        bra: "O Aquecedor de Cérebros",
        schema: 30827,
        hun: "Agymelengető Viselet",
        swe: "Krabbmössa"
    },
    1155: {
        img: "packable_provisions",
        eng: "Packable Provisions",
        pol: "Pakowalny prowiant",
        sch: "随身补给器",
        fre: "Provisions de Noël",
        bra: "Provisões Portáteis",
        schema: 30821,
        hun: "Táskányi Táplálék",
        swe: "Packbara Provisioner"
    },
    1156: {
        img: "elf_esteem",
        eng: "Elf Esteem",
        pol: "Elfia estyma",
        sch: "黏弹小精灵",
        fre: "L'elfet de Noël",
        bra: "Elfoestima",
        schema: 30836,
        hun: "Manóság",
        swe: "Dödligt alvar"
    },
    1157: {
        img: "sweet_smissmas_sweater",
        eng: "Sweet Smissmas Sweater",
        pol: "Słodziaśny śniąteczny sweter",
        sch: "甜蜜的圣诞毛衣",
        fre: "Doux tricot de Smissmas",
        bra: "Suave Suéter de Natal",
        schema: 30826,
        hun: "Gyönyörű Galácsonyi Garbó",
        swe: "Söt Smissmaströja"
    },
    1158: {
        img: "santarchimedes",
        eng: "Santarchimedes",
        pol: "Święty Mikomedes",
        sch: "圣基米德",
        fre: "Paparchimède",
        bra: "Arquinoeldes",
        schema: 30825,
        hun: "Mikuládész"
    },
    1159: {
        img: "socked_and_loaded",
        eng: "Socked and Loaded",
        pol: "Skarpeta słodkości",
        sch: "武装之袜",
        fre: "Enchaussé, c'est pesé",
        bra: "Meia Munida",
        schema: 30818,
        hun: "Zokniba Töltve",
        rom: "Înșosetat și încărcat",
        swe: "Julstrumpan"
    },
    1160: {
        img: "flammable_favor",
        eng: "Flammable Favor",
        pol: "Paląca przysługa",
        sch: "易燃之礼",
        fre: "Faveur inflammable",
        bra: "Favor Inflamável",
        schema: 30819,
        hun: "Meleg Meglepetés",
        swe: "Brandfarlig Gåva"
    },
    1161: {
        img: "bomb_beanie",
        eng: "Bomb Beanie",
        pol: "Niesamoprzylepna czapka",
        sch: "黏弹头套",
        fre: "Boonnet",
        bra: "Touca Bombástica",
        schema: 30823,
        hun: "Bombasapka",
        swe: "Bombmössa"
    },
    1162: {
        img: "electric_twanger",
        eng: "Electric Twanger",
        pol: "Pan elektryk",
        sch: "三昧电弦",
        fre: "La vibration électrique",
        bra: "O Suingue Elétrico",
        schema: 30824,
        hun: "Az Elektromos Pengettyű",
        swe: "Elguran"
    },
    1163: {
        img: "woolen_warmer",
        eng: "Woolen Warmer",
        pol: "Wełenka ciepełka",
        sch: "羊毛针织帽",
        fre: "Bonnet de laine",
        bra: "Gorro de Lã",
        schema: 30833,
        hun: "Gyapjúbúra",
        swe: "Ullmössa"
    },
    1164: {
        img: "snowwing",
        eng: "Snowwing",
        pol: "Śniegolot",
        sch: "雪翼滑板",
        fre: "Ailes des neiges",
        bra: "Pranchasa",
        schema: 30820,
        hun: "Szárnyboard",
        ger: "Schneewehe"
    },
    1165: {
        img: "pyro_the_flamedeer",
        eng: "Pyro the Flamedeer",
        pol: "Płomienifer Pyro",
        sch: "火焰驯鹿",
        fre: "Pyro le cerflamboyant",
        bra: "Pyro, a Rena das Chamas",
        schema: 30835,
        hun: "Piró, a Lángszarvas",
        swe: "Pyro Eldrenen"
    },
    1166: {
        img: "head_prize",
        eng: "Head Prize",
        pol: "Głowna nagroda",
        sch: "头等大奖",
        fre: "Renard empaillé",
        bra: "Gorro de Raposa",
        schema: 30838,
        hun: "A Fejdíj",
        swe: "Huvudpriset"
    },
    1167: {
        img: "bomber_knight",
        eng: "Bomber Knight",
        pol: "Bombowy rycerz",
        sch: "炸弹骑士头盔",
        fre: "Le chevalier boum",
        bra: "O Cavaleiro Bombardeiro",
        schema: 30830,
        hun: "A Bombajó Lovag",
        swe: "Bombriddaren"
    },
    1168: {
        img: "snowmann",
        eng: "Snowmann",
        pol: "Bałwanek",
        sch: "曼恩雪人帽",
        fre: "Le Mann de neige",
        bra: "O Mann de Neve",
        schema: 30829,
        hun: "A Hómannus",
        swe: "Snömannen"
    },
    1169: {
        img: "airtight_arsonist",
        eng: "Airtight Arsonist",
        pol: "Hermetyczny podpalacz",
        sch: "气密式纵火狂面罩",
        fre: "Masque à oxygène de l'incendiaire",
        bra: "Incendiário Impenetrável",
        schema: 30859,
        hun: "Légmentes Lánglovag",
        swe: "Lufttätt ljushuvud"
    },
    1170: {
        img: "flakcatcher",
        eng: "Flakcatcher",
        pol: "Flakamizela",
        sch: "弹药背心",
        fre: "Gilet attrape-balles",
        bra: "Para-Flak",
        schema: 30853,
        hun: "Repeszmellény",
        swe: "Splitterfångaren"
    },
    1171: {
        img: "airborne_attire",
        eng: "Airborne Attire",
        pol: "Powietrzne przyodzienie",
        sch: "飞行员夹克",
        fre: "Veste d'aviateur",
        bra: "Adornos do Aviador",
        schema: 30873,
        hun: "Repülős Ruházat",
        swe: "Pilotjacka"
    },
    1172: {
        img: "warhood",
        eng: "Warhood",
        pol: "Kaptur wojenny",
        sch: "蒙面兜帽",
        fre: "Chèche de guerre",
        bra: "Capuz de Guerra",
        schema: 30866,
        hun: "Hadicsuklya",
        swe: "Krigsluvan"
    },
    1173: {
        img: "field_practice",
        eng: "Field Practice",
        pol: "Praktyka polowa",
        sch: "战地医生贝雷帽",
        fre: "Médecin de terrain",
        bra: "Médico Militar",
        schema: 30862,
        hun: "Terepgyakorlat",
        swe: "Fältövning"
    },
    1174: {
        img: "head_mounted_double_observatory",
        eng: "Head Mounted Double Observatory",
        pol: "Podwójne patrzałki",
        sch: "头戴式双筒观测器",
        fre: "Visiolunettes",
        bra: "Biobservatório Cranial",
        schema: 30872,
        hun: "Fejre Illeszthető Kettős Obszervatórium",
        swe: "Huvudmonterad Dubbelobservatorium"
    },
    1175: {
        img: "blast_defense",
        eng: "Blast Defense",
        pol: "Wystrzałowa obrona",
        sch: "钢铁防爆盔",
        fre: "Protection du forgeron",
        bra: "Defesa Explosiva",
        schema: 30863,
        hun: "Robbanásvédelem",
        swe: "Bombskyddet"
    },
    1176: {
        img: "upgrade",
        eng: "Upgrade",
        pol: "Ulepszenie",
        sch: "高科技间谍",
        fre: "Cerveau mécanique",
        bra: "Visor de Visão",
        schema: 30848,
        hun: "A Fejlesztés",
        swe: "Uppgraderingen"
    },
    1177: {
        img: "speedsters_spandex",
        eng: "Speedster's Spandex",
        pol: "Lycra biegacza",
        sch: "疾跑者的肩带",
        fre: "Veste du pistard prompt",
        bra: "Tecido Expansível",
        schema: 30875,
        hun: "A Száguldó Spandexe",
        swe: "Fartdårens fartdräkt"
    },
    1178: {
        img: "down_under_duster",
        eng: "Down Under Duster",
        pol: "Płaszcz z pustkowi",
        sch: "遮尘风衣",
        fre: "Manteau antipodal",
        bra: "Espanador Australiano",
        schema: 30856,
        hun: "Ausztrálca",
        swe: "Kiwi-kappa"
    },
    1179: {
        img: "hawk_eyed_hunter",
        eng: "Hawk-Eyed Hunter",
        pol: "Jastrzębiooki łowca",
        sch: "鹰眼猎手",
        fre: "Œil de lynx",
        bra: "Olho do Predador",
        schema: 30858,
        hun: "Vércseszemű Vadász",
        swe: "Skarpsynt skytte"
    },
    1180: {
        img: "messengers_mail_bag",
        eng: "Messenger's Mail Bag",
        pol: "Listonoszka gońca",
        sch: "信使邮包",
        fre: "Sacoche du facteur",
        bra: "Mochila do Mensageiro",
        schema: 30869,
        hun: "A Hírnök Postazsákja",
        swe: "Brevbärarens brevväska"
    },
    1181: {
        img: "pocket_pauling",
        eng: "Pocket Pauling",
        pol: "Kieszonkowa Pauling",
        sch: "宝琳玩偶",
        fre: "Pauling de poche",
        bra: "Pauling de Bolso",
        schema: 30849,
        hun: "Zseb-Pauling",
        swe: "Fick-Pauling"
    },
    1182: {
        img: "archers_sterling",
        eng: "Archer's Sterling",
        pol: "Niezawodny strzelec",
        sch: "弓手的护鼻头盔",
        fre: "Archer australien",
        bra: "Arqueiro Certeiro",
        schema: 30874,
        hun: "Kitűnő Íjászsisak",
        swe: "Bågskyttehjälm"
    },
    1183: {
        img: "guilden_guardian",
        eng: "Guilden Guardian",
        pol: "Obrońca cechu",
        sch: "皇家卫队",
        fre: "Gardien médiéval",
        bra: "Guardião da Guilda",
        schema: 30857,
        hun: "Veretes Védelmező",
        swe: "Slottets skyddsvakt"
    },
    1184: {
        img: "legendary_lid",
        eng: "Legendary Lid",
        pol: "Legendarny dekiel",
        sch: "传奇武士的斗笠",
        fre: "Casque légendaire",
        bra: "O Disco da Dinastia",
        schema: 30868,
        hun: "Legendás Kalap",
        swe: "Mytomspunnen mästarhatt"
    },
    1185: {
        img: "plumbers_cap",
        eng: "Plumber's Cap",
        pol: "Zestaw hydraulika",
        sch: "水管工之帽",
        fre: "Casquette du plombier",
        bra: "Boina di Encanadore",
        schema: 30846,
        hun: "A Vízszerelő Sapkája",
        swe: "Rörmokarkeps"
    },
    1186: {
        img: "lightning_lid",
        eng: "Lightning Lid",
        pol: "Piorunujący czerep",
        sch: "闪电之盔",
        fre: "Casque à éclairs",
        bra: "O Capacete Cinético",
        schema: 30867,
        hun: "A Villámsisak",
        swe: "Blixthuvudet"
    },
    1187: {
        img: "flash_of_inspiration",
        eng: "Flash of Inspiration",
        pol: "Błysk inspiracji",
        sch: "灵光一闪",
        fre: "Tonnerre de Zeus",
        bra: "Faísca da Inspiração",
        schema: 30871,
        hun: "Isteni Szikra",
        swe: "Snilleblixt"
    },
    1188: {
        img: "firemans_essentials",
        eng: "Fireman's Essentials",
        pol: "Niezbędnik strażaka",
        sch: "消防员的必备品",
        fre: "Les essentiels du pompier",
        bra: "Ferramentas para Fogo",
        schema: 30900,
        hun: "Tűzoltó-alapfelszerelés",
        swe: "Brandmannens Väsentligheter"
    },
    1189: {
        img: "conaghers_utility_idol",
        eng: "Conaghers' Utility Idol",
        pol: "Bożek pożytku Conaghera",
        sch: "工程之神的塑像",
        fre: "Idole de Conagher",
        bra: "Ídolo de Utilidades dos Conaghers",
        schema: 30908,
        hun: "Conagher Műszaki Bálványa",
        swe: "Conaghers händiga avbildning"
    },
    1190: {
        img: "pithy_professional",
        eng: "Pithy Professional",
        pol: "Rdzenny profesjonalista",
        sch: "精干的专业佣兵",
        fre: "Salacot",
        bra: "O Profissional de Poucas Palavras",
        schema: 30915,
        hun: "Lényegretörő Szakértő",
        swe: "Proffsets tropikhjälm"
    },
    1191: {
        img: "classy_capper",
        eng: "Classy Capper",
        pol: "Klasyczny kapelusznik",
        sch: "气派的骑士帽",
        fre: "Le couvre-chef élégant",
        bra: "O Caçador de Classe",
        schema: 30893,
        hun: "A Klassz Kalap",
        swe: "Stilig Skyddare"
    },
    1192: {
        img: "most_dangerous_mane",
        eng: "Most Dangerous Mane",
        pol: "Najniebezpieczniejsza narośl",
        sch: "超级危险的胡须",
        fre: "La crinière la plus dangereuse",
        bra: "A Perigosíssima Juba",
        schema: 30894,
        hun: "A Legveszélyesebb Sörény",
        swe: "Den Farligaste Manen"
    },
    1193: {
        img: "forest_footwear",
        eng: "Forest Footwear",
        pol: "Obuwie leśnika",
        sch: "丛林之靴",
        fre: "Chaussures forestières",
        bra: "Botas Selvagens",
        schema: 30890,
        hun: "Erdei Lábravaló",
        swe: "Skogskor"
    },
    1194: {
        img: "shellmet",
        eng: "Shellmet",
        pol: "Łuskohełm",
        sch: "丛林老兵的头盔",
        fre: "Le cascrapace",
        bra: "O Cartuchete",
        schema: 30897,
        hun: "A Nagy Kaliber",
        swe: "Djungelhjälmen"
    },
    1195: {
        img: "attack_packs",
        eng: "Attack Packs",
        pol: "Szelki szturmu",
        sch: "作战装具",
        fre: "Réserves militaires",
        bra: "Colete de Combate",
        schema: 30896,
        hun: "Támadócsomag",
        swe: "Attackfack"
    },
    1196: {
        img: "nuke",
        eng: "Nuke",
        pol: "Książę",
        sch: "公爵发型",
        fre: "Le Duke",
        bra: "O Corte sem Chiclete",
        schema: 30885,
        hun: "Az Atom",
        ger: "Der Nuke"
    },
    1197: {
        img: "bait_and_bite",
        eng: "Bait and Bite",
        pol: "Duża sztuka",
        sch: "钓鱼工具包",
        fre: "Appâts et hameçons",
        bra: "Peixinho Pescado",
        schema: 30916,
        hun: "Harapós Csali",
        swe: "Bete och byte"
    },
    1198: {
        img: "riflemans_regalia",
        eng: "Rifleman's Regalia",
        pol: "Insygnia strzelca",
        sch: "步枪手军装",
        fre: "Tenue du fusilleur",
        bra: "Farda do Franco-atirador",
        schema: 30895,
        hun: "A Karabélyos Ékessége",
        swe: "Fältjägarens Finkläder"
    },
    1199: {
        img: "croaking_hazard",
        eng: "Croaking Hazard",
        pol: "Rechoczące niebezpieczeństwo",
        sch: "危险的呱呱",
        fre: "Le danger coassant",
        bra: "O Sapo Solidário",
        schema: 30881,
        hun: "A Brekegő Veszély",
        swe: "Kväkande Faran"
    },
    1200: {
        img: "transparent_trousers",
        eng: "Transparent Trousers",
        pol: "Bojówki bojownika",
        sch: "显而易见的裤子",
        fre: "Pantalons transparents",
        bra: "Calças Camufladas",
        schema: 30889,
        hun: "Nemlátszó Nadrág",
        swe: "Kamouflagebyxor"
    },
    1201: {
        img: "d_eye_monds",
        eng: "D-eye-monds",
        pol: "Błyskoczki",
        sch: "宝石之眼",
        fre: "D-yeux-mants",
        bra: "Olhos Diamantes",
        schema: 30901,
        hun: "Gyémántszemek",
        swe: "Ögonglitter"
    },
    1202: {
        img: "hunter_in_darkness",
        eng: "Hunter in Darkness",
        pol: "Nocny łowca",
        sch: "暗夜猎手",
        fre: "Chasseur de nuit",
        bra: "O Caçador Noturno",
        schema: 30877,
        hun: "A Sötétben Vadászó",
        swe: "Jägaren i Mörkret"
    },
    1203: {
        img: "tropical_toad",
        eng: "Tropical Toad",
        pol: "Tropikalna ropucha",
        sch: "热带蟾蜍",
        fre: "Crapaud tropical",
        bra: "Sapo Tropical",
        schema: 30909,
        hun: "Trópusi Varangy",
        swe: "Portabel padda"
    },
    1204: {
        img: "jungle_jersey",
        eng: "Jungle Jersey",
        pol: "Dżunglowy dżersej",
        sch: "丛林毛线衫",
        fre: "Veste forestière",
        bra: "Colete Selvagem",
        schema: 30888,
        hun: "Dzsungelöltözet",
        swe: "Djungeljacka"
    },
    1205: {
        img: "aztec_aggressor",
        eng: "Aztec Aggressor",
        pol: "Aztecki agresor",
        sch: "阿兹特克狂战士",
        fre: "L'agresseur aztèque",
        bra: "O Agressor Asteca",
        schema: 30914,
        hun: "Az Azték Agresszor",
        swe: "Aztekiska angriparen"
    },
    1206: {
        img: "war_eagle",
        eng: "War Eagle",
        pol: "Orzeł wojny",
        sch: "战鹰图腾面具",
        fre: "Aigle tiki",
        bra: "A Águia de Guerra",
        schema: 30887,
        hun: "A Harci Sas",
        swe: "Krigsörnen"
    },
    1207: {
        img: "quizzical_quetzal",
        eng: "Quizzical Quetzal",
        pol: "Dziwaczny kwezal",
        sch: "古怪的格查尔鸟",
        fre: "Quizzalcoalt",
        bra: "Quetzal Questionador",
        schema: 30878,
        hun: "Kötekedő Kvézál",
        swe: "Qacklande Quetzal"
    },
    1208: {
        img: "aloha_apparel",
        eng: "Aloha Apparel",
        pol: "Średnia hawajska",
        sch: "夏威夷衬衫",
        fre: "Chemise hawaïenne",
        bra: "Colarinho Calorento",
        schema: 30884,
        hun: "Hawaii Hacuka",
        swe: "Semesterskjortan"
    },
    1209: {
        img: "commando_elite",
        eng: "Commando Elite",
        pol: "Elitarny komandos",
        sch: "精英特种兵",
        fre: "Commando d'élite",
        bra: "Capacete do Comando",
        schema: 30912,
        hun: "Elit Különítmény",
        swe: "Kommandoelit"
    },
    1210: {
        img: "siberian_tigerstripe",
        eng: "Siberian Tigerstripe",
        pol: "Tygrys syberyjski",
        sch: "西伯利亚之虎",
        fre: "Uniforme militaire sibérien",
        bra: "Soldado da Sibéria",
        schema: 30913,
        hun: "Szibériai Tigriscsíkos",
        swe: "Sibiriska tigerstreck"
    },
    1211: {
        img: "cammy_jammies",
        eng: "Cammy Jammies",
        pol: "Pidżama moro",
        sch: "迷彩之裤",
        fre: "Le pyj' de camouflage",
        bra: "A Farda Parda",
        schema: 30891,
        hun: "Terepmintás Pizsi",
        swe: "Kamo-pyjamasen"
    },
    1212: {
        img: "deitys_dress",
        eng: "Deity's Dress",
        pol: "Szata bóstwa",
        sch: "羽蛇神祭服",
        fre: "Tenue du Quetzalcoatl",
        bra: "A Serpente Incendiada",
        schema: 30902,
        hun: "Isteni Viselet",
        swe: "Gudomsklädsel"
    },
    1213: {
        img: "sharp_chest_pain",
        eng: "Sharp Chest Pain",
        pol: "Kłujący ból",
        sch: "胸口插大刀",
        fre: "Douleur aiguë à la poitrine",
        bra: "Dor Afiada no Peito",
        schema: 30898,
        hun: "Szúró Mellkasi Fájdalom",
        swe: "Skarp Bröstsmärta"
    },
    1214: {
        img: "vitals_vest",
        eng: "Vitals Vest",
        pol: "Kamizelka konieczności",
        sch: "医疗必备品背心",
        fre: "Veste du toubib",
        bra: "Colete Médico",
        schema: 30906,
        hun: "Mentő Mellény",
        swe: "Vitala västen"
    },
    1215: {
        img: "battle_boonie",
        eng: "Battle Boonie",
        pol: "Bitewne boonie",
        sch: "军医布尼帽",
        fre: "Couvre-chef médical",
        bra: "Chapéu de Selva",
        schema: 30907,
        hun: "Harctéri Satyek",
        swe: "Stridsboonie"
    },
    1216: {
        img: "heavy_harness",
        eng: "Heavy Harness",
        pol: "Gruba uprząż",
        sch: "重型背带",
        fre: "Harnais du Heavy",
        bra: "Arnês do Heavy",
        schema: 30910,
        hun: "Gépágyús Hám",
        swe: "Heavys hängslen"
    },
    1217: {
        img: "fat_mans_field_cap",
        eng: "Fat Man's Field Cap",
        pol: "Masywna czapka polowa",
        sch: "肥佬的野战帽",
        fre: "Chapeau du replet",
        bra: "Quepe de Campo",
        schema: 30911,
        hun: "Kövér Ember Tábori Sapkája",
        swe: "Feta mannens fältmössa"
    },
    1218: {
        img: "conspicuous_camouflage",
        eng: "Conspicuous Camouflage",
        pol: "Ostentacyjny kamuflaż",
        sch: "引人注目的伪装服",
        fre: "Camouflage remarquable",
        bra: "A Camuflagem Chamativa",
        schema: 30892,
        hun: "A Feltűnő Álca",
        swe: "Iögonfallande kamouflage"
    },
    1219: {
        img: "feathered_fiend",
        eng: "Feathered Fiend",
        pol: "Pierzasty demon",
        sch: "羽蛇神面具",
        fre: "Heaume du Quetzalcoatl",
        bra: "Espírito Emplumado",
        schema: 30903,
        hun: "Tollas Démon",
        swe: "Befjädrad Best"
    },
    1220: {
        img: "hot_huaraches",
        eng: "Hot Huaraches",
        pol: "Gorące sandały",
        sch: "灼热人字拖",
        fre: "Huaraches embrasées",
        bra: "O Chinelo Abrasado",
        schema: 30905,
        hun: "A Forró Saruk",
        swe: "Varma sandalerna"
    },
    1221: {
        img: "pocket_saxton",
        eng: "Pocket Saxton",
        pol: "Kieszonkowy Saxton",
        sch: "口袋萨克斯顿",
        fre: "Saxton de poche",
        bra: "Saxton de Bolso",
        schema: 30880,
        hun: "Zseb-Saxton",
        swe: "Fick-Saxton"
    },
    1222: {
        img: "crit_cloak",
        eng: "Crit Cloak",
        pol: "Krytyczny kaptur",
        sch: "爆击火箭神教徒",
        fre: "Voile maya",
        bra: "O Capuz Crítico",
        schema: 30899,
        hun: "A Krit Köpeny",
        swe: "Crithuvan"
    },
    1223: {
        img: "sacrificial_stone",
        eng: "Sacrificial Stone",
        pol: "Kamień ofiarny",
        sch: "献祭之石",
        fre: "Pierre sacrificielle",
        bra: "Pedra Sacrificial",
        schema: 30904,
        hun: "Áldozókő",
        swe: "Offersten"
    },
    1224: {
        img: "bananades",
        eng: "Bananades",
        pol: "Bomby bananowe",
        sch: "香蕉炸弹",
        fre: "Bananades",
        bra: "Bananadas",
        schema: 30886,
        hun: "Robbanánok",
        swe: "Banater"
    },
    1225: {
        img: "jungle_wreath",
        eng: "Jungle Wreath",
        pol: "Dżunglowy wieniec",
        sch: "丛林里的花环",
        fre: "Couronne de fleurs",
        bra: "Coroa Florestal",
        schema: 30882,
        hun: "Dzsungelkoszorú",
        swe: "Djungelkrans"
    },
    1226: {
        img: "aztec_warrior",
        eng: "Aztec Warrior",
        pol: "Aztecki wojownik",
        sch: "阿兹特克战盔",
        fre: "Le guerrier aztèque",
        bra: "O Guerreiro Asteca",
        schema: 30879,
        hun: "Az Azték Harcos",
        swe: "Aztekiska Krigaren"
    },
    1227: {
        img: "slithering_scarf",
        eng: "Slithering Scarf",
        pol: "Syczący szal",
        sch: "嘶嘶作响的围巾",
        fre: "L'essarpe",
        bra: "O Cachecol Rastejante",
        schema: 30883,
        hun: "A Sikló Sál",
        swe: "Slingrande Scarf"
    },
    1228: {
        img: "mannana_peeled_war_paint",
        eng: "Mannana Peeled War Paint",
        pol: "Obrany banan | Barwy wojenne",
        sch: "曼恩香蕉 战绘",
        fre: "Mannanas | Peinture de guerre",
        bra: "Mannana Descascada Tinta de Guerra",
        schema: 16302,
        hun: "Hámozott Mannános Harci festés",
        rom: "Mannana Peeled Vopsea de război",
        swe: "Mannanskalad Krigsfärg"
    },
    1229: {
        img: "leopard_printed_war_paint",
        eng: "Leopard Printed War Paint",
        pol: "Panterka | Barwy wojenne",
        sch: "斑点豹纹 战绘",
        fre: "Léopard | Peinture de guerre",
        bra: "Pele de Leopardo Tinta de Guerra",
        schema: 16310,
        hun: "Leopárdmintás Harci festés",
        rom: "Leopard Printed Vopsea de război",
        swe: "Leopardmönstrad Krigsfärg"
    },
    1230: {
        img: "tiger_buffed_war_paint",
        eng: "Tiger Buffed War Paint",
        pol: "Tygrysi pazur | Barwy wojenne",
        sch: "老虎条纹 战绘",
        fre: "Tigre | Peinture de guerre",
        bra: "Pele de Tigre Tinta de Guerra",
        schema: 16307,
        hun: "Tigriscsíkozott Harci festés",
        rom: "Tiger Buffed Vopsea de război",
        swe: "Tigerputsad Krigsfärg"
    },
    1231: {
        img: "bamboo_brushed_war_paint",
        eng: "Bamboo Brushed War Paint",
        pol: "Bambusowe krycie | Barwy wojenne",
        sch: "势如破竹 战绘",
        fre: "Bambou | Peinture de guerre",
        bra: "Pintura de Bambu Tinta de Guerra",
        schema: 16306,
        hun: "Bambusszal Borított Harci festés",
        rom: "Bamboo Brushed Vopsea de război",
        swe: "Bambuborstad Krigsfärg"
    },
    1232: {
        img: "anodized_aloha_war_paint",
        eng: "Anodized Aloha War Paint",
        pol: "Anodowane aloha | Barwy wojenne",
        sch: "椰林风光 战绘",
        fre: "Aloha | Peinture de guerre",
        bra: "Aloha-dização Tinta de Guerra",
        schema: 16305,
        hun: "Alohásan Eloxált Harci festés",
        rom: "Anodized Aloha Vopsea de război",
        swe: "Anodiserad Aloha Krigsfärg"
    },
    1233: {
        img: "pina_polished_war_paint",
        eng: "Piña Polished War Paint",
        pol: "Piña colada | Barwy wojenne",
        sch: "菠萝图案 战绘",
        fre: "Piña | Peinture de guerre",
        bra: "Aroma de Abacaxi Tinta de Guerra",
        schema: 16309,
        hun: "Ananászos Harci festés",
        rom: "Piña Polished Vopsea de război",
        swe: "Piña-polerad Krigsfärg"
    },
    1234: {
        img: "croc_dusted_war_paint",
        eng: "Croc Dusted War Paint",
        pol: "Siła krokodyla | Barwy wojenne",
        sch: "鳄鱼池塘 战绘",
        fre: "Croco Peinture de guerre",
        bra: "Crocodilos Cromados Tinta de Guerra",
        schema: 16308,
        hun: "Krokodilos Harci festés",
        rom: "Croc Dusted Vopsea de război",
        swe: "Krokodildammad Krigsfärg"
    },
    1235: {
        img: "macaw_masked_war_paint",
        eng: "Macaw Masked War Paint",
        pol: "Papuzi kamuflaż | Barwy wojenne",
        sch: "金刚鹦鹉 战绘",
        fre: "Ara macao | Peinture de guerre",
        bra: "Disfarce de Arara Tinta de Guerra",
        schema: 16303,
        hun: "Papagájborítású Harci festés",
        rom: "Macaw Masked Vopsea de război",
        swe: "Aramaskerad Krigsfärg"
    },
    1236: {
        img: "yeti_coated_war_paint",
        eng: "Yeti Coated War Paint",
        pol: "Skóra z yeti | Barwy wojenne",
        sch: "雪人之眼 战绘",
        fre: "Yéti | Peinture de guerre",
        bra: "Revestimento Abominável Tinta de Guerra",
        schema: 16300,
        hun: "Jetiborítású Harci festés",
        rom: "Yeti Coated Vopsea de război",
        swe: "Yetiklädd Krigsfärg"
    },
    1237: {
        img: "sax_waxed_war_paint",
        eng: "Sax Waxed War Paint",
        pol: "Złoty mat | Barwy wojenne",
        sch: "萨克斯顿 战绘",
        fre: "Saxton | Peinture de guerre",
        bra: "Saxton à Cera Tinta de Guerra",
        schema: 16304,
        hun: "Saxin Sima Harci festés",
        rom: "Sax Waxed Vopsea de război",
        swe: "Saxvaxad Krigsfärg"
    },
    1238: {
        img: "park_pigmented_war_paint",
        eng: "Park Pigmented War Paint",
        pol: "Nastrój parku | Barwy wojenne",
        sch: "公园海报 战绘",
        fre: "Yeti Park | Peinture de guerre",
        bra: "Passeio no Parque Tinta de Guerra",
        schema: 16301,
        hun: "Parkstílusú Harci festés",
        rom: "Park Pigmented Vopsea de război",
        swe: "Parkpigmenterad Krigsfärg"
    },
    1239: {
        img: "bloom_buffed_war_paint",
        eng: "Bloom Buffed War Paint",
        pol: "Kwiecisty rozkwit | Barwy wojenne",
        sch: "鲜花盛开 战绘",
        fre: "Floraison | Peinture de guerre",
        bra: "Forro Florido Tinta de Guerra",
        schema: 17200,
        hun: "Virágba Borult Harci festés",
        rom: "Bloom Buffed Vopsea de război",
        swe: "Blomputsad Krigsfärg"
    },
    1240: {
        img: "cardboard_boxed_war_paint",
        eng: "Cardboard Boxed War Paint",
        pol: "Kartonowe opakowanie | Barwy wojenne",
        sch: "纸盒包裹 战绘",
        fre: "Emboîté | Peinture de guerre",
        bra: "Papelão Tinta de Guerra",
        schema: 17211,
        hun: "Kartondobozolt Harci festés",
        rom: "Cardboard Boxed Vopsea de război",
        swe: "Kartonglåda Krigsfärg"
    },
    1241: {
        img: "star_crossed_war_paint",
        eng: "Star Crossed War Paint",
        pol: "Gwiezdna podróż | Barwy wojenne",
        sch: "璀璨星空 战绘",
        fre: "Stellaire | Peinture de guerre",
        bra: "Estrelado Tinta de Guerra",
        schema: 17208,
        hun: "Rossz Csillagzatú Harci festés",
        rom: "Star Crossed Vopsea de război",
        swe: "Stjärnkorsad Krigsfärg"
    },
    1242: {
        img: "merc_stained_war_paint",
        eng: "Merc Stained War Paint",
        pol: "Symbol najemnika | Barwy wojenne",
        sch: "佣兵之印 战绘",
        fre: "Mercenaire | Peinture de guerre",
        bra: "Mercenário Cúbico Tinta de Guerra",
        schema: 17203,
        hun: "Zsoldfoltos Harci festés",
        rom: "Merc Stained Vopsea de război",
        swe: "Soldatstänkt Krigsfärg"
    },
    1243: {
        img: "quack_canvassed_war_paint",
        eng: "Quack Canvassed War Paint",
        pol: "Kwacze opowieści | Barwy wojenne",
        sch: "致命之鸭 战绘",
        fre: "Coincoin | Peinture de guerre",
        bra: "Patos Estam-patos Tinta de Guerra",
        schema: 17201,
        hun: "Kacsákkal Tarkított Harci festés",
        rom: "Quack Canvassed Vopsea de război",
        swe: "Kvack-kanvasen Krigsfärg"
    },
    1244: {
        img: "clover_camod_war_paint",
        eng: "Clover Camo'd War Paint",
        pol: "Irlandzkie szczęście | Barwy wojenne",
        sch: "四叶草纹 战绘",
        fre: "Porte-bonheur | Peinture de guerre",
        bra: "Camuflagem de Trevos Tinta de Guerra",
        schema: 17209,
        hun: "Lóherékkel Álcázott Harci festés",
        rom: "Clover Camo'd Vopsea de război",
        swe: "Klöverkamouflerad Krigsfärg"
    },
    1245: {
        img: "pizza_polished_war_paint",
        eng: "Pizza Polished War Paint",
        pol: "Palce lizać | Barwy wojenne",
        sch: "食欲大振 战绘",
        fre: "Pizza | Peinture de guerre",
        bra: "Polimento de Pizza Tinta de Guerra",
        schema: 17206,
        hun: "Pizzával Polírozott Harci festés",
        rom: "Pizza Polished Vopsea de război",
        swe: "Pizzapolerad Krigsfärg"
    },
    1246: {
        img: "kill_covered_war_paint",
        eng: "Kill Covered War Paint",
        pol: "Zabójstwo potwierdzone | Barwy wojenne",
        sch: "杀戮刻痕 战绘",
        fre: "Dénombrement | Peinture de guerre",
        bra: "Contando Vítimas Tinta de Guerra",
        schema: 17204,
        hun: "Gyilokrovott Harci festés",
        rom: "Kill Covered Vopsea de război",
        swe: "Mordtäckt Krigsfärg"
    },
    1247: {
        img: "bank_rolled_war_paint",
        eng: "Bank Rolled War Paint",
        pol: "Na bogato | Barwy wojenne",
        sch: "为所欲为 战绘",
        fre: "Billets de banque | Peinture de guerre",
        bra: "Cobertura de Cédulas Tinta de Guerra",
        schema: 17202,
        hun: "Pénzelt Harci festés",
        rom: "Bank Rolled Vopsea de război",
        swe: "Bankrullad Krigsfärg"
    },
    1248: {
        img: "bonk_varnished_war_paint",
        eng: "Bonk Varnished War Paint",
        pol: "Bonkowo | Barwy wojenne",
        sch: "辐射超标 战绘",
        fre: "Bonk | Peinture de guerre",
        bra: "Verniz Bonk Tinta de Guerra",
        schema: 17207,
        hun: "Poffra Fényezett Harci festés",
        rom: "Bonk Varnished Vopsea de război",
        swe: "Bonk-lackerad Krigsfärg"
    },
    1249: {
        img: "dream_piped_war_paint",
        eng: "Dream Piped War Paint",
        pol: "Słodziaśne sny | Barwy wojenne",
        sch: "幻境之物 战绘",
        fre: "Rose bonbon | Peinture de guerre",
        bra: "Animais Fofinhos Tinta de Guerra",
        schema: 17212,
        hun: "Vágyálmodott Harci festés",
        rom: "Dream Piped Vopsea de război",
        swe: "Drömdekor Krigsfärg"
    },
    1250: {
        img: "freedom_wrapped_war_paint",
        eng: "Freedom Wrapped War Paint",
        pol: "Waleczna wolność | Barwy wojenne",
        sch: "星条花纹 战绘",
        fre: "Libertaire | Peinture de guerre",
        bra: "Padrão Patriota Tinta de Guerra",
        schema: 17210,
        hun: "Szabadságba Csomagolt Harci festés",
        rom: "Freedom Wrapped Vopsea de război",
        swe: "Frihetsinslaget Krigsfärg"
    },
    1251: {
        img: "fire_glazed_war_paint",
        eng: "Fire Glazed War Paint",
        pol: "Glazurowany ogień | Barwy wojenne",
        sch: "烈焰之纹 战绘",
        fre: "Brasier | Peinture de guerre",
        bra: "Chama Esmaltada Tinta de Guerra",
        schema: 17205,
        hun: "Tűznyalta Harci festés",
        rom: "Fire Glazed Vopsea de război",
        swe: "Eldglaserad Krigsfärg"
    },
    1252: {
        img: "civic_duty_mk_ii_war_paint",
        eng: "Civic Duty Mk.II War Paint",
        pol: "Obywatelski obowiązek wz. II | Barwy wojenne",
        sch: "公民义务 Mk.II 战绘",
        fre: "Devoir civique Mk.II | Peinture de guerre",
        bra: "Dever Civil Mk.II Tinta de Guerra",
        schema: 16144,
        hun: "Állampolgári Kötelesség, II. széria Harci festés",
        rom: "Civic Duty Mk.II Vopsea de război",
        swe: "Medborgerliga Plikten Mk.II Krigsfärg"
    },
    1253: {
        img: "civil_servant_mk_ii_war_paint",
        eng: "Civil Servant Mk.II War Paint",
        pol: "Służba cywilna wz. II | Barwy wojenne",
        sch: "公仆 Mk.II 战绘",
        fre: "Fonctionnaire Mk.II | Peinture de guerre",
        bra: "Servidor Público Mk.II Tinta de Guerra",
        schema: 16139,
        hun: "Köztisztviselő, II. széria Harci festés",
        rom: "Civil Servant Mk.II Vopsea de război",
        swe: "Statstjänstemannen Mk.II Krigsfärg"
    },
    1254: {
        img: "autumn_mk_ii_war_paint",
        eng: "Autumn Mk.II War Paint",
        pol: "Jesień wz. II | Barwy wojenne",
        sch: "秋日 Mk.II 战绘",
        fre: "Automne Mk.II | Peinture de guerre",
        bra: "Outono Mk.II Tinta de Guerra",
        schema: 16160,
        hun: "Őszi, II. széria Harci festés",
        rom: "Autumn Mk.II Vopsea de război",
        swe: "Höst Mk.II Krigsfärg"
    },
    1255: {
        img: "plaid_potshotter_mk_ii_war_paint",
        eng: "Plaid Potshotter Mk.II War Paint",
        pol: "Strzelec w kratę wz. II | Barwy wojenne",
        sch: "格纹猛击者 Mk.II 战绘",
        fre: "Tireur à carreau Mk.II | Peinture de guerre",
        bra: "Saia Mk.II Tinta de Guerra",
        schema: 16122,
        hun: "Kockás Kocalövész, II. széria Harci festés",
        rom: "Plaid Potshotter Mk.II Vopsea de război",
        swe: "Plädpisten Mk.II Krigsfärg"
    },
    1256: {
        img: "night_owl_mk_ii_war_paint",
        eng: "Night Owl Mk.II War Paint",
        pol: "Nocna sowa wz. II | Barwy wojenne",
        sch: "夜枭 Mk.II 战绘",
        fre: "Oiseau de nuit Mk.II | Peinture de guerre",
        bra: "Coruja da Noite Mk.II Tinta de Guerra",
        schema: 16114,
        hun: "Éjjeli Bagoly, II. széria Harci festés",
        rom: "Night Owl Mk.II Vopsea de război",
        swe: "Nattugglan Mk.II Krigsfärg"
    },
    1257: {
        img: "woodsy_widowmaker_mk_ii_war_paint",
        eng: "Woodsy Widowmaker Mk.II War Paint",
        pol: "Lesisty owdawiacz wz. II | Barwy wojenne",
        sch: "绝后之木 Mk.II 战绘",
        fre: "Endeuilleur boisé Mk.II | Peinture de guerre",
        bra: "Viuvadora Verdejante Mk.II Tinta de Guerra",
        schema: 16113,
        hun: "Erdei Özvegycsináló, II. széria Harci festés",
        rom: "Woodsy Widowmaker Mk.II Vopsea de război",
        swe: "Skogens Änkmakare Mk.II Krigsfärg"
    },
    1258: {
        img: "forest_fire_mk_ii_war_paint",
        eng: "Forest Fire Mk.II War Paint",
        pol: "Leśny ogień wz. II | Barwy wojenne",
        sch: "森林之火 Mk.II 战绘",
        fre: "Feu de forêt Mk.II | Peinture de guerre",
        bra: "Fogo na Floresta Mk.II Tinta de Guerra",
        schema: 16109,
        hun: "Erdőtűz, II. széria Harci festés",
        rom: "Forest Fire Mk.II Vopsea de război",
        swe: "Skogsbrand Mk.II Krigsfärg"
    },
    1259: {
        img: "woodland_warrior_mk_ii_war_paint",
        eng: "Woodland Warrior Mk.II War Paint",
        pol: "Leśny wojownik wz. II | Barwy wojenne",
        sch: "林地战士 Mk.II 战绘",
        fre: "Guerrier des bois Mk.II | Peinture de guerre",
        bra: "Matador do Matagal Mk.II Tinta de Guerra",
        schema: 16106,
        hun: "Erdei Harcos, II. széria Harci festés",
        rom: "Woodland Warrior Mk.II Vopsea de război",
        swe: "Skogskrigaren Mk.II Krigsfärg"
    },
    1260: {
        img: "carpet_bomber_mk_ii_war_paint",
        eng: "Carpet Bomber Mk.II War Paint",
        pol: "Nalot dywanowy wz. II | Barwy wojenne",
        sch: "地毯式轰炸 Mk.II 战绘",
        fre: "Bombardier tapissé Mk.II | Peinture de guerre",
        bra: "Arrasa Quarteirão Mk.II Tinta de Guerra",
        schema: 16104,
        hun: "Szőnyegbombázó, II. széria Harci festés",
        rom: "Carpet Bomber Mk.II Vopsea de război",
        swe: "Mattbombaren Mk.II Krigsfärg"
    },
    1261: {
        img: "wrapped_reviver_mk_ii_war_paint",
        eng: "Wrapped Reviver Mk.II War Paint",
        pol: "Spakowany ożywiciel wz. II | Barwy wojenne",
        sch: "迷彩救援者 Mk.II 战绘",
        fre: "Ressuscitateur emballé Mk.II | Peinture de guerre",
        bra: "Revivedor Embrulhado Mk.II Tinta de Guerra",
        schema: 16102,
        hun: "Becsomagolt Felélesztő, II. széria Harci festés",
        rom: "Wrapped Reviver Mk.II Vopsea de război",
        swe: "Omlindade Återupplivaren Mk.II Krigsfärg"
    },
    1262: {
        img: "nutcracker_mk_ii_war_paint",
        eng: "Nutcracker Mk.II War Paint",
        pol: "Dziadek do orzechów wz. II | Barwy wojenne",
        sch: "胡桃夹子 Mk.II 战绘",
        fre: "Casse-noix Mk.II | Peinture de guerre",
        bra: "Quebra-nozes Mk.II Tinta de Guerra",
        schema: 16161,
        hun: "Diótörő, II. széria Harci festés",
        rom: "Nutcracker Mk.II Vopsea de război",
        swe: "Nötknäpparen Mk.II Krigsfärg"
    },
    1263: {
        img: "smalltown_bringdown_mk_ii_war_paint",
        eng: "Smalltown Bringdown Mk.II War Paint",
        pol: "Miasteczkowy łomot wz. II | Barwy wojenne",
        sch: "夷平小镇 Mk.II 战绘",
        fre: "Ratatineur de village Mk.II | Peinture de guerre",
        bra: "Vilão do Vilarejo Mk.II Tinta de Guerra",
        schema: 16143,
        hun: "Kisvárosi Kedélyrontó, II. széria Harci festés",
        rom: "Smalltown Bringdown Mk.II Vopsea de război",
        swe: "Ortutplånaren Mk.II Krigsfärg"
    },
    1264: {
        img: "macabre_web_mk_ii_war_paint",
        eng: "Macabre Web Mk.II War Paint",
        pol: "Makabryczna sieć wz. II | Barwy wojenne",
        sch: "亡者之网 Mk.II 战绘",
        fre: "Toiles macabres Mk.II | Peinture de guerre",
        bra: "Teia Macabra Mk.II Tinta de Guerra",
        schema: 16163,
        hun: "Kísértethálós, II. széria Harci festés",
        rom: "Macabre Web Mk.II Vopsea de război",
        swe: "Kusligt nät Mk.II Krigsfärg"
    },
    1265: {
        img: "iron_wood_mk_ii_war_paint",
        eng: "Iron Wood Mk.II War Paint",
        pol: "Drzewo żelazne wz. II | Barwy wojenne",
        sch: "铁木 Mk.II 战绘",
        fre: "Bois métallique Mk.II | Peinture de guerre",
        bra: "Madeira de Ferro Mk.II Tinta de Guerra",
        schema: 16120,
        hun: "Vasfa, II. széria Harci festés",
        rom: "Iron Wood Mk.II Vopsea de război",
        swe: "Järnträ Mk.II Krigsfärg"
    },
    1266: {
        img: "backwoods_boomstick_mk_ii_war_paint",
        eng: "Backwoods Boomstick Mk.II War Paint",
        pol: "Grzmiący kij z puszczy wz. II | Barwy wojenne",
        sch: "荒野神兵 Mk.II 战绘",
        fre: "Fusil de derrière les fagots Mk.II | Peinture de guerre",
        bra: "Sertão Mk.II Tinta de Guerra",
        schema: 16112,
        hun: "Susnyás-sörétes, II. széria Harci festés",
        rom: "Backwoods Boomstick Mk.II Vopsea de război",
        swe: "Skogshaglet Mk.II Krigsfärg"
    },
    1267: {
        img: "masked_mender_mk_ii_war_paint",
        eng: "Masked Mender Mk.II War Paint",
        pol: "Zakamuflowany uzdrowiciel wz. II | Barwy wojenne",
        sch: "蒙面者 Mk.II 战绘",
        fre: "Réparateur masqué Mk.II | Peinture de guerre",
        bra: "Medicamento Mascarado Mk.II Tinta de Guerra",
        schema: 16105,
        hun: "Maszkos Befoltozó, II. széria Harci festés",
        rom: "Masked Mender Mk.II Vopsea de război",
        swe: "Maskerade Helaren Mk.II Krigsfärg"
    },
    1268: {
        img: "dead_reckoner_mk_ii_war_paint",
        eng: "Dead Reckoner Mk.II War Paint",
        pol: "Trupi zwiad wz. II | Barwy wojenne",
        sch: "死亡判官 Mk.II 战绘",
        fre: "Cible de la mort Mk.II | Peinture de guerre",
        bra: "Cadáver Calculista Mk.II Tinta de Guerra",
        schema: 16151,
        hun: "Halálos Leszámoló, II. széria Harci festés",
        rom: "Dead Reckoner Mk.II Vopsea de război",
        swe: "Döda Rannsakaren Mk.II Krigsfärg"
    },
    1269: {
        img: "bovine_blazemaker_mk_ii_war_paint",
        eng: "Bovine Blazemaker Mk.II War Paint",
        pol: "Bawoli podpalacz wz. II | Barwy wojenne",
        sch: "奶牛花纹 Mk.II 战绘",
        fre: "Bovin Mk.II | Peinture de guerre",
        bra: "Bovino na Brasa Mk.II Tinta de Guerra",
        schema: 16130,
        hun: "Tüzes Tehén, II. széria Harci festés",
        rom: "Bovine Blazemaker Mk.II Vopsea de război",
        swe: "Oxlika Osaren Mk.II Krigsfärg"
    },
    1270: {
        img: "blast_blocker",
        eng: "Blast Blocker",
        pol: "Pancerz przeciwwybuchowy",
        sch: "防爆背心",
        fre: "Gilet pare-bombes",
        bra: "Exploqueio",
        schema: 30945,
        hun: "Löketfogó",
        swe: "Sprängskyddet"
    },
    1271: {
        img: "caribou_companion",
        eng: "Caribou Companion",
        pol: "Kompan karibu",
        sch: "驯鹿防寒帽",
        fre: "Caribonnet",
        bra: "O Caribu Companheiro",
        schema: 30974,
        hun: "Karibu Koma",
        swe: "Renmössa"
    },
    1272: {
        img: "pocket_santa",
        eng: "Pocket Santa",
        pol: "Kieszonkowy Mikołaj",
        sch: "口袋圣诞老人",
        fre: "Père Noël de poche",
        bra: "Noel de Bolso",
        schema: 30972,
        hun: "Zsebmikulás",
        swe: "Ficktomte"
    },
    1273: {
        img: "down_tundra_coat",
        eng: "Down Tundra Coat",
        pol: "Puchowa kurtka",
        sch: "冻原防寒大衣",
        fre: "Veste du sniper de la toundra",
        bra: "Casaco da Tundra",
        schema: 30971,
        hun: "Nyári Tundrakabát",
        swe: "Tundrajacka"
    },
    1274: {
        img: "puffy_polar_cap",
        eng: "Puffy Polar Cap",
        pol: "Puszysta polarna czapa",
        sch: "极地毛绒帽",
        fre: "Casquette rembourrée",
        bra: "Boné Polar Acolchoado",
        schema: 30958,
        hun: "Pufi Polársapka",
        swe: "Vintermössa"
    },
    1275: {
        img: "polar_bear",
        eng: "Polar Bear",
        pol: "Niedźwiedź polarny",
        sch: "北极巨熊",
        fre: "Ours polaire",
        bra: "Urso Polar",
        schema: 30964,
        hun: "Jegesmedve",
        swe: "Isbjörnen"
    },
    1276: {
        img: "sinners_shade",
        eng: "Sinner's Shade",
        pol: "Grzeszny grubas",
        sch: "罪者之镜",
        fre: "Duelliste au soleil",
        bra: "Sombra do Pecador",
        schema: 30959,
        hun: "A Kötélrevaló Kalapja",
        swe: "Syndarens solskydd"
    },
    1277: {
        img: "brass_bucket",
        eng: "Brass Bucket",
        pol: "Rakietowy wiadrogłowy",
        sch: "火箭十字盔",
        fre: "Heaume du suzerain",
        bra: "O Balde de Bronze",
        schema: 30969,
        hun: "Rézvödör",
        swe: "Mässingshjälm"
    },
    1278: {
        img: "pocket_yeti",
        eng: "Pocket Yeti",
        pol: "Kieszonkowy yeti",
        sch: "口袋雪人",
        fre: "Yéti de poche",
        bra: "Yeti de Bolso",
        schema: 30929,
        hun: "Zsebjeti",
        swe: "Fickyeti"
    },
    1279: {
        img: "truckers_topper",
        eng: "Trucker's Topper",
        pol: "Typowy tirowiec",
        sch: "卡车司机帽",
        fre: "Casquette du routier",
        bra: "Boné do Caminhoneiro",
        schema: 30930,
        hun: "Kiköpött Kamionos",
        swe: "Truckerkepsen"
    },
    1280: {
        img: "sledders_sidekick",
        eng: "Sledder's Sidekick",
        pol: "Psi pomocnik",
        sch: "雪橇小伙伴",
        fre: "Copain canin",
        bra: "O Amigo do Ombro",
        schema: 30923,
        hun: "Szánkópajtás",
        swe: "Slädförarens sällskap"
    },
    1281: {
        img: "wild_west_whiskers",
        eng: "Wild West Whiskers",
        pol: "Bardzo dzikie bokobrody",
        sch: "狂野西部胡须",
        fre: "Moustache du Far West",
        bra: "Bigode do Velho Oeste",
        schema: 30960,
        hun: "Alávaló Arcszőrzet",
        swe: "Vilda västernskägg"
    },
    1282: {
        img: "coldfront_commander",
        eng: "Coldfront Commander",
        pol: "Dowódca z zimnego frontu",
        sch: "严寒战线指挥官",
        fre: "Visière du front froid",
        bra: "Comandante Frente Fria",
        schema: 30939,
        hun: "Coldfront Parancsnok",
        swe: "Kallfrontkaptenen"
    },
    1283: {
        img: "burning_beanie",
        eng: "Burning Beanie",
        pol: "Dogrzany dekiel",
        sch: "烈焰造型无边帽",
        fre: "Bonnet chauffant",
        bra: "Gorro Flamejante",
        schema: 30936,
        hun: "Tüzes Tökfödő",
        swe: "Het hatt"
    },
    1284: {
        img: "melody_of_misery",
        eng: "Melody Of Misery",
        pol: "Melodia męki",
        sch: "悲惨之声",
        fre: "Mélodie de misère",
        bra: "Melodia da Miséria",
        schema: 30973,
        hun: "Szenvedés Szimfónia",
        swe: "Misärens melodi"
    },
    1285: {
        img: "handsome_hitman",
        eng: "Handsome Hitman",
        pol: "Zniewalający zabójca",
        sch: "风流杀手",
        fre: "Séducteur à gages",
        bra: "Assassino Bonitão",
        schema: 30955,
        hun: "Jóképű Jégretevő",
        swe: "Tjusig torped"
    },
    1286: {
        img: "cats_pajamas",
        eng: "Cat's Pajamas",
        pol: "Koci kostium",
        sch: "猫耳睡衣",
        fre: "Pychama",
        bra: "Pijama de Gatinho",
        schema: 30937,
        hun: "Cicapizsi",
        swe: "Kattens pyjamas"
    },
    1287: {
        img: "coldfront_carapace",
        eng: "Coldfront Carapace",
        pol: "Pancerz z zimnego frontu",
        sch: "严寒战线防护衣",
        fre: "Attirail du front froid",
        bra: "Carapaça Frente Fria",
        schema: 30940,
        hun: "Coldfront Páncél",
        swe: "Köldskyddet"
    },
    1288: {
        img: "hungover_hero",
        eng: "Hungover Hero",
        pol: "Mrożone mamrotanie",
        sch: "宿醉英雄",
        fre: "Gueule de bois",
        bra: "Herói de Ressaca",
        schema: 30954,
        hun: "Másnapos Hős",
        swe: "Superdräkten"
    },
    1289: {
        img: "balloonihoodie",
        eng: "Balloonihoodie",
        pol: "Balonokaptur",
        sch: "气球独角兽兜帽",
        fre: "Ballicapuche",
        bra: "Capuznicórnio",
        schema: 30928,
        hun: "Kapucnikornis",
        swe: "Ballonghuva"
    },
    1290: {
        img: "geometrical_teams_war_paint",
        eng: "Geometrical Teams War Paint",
        pol: "Geometria | Barwy wojenne",
        sch: "几何图案 战绘",
        fre: "Géométrie | Peinture de guerre",
        bra: "Formas Geométricas Tinta de Guerra",
        schema: 17215,
        hun: "Geometriai csapatok Harci festés",
        rom: "Geometrical Teams Vopsea de război",
        swe: "Geometriska lag Krigsfärg"
    },
    1291: {
        img: "bomber_soul_war_paint",
        eng: "Bomber Soul War Paint",
        pol: "Dusza bombardiera | Barwy wojenne",
        sch: "高空轰炸 战绘",
        fre: "Militaire | Peinture de guerre",
        bra: "Alma do Bombardeiro Tinta de Guerra",
        schema: 17217,
        hun: "Bombázó lélek Harci festés",
        rom: "Bomber Soul Vopsea de război",
        swe: "Flygplansplåt Krigsfärg"
    },
    1292: {
        img: "polar_surprise_war_paint",
        eng: "Polar Surprise War Paint",
        pol: "Polarna niespodzianka | Barwy wojenne",
        sch: "圣诞萌物 战绘",
        fre: "Ours polaire | Peinture de guerre",
        bra: "Surpresa Polar Tinta de Guerra",
        schema: 17221,
        hun: "Sarkvidéki meglepetés Harci festés",
        rom: "Polar Surprise Vopsea de război",
        swe: "Nordpolspresent Krigsfärg"
    },
    1293: {
        img: "cabin_fevered_war_paint",
        eng: "Cabin Fevered War Paint",
        pol: "Lśnienie | Barwy wojenne",
        sch: "幽闭恐惧 战绘",
        fre: "Hexagone | Peinture de guerre",
        bra: "Claustrofobia Tinta de Guerra",
        schema: 17220,
        hun: "Bezártság Harci festés",
        rom: "Cabin Fevered Vopsea de război",
        swe: "Klaustrofobi Krigsfärg"
    },
    1294: {
        img: "alien_tech_war_paint",
        eng: "Alien Tech War Paint",
        pol: "Technologia obcych | Barwy wojenne",
        sch: "外星科技 战绘",
        fre: "Extraterrestre | Peinture de guerre",
        bra: "Tecnologia Alienígena Tinta de Guerra",
        schema: 17232,
        hun: "Földönkívüli technológia Harci festés",
        rom: "Alien Tech Vopsea de război",
        swe: "Alienteknologi Krigsfärg"
    },
    1295: {
        img: "dovetailed_war_paint",
        eng: "Dovetailed War Paint",
        pol: "Gołębie serce | Barwy wojenne",
        sch: "阿基米德 战绘",
        fre: "Plumes d'Archimède | Peinture de guerre",
        bra: "Pombos Tinta de Guerra",
        schema: 17224,
        hun: "Fecskefarkas Harci festés",
        rom: "Dovetailed Vopsea de război",
        swe: "Duvflock Krigsfärg"
    },
    1296: {
        img: "damascus_and_mahogany_war_paint",
        eng: "Damascus & Mahogany War Paint",
        pol: "Stal damasceńska i mahoń | Barwy wojenne",
        sch: "大马士革 战绘",
        fre: "Damascus et Mahogany | Peinture de guerre",
        bra: "Damasco e Mogno Tinta de Guerra",
        schema: 17234,
        hun: "Damaszkusz és mahagóni Harci festés",
        rom: "Damascus & Mahogany Vopsea de război",
        swe: "Damaskus och mahogny Krigsfärg"
    },
    1297: {
        img: "hazard_warning_war_paint",
        eng: "Hazard Warning War Paint",
        pol: "Zagrożenie środowiskowe | Barwy wojenne",
        sch: "危险警告 战绘",
        fre: "Danger | Peinture de guerre",
        bra: "Aviso de Perigo Tinta de Guerra",
        schema: 17226,
        hun: "Veszélyjelzés Harci festés",
        rom: "Hazard Warning Vopsea de război",
        swe: "Varningsmönster Krigsfärg"
    },
    1298: {
        img: "neo_tokyo_war_paint",
        eng: "Neo Tokyo War Paint",
        pol: "Neo Tokio | Barwy wojenne",
        sch: "新东京都 战绘",
        fre: "Neo Tokyo | Peinture de guerre",
        bra: "Neo Tokyo Tinta de Guerra",
        schema: 17214,
        hun: "Neo Tokyo Harci festés",
        rom: "Neo Tokyo Vopsea de război",
        swe: "Neo Tokyo Krigsfärg"
    },
    1299: {
        img: "uranium_war_paint",
        eng: "Uranium War Paint",
        pol: "Uran | Barwy wojenne",
        sch: "核子动力 战绘",
        fre: "Uranium | Peinture de guerre",
        bra: "Urânio Tinta de Guerra",
        schema: 17218,
        hun: "Urán Harci festés",
        rom: "Uranium Vopsea de război",
        swe: "Uranium Krigsfärg"
    },
    1300: {
        img: "hana_war_paint",
        eng: "Hana War Paint",
        pol: "Hana | Barwy wojenne",
        sch: "寒梅盛开 战绘",
        fre: "Hana | Peinture de guerre",
        bra: "Hana Tinta de Guerra",
        schema: 17223,
        hun: "Hana Harci festés",
        rom: "Hana Vopsea de război",
        swe: "Hana Krigsfärg"
    },
    1301: {
        img: "cosmic_calamity_war_paint",
        eng: "Cosmic Calamity War Paint",
        pol: "Kosmiczna katastrofa | Barwy wojenne",
        sch: "宇宙灾难 战绘",
        fre: "Cosmos | Peinture de guerre",
        bra: "Calamidade Cósmica Tinta de Guerra",
        schema: 17225,
        hun: "Kozmikus felfordulás Harci festés",
        rom: "Cosmic Calamity Vopsea de război",
        swe: "Kosmisk katastrof Krigsfärg"
    },
    1302: {
        img: "mosaic_war_paint",
        eng: "Mosaic War Paint",
        pol: "Mozaika | Barwy wojenne",
        sch: "古典工艺 战绘",
        fre: "Mosaïque | Peinture de guerre",
        bra: "Mosaico Tinta de Guerra",
        schema: 17228,
        hun: "Mozaik Harci festés",
        rom: "Mosaic Vopsea de război",
        swe: "Mosaik Krigsfärg"
    },
    1303: {
        img: "jazzy_war_paint",
        eng: "Jazzy War Paint",
        pol: "Bajer | Barwy wojenne",
        sch: "变幻之色 战绘",
        fre: "Jazzy | Peinture de guerre",
        bra: "Jazz Tinta de Guerra",
        schema: 17230,
        hun: "Dzsesszes Harci festés",
        rom: "Jazzy Vopsea de război",
        swe: "Jazzig Krigsfärg"
    },
    1304: {
        img: "miami_element_war_paint",
        eng: "Miami Element War Paint",
        pol: "Klimat Miami | Barwy wojenne",
        sch: "五彩像素 战绘",
        fre: "Miami | Peinture de guerre",
        bra: "Elemento Miami Tinta de Guerra",
        schema: 17213,
        hun: "A Miami-tényező Harci festés",
        rom: "Miami Element Vopsea de război",
        swe: "Miamimönster Krigsfärg"
    },
    1305: {
        img: "antarctic_eyewear",
        eng: "Antarctic Eyewear",
        pol: "Szkła szóstego kontynentu",
        sch: "南极护目镜",
        fre: "Lunettes de l'Antarctique",
        bra: "Visual Invernal",
        schema: 30977,
        hun: "Zimankós Szemüveg",
        swe: "Sydpolsglasögon"
    },
    1306: {
        img: "assassins_attire",
        eng: "Assassin's Attire",
        pol: "Szykowny skrytobójca",
        sch: "刺杀者西装",
        fre: "Gentleman assassin",
        bra: "Assassino Aprumado",
        schema: 30989,
        hun: "Orgyilkos Öltözet",
        swe: "Lönnmördarkostym"
    },
    1307: {
        img: "hot_case",
        eng: "Hot Case",
        pol: "Gorąca sprawa",
        sch: "烫手神探",
        fre: "Inspecteur rouget",
        bra: "Gabardina do Gatuno",
        schema: 30986,
        hun: "Forró Nyomon",
        swe: "Hett fall"
    },
    1308: {
        img: "sky_high_fly_guy",
        eng: "Sky High Fly Guy",
        pol: "Lotnik wysokich lotów",
        sch: "火箭飞人",
        fre: "L'aviateur",
        bra: "Ás dos Ares",
        schema: 30984,
        hun: "Magasröptű Mannus",
        swe: "Idiotpilot"
    },
    1309: {
        img: "tsar_platinum",
        eng: "Tsar Platinum",
        pol: "Platynowy car",
        sch: "白金沙皇",
        fre: "Tsar platinum",
        bra: "Czar Platinado",
        schema: 30980,
        hun: "Cári Platina",
        ger: "Zar Platinum"
    },
    1310: {
        img: "head_hedge",
        eng: "Head Hedge",
        pol: "Krzaczasty kamuflaż",
        sch: "丛林伪装盔",
        fre: "Haie-de-forme",
        bra: "Arbusto Astuto",
        schema: 30978,
        hun: "Sisaksövény",
        swe: "Huvudbuske"
    },
    1311: {
        img: "tundra_top",
        eng: "Tundra Top",
        pol: "Polarna przykrywka",
        sch: "极地毛线帽",
        fre: "Bonnet de la toundra",
        bra: "Touca de Tundra",
        schema: 30976,
        hun: "Fagyos Fejfedő",
        swe: "Polarpirka"
    },
    1312: {
        img: "wipe_out_wraps",
        eng: "Wipe Out Wraps",
        pol: "Ocieplane okrycie",
        sch: "滑雪新手",
        fre: "Bandages essuie-tout",
        bra: "Agasalho Arrasador",
        schema: 30990,
        hun: "Hóviharkabát",
        swe: "Vurpajacka"
    },
    1313: {
        img: "punks_pomp",
        eng: "Punk's Pomp",
        pol: "Punkowa pomada",
        sch: "浮夸的朋克发型",
        fre: "Pompadour punk",
        bra: "Cabeleira do Cafajeste",
        schema: 30993,
        hun: "Suhanc Séró",
        swe: "Raggarfrilla"
    },
    1314: {
        img: "starboard_crusader",
        eng: "Starboard Crusader",
        pol: "Gwiezdny krzyżowiec",
        sch: "右舷斗士",
        fre: "Héritage du futur",
        bra: "O Capitão Dito-Kujo",
        schema: 30981,
        hun: "Hajóskapitány"
    },
    1315: {
        img: "aristotle",
        eng: "Aristotle",
        pol: "Arystoteles",
        sch: "鸦里士多德",
        fre: "Aristote",
        bra: "Aristóteles",
        schema: 30988,
        hun: "Arisztotelész",
        swe: "Aristoteles"
    },
    1316: {
        img: "burning_question",
        eng: "Burning Question",
        pol: "Palące pytanie",
        sch: "灼人奇案",
        fre: "Question brûlante",
        bra: "Interrogação Incendiária",
        schema: 30987,
        hun: "Égető Kérdés",
        swe: "Brännande fråga"
    },
    1317: {
        img: "veterans_attire",
        eng: "Veterans Attire",
        pol: "Kurtka kombatanta",
        sch: "老兵的军服",
        fre: "La vestéran",
        bra: "Vestes do Veterano",
        schema: 30983
    },
    1318: {
        img: "scourge_of_the_sky",
        eng: "Scourge of the Sky",
        pol: "Nieszczęście nieboskłonu",
        sch: "空中浩劫",
        fre: "Appui aérien",
        bra: "Flagelo dos Céus",
        schema: 30982,
        hun: "Égi Veszedelem",
        swe: "Luftens herre"
    },
    1319: {
        img: "blizzard_britches",
        eng: "Blizzard Britches",
        pol: "Zmrożone wycierusy",
        sch: "暴风滑雪裤",
        fre: "Le fute à peuf",
        bra: "Calçados Congelantes",
        schema: 30991,
        hun: "Hóviharnadrág",
        swe: "Snöovädersbyxor"
    },
    1320: {
        img: "cold_case",
        eng: "Cold Case",
        pol: "Schłodzony schowek",
        sch: "便携式啤酒冷却装置",
        fre: "Réfrigérateur portatif",
        bra: "Frigobolsa",
        schema: 30992,
        hun: "Hátihűtő",
        swe: "Kylväska"
    },
    1321: {
        img: "private_maggot_muncher",
        eng: "Private Maggot Muncher",
        pol: "Szeregowy Larwożerca",
        sch: "蛆虫毁灭者",
        fre: "Dévermineur personnel",
        bra: "Águia Aspira",
        schema: 30985,
        hun: "Kukaccsócsáló Közlegény",
        swe: "Menig maskätare"
    },
    1322: {
        img: "frag_proof_fragger",
        eng: "Frag Proof Fragger",
        pol: "Osłona oczodołów",
        sch: "防破片头盔",
        fre: "Casque résolument sécurisé",
        bra: "Bombardeador Antibomba",
        schema: 30979,
        hun: "Bombabiztos Bukósisak",
        swe: "Skottsäker skotte"
    },
    1323: {
        img: "robin_walkers",
        eng: "Robin Walkers",
        pol: "Ptasie papucie",
        fre: "Charentaises de Robin",
        sch: "Robin Walkers",
        bra: "Pantufas do Robin",
        schema: 30975,
        hun: "Csicseri Pacsker"
    },
    1324: {
        img: "deadbeats",
        eng: "Deadbeats",
        pol: "Śmierćheisery",
        sch: "低音至死",
        fre: "Sono mortelle",
        bra: "Fones de Morrido",
        schema: 30997,
        hun: "Csontfüles",
        swe: "Dödlurar"
    },
    1325: {
        img: "murderers_motif",
        eng: "Murderer's Motif",
        pol: "Motyw mordercy",
        sch: "杀手面具",
        fre: "Masque de zéro",
        bra: "Aspecto do Assassino",
        schema: 31016,
        hun: "Álnok Álarcos",
        swe: "Mördarens Motiv"
    },
    1326: {
        img: "bandits_boots",
        eng: "Bandit's Boots",
        pol: "Bandyckie buciska",
        sch: "恶棍之靴",
        fre: "Bottes de bandit",
        bra: "Botas do Bandido",
        schema: 31015,
        hun: "Banditacsizma",
        swe: "Banditstövlar"
    },
    1327: {
        img: "highway_star",
        eng: "Highway Star",
        pol: "Król szos",
        sch: "公路之星",
        fre: "Biker de l'outback",
        bra: "Estrela da Estrada",
        schema: 31010,
        hun: "Országúti Harcos",
        swe: "Motorvägsstjärna"
    },
    1328: {
        img: "mann_o_war",
        eng: "Mann-O-War",
        pol: "Zawzięty żeglarz",
        sch: "重型海盗",
        fre: "Corsaire de l'Arctique",
        bra: "Capitão Mann",
        schema: 31008,
        hun: "Hadihajó Kapitány",
        swe: "Örlogsmann"
    },
    1329: {
        img: "olympic_leapers",
        eng: "Olympic Leapers",
        pol: "Pantofle posłańca",
        sch: "奥林匹亚信使",
        fre: "Sandales d'Hermès",
        bra: "Saltador Olímpico",
        schema: 30999,
        hun: "Szárnyas Surranók",
        swe: "Olympiska dojor"
    },
    1330: {
        img: "hephaistos_handcraft",
        eng: "Hephaistos' Handcraft",
        pol: "Chluba Hefajstosa",
        sch: "火神之盔",
        fre: "Hoplite bondissant",
        bra: "Elmo de Hefesto",
        schema: 31000,
        hun: "Spártai Sisak",
        swe: "Hefaistos Hantverk"
    },
    1331: {
        img: "vampire_vanquisher",
        eng: "Vampire Vanquisher",
        pol: "Wyganiacz wampirów",
        sch: "吸血鬼猎人",
        fre: "Terreur des vampires",
        bra: "Carrasco do Conde",
        schema: 31003,
        hun: "Vámpírvadász",
        swe: "Vampyrförgöraren"
    },
    1332: {
        img: "mini_engy",
        eng: "Mini-Engy",
        pol: "Miniaturowy Inżynier",
        sch: "迷你工程师",
        fre: "Enjouetneer",
        bra: "Engizinho",
        schema: 31013,
        hun: "Mini-Mérnök"
    },
    1333: {
        img: "arachno_arsonist",
        eng: "Arachno-Arsonist",
        pol: "Pajęczy podpalacz",
        sch: "蛛形纵火狂",
        fre: "Cracheur de venin",
        bra: "Piracnofobia",
        schema: 31007,
        hun: "Piromán Pókszabású",
        swe: "Spindel-pyro-mannen"
    },
    1334: {
        img: "gaelic_glutton",
        eng: "Gaelic Glutton",
        pol: "Szkocki spaślak",
        sch: "苏格兰大胃王",
        fre: "Glouton gaélique",
        bra: "Gula Gaélica",
        schema: 31017,
        hun: "Falánk Felföldi",
        swe: "Keltisk Korpulens"
    },
    1335: {
        img: "dressperado",
        eng: "Dressperado",
        pol: "Kataloński kryminalista",
        sch: "暴徒型男",
        fre: "Renard rusé",
        bra: "Farda do Forasteiro",
        schema: 31014,
        hun: "Trendkívüli"
    },
    1336: {
        img: "pyro_in_chinatown",
        eng: "Pyro in Chinatown",
        pol: "Pyro w Chinatown",
        sch: "霓虹灯饰",
        fre: "Incendiaire de Chinatown",
        bra: "Pyro em Chinatown",
        schema: 31004,
        hun: "Neonlámpás Negyed",
        swe: "Pyro i Chinatown"
    },
    1337: {
        img: "athenian_attire",
        eng: "Athenian Attire",
        pol: "Grecka garderoba",
        sch: "雅典战袍",
        fre: "Habit d'Athénien",
        bra: "Adornos Atenienses",
        schema: 31001,
        hun: "Görög Gönc",
        swe: "Atensk tunika"
    },
    1338: {
        img: "tank_top",
        eng: "Tank Top",
        pol: "Czołowy czołg",
        sch: "装甲之帽",
        fre: "Chauve d'assaut",
        bra: "Guerra Psicológica",
        schema: 31002,
        hun: "Tanksapka",
        swe: "Tankmössa"
    },
    1339: {
        img: "aim_assistant",
        eng: "Aim Assistant",
        pol: "Asystent celowania",
        sch: "步哨助理",
        fre: "Aide à la visée",
        bra: "Senhor Sentinela",
        schema: 31012,
        hun: "Célzási Segéd",
        swe: "Siktesassistent"
    },
    1340: {
        img: "defragmenting_hard_hat_17",
        eng: "Defragmenting Hard Hat 17%",
        pol: "Defragmentacja kasku 17%",
        sch: "碎片清理帽",
        fre: "Casque défragmenté à 17 %",
        bra: "Desfragmentando Capacete 17%",
        schema: 31011,
        hun: "Sisaktöredezettség-mentesítés: 17%",
        swe: "Defragmenterar hjälm 17%"
    },
    1341: {
        img: "terror_antula",
        eng: "Terror-antula",
        pol: "Terrorantula",
        sch: "恐怖大蜘蛛",
        fre: "Terreur-tula",
        bra: "Terror-ântula",
        schema: 30996
    },
    1342: {
        img: "mr_quackers",
        eng: "Mr. Quackers",
        pol: "Pan Kwaczak",
        sch: "鸭嘴面具",
        fre: "Canard déchaîné",
        bra: "Patrão Patão",
        schema: 31006,
        hun: "Hápi Úr",
        swe: "Mr. Kvack"
    },
    1343: {
        img: "lucky_cat_hat",
        eng: "Lucky Cat Hat",
        pol: "Mycka manekineko",
        sch: "好运招财猫",
        fre: "Chat-peau porte-bonheur",
        bra: "Gorro do Gato da Sorte",
        schema: 30998,
        hun: "Macskalap",
        swe: "Lyckokatt-hatt"
    },
    1344: {
        img: "haunted_ghosts_war_paint",
        eng: "Haunted Ghosts War Paint",
        pol: "Nawiedzone duchy | Barwy wojenne",
        sch: "恐怖鬼魂 战绘",
        fre: "Vert de peur | Peinture de guerre",
        bra: "Fantasmas Assombrados Tinta de Guerra",
        schema: 17236,
        hun: "Kísérteties szellemek Harci festés",
        rom: "Haunted Ghosts Vopsea de război",
        swe: "Hemsökta spöken Krigsfärg"
    },
    1345: {
        img: "totally_boned_war_paint",
        eng: "Totally Boned War Paint",
        pol: "Całkowite skostnienie | Barwy wojenne",
        sch: "白骨累累 战绘",
        fre: "Paquet d'os | Peinture de guerre",
        bra: "Puro Osso Tinta de Guerra",
        schema: 17240,
        hun: "Kicsontozva Harci festés",
        rom: "Totally Boned Vopsea de război",
        swe: "Helt benad Krigsfärg"
    },
    1346: {
        img: "horror_holiday_war_paint",
        eng: "Horror Holiday War Paint",
        pol: "Straszne święta | Barwy wojenne",
        sch: "恐怖假日 战绘",
        fre: "Vacances mortelles | Peinture de guerre",
        bra: "Passeio Pavoroso Tinta de Guerra",
        schema: 17239,
        hun: "Rémünnep Harci festés",
        rom: "Horror Holiday Vopsea de război",
        swe: "Skräckhögtid Krigsfärg"
    },
    1347: {
        img: "spirit_of_halloween_war_paint",
        eng: "Spirit of Halloween War Paint",
        pol: "Duch Halloween | Barwy wojenne",
        sch: "万圣之魂 战绘",
        fre: "Esprit d'Halloween | Peinture de guerre",
        bra: "Espírito de Dia das Bruxas Tinta de Guerra",
        schema: 17238,
        hun: "Halloweeni hangulat Harci festés",
        rom: "Spirit of Halloween Vopsea de război",
        swe: "Halloweenspöken Krigsfärg"
    },
    1348: {
        img: "calavera_canvas_war_paint",
        eng: "Calavera Canvas War Paint",
        pol: "Kolorowa calavera | Barwy wojenne",
        sch: "亡灵狂欢 战绘",
        fre: "Calaveras | Peinture de guerre",
        bra: "Caveiras Coloridas Tinta de Guerra",
        schema: 17244,
        hun: "Calavera vászon Harci festés",
        rom: "Calavera Canvas Vopsea de război",
        swe: "Calavera-kanvas Krigsfärg"
    },
    1349: {
        img: "spectral_shimmered_war_paint",
        eng: "Spectral Shimmered War Paint",
        pol: "Duchowy błysk | Barwy wojenne",
        sch: "幽灵缠身 战绘",
        fre: "Fantôluminescence | Peinture de guerre",
        bra: "Brilho Espectral Tinta de Guerra",
        schema: 17237,
        hun: "Szellemesen sziporkázó Harci festés",
        rom: "Spectral Shimmered Vopsea de război",
        swe: "Spökskimmer Krigsfärg"
    },
    1350: {
        img: "skull_study_war_paint",
        eng: "Skull Study War Paint",
        pol: "Czaszkowa krata | Barwy wojenne",
        sch: "暗色骷髅 战绘",
        fre: "Étude de crânes | Peinture de guerre",
        bra: "Estudo Esquelético Tinta de Guerra",
        schema: 17235,
        hun: "Koponyatanulmány Harci festés",
        rom: "Skull Study Vopsea de război",
        swe: "Dödskallekontor Krigsfärg"
    },
    1351: {
        img: "ghost_town_war_paint",
        eng: "Ghost Town War Paint",
        pol: "Miasto duchów | Barwy wojenne",
        sch: "阴森之城 战绘",
        fre: "Ville fantôme | Peinture de guerre",
        bra: "Cidade Fantasma Tinta de Guerra",
        schema: 17242,
        hun: "Szellemlakta Harci festés",
        rom: "Ghost Town Vopsea de război",
        swe: "Spökstad Krigsfärg"
    },
    1352: {
        img: "tumor_toasted_war_paint",
        eng: "Tumor Toasted War Paint",
        pol: "Wypieczone nowotwory | Barwy wojenne",
        sch: "肿瘤附体 战绘",
        fre: "Tu meurs | Peinture de guerre",
        bra: "Torrada com Tumor Tinta de Guerra",
        schema: 17243,
        hun: "Tumoros Harci festés",
        rom: "Tumor Toasted Vopsea de război",
        swe: "Tumör-toast Krigsfärg"
    },
    1353: {
        img: "electroshocked_war_paint",
        eng: "Electroshocked War Paint",
        pol: "Elektrowstrząs | Barwy wojenne",
        sch: "电刑处决 战绘",
        fre: "Électrochocs | Peinture de guerre",
        bra: "Eletrochoque Tinta de Guerra",
        schema: 17241,
        hun: "Delejes Harci festés",
        rom: "Electroshocked Vopsea de război",
        swe: "Elektrochockad Krigsfärg"
    },
    1354: {
        img: "batters_helmet",
        eng: "Batter's Helmet",
        pol: "Hełm pałkarza",
        sch: "击球手头盔",
        fre: "Casque de batteur",
        bra: "Capacete do Rebatedor",
        schema: 52,
        hun: "Baseball-sisak",
        swe: "Slagmanshjälm"
    },
    1355: {
        img: "bonk_helm",
        eng: "Bonk Helm",
        pol: "Hełm Bonk",
        sch: "饮料头盔",
        fre: "Casque Bonk",
        bra: "Capacete Bonk",
        schema: 106,
        hun: "Poff! Sisak",
        swe: "Bonk-Hjälm"
    },
    1356: {
        img: "bonk_boy",
        eng: "Bonk Boy",
        pol: "Bonk Boy",
        sch: "原子小子",
        fre: "Bonk Boy",
        bra: "Garoto Bonk",
        schema: 451,
        hun: "Bonksrác",
        swe: "Bonk-Pojken"
    },
    1357: {
        img: "el_jefe",
        eng: "El Jefe",
        pol: "El Jefe",
        sch: "革命家之帽",
        fre: "El Jefe",
        bra: "El Jefe",
        schema: 539,
        swe: "Bossen"
    },
    1358: {
        img: "hermes",
        eng: "Hermes",
        pol: "Hermes",
        sch: "赫耳墨斯之帽",
        fre: "L'Hermès",
        bra: "O Hermes",
        schema: 633,
        hun: "A Hermész",
        ger: "Der Götterbote"
    },
    1359: {
        img: "soldiers_stash",
        eng: "Soldier's Stash",
        pol: "Skarb Żołnierza",
        sch: "士兵的猫眼带",
        fre: "Planque du Soldier",
        bra: "Suprimentos do Soldier",
        schema: 54,
        hun: "Katonai vésztartalék",
        swe: "Soldatens Gömma"
    },
    1360: {
        img: "stainless_pot",
        eng: "Stainless Pot",
        pol: "Rondel nierdzewny",
        sch: "不锈锅",
        fre: "Casserole en acier",
        bra: "Panela Inoxidável",
        schema: 98,
        hun: "Rohamfazék",
        swe: "Rostfri Kastrull"
    },
    1361: {
        img: "honchos_headgear",
        eng: "Honcho's Headgear",
        pol: "Oficerka przywódcy",
        sch: "领导者的军帽",
        fre: "La Casquette du Honcho",
        bra: "Acessórios do Honcho",
        schema: 391,
        hun: "Tisztes Tányérsapka",
        swe: "Honchos Huvudbonad"
    },
    1362: {
        img: "brain_bucket",
        eng: "Brain Bucket",
        pol: "Wiadro na mózg",
        sch: "僵尸的铁桶",
        fre: "Seau de Cerveau",
        bra: "Balde de Cérebros",
        schema: 434,
        hun: "Agyvödör",
        swe: "Hjärnhink"
    },
    1363: {
        img: "team_captain",
        eng: "Team Captain",
        pol: "Kapitan brygady",
        sch: "团队领袖之帽",
        fre: "Le Capitaine d'Équipe",
        bra: "O Capitão da Equipe",
        schema: 378,
        hun: "A Csapatkapitány",
        swe: "Lagkaptenen"
    },
    1364: {
        img: "armored_authority",
        eng: "Armored Authority",
        pol: "Pancerny autorytet",
        sch: "将军的军盔",
        fre: "Autorité Blindée",
        bra: "Autoridade Blindada",
        schema: 445,
        hun: "Hetyke Hatóság",
        swe: "Bepansrad Befogenhet"
    },
    1365: {
        img: "jumpers_jeepcap",
        eng: "Jumper's Jeepcap",
        pol: "Patrolówka desantowca",
        sch: "伞兵的羊毛帽",
        fre: "Casquette de parachutiste",
        bra: "Boina do Paraquedista",
        schema: 417,
        hun: "Ejtőernyős Jeepcap",
        swe: "Hopparens Jeephatt"
    },
    1366: {
        img: "lord_cockswains_pith_helmet",
        eng: "Lord Cockswain's Pith Helmet",
        pol: "Hełm korkowy lorda Cockswaina",
        sch: "考克斯文公爵的神气头盔",
        fre: "Casque Colonial de Lord Cockswain",
        bra: "Chapéu de Safari do Lorde Cockswain",
        schema: 439,
        hun: "Lord Cockswain Trópusi Sisakja",
        swe: "Lord Cockswains Tropikhjälm"
    },
    1367: {
        img: "lucky_shot",
        eng: "Lucky Shot",
        pol: "Szczęśliwy traf",
        sch: "幸运一击",
        fre: "Le Coup de Chance",
        bra: "O Tiro de Sorte",
        schema: 701,
        hun: "A Szerencsés Lövés",
        swe: "Lyckoträffen"
    },
    1368: {
        img: "conquistador",
        eng: "Conquistador",
        pol: "Konkwistador",
        sch: "西班牙征服者",
        fre: "Le Conquistador",
        bra: "El Conquistador",
        schema: 721,
        hun: "A Konkvisztádor",
        swe: "Conquistadoren"
    },
    1369: {
        img: "infernal_impaler",
        eng: "Infernal Impaler",
        pol: "Piekielny palownik",
        sch: "凶残的恶魔头",
        fre: "L'Empaleur Infernal",
        bra: "O Empalador Infernal",
        schema: 575,
        hun: "Az Ördögi Öklelő",
        swe: "Djävulska Genomborraren"
    },
    1370: {
        img: "pyros_beanie",
        eng: "Pyro's Beanie",
        pol: "Wirniczek Pyro",
        sch: "火焰兵的无边帽",
        fre: "Bonnet du Pyro",
        bra: "Pyrocóptero",
        schema: 51,
        hun: "Piró piciny propellere",
        swe: "Pyrons Propellermössa"
    },
    1371: {
        img: "brigade_helm",
        eng: "Brigade Helm",
        pol: "Hełm brygadzisty",
        sch: "军用消防头盔",
        fre: "Casque de pompier",
        bra: "Capacete da Brigada",
        schema: 105,
        hun: "Tűzoltósiak",
        swe: "Brandmanshjälm"
    },
    1372: {
        img: "triboniophorus_tyrannus",
        eng: "Triboniophorus Tyrannus",
        pol: "Triboniophorus Tyrannus",
        sch: "懒惰的鼻涕虫",
        fre: "Triboniophorus Tyrannus",
        bra: "Triboniophorus Tyrannus",
        schema: 151
    },
    1373: {
        img: "vintage_merryweather",
        eng: "Vintage Merryweather",
        pol: "Zabytek strażaka",
        sch: "老式消防头盔",
        fre: "Casque Millésimé",
        bra: "Capacete Clássico de Bombeiro",
        schema: 182,
        hun: "Ósdi Tűzoltósisak",
        swe: "Gammaldags Brandhjälm"
    },
    1374: {
        img: "dead_cone",
        eng: "Dead Cone",
        pol: "Martwy pachołek",
        sch: "僵尸的路障",
        fre: "Cône du Mort",
        bra: "Cone Morto",
        schema: 435,
        hun: "Haláli Bója",
        swe: "Död Kon"
    },
    1375: {
        img: "connoisseurs_cap",
        eng: "Connoisseur's Cap",
        pol: "Czapka konesera",
        sch: "行家的厨师帽",
        fre: "Toque du Connaisseur",
        bra: "Chapéu do Chef",
        schema: 394,
        hun: "Ételművész Éke",
        swe: "Mästerkockens Mössa"
    },
    1376: {
        img: "birdcage",
        eng: "Birdcage",
        pol: "Klatka dla ptaków",
        sch: "鸟笼",
        fre: "La Volière",
        bra: "A Gaiola",
        schema: 615,
        hun: "A Kalitka",
        swe: "Fågelburen"
    },
    1377: {
        img: "little_buddy",
        eng: "Little Buddy",
        pol: "Koleżka",
        sch: "水手帽",
        fre: "Le Petit Moussaillon",
        bra: "O Tesouro",
        schema: 612,
        hun: "A Kis Matróz",
        swe: "Skepparens Kompis"
    },
    1378: {
        img: "bubble_pipe",
        eng: "Bubble Pipe",
        pol: "Bańkowa fajka",
        sch: "带烟斗的氧气罩",
        fre: "La Bulle à Pipe",
        bra: "A Bolha de Fumo",
        schema: 597,
        hun: "A Pipabuborék",
        swe: "Bubbelbubblan"
    },
    1379: {
        img: "waxy_wayfinder",
        eng: "Waxy Wayfinder",
        pol: "Obłędny ognik",
        sch: "寻路的蜡烛头",
        fre: "La Bougie à Tête Chercheuse",
        bra: "A Luz no Fim do Túnel",
        schema: 753,
        hun: "A Viaszos Vészvilágítás",
        swe: "Vaxade vägvisaren"
    },
    1380: {
        img: "scotsmans_stove_pipe",
        eng: "Scotsman's Stove Pipe",
        pol: "Szapoklak Szkota",
        sch: "苏格兰大礼帽",
        fre: "Chapeau haut de forme",
        bra: "Cartola do Escocês",
        schema: 120,
        hun: "Skót Cilinder",
        swe: "Skottens Cylinderhatt"
    },
    1381: {
        img: "carousers_capotain",
        eng: "Carouser's Capotain",
        pol: "Pijany purytanin",
        sch: "闹事者头目之冠",
        fre: "Chapeau de beuverie",
        bra: "Cartola do Inquisidor",
        schema: 259,
        hun: "Kapatos Telepes",
        swe: "Festprissens Kapott"
    },
    1382: {
        img: "samur_eye",
        eng: "Samur-Eye",
        pol: "Samur-Eye",
        sch: "武士之眼",
        fre: "Samour-Oeil",
        bra: "Samur-ai",
        schema: 359,
        hun: "Szemtelen Szamuráj",
        swe: "Samur-Aj"
    },
    1383: {
        img: "conjurers_cowl",
        eng: "Conjurer's Cowl",
        pol: "Kaptur magika",
        sch: "魔术师的兜帽",
        fre: "Le Capuchon du Magicien",
        bra: "O Capuz do Mágicko",
        schema: 465,
        hun: "A Csodatevő Csuklyája",
        swe: "Häxmästarens Huva"
    },
    1384: {
        img: "sultans_ceremonial",
        eng: "Sultan's Ceremonial",
        pol: "Sułtański turban ceremonialny",
        sch: "苏丹的羽毛头巾",
        fre: "Turban Cérémonial du Sultan",
        bra: "Cerimonial do Sultão",
        schema: 403,
        hun: "Szemlátomást Szultán",
        swe: "Sultanens Ceremonier"
    },
    1385: {
        img: "mask_of_the_shaman",
        eng: "Mask of the Shaman",
        pol: "Maska szamana",
        sch: "萨满面具",
        fre: "Le Masque du Chaman",
        bra: "A Máscara do Xamã",
        schema: 514,
        hun: "A Sámán Maszkja",
        swe: "Shamanens Mask"
    },
    1386: {
        img: "buccaneers_bicorne",
        eng: "Buccaneer's Bicorne",
        pol: "Bikorn bukaniera",
        sch: "海盗的双角帽",
        fre: "Le bicorne de flibustier",
        bra: "O Bicórnio do Bucaneiro",
        schema: 607,
        hun: "A Mohó Martalóc",
        swe: "Sjörövarens Skeppshatt"
    },
    1387: {
        img: "pugilists_protector",
        eng: "Pugilist's Protector",
        pol: "Ochraniacz pięściarza",
        sch: "拳击手头套",
        fre: "Protecteur du pugiliste",
        bra: "Protetor do Pugilista",
        schema: 246,
        hun: "Az Ökölvívó Arcvédője",
        swe: "Boxarens Beskyddare"
    },
    1388: {
        img: "dread_knot",
        eng: "Dread Knot",
        pol: "Kok sumity",
        sch: "无畏者的唐轮头",
        fre: "Cuir Assez Chevelu",
        bra: "Nó Pavoroso",
        schema: 358,
        hun: "Rettentő Csatahaj",
        swe: "Tungviktsknut"
    },
    1389: {
        img: "capos_capper",
        eng: "Capo's Capper",
        pol: "Kapelusz capo",
        sch: "凯波尼的软呢帽",
        fre: "Feutre du Capo",
        bra: "Chapéu do Capo",
        schema: 427,
        hun: "Capone Kalapója",
        swe: "Mafiosons Mössa"
    },
    1390: {
        img: "dragonborn_helmet",
        eng: "Dragonborn Helmet",
        pol: "Hełm smoczego dziecięcia",
        sch: "龙裔头盔",
        fre: "Casque du Dovahkiin",
        bra: "Capacete do Dragonborn",
        schema: 517,
        hun: "Sárkányfi-sisak",
        swe: "Den Drakföddes Hjälm"
    },
    1391: {
        img: "gym_rat",
        eng: "Gym Rat",
        pol: "Gimnasta",
        sch: "运动头带",
        fre: "Le Rat de la Salle de Gym",
        bra: "O Rato de Academia",
        schema: 613,
        hun: "A Kigyúrt Fazon",
        swe: "Gymråttan"
    },
    1392: {
        img: "one_man_army",
        eng: "One-Man Army",
        pol: "Jednoosobowa armia",
        sch: "兰博的头带",
        fre: "L'Armée Fatale",
        bra: "O Exército de um Homem Só",
        schema: 601,
        hun: "Az Egyszemélyes Hadsereg",
        swe: "Enmans-armén"
    },
    1393: {
        img: "engineers_cap",
        eng: "Engineer's Cap",
        pol: "Czapka maszynisty",
        sch: "工程师的鸭舌帽",
        fre: "Casquette de l'Engineer",
        bra: "Boné do Maquinista",
        schema: 95,
        hun: "Masiniszta-sapka",
        swe: "Lokförarkeps"
    },
    1394: {
        img: "big_country",
        eng: "Big Country",
        pol: "Dusza country",
        sch: "帅气的马莱发型",
        fre: "Grand pays",
        bra: "Da Cabeça Nasce o Cabelo",
        schema: 382,
        hun: "A Nagy Lobonc",
        swe: "Vackra Västern"
    },
    1395: {
        img: "hetmans_headpiece",
        eng: "Hetman's Headpiece",
        pol: "Chluba hetmana",
        sch: "哥萨克酋长的羽毛帽",
        fre: "Chapeau de Hetman",
        bra: "Chapéu do Hetman",
        schema: 436,
        hun: "Hetman Fejvédője",
        swe: "Hetmans Hatt"
    },
    1396: {
        img: "pencil_pusher",
        eng: "Pencil Pusher",
        pol: "Ufryzowany urzędas",
        sch: "会计师的铅笔",
        fre: "Le Gratte-papier",
        bra: "O Faz-tudo",
        schema: 605,
        hun: "Az Irodista",
        swe: "Kontorsnissen"
    },
    1397: {
        img: "virtual_reality_headset",
        eng: "Virtual Reality Headset",
        pol: "Zestaw wirtualnej rzeczywistości",
        sch: "虚拟现实头戴式显示器",
        fre: "Le Casque de Réalité Virtuelle",
        bra: "O Headset de Realidade Virtual",
        schema: 628,
        hun: "A Virtuális-valóság Sisak",
        swe: "Virtuella verklighetsheadsetet"
    },
    1398: {
        img: "brainiac_hairpiece",
        eng: "Brainiac Hairpiece",
        pol: "Peruka mózgowca",
        sch: "发明家的白发",
        fre: "La Coiffure du Brainiac",
        bra: "O Cabelo Neural",
        schema: 590,
        hun: "Az Agyas Haja",
        swe: "Geniets Löshår"
    },
    1399: {
        img: "geisha_boy",
        eng: "Geisha Boy",
        pol: "Męska gejsza",
        sch: "男艺妓头饰",
        fre: "Geisha Boy",
        bra: "Garoto Gueixa",
        schema: 363,
        hun: "Gésafiú",
        swe: "Geisha-pojke"
    },
    1400: {
        img: "medics_mountain_cap",
        eng: "Medic's Mountain Cap",
        pol: "Górska czapka Medyka",
        sch: "医生的野战帽",
        fre: "Casquette de Montagnard du Medic",
        bra: "Quepe Montanhês do Médico",
        schema: 381,
        hun: "A Szanitéc Sildes Sapkája",
        swe: "Fältsjukvårdarens Fjällmössa"
    },
    1401: {
        img: "doctors_sack",
        eng: "Doctor's Sack",
        pol: "Torebka doktora",
        sch: "医生的冰袋",
        fre: "Bouillotte du Docteur",
        bra: "Bolsa do Doutor",
        schema: 398,
        hun: "Orvos Jégzacskója",
        swe: "Doktorns Ispåse"
    },
    1402: {
        img: "masters_yellow_belt",
        eng: "Master's Yellow Belt",
        pol: "Żółty pas mistrza",
        sch: "大师的黄带",
        fre: "Ceinture jaune du maître",
        bra: "Faixa Amarela do Mestre",
        schema: 110,
        hun: "A Mester Sárga Öve",
        swe: "Mästarens Gula Bälte"
    },
    1403: {
        img: "crocleather_slouch",
        eng: "Crocleather Slouch",
        pol: "Kapelusz z krokodylej skóry",
        sch: "鳄鱼皮宽边软帽",
        fre: "Chapeau Mou en Cuir de Croco",
        bra: "Crocourodilo Preguiçoso",
        schema: 344,
        hun: "Krokodilbőr Kalap",
        swe: "Krokodilslokhatt"
    },
    1404: {
        img: "anger",
        eng: "Anger",
        pol: "Wściekły",
        sch: "暴怒兜帽",
        fre: "Le rageux",
        bra: "Raiva",
        schema: 518,
        schema2: 5758,
        hun: "Düh",
        rom: "Furie",
        ger: "Zorn"
    },
    1405: {
        img: "your_worst_nightmare",
        eng: "Your Worst Nightmare",
        pol: "Twój najgorszy koszmar",
        sch: "让你做恶梦的头巾",
        fre: "Votre pire cauchemar",
        bra: "Seu Pior Pesadelo",
        schema: 600,
        hun: "A Legrosszabb Rémálmod",
        swe: "Din Värsta Mardröm"
    },
    1406: {
        img: "swagmans_swatter",
        eng: "Swagman's Swatter",
        pol: "Zasłona swagmana",
        sch: "流浪者的软木帽",
        fre: "Le chasse-mouche de vagabond",
        bra: "O Esmaga-moscas do Moiteiro",
        schema: 626,
        hun: "A Fényűző Légyűző",
        swe: "Strykarens Smälla"
    },
    1407: {
        img: "fruit_shoot",
        eng: "Fruit Shoot",
        pol: "Owocny strzał",
        sch: "苹果射击帽",
        fre: "Tir fruité",
        bra: "O Chapéu do Tell",
        schema: 759,
        hun: "A Gyümölcs-Ölő",
        swe: "Fruktskadan"
    },
    1408: {
        img: "magistrates_mullet",
        eng: "Magistrate's Mullet",
        pol: "Peruka sędziego",
        sch: "裁判官的假发",
        fre: "Perruque de Magistrat",
        bra: "Mullet do Magistrado",
        schema: 147,
        hun: "Uraság Parókája",
        swe: "Magistratens Hockeyfrilla"
    },
    1409: {
        img: "noh_mercy",
        eng: "Noh Mercy",
        pol: "Horrendalna hannya",
        sch: "般若能面",
        fre: "Nô Merci",
        bra: "Noh Terei Misericórdia",
        schema: 361,
        hun: "A Kegyetlen",
        swe: "Ingen Nohd"
    },
    1410: {
        img: "charmers_chapeau",
        eng: "Charmer's Chapeau",
        pol: "Kapelusz szarmanckiego kawalera",
        sch: "引人注目的骑士帽",
        fre: "Chapeau du Charmeur",
        bra: "Chapeau Charmoso",
        schema: 397,
        hun: "Sármos Chapeau",
        swe: "Charmörens Chapeau"
    },
    1411: {
        img: "janissary_ketche",
        eng: "Janissary Ketche",
        pol: "Janczarskie kece",
        sch: "土耳其禁卫军头冠",
        fre: "Ketche du Janissaire",
        bra: "Ketche do Janízaro",
        schema: 437,
        hun: "Janicsár Kecséje",
        swe: "Janitsjar Ketche"
    },
    1412: {
        img: "nanobalaclava",
        eng: "Nanobalaclava",
        pol: "Nanokominiarka",
        sch: "纳米科技面罩",
        fre: "La Nanocagoule",
        bra: "O Nanobalaclava",
        schema: 521,
        hun: "A Nanosímaszk",
        swe: "Nanobalaklavan"
    },
    1413: {
        img: "linspecteur",
        eng: "L'Inspecteur",
        pol: "L'Inspecteur",
        sch: "法国警督",
        fre: "L'inspecteur",
        bra: "L'Inspecteur",
        schema: 622,
        swe: "L'Inspekteur"
    },
    1414: {
        img: "noble_amassment_of_hats",
        eng: "Noble Amassment of Hats",
        pol: "Zacna miara kapeluszy",
        sch: "高贵的层叠帽",
        fre: "Pile nobiliaire de couvre-chefs",
        bra: "Conjunto Nobre de Chapéus",
        schema: 137,
        hun: "Nemes Kalapok Gyűjteménye",
        swe: "Nobel Anhopning Av Hattar"
    },
    1415: {
        img: "towering_pillar_of_hats",
        eng: "Towering Pillar of Hats",
        pol: "Niebotyczny słup kapeluszy",
        sch: "高耸的塔柱帽",
        fre: "Imposante pile de chapeaux",
        bra: "Pilar Monumental de Chapéus",
        schema: 135,
        hun: "Magasan Tornyosuló Kalap-oszlop",
        swe: "Reslig Pelare Av Hattar"
    },
    1416: {
        img: "company_man",
        eng: "Company Man",
        pol: "Człowiek korporacji",
        sch: "萨里夫工业公司员工帽",
        fre: "Casquette de l'Employé",
        bra: "O Homem da Empresa",
        schema: 523,
        hun: "A Cég Embere",
        swe: "Företagsmannen"
    },
    1417: {
        img: "killer_exclusive",
        eng: "Killer Exclusive",
        pol: "Łowca sensacji",
        sch: "独家杀手",
        fre: "Le Scoop Meurtrier",
        bra: "O Furo de Reportagem",
        schema: 538,
        hun: "Az Irtó-különkiadás",
        swe: "Mördande Ensamrätt"
    },
    1418: {
        img: "hot_dogger",
        eng: "Hot Dogger",
        pol: "Hotdoggista",
        sch: "热狗摊售货员帽子",
        fre: "Le vendeur de hot dogs",
        bra: "O Cachorrão Quente",
        schema: 614,
        hun: "A Hot-Dogos",
        swe: "Korvgubben"
    },
    1419: {
        img: "human_cannonball",
        eng: "Human Cannonball",
        pol: "Ludzka kula armatnia",
        sch: "人肉炮弹",
        fre: "L'Homme-canon",
        bra: "A Bala de Canhão Humana",
        schema: 817,
        hun: "Az Emberi Ágyúgolyó",
        swe: "Den Mänskliga Kanonkulan"
    },
    1420: {
        img: "antlers",
        eng: "Antlers",
        pol: "Poroże",
        sch: "驯鹿鹿角",
        fre: "Bois",
        bra: "Galhadas",
        schema: 993,
        hun: "Agancsok",
        swe: "Horn"
    },
    1421: {
        img: "dread_hiding_hood",
        eng: "Dread Hiding Hood",
        pol: "Złowieszczy kapturek",
        sch: "惊惧隐蔽兜帽",
        fre: "Le redoutable petit chaperon",
        bra: "Chapeuzinho Voraz",
        schema: 1095,
        hun: "A Rettentő Rejtőcsuklya",
        swe: "Dödluvan"
    },
    1422: {
        img: "pocket_pardner",
        eng: "Pocket Pardner",
        pol: "Kieszonkowy partner",
        sch: "口袋工程师",
        fre: "Partenaire de poche",
        bra: "Parceiro de Bolso",
        schema: 31026,
        hun: "Zsebpajtás",
        swe: "Fickpartner"
    },
    1423: {
        img: "wise_whiskers",
        eng: "Wise Whiskers",
        pol: "Wykwintne wąsiska",
        sch: "仙人之须",
        fre: "Moustache de sage",
        bra: "Barba do Velho Sábio",
        schema: 31031,
        hun: "Bölcs Szakáll",
        swe: "Visdomsskägg"
    },
    1424: {
        img: "snowcapped",
        eng: "Snowcapped",
        pol: "Śnieżna czapa",
        sch: "护耳军帽",
        fre: "Guerre froide",
        bra: "Ushanka de Inverno",
        schema: 31028,
        hun: "Hósapka",
        swe: "Snökeps"
    },
    1425: {
        img: "paka_parka",
        eng: "Paka Parka",
        pol: "Parka pakera",
        sch: "因纽特大衣",
        fre: "Parka de l'Alaska",
        bra: "Parca Paka",
        schema: 31030,
        swe: "Pakaparka"
    },
    1426: {
        img: "catchers_companion",
        eng: "Catcher's Companion",
        pol: "Pobratymiec pałkarza",
        sch: "棒球小鸟",
        fre: "Bec non cloué",
        bra: "Ajudante do Arremessador",
        schema: 31021,
        hun: "Az Elkapó Társa",
        swe: "Fångarens fågelkompis"
    },
    1427: {
        img: "juveniles_jumper",
        eng: "Juvenile's Jumper",
        pol: "Szczeniacki sweter",
        sch: "少年的毛衣",
        fre: "Classique de Noël",
        bra: "Malha do Mancebo",
        schema: 31022,
        hun: "Fiatalos Pulcsi",
        swe: "Ungdomlig ulltröja"
    },
    1428: {
        img: "crack_pot",
        eng: "Crack Pot",
        pol: "Doniczkowy dureń",
        sch: "脑袋开花",
        fre: "Fine fleur",
        bra: "Vaso Capilar",
        schema: 31024,
        hun: "Taktikai Virágcserép",
        swe: "Blomkruka"
    },
    1429: {
        img: "climbing_commander",
        eng: "Climbing Commander",
        pol: "Docieplony dowódca",
        sch: "登山指挥官",
        fre: "Grimpeur des Rocheuses",
        bra: "Comandante do Cume",
        schema: 31025,
        hun: "Hegymászó Parancsnok",
        swe: "Klättringskommendör"
    },
    1430: {
        img: "mighty_mitre",
        eng: "Mighty Mitre",
        pol: "Mocarna mitra",
        sch: "主教之冠",
        fre: "Miracle de la médecine",
        bra: "Mitra Majestosa",
        schema: 31034,
        hun: "Fenséges Föveg",
        swe: "Mäktig mitra"
    },
    1431: {
        img: "puggyback",
        eng: "Puggyback",
        pol: "Przenośna psiarnia",
        sch: "巴哥背包",
        fre: "Sac à dogue",
        bra: "Cão de Retaguarda",
        schema: 31032,
        hun: "Mopsz Málha",
        swe: "Ryggmops"
    },
    1432: {
        img: "cool_capuchon",
        eng: "Cool Capuchon",
        pol: "Kozacki capuchon",
        sch: "冷风兜帽",
        fre: "Capuche stylée",
        bra: "Capuz Caloroso",
        schema: 31029,
        hun: "Klassz Kapucni",
        swe: "Vinterkapuschong"
    },
    1433: {
        img: "pocket_admin",
        eng: "Pocket Admin",
        pol: "Kieszonkowa Administratorka",
        sch: "口袋管理员",
        fre: "Admin de poche",
        bra: "Administradora de Bolso",
        schema: 31019,
        hun: "Zsebadmin",
        swe: "Fickadministratör"
    },
    1434: {
        img: "harry",
        eng: "Harry",
        pol: "Harry",
        sch: "懒猫哈里",
        fre: "Harry",
        bra: "Harry",
        schema: 31033,
        hun: "Bundi"
    },
    1435: {
        img: "misers_muttonchops",
        eng: "Miser's Muttonchops",
        pol: "Szczecina sknerusa",
        sch: "吝啬鬼络腮胡",
        fre: "Rouflaquettes",
        bra: "Patilhas do Pão-duro",
        schema: 31027,
        hun: "Fukar Barkó",
        swe: "Snåljåpens skägg"
    },
    1436: {
        img: "polar_pal",
        eng: "Polar Pal",
        pol: "Polarny przyjaciel",
        sch: "极地伴侣",
        fre: "Pote du pôle",
        bra: "Amigo Polar",
        schema: 31018,
        hun: "Medvekoma",
        swe: "Polarpolare"
    },
    1437: {
        img: "dumb_bell",
        eng: "Dumb Bell",
        pol: "Cymbał brzmiący",
        sch: "不哑的铃",
        fre: "Sonneur de cloche",
        bra: "Sino do Asinino",
        schema: 31035,
        hun: "Kerge Kolomp",
        swe: "Bjällerklant"
    },
    1438: {
        img: "millennial_mercenary",
        eng: "Millennial Mercenary",
        pol: "Militarny milenials",
        sch: "00后佣兵",
        fre: "Mercenaire de la génération Y",
        bra: "Mercenário Moderninho",
        schema: 31023,
        hun: "Z-generációs Zsoldos",
        swe: "Sekelskiftessoldat"
    },
    1439: {
        img: "bread_heads",
        eng: "Bread Heads",
        pol: "Chlebogłowy",
        sch: "面包怪兽",
        fre: "Mangeur de pain",
        bra: "Cabeça de Pão",
        schema: 31020,
        hun: "Borzasztó Buci",
        swe: "Brödhuvud"
    },
    1440: {
        img: "melted_mop",
        eng: "Melted Mop",
        pol: "Roztopiony rożek",
        fre: "Fonte des glaces",
        sch: "融化的冰淇淋",
        bra: "Congelador de Cérebro",
        schema: 31041,
        hun: "Leolvadás",
        swe: "Smältande strutglass"
    },
    1441: {
        img: "staplers_specs",
        eng: "Stapler's Specs",
        pol: "Szkła zszywacza",
        fre: "Officier de bureau",
        sch: "订书机英雄",
        bra: "O Estressado do Escritório",
        schema: 31036,
        hun: "Tüzetes Tekintet",
        swe: "Byråkratiska brillor"
    },
    1442: {
        img: "shutterbug",
        eng: "Shutterbug",
        pol: "Fotopluskwa",
        fre: "Caméra cachée",
        sch: "相机礼帽",
        bra: "Chapéu Espião",
        schema: 31048,
        hun: "Kémképész",
        swe: "Kubbkamera"
    },
    1443: {
        img: "backbreakers_guards",
        eng: "Backbreaker's Guards",
        pol: "Ochraniacze rozwalającego",
        fre: "Protections du footballeur américain",
        sch: "黏弹橄榄球护肩",
        bra: "Ombreiras do Quebra-costas",
        schema: 31039,
        hun: "Letámadó Vállvédő",
        swe: "Anfallarens axelskydd"
    },
    1444: {
        img: "mediterranean_mercenary",
        eng: "Mediterranean Mercenary",
        pol: "Śródziemnomorski najemnik",
        fre: "Mercenaire méditerranéen",
        sch: "地中海佣兵",
        bra: "Mercenário Mediterrâneo",
        schema: 31052,
        hun: "Mediterrán Martalóc",
        swe: "Medelhavserövrare"
    },
    1445: {
        img: "dancing_doe",
        eng: "Dancing Doe",
        pol: "Roztańczony Ricardo",
        fre: "Bandana exaltant",
        sch: "箭迟但到",
        bra: "Doe Dançante",
        schema: 31045,
        hun: "Táncos Csávó",
        swe: "Bandanadans"
    },
    1446: {
        img: "pompous_privateer",
        eng: "Pompous Privateer",
        pol: "Kozacki kaper",
        fre: "Corsaire cabotin",
        sch: "自大的海盗",
        bra: "Corsário Convencido",
        schema: 31043,
        hun: "Kiöltözött Kalóz",
        swe: "Storslagen sjörövare"
    },
    1447: {
        img: "bottle_cap",
        eng: "Bottle Cap",
        pol: "Kapslówka",
        fre: "Casquette anti-soif",
        sch: "原子能饮料帽",
        bra: "Boné Atômico",
        schema: 31042,
        hun: "Kupak",
        swe: "Läskkeps"
    },
    1448: {
        img: "brain_interface",
        eng: "Brain Interface",
        pol: "Inteligentny interfejs",
        fre: "Interface neuronale",
        sch: "脑机接口头盔",
        bra: "Interface Cerebral",
        schema: 31049,
        hun: "Agyinterfész",
        swe: "Hjärngränssnitt"
    },
    1449: {
        img: "wanderers_wear",
        eng: "Wanderer's Wear",
        pol: "Warstwa wędrowca",
        fre: "Tenue de rando",
        sch: "旅行者的大衣",
        bra: "Casaco do Viajante",
        schema: 31051,
        hun: "Vándorviselet",
        swe: "Vandrarens värmejacka"
    },
    1450: {
        img: "spawn_camper",
        eng: "Spawn Camper",
        pol: "Obozowicz na bazie",
        fre: "Campeur en feu",
        sch: "出生点看守者",
        bra: "Campista da Base",
        schema: 31050,
        hun: "Született Táborozó",
        swe: "Bastältaren"
    },
    1451: {
        img: "backbreakers_skullcracker",
        eng: "Backbreaker's Skullcracker",
        pol: "Rozłupywacz rozwalającego",
        fre: "Brise-crânes du footballeur américain",
        sch: "黏弹橄榄球头盔",
        bra: "Quebra-crânios do Quebra-costas",
        schema: 31038,
        hun: "Letámadó Légiós",
        swe: "Romersk skallkrossare"
    },
    1452: {
        img: "kapitans_kaftan",
        eng: "Kapitan's Kaftan",
        pol: "Kaftan kapitana",
        fre: "Caftan du capitaine",
        sch: "队长的长袍",
        bra: "Cafetã do Capitão",
        schema: 31053,
        hun: "Kapitányi Kaftán",
        swe: "Kaptenens kaftan"
    },
    1453: {
        img: "wagga_wagga_wear",
        eng: "Wagga Wagga Wear",
        pol: "Pustynne palto",
        fre: "Manteau de Wagga Wagga",
        sch: "瓦加瓦加外套",
        bra: "Veste de Wagga Wagga",
        schema: 31055,
        hun: "Wagga Wagga Viselet",
        swe: "Wagga Wagga-kappa"
    },
    1454: {
        img: "speedy_scoundrel",
        eng: "Speedy Scoundrel",
        pol: "Śpieszny szubrawiec",
        fre: "Moussaillon magnanime",
        sch: "神速流氓",
        bra: "Pirata Perspicaz",
        schema: 31056,
        hun: "Szélsebes Szélhámos",
        swe: "Sjöfarande skurk"
    },
    1455: {
        img: "dynamite_abs",
        eng: "Dynamite Abs",
        pol: "Bombowy bebech",
        fre: "Abdynamites",
        sch: "炸药腹肌",
        bra: "Tanquinho Turbinado",
        schema: 31037,
        hun: "Kirobbanó Forma",
        swe: "Dynamitmuskler"
    },
    1456: {
        img: "unforgiven_glory",
        eng: "Unforgiven Glory",
        pol: "Nieprzebaczona chwała",
        fre: "Glorieux borgne",
        sch: "无可饶恕之荣耀",
        bra: "Glória Imperdoável",
        schema: 31040,
        hun: "Megbocsáthatatlan Dicsőség",
        ger: "Unverziehener Ruhm"
    },
    1457: {
        img: "bobby_bonnet",
        eng: "Bobby Bonnet",
        pol: "Garniec gliniarza",
        fre: "Policier de Sa Majesté",
        sch: "英式警察头盔",
        bra: "Boina Britânica",
        schema: 31057,
        hun: "Bobby-kalap",
        swe: "Bobbyhjälm"
    },
    1458: {
        img: "bare_necessities",
        eng: "Bare Necessities",
        pol: "Podstawowe potrzeby",
        fre: "Le minimum vital",
        sch: "基本必需品",
        bra: "Necessidades Básicas",
        schema: 31054,
        hun: "Alapellátmány",
        swe: "Nödvändigheter"
    },
    1459: {
        img: "fiery_phoenix",
        eng: "Fiery Phoenix",
        pol: "Ognisty Feniks",
        fre: "Phénix flamboyant",
        sch: "不死之鸟",
        bra: "Fênix Flamejante",
        schema: 31047,
        hun: "Forró Főnix",
        swe: "Flammande fenix"
    },
    1460: {
        img: "peacebreaker",
        eng: "Peacebreaker",
        pol: "Siewca wojny",
        fre: "Tête chercheuse",
        sch: "和平破坏者",
        bra: "Provocador",
        schema: 31044,
        hun: "Békefeltartó",
        swe: "Fredsbrytaren"
    },
    1461: {
        img: "texas_toast",
        eng: "Texas Toast",
        pol: "Teksański tost",
        fre: "Pain gagné",
        sch: "德州面包机",
        bra: "Torradeira Texana",
        schema: 31046,
        swe: "Brödrostshatt"
    },
    1462: {
        img: "baseball_bills_sports_shine",
        eng: "Baseball Bill's Sports Shine",
        pol: "Sportowy połysk baseballowego Bogusia",
        sch: "棒球比尔的发蜡头",
        fre: "Cheveux gominés",
        bra: "Bill, Estrela Esportiva do Beisebol",
        schema: 111,
        hun: "Baseball Bill belőtt búrája",
        swe: "Baseboll-Bills Vaxade Vågor"
    },
    1463: {
        img: "boston_boom_bringer",
        eng: "Boston Boom-Bringer",
        pol: "Bostoński boom-bringer",
        sch: "波士顿噪音制造者",
        fre: "Le Bon Son de Boston",
        bra: "O Pancadão da Periferia",
        schema: 707,
        hun: "A Bostoni Bedöngető",
        swe: "Bostonbergsprängaren"
    },
    1464: {
        img: "stereoscopic_shades",
        eng: "Stereoscopic Shades",
        pol: "Stereoskopowe szkła",
        sch: "3D 立体眼镜",
        fre: "Les Lunettes Stéréoscopiques",
        bra: "Os Óculos Estereoscópicos",
        schema: 630,
        hun: "A Sztereoszkopikus Szemüveg",
        swe: "Stereoskopiska Solglasögon"
    },
    1465: {
        img: "soldiers_stogie",
        eng: "Soldier's Stogie",
        pol: "Puro piechura",
        sch: "士兵的廉价雪茄",
        fre: "Le cigare du Soldier",
        bra: "O Fumo do Soldier",
        schema: 852,
        hun: "A Katona Staubja",
        swe: "Soldatens Cigarill"
    },
    1466: {
        img: "apparitions_aspect",
        eng: "Apparition's Aspect",
        pol: "Zjawisko nadpyrodzone",
        sch: "幽灵面罩",
        fre: "L'aspect de l'apparition",
        bra: "Aspecto da Aparição",
        schema: 571,
        schema2: 5759,
        hun: "Jóképű Jelenés",
        swe: "Spökbildens Synpunkt"
    },
    1467: {
        img: "burning_bongos",
        eng: "Burning Bongos",
        pol: "Płonące bongosy",
        sch: "纵火狂的小鼓",
        fre: "Les Bongos Ardents",
        bra: "Os Bongôs em Brasas",
        schema: 746,
        hun: "A Lángoló Bongók",
        swe: "Brinnande Bongotrummorna"
    },
    1468: {
        img: "cremators_conscience",
        eng: "Cremator's Conscience",
        pol: "Sumienie sadysty",
        sch: "焚尸者的良心",
        fre: "La Conscience du Crémateur",
        bra: "A Consciência do Cremador",
        schema: 632,
        hun: "A Lángszórós Lelkiismerete",
        swe: "Krematörens Samvete"
    },
    1469: {
        img: "last_breath",
        eng: "Last Breath",
        pol: "Ostatnie tchnienie",
        sch: "骷髅头骨",
        fre: "Le Dernier Souffle",
        bra: "O Último Suspiro",
        schema: 570,
        hun: "Az Utolsó Lélegzet",
        swe: "Det sista andetaget"
    },
    1470: {
        img: "popeyes",
        eng: "Pop-eyes",
        pol: "Gibające gały",
        sch: "弹簧眼",
        fre: "Pop-eyes",
        bra: "Olhos Zarolhos",
        schema: 30176,
        hun: "A kidülledő szemek",
        swe: "Ögonaböj"
    },
    1471: {
        img: "a_whiff_of_the_old_brimstone",
        eng: "A Whiff of the Old Brimstone",
        pol: "Powiew siareczki",
        sch: "一丝古老的硫磺气息",
        fre: "Une bouffée de soufre à l'ancienne",
        bra: "Uma Baforada de Enxofre",
        schema: 610,
        hun: "Egy Szippantás a Jó Öreg Kénkőből",
        swe: "En pust av det gamla svavlet"
    },
    1472: {
        img: "aladdins_private_reserve",
        eng: "Aladdin's Private Reserve",
        pol: "Osobista rezerwa Aladyna",
        sch: "阿拉丁的私人藏品",
        fre: "La réserve privée d'Aladdin",
        bra: "Reserva Especial do Aladim",
        schema: 708,
        hun: "Aladdin Dugipiája",
        swe: "Aladdins privata reserv"
    },
    1473: {
        img: "liquor_locker",
        eng: "Liquor Locker",
        pol: "Bombowy barek",
        sch: "烈酒储存箱",
        fre: "Le Coffre à liqueurs",
        bra: "O Baú de Bebidas",
        schema: 771,
        hun: "A Löttyös Ládikó",
        swe: "Spritskåpet"
    },
    1474: {
        img: "snapped_pupil",
        eng: "Snapped Pupil",
        pol: "Zaślepka",
        sch: "独眼快照",
        fre: "La Photo de la pupille",
        bra: "A Pupila Dilatada",
        schema: 709,
        hun: "A Lekapott Pupilla",
        swe: "Den plåtade pupillen"
    },
    1475: {
        img: "little_bear",
        eng: "Little Bear",
        pol: "Mały miś",
        sch: "育熊袋",
        fre: "Petit Ours Robot",
        bra: "O Ursinho",
        schema: 1097,
        hun: "A Kismaci",
        swe: "Den lilla björnen"
    },
    1476: {
        img: "toss_proof_towel",
        eng: "Toss-Proof Towel",
        pol: "Rzutoodporny ręcznik",
        sch: "扔不掉的毛巾",
        fre: "Serviette du vainqueur",
        bra: "Toalha Antidesistência",
        schema: 757,
        hun: "Bedobás-Álló Törölköző",
        swe: "Kastsäkra Handduken"
    },
    1477: {
        img: "texas_half_pants",
        eng: "Texas Half-Pants",
        pol: "Wyczesane czapsy",
        sch: "德州五分裤",
        fre: "Le Demi-Pantalon du Texas",
        bra: "Meia-calça Texana",
        schema: 755,
        hun: "Texasi Félnadrág",
        swe: "Texas Halvbyxor"
    },
    1478: {
        img: "texas_slims_dome_shine",
        eng: "Texas Slim's Dome Shine",
        pol: "Błyszcząca glaca teksańskiego Staśka",
        sch: "德州瘦子三分头",
        fre: "Crâne chauve et luisant",
        bra: "Cúpula Brilhante do Texas Slim",
        schema: 118,
        hun: "Texas Slim Kopár Kobakja",
        swe: "Texas Slims Skallglans"
    },
    1479: {
        img: "archimedes",
        eng: "Archimedes",
        pol: "Archimedes",
        sch: "阿基米德",
        fre: "Archimède",
        bra: "Arquimedes",
        schema: 828,
        hun: "Arkhimédész",
        rom: "Arhimede",
        swe: "Arkimedes"
    },
    1480: {
        img: "couvre_corner",
        eng: "Couvre Corner",
        pol: "Poważna poszetka",
        sch: "整洁的手帕",
        fre: "Mouchoir en coin",
        bra: "Canto Encouvrerto",
        schema: 620,
        hun: "Csinos Csücsök",
        swe: "Nätt Näsduk"
    },
    1481: {
        img: "nine_pipe_problem",
        eng: "Nine-Pipe Problem",
        pol: "Problem na dziewięć fajek",
        sch: "九口烟的大问题",
        fre: "Le problème pour neuf pipes",
        bra: "O Enigma de Nove Tragadas",
        schema: 657,
        hun: "A Kilencpipás Feladat",
        swe: "Niopiporsproblemet"
    },
    1482: {
        img: "surgeons_stethoscope",
        eng: "Surgeon's Stethoscope",
        pol: "Stetoskop Eskulapa",
        sch: "外科医生的听诊器",
        fre: "Le Stéthoscope de Chirurgien",
        bra: "O Estetoscópio do Cirurgião",
        schema: 621,
        hun: "A Sebészi Sztetoszkóp",
        swe: "Sjukvårdarens Stetoskop"
    },
    1483: {
        img: "ritzy_ricks_hair_fixative",
        eng: "Ritzy Rick's Hair Fixative",
        pol: "Utrwalacz do włosów Ryżawego Ryśka",
        sch: "时髦瑞克的后梳头",
        fre: "Coupe parfaite, fixation extrême",
        bra: "Gel de Cabelo do Ritzy Rick",
        schema: 117,
        hun: "Ritzy Rick Sirály Sérója",
        swe: "Ritzy Ricks Hårfixativ"
    },
    1484: {
        img: "camera_beard",
        eng: "Camera Beard",
        pol: "Broda z aparatem",
        sch: "相机胡子",
        fre: "Barbe-appareil photo",
        bra: "Barba Câmera",
        schema: 103,
        hun: "Fényképező-szakáll",
        swe: "Kameraskägg"
    },
    1485: {
        img: "sneaky_spats_of_sneaking",
        eng: "Sneaky Spats of Sneaking",
        pol: "Ciche cholewniki cichociemnego",
        sch: "潜入者的潜入专用鞋",
        fre: "Les Guêtres furtives de furetage",
        bra: "Os Sapatos Sorrateiros de Sorrateiridade",
        schema: 763,
        hun: "A Lopva Lopódzó Kamáslik",
        swe: "De Smygiga Smygardamaskerna"
    },
    1486: {
        img: "spectres_spectacles",
        eng: "Spectre's Spectacles",
        pol: "Binokle widma",
        sch: "幽灵的眼镜",
        fre: "Les Lunettes de spectre",
        bra: "O Espetáculo do Espectro",
        schema: 629,
        hun: "A Szellemes Szemüveg",
        swe: "Gastens Glasögon"
    },
    1487: {
        img: "champ_stamp",
        eng: "Champ Stamp",
        pol: "Tatuaż twardziela",
        sch: "佣兵的龙刺青",
        fre: "Le Tampon du Champion",
        bra: "A Marca do Campeão",
        schema: 815,
        hun: "A Bajnoki Pecsét",
        swe: "Mästarmärket"
    },
    1488: {
        img: "clan_pride",
        eng: "Clan Pride",
        pol: "Duma klanu",
        sch: "家族徽章",
        fre: "Fierté de l'Équipe",
        bra: "Orgulho do Clã",
        schema: 625,
        hun: "Klánbüszkeség",
        swe: "Klanens Stolthet"
    },
    1489: {
        img: "dr_whoa",
        eng: "Dr. Whoa",
        pol: "Dr Whoa",
        sch: "绅士的蝴蝶结",
        fre: "Dr. Whoa",
        bra: "TARDIS Demais",
        schema: 639,
        hun: "Dr. Hú"
    },
    1490: {
        img: "flair",
        eng: "Flair!",
        pol: "Przypinki!",
        sch: "漂亮的小徽章",
        fre: "Flair !",
        bra: "Broches!",
        schema: 619,
        hun: "Csecsebecse!",
        swe: "Känsla!"
    },
    1491: {
        img: "itsy_bitsy_spyer",
        eng: "Itsy Bitsy Spyer",
        pol: "Tyci-tyci Szpieg",
        sch: "小不点间谍",
        fre: "Le Itsy Bitsy Spyer",
        bra: "O Espiãozinho",
        schema: 646,
        hun: "Az Ici-Pici-Kém",
        swe: "Imse Vimse Spion"
    },
    1492: {
        img: "marxman",
        eng: "Marxman",
        pol: "Marksista wyborowy",
        sch: "马列主义者",
        fre: "Marx la Menace",
        bra: "Marxa para a Morte",
        schema: 816,
        hun: "Enyhe célzÁsz",
        rom: "Ţintă Fixă",
        swe: "Marximalt Luftgevär"
    },
    1493: {
        img: "photo_badge",
        eng: "Photo Badge",
        pol: "Odznaka ze zdjęciem",
        sch: "照片徽章",
        fre: "Badge Photo",
        bra: "Crachá com Foto",
        schema: 623,
        hun: "Fényképes Jelvény",
        swe: "Fotomärke"
    },
    1494: {
        img: "reindoonicorn",
        eng: "Reindoonicorn",
        pol: "Reniferożec",
        sch: "气球驯鹿独角兽",
        fre: "Le Rennicorne",
        bra: "O Renacórnio",
        schema: 995,
        hun: "A Rénkornis",
        swe: "Renhörningen"
    },
    1495: {
        img: "teufort_tooth_kicker",
        eng: "Teufort Tooth Kicker",
        pol: "Teufordzkie kopacze trzonowców",
        sch: "双城碎齿者",
        fre: "Le botteur de dents de Teufort",
        bra: "O Tira Dentes de Teufort",
        schema: 734,
        hun: "A Teuforti Szájbarúgó",
        swe: "Teufort-tandsparkarna"
    },
    1496: {
        img: "triad_trinket",
        eng: "Triad Trinket",
        pol: "Ozdoba opryszka",
        sch: "三合会饰衣",
        fre: "Le Colifichet de la Triade",
        bra: "O Talismã da Tríade",
        schema: 814,
        hun: "A Triád Bizsu",
        swe: "Brottssyndikatets Skräpsmycke"
    },
    1497: {
        img: "tuxxy",
        eng: "Tuxxy",
        pol: "Saxxowny smoking",
        sch: "萨氏燕尾服",
        fre: "Le Tuxxy",
        bra: "O Tuxxy",
        schema: 955,
        hun: "A Szmoxxy",
        ger: "Der Tuxxy"
    },
    1498: {
        img: "DUMMY",
        eng: "DUMMY"
    },
    1499: {
        img: "derangement_garment",
        eng: "Derangement Garment",
        pol: "Pasy psychola",
        fre: "Camisole de sadiste",
        sch: "狂人约束衣",
        bra: "Indulto do Maluco",
        schema: 31078,
        hun: "Eszelős Öltözék",
        swe: "Tvångströja"
    },
    1500: {
        img: "fuel_injector",
        eng: "Fuel Injector",
        pol: "Połykacz paliwa",
        fre: "Pompe à démence",
        sch: "燃料喷射器",
        bra: "Injetor de Combustível",
        schema: 31081,
        hun: "Üzemanyag-befecskendező",
        swe: "Bränsleslangar"
    },
    1501: {
        img: "party_poncho",
        eng: "Party Poncho",
        pol: "Przyjęciowe ponczo",
        fre: "Poncho de fiesta",
        sch: "派对斗篷",
        bra: "Poncho Pitoresco",
        schema: 31070,
        hun: "Parti-poncsó",
        swe: "Partyponcho"
    },
    1502: {
        img: "pocket_halloween_boss",
        eng: "Pocket Halloween Boss",
        pol: "Kieszonkowy boss halloweenowy",
        fre: "Boss d'Halloween de poche",
        sch: "口袋妖怪",
        bra: "Chefão de Bolso",
        schema: 31061,
        hun: "Zseb-halloweeni-főellenség",
        swe: "Fick-Halloweenboss"
    },
    1503: {
        img: "binoculus",
        eng: "BINOCULUS!",
        pol: "BINOKULUS!",
        fre: "BINOCULUS !",
        sch: "魔眼（镜）",
        bra: "BINÓCULUS!",
        schema: 31060,
        ger: "BINOKULUS!"
    },
    1504: {
        img: "candy_cranium",
        eng: "Candy Cranium",
        pol: "Cukrowa czacha",
        fre: "Pochette surprise",
        sch: "糖果脑袋",
        bra: "Crânio Confeitado",
        schema: 31067,
        hun: "Cukorpofa",
        swe: "Skallekaramell"
    },
    1505: {
        img: "el_mostacho",
        eng: "El Mostacho",
        pol: "El wąsaczo",
        fre: "El Mostacho",
        sch: "长须神工",
        bra: "El Bigodón",
        schema: 31074,
        hun: "El Bajuszo"
    },
    1506: {
        img: "bat_hat",
        eng: "Bat Hat",
        pol: "Nietoperze nakrycie",
        fre: "Chauve souriant",
        sch: "卡通蝙蝠帽",
        bra: "Capelo Morcego",
        schema: 31058,
        hun: "Denevérsapka",
        swe: "Fladdermössa"
    },
    1507: {
        img: "convict_cap",
        eng: "Convict Cap",
        pol: "Obity osadzony",
        fre: "Bonnet de bagnard",
        sch: "囚犯之帽",
        bra: "Chapéu do Condenado",
        schema: 31080,
        hun: "Fegyencsapka",
        swe: "Fängelsemössa"
    },
    1508: {
        img: "bread_biter",
        eng: "Bread Biter",
        pol: "Chrupacz chleba",
        fre: "Un pain c'est tout",
        sch: "面包口罩",
        bra: "Dentada Mofada",
        schema: 31062,
        hun: "Kenyérharapó",
        swe: "Brödbitaren"
    },
    1509: {
        img: "el_zapateador",
        eng: "El Zapateador",
        pol: "El Zapateador",
        fre: "El Zapateador",
        sch: "革命领袖",
        bra: "El Zapateador",
        schema: 31069
    },
    1510: {
        img: "voodoo_vizier",
        eng: "Voodoo Vizier",
        pol: "Wezyr voodoo",
        fre: "Vizir vaudou",
        sch: "巫毒面具",
        bra: "Vizir Vudu",
        schema: 31072,
        hun: "Vudu Vezír",
        swe: "Voodoomask"
    },
    1511: {
        img: "soviet_strongmann",
        eng: "Soviet Strongmann",
        pol: "Sowiecki strongmann",
        fre: "Force soviétique",
        sch: "苏维埃猛男",
        bra: "Musculatura Márxima",
        schema: 31079,
        hun: "Szovjet Erősember",
        swe: "Sovjetisk styrkelyftare"
    },
    1512: {
        img: "skullbrero",
        eng: "Skullbrero",
        pol: "Czaszkombrero",
        fre: "Sombrero sinistre",
        sch: "亡者之帽",
        bra: "Cavero",
        schema: 31066,
        hun: "Koponyero",
        swe: "Skallebrero"
    },
    1513: {
        img: "horrible_horns",
        eng: "Horrible Horns",
        pol: "Potworne poroże",
        fre: "Cornes horribles",
        sch: "恐怖魔角",
        bra: "Chifres Cabulosos",
        schema: 31063,
        hun: "Szörnyű Szarvak",
        swe: "Hemska horn"
    },
    1514: {
        img: "madmanns_muzzle",
        eng: "Madmann's Muzzle",
        pol: "Namordnik niepoczytalnego",
        fre: "Muselière meurtrière",
        sch: "狂人面罩",
        bra: "Mordaça do Maluco",
        schema: 31077,
        hun: "Az Őrült Szájkosara",
        swe: "Manikerns munkorg"
    },
    1515: {
        img: "trick_stabber",
        eng: "Trick Stabber",
        pol: "Szmaciany spryciarz",
        fre: "Poignardeur",
        sch: "面刺专家",
        bra: "Apanhador de Costas",
        schema: 31064,
        hun: "Trükkös Bökő",
        swe: "Knivhuggsknep"
    },
    1516: {
        img: "elizabeth_the_third",
        eng: "Elizabeth the Third",
        pol: "Elżbieta Trzecia",
        fre: "Élisabeth III",
        sch: "伊丽莎白三世",
        bra: "Elizabeth III",
        schema: 31084,
        hun: "Harmadik Elizabeth",
        swe: "Elizabeth III"
    },
    1517: {
        img: "head_of_the_dead",
        eng: "Head of the Dead",
        pol: "Twarz truposza",
        fre: "Tête de mort",
        sch: "亡者之王",
        bra: "Cabeça dos Mortos",
        schema: 31065,
        hun: "A Halál-fejes",
        swe: "Dödens skalle"
    },
    1518: {
        img: "racc_mann",
        eng: "Racc Mann",
        pol: "Superszop",
        fre: "Raton tueur",
        sch: "浣熊侠",
        bra: "Guaxe-Mann",
        schema: 31071,
        hun: "Mosómannus",
        swe: "Tvättbjörnsmannen"
    },
    1519: {
        img: "pyro_lantern",
        eng: "Pyr'o Lantern",
        pol: "Dyniowa światłość",
        fre: "Citrouille lanterne",
        sch: "火焰南瓜灯",
        bra: "Pyro Sem Cabeça",
        schema: 31076,
        hun: "Pirolámpás",
        swe: "Pyrolykta"
    },
    1520: {
        img: "mister_bones",
        eng: "Mister Bones",
        pol: "Pan Kostek",
        fre: "Monsieur Bones",
        sch: "骷髅先生",
        bra: "Senhor Ossos",
        schema: 31059,
        hun: "Csont úr",
        swe: "Herr Bones"
    },
    1521: {
        img: "pebbles_the_penguin",
        eng: "Pebbles the Penguin",
        pol: "Pingwin Pierzuś",
        fre: "Manchot l'empereur",
        sch: "小企鹅卵石",
        bra: "Pedro, o Pinguim",
        schema: 31086,
        hun: "Pajti, a pingvin",
        swe: "Pingvinen Pebbles"
    },
    1522: {
        img: "citizen_cane",
        eng: "Citizen Cane",
        pol: "Cukrowy cudak",
        fre: "Chapeau d'orge",
        sch: "公民糖杖",
        bra: "Cidadão Açucarado",
        schema: 31091,
        hun: "Cukorpolgár",
        swe: "Sockertopp"
    },
    1523: {
        img: "missing_piece",
        eng: "Missing Piece",
        pol: "Brakujący element",
        fre: "Bonnet introuvable",
        sch: "失踪人口",
        bra: "Peça Perdida",
        schema: 31101,
        hun: "Hiányzó Darab",
        ger: "Fehlendes Stück"
    },
    1524: {
        img: "glittering_garland",
        eng: "Glittering Garland",
        pol: "Wymuskany wieniec",
        fre: "Guirlande enguirlandée",
        sch: "炫目花环",
        bra: "Guirlanda Brilhante",
        schema: 31093,
        hun: "Fénylő Füzér",
        swe: "Glittrig krans"
    },
    1525: {
        img: "yule_hog",
        eng: "Yule Hog",
        pol: "Szapoklak z szynki",
        fre: "Jambon de Noël",
        sch: "圣诞火腿",
        bra: "Presunto Presunçoso",
        schema: 31087,
        hun: "Karácsonyi Malac",
        swe: "Honungsgriljerad hatt"
    },
    1526: {
        img: "gingerbread_mann",
        eng: "Gingerbread Mann",
        pol: "Lukrowy ludzik",
        fre: "Mercenaire d'épice",
        sch: "姜饼曼恩",
        bra: "Mann-Biscoito",
        schema: 31088,
        hun: "Mézeskalács Mannus",
        swe: "Pepparkaksmann"
    },
    1527: {
        img: "bumble_beenie",
        eng: "Bumble Beenie",
        pol: "Trzmiela czapa",
        fre: "Roi de la ruche",
        sch: "蜜蜂之帽",
        bra: "Gorrobelha",
        schema: 31085,
        hun: "Méhessapka",
        swe: "Humleluva"
    },
    1528: {
        img: "spiky_viking",
        eng: "Spiky Viking",
        pol: "Kolczasty wiking",
        fre: "Casque à pointes",
        sch: "维京刺盔",
        bra: "Espinhos Escandinavos",
        schema: 31100,
        hun: "Tüskés Viking",
        swe: "Vass viking"
    },
    1529: {
        img: "pocket_medes",
        eng: "Pocket-Medes",
        pol: "Kieszonkomedes",
        fre: "Archimède de poche",
        sch: "袋基米德",
        bra: "Arquimedes de Bolso",
        schema: 31099,
        hun: "Zseb-médész",
        ger: "Taschen-Medes"
    },
    1530: {
        img: "discovision",
        eng: "Discovision",
        pol: "Discowizja",
        fre: "Discovision",
        sch: "迪斯科之眼",
        bra: "Discotécnico",
        schema: 31096,
        hun: "Diszkólátás"
    },
    1531: {
        img: "telefragger_toque",
        eng: "Telefragger Toque",
        pol: "Toczek telefraggera",
        fre: "Toque téléportatrice",
        sch: "传送绞肉机",
        bra: "Touca da Teletransmorte",
        schema: 31098,
        hun: "Teleport-tökfödő",
        swe: "Telefraggarens mössa"
    },
    1532: {
        img: "globetrotter",
        eng: "Globetrotter",
        pol: "Okrągły odkrywca",
        fre: "Souvenir de 2fort",
        sch: "雪景之帽",
        bra: "Lembrancinha de 2Fort",
        schema: 31090,
        hun: "Világjáró",
        ger: "Weltenbummler"
    },
    1533: {
        img: "winter_wrap_up",
        eng: "Winter Wrap Up",
        pol: "Zimowe zakrycie",
        fre: "Emmitouflage",
        sch: "冬日套装",
        bra: "Agasalho Álgido",
        schema: 31094,
        hun: "Sapka, Sál",
        ger: "Winterschal"
    },
    1534: {
        img: "mislaid_sweater",
        eng: "Mislaid Sweater",
        pol: "Zapodziany sweter",
        fre: "Pull introuvable",
        sch: "迷失毛衣",
        bra: "Suéter do Sorrateiro",
        schema: 31102,
        hun: "Elhagyott Pulóver"
    },
    1535: {
        img: "public_speaker",
        eng: "Public Speaker",
        pol: "Chodząca rozgłośnia",
        fre: "Annonceur",
        sch: "高音喇叭",
        bra: "Palestrinha",
        schema: 31095,
        hun: "Harci Hangadó"
    },
    1536: {
        img: "smissmas_saxton",
        eng: "Smissmas Saxton",
        pol: "Śniąteczny Saxton",
        fre: "Père Saxton",
        sch: "萨克斯顿圣诞帽",
        bra: "Saxton Noel",
        schema: 31089,
        hun: "Galácsonyi Saxton",
        ger: "Smissmas-Saxton"
    },
    1537: {
        img: "provisions_cap",
        eng: "Provisions Cap",
        pol: "Czapka zapasów",
        fre: "Casquette Provisions",
        sch: "补给帽",
        bra: "Patrocínio do Fornecedor",
        schema: 31097,
        hun: "Ellátmánysapka"
    },
    1538: {
        img: "candy_crown",
        eng: "Candy Crown",
        pol: "Piernikowe panisko",
        fre: "Monsieur Tartine",
        sch: "糖果之冠",
        bra: "Adorno Açucarado",
        schema: 31092,
        hun: "Mézeskalács-korona",
        swe: "Karamellkungen"
    },
    1539: {
        img: "alpine_war_paint",
        eng: "Alpine War Paint",
        pol: "Alpy | Barwy wojenne",
        fre: "Alpin | Peinture de guerre",
        sch: "阿尔卑斯 战绘",
        bra: "Alpina Tinta de Guerra",
        schema: 17252,
        hun: "Alpesi Harci festés",
        rom: "Alpine Vopsea de război",
        swe: "Alpint Krigsfärg"
    },
    1540: {
        img: "gift_wrapped_war_paint",
        eng: "Gift Wrapped War Paint",
        pol: "Zapakowany prezent | Barwy wojenne",
        fre: "Cadeau emballé | Peinture de guerre",
        sch: "圣诞礼盒 战绘",
        bra: "Embrulho de Presente Tinta de Guerra",
        schema: 17253,
        hun: "Becsomagolt Harci festés",
        rom: "Gift Wrapped Vopsea de război",
        swe: "Presentpapper Krigsfärg"
    },
    1541: {
        img: "seriously_snowed_war_paint",
        eng: "Seriously Snowed War Paint",
        pol: "Straszna śnieżyca | Barwy wojenne",
        fre: "Neige abondante | Peinture de guerre",
        sch: "雪人佣兵 战绘",
        bra: "Mercenáriozinhos de Neve Tinta de Guerra",
        schema: 17249,
        hun: "Súlyosan behavazott Harci festés",
        rom: "Seriously Snowed Vopsea de război",
        swe: "Snösoldater Krigsfärg"
    },
    1542: {
        img: "igloo_war_paint",
        eng: "Igloo War Paint",
        pol: "Igloo | Barwy wojenne",
        fre: "Igloo | Peinture de guerre",
        sch: "冰砖小屋 战绘",
        bra: "Iglu Tinta de Guerra",
        schema: 17248,
        hun: "Jégkunyhó Harci festés",
        rom: "Igloo Vopsea de război",
        swe: "Igloo Krigsfärg"
    },
    1543: {
        img: "sleighin_style_war_paint",
        eng: "Sleighin' Style War Paint",
        pol: "Kulig | Barwy wojenne",
        fre: "Traîneau stylé | Peinture de guerre",
        sch: "雪橇彩带 战绘",
        bra: "Trenó Tinta de Guerra",
        schema: 17251,
        hun: "Szánkózós stílusú Harci festés",
        rom: "Sleighin' Style Vopsea de război",
        swe: "Bjällerbang Krigsfärg"
    },
    1544: {
        img: "snow_covered_war_paint",
        eng: "Snow Covered War Paint",
        pol: "Pokrywa śnieżna | Barwy wojenne",
        fre: "Couvert de neige | Peinture de guerre",
        sch: "雪花覆盖 战绘",
        bra: "Flocos de Neve Tinta de Guerra",
        schema: 17245,
        hun: "Hófedte Harci festés",
        rom: "Snow Covered Vopsea de război",
        swe: "Snötäckt Krigsfärg"
    },
    1545: {
        img: "frost_ornamented_war_paint",
        eng: "Frost Ornamented War Paint",
        pol: "Mroźne zdobienie | Barwy wojenne",
        fre: "Décorations givrées | Peinture de guerre",
        sch: "冰霜之饰 战绘",
        bra: "Ornamento Congelado Tinta de Guerra",
        schema: 17246,
        hun: "Fagy díszítette Harci festés",
        rom: "Frost Ornamented Vopsea de război",
        swe: "Frostsnirkel Krigsfärg"
    },
    1546: {
        img: "smissmas_camo_war_paint",
        eng: "Smissmas Camo War Paint",
        pol: "Śniąteczny kamuflaż | Barwy wojenne",
        fre: "Camouflage Smissmas | Peinture de guerre",
        sch: "圣诞迷彩 战绘",
        bra: "Camuflagem Festiva Tinta de Guerra",
        schema: 17250,
        hun: "Galácsonyi álca Harci festés",
        rom: "Smissmas Camo Vopsea de război",
        swe: "Julkamouflage Krigsfärg"
    },
    1547: {
        img: "smissmas_village_war_paint",
        eng: "Smissmas Village War Paint",
        pol: "Śniąteczna wioska | Barwy wojenne",
        fre: "Village Smissmas | Peinture de guerre",
        sch: "圣诞村庄 战绘",
        bra: "Vila do Polo Norte Tinta de Guerra",
        schema: 17247,
        hun: "Galácsonyi falu Harci festés",
        rom: "Smissmas Village Vopsea de război",
        swe: "Julpynt Krigsfärg"
    },
    1548: {
        img: "winterland_wrapped_war_paint",
        eng: "Winterland Wrapped War Paint",
        pol: "Zimowa kraina | Barwy wojenne",
        fre: "Emballage hivernal | Peinture de guerre",
        sch: "冬日条纹 战绘",
        bra: "Embrulho de Natal Tinta de Guerra",
        schema: 17254,
        hun: "Galácsonyra csomagolt Harci festés",
        rom: "Winterland Wrapped Vopsea de război",
        swe: "Julklappspapper Krigsfärg"
    },
    1549: {
        img: "self_care",
        eng: "Self-Care",
        pol: "Samoopieka",
        sch: "医者自医",
        bra: "Autoajuda",
        schema: 31122,
        hun: "Öngyógyító",
        fre: "Bandages de fortune",
        swe: "Egenvård"
    },
    1550: {
        img: "the_jarmaments",
        eng: "Jarmaments",
        pol: "Sikwondowe słoiki",
        sch: "瓶手道武装",
        bra: "Jarmamentos",
        schema: 31120,
        hun: "Harci Üvegcsék",
        fre: "Jarmements",
        ger: "Jarategürtel"
    },
    1551: {
        img: "sophisticated_smoker",
        eng: "Sophisticated Smoker",
        pol: "Nienaganny nałogowiec",
        fre: "Fumeur fumeux",
        sch: "雪茄大佬",
        bra: "Charuto Charmoso",
        schema: 31106,
        hun: "Szivaros Szivar",
        swe: "Raffinerad rökare"
    },
    1552: {
        img: "soda_specs",
        eng: "Soda Specs",
        pol: "Sodowe szkła",
        sch: "原子能眼镜",
        bra: "Olhar Refrescante",
        schema: 31116,
        hun: "Üdcsiszemcsi",
        fre: "Lunettes rafraichissantes",
        swe: "Bonkbrillor"
    },
    1553: {
        img: "fire_fighter",
        eng: "Fire Fighter",
        pol: "Wojownik ognia",
        fre: "Combattant enflammé",
        sch: "街头火霸王",
        bra: "Lutador em Chamas",
        schema: 31108,
        hun: "Lánglovag",
        ger: "Feuerkämpfer"
    },
    1554: {
        img: "poolside_polo",
        eng: "Poolside Polo",
        pol: "Plażowe polo",
        sch: "池边衬衫",
        bra: "Polo do Praieiro",
        schema: 31118,
        hun: "Vízipóló",
        fre: "Polo du croisiériste",
        ger: "Pool-Polo"
    },
    1555: {
        img: "crabe_de_chapeau",
        eng: "Crabe de Chapeau",
        pol: "Crabe de chapeau",
        fre: "Crabe de chapeau",
        sch: "蟹蟹之帽",
        bra: "Chapeau de Karranguejo",
        schema: 31109,
        ger: "Krabbenhut"
    },
    1556: {
        img: "california_cap",
        eng: "California Cap",
        pol: "Kalifornijska czapka",
        sch: "加州棒球帽",
        bra: "Boné Californiano",
        schema: 31117,
        hun: "Strandsatyek",
        fre: "Casquette californienne",
        swe: "Kalifornisk keps"
    },
    1557: {
        img: "bazaar_bauble",
        eng: "Bazaar Bauble",
        pol: "Bazarowe bawidełko",
        sch: "惊喜小兵",
        bra: "Bugiganga do Bazar",
        schema: 31112,
        hun: "Bazári Bigyó",
        fre: "Soldier à ressort",
        ger: "Basarspielerei"
    },
    1558: {
        img: "head_of_defense",
        eng: "Head Of Defense",
        pol: "Czołowy obrońca",
        sch: "步哨头盔",
        bra: "Mini-stro da Defesa",
        schema: 31114,
        hun: "A Fővédnök",
        fre: "Mitraille entêtée"
    },
    1559: {
        img: "flatliner",
        eng: "Flatliner",
        pol: "Pulsowizjer",
        sch: "平线庸医",
        bra: "Visão Vascular",
        schema: 31121,
        hun: "Szívmonitor",
        fre: "Électroencéphalographe",
        swe: "Hjärtflimmer"
    },
    1560: {
        img: "tools_of_the_tourist",
        eng: "Tools of the Tourist",
        pol: "Torba turysty",
        sch: "游客的挎包",
        bra: "Ferramentas do Farofeiro",
        schema: 31119,
        hun: "Turistakellékek",
        fre: "Attirail du touriste",
        ger: "Urlauberutensilien"
    },
    1561: {
        img: "hazard_headgear",
        eng: "Hazard Headgear",
        pol: "Niezabezpieczone nakrycie",
        sch: "危爆之盔",
        bra: "Equipamento de Proteção Parcial",
        schema: 31115,
        hun: "Biztonsági Bukósisak",
        fre: "Casque casse-cou",
        ger: "Gefahrschutzglocke"
    },
    1562: {
        img: "birds_eye_viewer",
        eng: "Bird's Eye Viewer",
        pol: "Ptasia perspektywa",
        fre: "Espigeon",
        sch: "间谍鸽",
        bra: "Pombo de Vista",
        schema: 31110,
        hun: "Madártávlati Megfigyelő",
        swe: "Fågelperspektiv"
    },
    1563: {
        img: "breach_and_bomb",
        eng: "Breach and Bomb",
        pol: "Wyważyć i wysadzić",
        sch: "以爆制暴",
        bra: "Infiltrar e Detonar",
        schema: 31113,
        hun: "Behatolás és Bombázás",
        fre: "Force spéciale",
        ger: "Bombenschutzhelm"
    },
    1564: {
        img: "momma_kiev",
        eng: "Momma Kiev",
        pol: "Kijowska kwoka",
        sch: "基辅老母鸡",
        bra: "Mamãe Kiev",
        schema: 31123,
        hun: "Kijev Mama",
        fre: "Mama Kiev",
        swe: "Mamma Kiev"
    },
    1565: {
        img: "flamehawk",
        eng: "Flamehawk",
        pol: "Iskrzący irokez",
        fre: "Pyroquois",
        sch: "莫火克发型",
        bra: "Flamoicano",
        schema: 31107,
        hun: "Tűzsörény",
        swe: "Påtuttad tuppkam"
    },
    1566: {
        img: "loaf_loafers",
        eng: "Loaf Loafers",
        pol: "Bochenkowe buty",
        fre: "Paintoufles",
        sch: "面包大脚",
        bra: "Pãotufas",
        schema: 31105,
        hun: "Bucibakancs",
        swe: "Limploafers"
    },
    1567: {
        img: "smoking_jacket",
        eng: "Smoking Jacket",
        pol: "Bonżurka palacza",
        sch: "烟鬼夹克",
        bra: "Traje de Tabaco",
        schema: 31124,
        hun: "Szmoking",
        fre: "Costabac",
        swe: "Rökrock"
    },
    1568: {
        img: "hawk_warrior",
        eng: "Hawk Warrior",
        pol: "Jastrzębi wojownik",
        fre: "Faucon de guerre",
        sch: "白头鹰战士",
        bra: "Guerreiro Águia",
        schema: 31111,
        hun: "Sólyomharcos",
        swe: "Örnkrigare"
    },
    1569: {
        img: "breadcrab",
        eng: "Breadcrab",
        pol: "Chlebokrab",
        fre: "Pain de tête",
        sch: "面包头蟹",
        bra: "Pãorasita",
        schema: 31104,
        hun: "Rágós Kenyér",
        swe: "Krabbkaka"
    },
    1570: {
        img: "hypno_eyes",
        eng: "Hypno-eyes",
        pol: "Hipnowzrok",
        fre: "Yeuxpnotiseurs",
        sch: "催眠之眼",
        bra: "Hipnolhos",
        schema: 31103,
        hun: "Hipno-szemek",
        swe: "Hypno-brillor"
    },
    1571: {
        img: "vaccinator",
        eng: "Vaccinator",
        pol: "Zaszczepiacz",
        fre: "Vaccinateur",
        sch: "疫苗接种枪",
        bra: "Vacinadora",
        schema: 998,
        schema2: 5756,
        hun: "Beoltó",
        swe: "Vaccinatör"
    },
    1572: {
        img: "air_strike",
        eng: "Air Strike",
        pol: "Atak powietrzny",
        fre: "La Frappe aérienne",
        sch: "空袭火箭筒",
        bra: "O Ataque Aéreo",
        schema: 1104,
        schema2: 5753,
        hun: "A Légicsapás",
        swe: "Luftangreppet"
    },
    1573: {
        img: "pomson_6000",
        eng: "Pomson 6000",
        pol: "Pomson 6000",
        fre: "Le Pomson 6000",
        sch: "歼灭者 6000",
        bra: "O Pomson 6000",
        schema: 588,
        schema2: 5661,
        hun: "A Pomson 6000",
        ger: "Der Pomson 6000"
    },
    1574: {
        img: "the_seared_sorcerer",
        eng: "Seared Sorcerer",
        pol: "Zapalający zaklinacz",
        fre: "Pyromage",
        sch: "焦灼炎术师",
        bra: "Bruxo das Brasas",
        schema: 31143,
        hun: "Tűzmágus",
        swe: "Svedd svartmagiker"
    },
    1575: {
        img: "the_boom_boxers",
        eng: "Boom Boxers",
        pol: "Bumbokserki",
        fre: "Box-cœurs",
        sch: "甜心内裤",
        schema: 31133,
        hun: "Kirobbanó Ülep",
        bra: "Samba Coração",
        swe: "Pang i brallan"
    },
    1576: {
        img: "king_cardbeard",
        eng: "King Cardbeard",
        pol: "Król Kartonobrody",
        fre: "Monarque en carton",
        sch: "纸板皇冠",
        bra: "Rei Barbelão",
        schema: 31130,
        hun: "Kartonszakáll Király",
        swe: "Kartongkungen"
    },
    1577: {
        img: "flavorful_baggies",
        eng: "Flavorful Baggies",
        pol: "Pstrokate pumpy",
        fre: "Pantalon de bon gout",
        sch: "小丑波点裤",
        schema: 31145,
        bra: "Calças da Palhaçada",
        hun: "Pöttyös Buggyos",
        swe: "Pajasens pösbyxor"
    },
    1578: {
        img: "calamitous_cauldron",
        eng: "Calamitous Cauldron",
        pol: "Katastrofalny kocioł",
        fre: "Chaudron calamiteux",
        sch: "倾覆之釜",
        bra: "Caldeirão Calamitoso",
        schema: 31146,
        hun: "Üstök-üst",
        swe: "Katastrofal kittel"
    },
    1579: {
        img: "a_handsome_handy_thing",
        eng: "A Handsome Handy Thing",
        pol: "Poręczna rąsia",
        fre: "Tête paumée",
        sch: "留了一手",
        bra: "Uma Mão Vai Na Cabeça",
        schema: 31129,
        hun: "Kézrátétel",
        swe: "Huvudsaken"
    },
    1580: {
        img: "handsome_devil",
        eng: "Handsome Devil",
        pol: "Przystojny diabeł",
        fre: "Diable distingué",
        sch: "潇洒魔男",
        bra: "Demônio Elegante",
        schema: 31135,
        hun: "Jóképű Ördög",
        swe: "DemOni"
    },
    1581: {
        img: "hollowed_helm",
        eng: "Hollowed Helm",
        pol: "Dyniowa defensywa",
        fre: "Cascourge",
        sch: "空心南瓜盔",
        bra: "Elmo Espantador",
        schema: 31128,
        hun: "Kivájt Tökfödő",
        swe: "Karvad hjälm"
    },
    1582: {
        img: "goblineer",
        eng: "Goblineer",
        pol: "Goblinier",
        fre: "Goblineer",
        sch: "工布林",
        bra: "Goblinheiro",
        schema: 31150,
        hun: "Törpnök",
        swe: "Ingeniare ingenium kobold"
    },
    1583: {
        img: "the_ghoul_box",
        eng: "Ghoul Box",
        pol: "Ghul narzędziowy",
        fre: "Goule à outils",
        sch: "怪物工具箱",
        bra: "Mal-eta de Ferramentas",
        schema: 31151,
        hun: "Rémszerszámos",
        swe: "Sattygslåda"
    },
    1584: {
        img: "glow_from_below",
        eng: "Glow from Below",
        pol: "Światełko z otchłani",
        fre: "Baudroie luminescente",
        sch: "深海之光",
        bra: "Brilho das Profundezas",
        schema: 31149,
        hun: "Fény a Mélyben",
        swe: "Djuphavets ljushuvud"
    },
    1585: {
        img: "eye_see_you",
        eng: "Eye-See-You",
        pol: "Mam na ciebie oko",
        fre: "Yeux-te-vois",
        sch: "别具慧眼",
        bra: "Olho-por-Olho",
        schema: 31134,
        hun: "Szemtől Szemben",
        swe: "Ögon på skaft"
    },
    1586: {
        img: "impish_ears",
        eng: "Impish Ears",
        pol: "Chochlikowe uszy",
        fre: "Oreilles de farfadet",
        sch: "小鬼耳朵",
        schema: 31125,
        bra: "Orelhas Pontiagudas",
        hun: "Koboldfülek",
        swe: "Spetsade öron"
    },
    1587: {
        img: "sir_pumpkinton",
        eng: "Sir Pumpkinton",
        pol: "Sir Dyńkinton",
        fre: "Sieur citrouille",
        sch: "南瓜爵士礼帽",
        bra: "Senhor Cabeça-de-abóbora",
        schema: 31131,
        hun: "Sir Tökkobaki",
        swe: "Von Pumpa"
    },
    1588: {
        img: "gourd_grin",
        eng: "Gourd Grin",
        pol: "Dyniowy uśmiech",
        fre: "Rictus sardonique",
        sch: "裂口南瓜巾",
        bra: "Cara de Cabaço",
        schema: 31127,
        hun: "Tökre Vigyorog",
        swe: "Oktobernattens leende"
    },
    1589: {
        img: "semi_tame_trappers_hat",
        eng: "Semi-tame Trapper's Hat",
        pol: "Półoswojona czapka trapera",
        fre: "Raton laveur empaillé",
        sch: "菜鸟猎人",
        schema: 31147,
        bra: "Guaxi-ni-mim",
        hun: "Félszelíd Prémsapka",
        swe: "Halvtam sjubbskinnsmössa"
    },
    1590: {
        img: "the_fire_tooth",
        eng: "Fire Tooth",
        pol: "Ognisty ząb",
        fre: "Clown incendiaire",
        sch: "大牙小丑",
        schema: 31144,
        bra: "Sorveteiro Sinistro",
        hun: "Tüzes Pofa",
        swe: "Pyrande pajas"
    },
    1591: {
        img: "wavefinder",
        eng: "Wavefinder",
        pol: "Wykrywacz fal",
        fre: "Ondoscope",
        sch: "寻波装置",
        bra: "Sente a Sintonia",
        schema: 31148,
        hun: "Állomáskereső",
        swe: "Elmuffar"
    },
    1592: {
        img: "misfortune_fedora",
        eng: "Misfortune Fedora",
        pol: "Niefortunna fedora",
        fre: "Feutre maudit",
        sch: "厄运迷雾礼帽",
        bra: "Fedora Futurista",
        schema: 31136,
        hun: "A Balsors Puhakalapja",
        swe: "Faslig fedora"
    },
    1593: {
        img: "wrap_a_khamon",
        eng: "Wrap-A-Khamon",
        pol: "Bandażochamon",
        fre: "Momie-cyclope",
        sch: "法老的折叠礼帽",
        bra: "Tutâncamúmia",
        schema: 31132,
        hun: "Kalapmúmia",
        swe: "Tutankhamons bandage"
    },
    1594: {
        img: "balloonicorpse",
        eng: "Balloonicorpse",
        pol: "Balonozwłoki",
        fre: "Ballicorps",
        sch: "气球独角尸",
        bra: "Balãonecrórnio",
        schema: 31126,
        hun: "Csontlufikornis",
        swe: "Ballongdöingen"
    },
    1595: {
        img: "all_hallows_hatte",
        eng: "All Hallows' Hatte",
        pol: "Kapelusz wszystkich świętych",
        fre: "Toussorcier",
        sch: "万灵彩灯之帽",
        bra: "Bruxo Básico",
        schema: 31152,
        hun: "Kísértő Kalpag",
        swe: "Allhelgonahatt"
    },
    1596: {
        img: "organically_hellraised_war_paint",
        eng: "Organ-ically Hellraised War Paint",
        pol: "Organiczny pomiot | Barwy wojenne",
        sch: "原生狱火 战绘",
        bra: "Terror Orgânico Tinta de Guerra",
        schema: 17256,
        fre: "Incinération infernale | Peinture de guerre",
        hun: "Szerv-esen pokolbantermesztett Harci festés",
        rom: "Organ-ically Hellraised Vopsea de război",
        swe: "Helvetets lågor Krigsfärg"
    },
    1597: {
        img: "spider_season_war_paint",
        eng: "Spider Season War Paint",
        pol: "Pajęcza pora | Barwy wojenne",
        sch: "蜘蛛之季 战绘",
        bra: "Temporada de Aranhas Tinta de Guerra",
        schema: 17269,
        fre: "Araignées géantes | Peinture de guerre",
        hun: "Pókszezon Harci festés",
        rom: "Spider Season Vopsea de război",
        swe: "Spindelsäsong Krigsfärg"
    },
    1598: {
        img: "gourdy_green_war_paint",
        eng: "Gourdy Green War Paint",
        pol: "Dyniowa zieleń | Barwy wojenne",
        sch: "南瓜迷彩 战绘",
        bra: "Horta de Abóboras Tinta de Guerra",
        schema: 17267,
        fre: "Gazon maudit | Peinture de guerre",
        hun: "Tökös zöld Harci festés",
        rom: "Gourdy Green Vopsea de război",
        swe: "Pumpaodling Krigsfärg"
    },
    1599: {
        img: "eyestalker_war_paint",
        eng: "Eyestalker War Paint",
        pol: "Podglądaczka | Barwy wojenne",
        sch: "眼球果实 战绘",
        schema: 17265,
        fre: "Phytœil | Peinture de guerre",
        bra: "Guaran-ahhhhh Tinta de Guerra",
        hun: "Szemkocsányos Harci festés",
        rom: "Eyestalker Vopsea de război",
        swe: "Coreopsis Krigsfärg"
    },
    1600: {
        img: "death_deluxe_war_paint",
        eng: "Death Deluxe War Paint",
        pol: "Luksusowa śmierć | Barwy wojenne",
        sch: "死亡玫瑰 战绘",
        bra: "Morte de Luxo Tinta de Guerra",
        schema: 17263,
        fre: "Cercueil | Peinture de guerre",
        hun: "Díszes halál Harci festés",
        rom: "Death Deluxe Vopsea de război",
        swe: "Dödslyx Krigsfärg"
    },
    1601: {
        img: "portal_plastered_war_paint",
        eng: "Portal Plastered War Paint",
        pol: "Portalowa tapeta | Barwy wojenne",
        sch: "冥界之门 战绘",
        bra: "Pintura de Portal Tinta de Guerra",
        schema: 17262,
        fre: "Portail magique | Peinture de guerre",
        hun: "Portáltapaszos Harci festés",
        rom: "Portal Plastered Vopsea de război",
        swe: "Portalsnurr Krigsfärg"
    },
    1602: {
        img: "candy_coated_war_paint",
        eng: "Candy Coated War Paint",
        pol: "Cukierek | Barwy wojenne",
        sch: "万圣糖果 战绘",
        bra: "Revestimento Doce Tinta de Guerra",
        schema: 17258,
        fre: "Friandise | Peinture de guerre",
        hun: "Cukorkapapíros Harci festés",
        rom: "Candy Coated Vopsea de război",
        swe: "Kanderat Krigsfärg"
    },
    1603: {
        img: "spiders_cluster_war_paint",
        eng: "Spider's Cluster War Paint",
        pol: "Sieć pająka | Barwy wojenne",
        sch: "毒巢蛛网 战绘",
        bra: "Aglomerado de Aranhas Tinta de Guerra",
        schema: 17266,
        fre: "Toiles d'araignée | Peinture de guerre",
        hun: "Pókfürtös Harci festés",
        rom: "Spider's Cluster Vopsea de război",
        swe: "Spindeldesign Krigsfärg"
    },
    1604: {
        img: "raving_dead_war_paint",
        eng: "Raving Dead War Paint",
        pol: "Tańczący umarli | Barwy wojenne",
        sch: "亡者狂言 战绘",
        bra: "Mortos Muito Loucos Tinta de Guerra",
        schema: 17264,
        fre: "Squelette | Peinture de guerre",
        hun: "Haláli buli Harci festés",
        rom: "Raving Dead Vopsea de război",
        swe: "Dödsparty Krigsfärg"
    },
    1605: {
        img: "crawlspace_critters_war_paint",
        eng: "Crawlspace Critters War Paint",
        pol: "Piwniczne pełzacze | Barwy wojenne",
        sch: "狭缝生物 战绘",
        bra: "Criaturinhas Escondidas Tinta de Guerra",
        schema: 17261,
        fre: "Galetas | Peinture de guerre",
        hun: "Falban motoszkálós Harci festés",
        rom: "Crawlspace Critters Vopsea de război",
        swe: "Kryp i krypin Krigsfärg"
    },
    1606: {
        img: "sweet_toothed_war_paint",
        eng: "Sweet Toothed War Paint",
        pol: "Łakomczuch | Barwy wojenne",
        sch: "嗜甜成瘾 战绘",
        bra: "Dentinhos Doces Tinta de Guerra",
        schema: 17260,
        fre: "Bec sucré | Peinture de guerre",
        hun: "Édesszájú Harci festés",
        rom: "Sweet Toothed Vopsea de război",
        swe: "Gottegris Krigsfärg"
    },
    1607: {
        img: "helldriver_war_paint",
        eng: "Helldriver War Paint",
        pol: "Piekielnik | Barwy wojenne",
        sch: "地狱追逐 战绘",
        bra: "Helldriver Tinta de Guerra",
        schema: 17255,
        fre: "Chlorophylle | Peinture de guerre",
        hun: "Pokolhajtó Harci festés",
        rom: "Helldriver Vopsea de război",
        swe: "Grönt inferno Krigsfärg"
    },
    1608: {
        img: "mummified_mimic_war_paint",
        eng: "Mummified Mimic War Paint",
        pol: "Mumifikowany mimik | Barwy wojenne",
        sch: "狞笑干尸 战绘",
        bra: "Mímico Mumificado Tinta de Guerra",
        schema: 17268,
        fre: "Momification | Peinture de guerre",
        hun: "Múmia-mimikri Harci festés",
        rom: "Mummified Mimic Vopsea de război",
        swe: "Mumiebandage Krigsfärg"
    },
    1609: {
        img: "pumpkin_pied_war_paint",
        eng: "Pumpkin Pied War Paint",
        pol: "Dyniowe ciasto | Barwy wojenne",
        sch: "南瓜甜点 战绘",
        bra: "Torta de Abóbora Tinta de Guerra",
        schema: 17259,
        fre: "Tarte aux citrouilles | Peinture de guerre",
        hun: "Tökös-pités Harci festés",
        rom: "Pumpkin Pied Vopsea de război",
        swe: "Höstmys Krigsfärg"
    },
    1610: {
        img: "spectrum_splattered_war_paint",
        eng: "Spectrum Splattered War Paint",
        pol: "Rozbryzgany duch | Barwy wojenne",
        sch: "魔血泼溅 战绘",
        bra: "Gotas Cromáticas Tinta de Guerra",
        schema: 17257,
        fre: "Spectre | Peinture de guerre",
        hun: "Spektrálpacás Harci festés",
        rom: "Spectrum Splattered Vopsea de război",
        swe: "Spektrumsplatter Krigsfärg"
    },
    1611: {
        img: "taunt_carlton",
        eng: "Taunt: The Carlton",
        schema: 1168,
        pol: "Drwina: Carlton",
        fre: "Raillerie : Le Carlton",
        sch: "嘲讽：卡尔顿之舞",
        bra: "Provocação: O Carlton",
        hun: "Beszólás: A Carlton",
        swe: "Hån: The Carlton"
    },
    1612: {
        img: "taunt_runners_rhythm",
        eng: "Taunt: Runner's Rhythm",
        schema: 30921,
        pol: "Drwina: Bębny biegacza",
        fre: "Raillerie : Garde le rythme",
        sch: "嘲讽：敲小鼓",
        bra: "Provocação: Ritmo do Corredor",
        hun: "Beszólás: Fürge ritmus",
        swe: "Hån: Löparens rytm"
    },
    1613: {
        img: "taunt_scooty_scoot",
        eng: "Taunt: The Scooty Scoot",
        schema: 1197,
        pol: "Drwina: Skauci skuter",
        fre: "Raillerie : Le Scouteur",
        sch: "嘲讽：宝琳的摩托",
        bra: "Provocação: Scooter do Scout",
        hun: "Beszólás: Ráérősen robogó",
        swe: "Hån: Skoter-Scout"
    },
    1614: {
        img: "taunt_trackmans_touchdown",
        eng: "Taunt: The Trackman's Touchdown",
        schema: 30917,
        pol: "Drwina: Pierwszorzędne przyłożenie",
        fre: "Raillerie : Le footballeur américain",
        sch: "嘲讽：触地得分",
        bra: "Provocação: Touchdown do Atleta",
        hun: "Beszólás: Tökre touchdown",
        swe: "Hån: Triathletens touchdown"
    },
    1615: {
        img: "taunt_fubar_fanfare",
        eng: "Taunt: The Fubar Fanfare",
        schema: 30761,
        pol: "Drwina: Fanfara defetysty",
        fre: "Raillerie : La fanfare Fubar",
        sch: "嘲讽：坑爹的长号",
        bra: "Provocação: Fanfarra da Falha",
        hun: "Beszólás: A Hogyaza Harsona",
        swe: "Hån: Fubar-fanfaren"
    },
    1616: {
        img: "taunt_panzer_pants",
        eng: "Taunt: Panzer Pants",
        schema: 1196,
        pol: "Drwina: Pancerne pantalony",
        fre: "Raillerie : Le sot d'assaut",
        sch: "嘲讽：装甲之裤",
        bra: "Provocação: Passeio de Panzer",
        hun: "Beszólás: Tankolás",
        swe: "Hån: Pansarpantalonger"
    },
    1617: {
        img: "taunt_soldiers_requiem",
        eng: "Taunt: Soldier's Requiem",
        schema: 30673,
        pol: "Drwina: Requiem Żołnierza",
        fre: "Raillerie : Requiem du Soldier",
        sch: "嘲讽：士兵的安魂曲",
        bra: "Provocação: Réquiem do Soldier",
        hun: "Beszólás: A Katona rekviemje",
        swe: "Hån: Soldatens Rekviem"
    },
    1618: {
        img: "taunt_balloonibouncer",
        eng: "Taunt: The Balloonibouncer",
        schema: 30763,
        pol: "Drwina: Balonohuśtawka",
        fre: "Raillerie : La Ballicorne à ressort",
        sch: "嘲讽：摇摇独角兽",
        bra: "Provocação: O Brinquedocórnio",
        hun: "Beszólás: A ballonhintázó",
        swe: "Hån: Balloonihopparen"
    },
    1619: {
        img: "taunt_headcase",
        eng: "Taunt: The Headcase",
        schema: 30876,
        pol: "Drwina: Danie głowne",
        fre: "Raillerie : Le plat de résistance",
        sch: "嘲讽：头等大餐",
        bra: "Provocação: Antropofagia",
        hun: "Beszólás: Fejtál",
        swe: "Hån: Huvudrätten"
    },
    1620: {
        img: "taunt_skating_scorcher",
        eng: "Taunt: The Skating Scorcher",
        schema: 30919,
        pol: "Drwina: Spopielający sportowiec",
        fre: "Raillerie : Le patineur arsoniste",
        sch: "嘲讽：溜冰健将",
        bra: "Provocação: O Patinador Incendiário",
        hun: "Beszólás: A kormos korcsolyázó",
        swe: "Hån: Skridskouppvärmning"
    },
    1621: {
        img: "taunt_bad_pipes",
        eng: "Taunt: Bad Pipes",
        schema: 30671,
        pol: "Drwina: Kiepskie dudy",
        fre: "Raillerie : Corne'amuse",
        sch: "嘲讽：差劲的风笛手",
        bra: "Provocação: Gaita de Falha",
        hun: "Beszólás: Hamis duda",
        swe: "Hån: Säckiga pipor"
    },
    1622: {
        img: "taunt_scotsmanns_stagger",
        eng: "Taunt: Scotsmann's Stagger",
        schema: 30840,
        pol: "Drwina: Szkocki spacer",
        fre: "Raillerie : L'écossais pompette",
        sch: "嘲讽：东倒西歪",
        bra: "Provocação: Escocês Estonteado",
        hun: "Beszólás: Skót dülöngélős",
        swe: "Hån: Skottens Suprunda"
    },
    1623: {
        img: "taunt_table_tantrum",
        eng: "Taunt: The Table Tantrum",
        schema: 1174,
        pol: "Drwina: Rozróba przy stole",
        fre: "Raillerie : Le lancer de table",
        sch: "嘲讽：圣诞大餐",
        bra: "Provocação: Virada de Mesa",
        hun: "Beszólás: Borulj, borulj, asztalkám!",
        swe: "Hån: Bordsvältaren"
    },
    1624: {
        img: "taunt_proletariat_posedown",
        eng: "Taunt: The Proletariat Posedown",
        schema: 30616,
        pol: "Drwina: Proletariacka poza",
        fre: "Raillerie : La pose prolétaire",
        sch: "嘲讽：工人阶级之怒",
        bra: "Provocação: A Pose do Proletariado",
        hun: "Beszólás: A proletár pózolás",
        swe: "Hån: Arbetarklassens uppvisning"
    },
    1625: {
        img: "taunt_russian_arms_race",
        eng: "Taunt: The Russian Arms Race",
        schema: 30843,
        pol: "Drwina: Rosyjski wyścig zbrojeń",
        fre: "Raillerie : Les grandes pompes",
        sch: "嘲讽：独臂俯卧撑",
        bra: "Provocação: Flexão da Revolução",
        hun: "Beszólás: Az orosz karizma",
        swe: "Hån: Tung träning"
    },
    1626: {
        img: "taunt_soviet_strongarm",
        eng: "Taunt: The Soviet Strongarm",
        schema: 30844,
        pol: "Drwina: Sowiecki siłacz",
        fre: "Raillerie : Halte aux haltères",
        sch: "嘲讽：苏维埃大力士",
        bra: "Provocação: Treino de Bíceps",
        hun: "Beszólás: Szovjet karhatalom",
        swe: "Hån: Lyftkranen från Sovjet"
    },
    1627: {
        img: "taunt_bucking_bronco",
        eng: "Taunt: Bucking Bronco",
        schema: 30618,
        pol: "Drwina: Dziki jeździec",
        fre: "Raillerie : Rodéo sauvage",
        sch: "嘲讽：驯服野马",
        bra: "Provocação: Tecno-touro do Texas",
        hun: "Beszólás: Ugráló vadbika",
        swe: "Hån: Hoppande hingsten"
    },
    1628: {
        img: "taunt_dueling_banjo",
        eng: "Taunt: The Dueling Banjo",
        schema: 30842,
        pol: "Drwina: Bajeranckie banjo",
        fre: "Raillerie : Le pro du banjo",
        sch: "嘲讽：斗琴",
        bra: "Provocação: Duelo de Banjo",
        hun: "Beszólás: Bendzsópárbaj",
        swe: "Hån: Banjospelaren"
    },
    1629: {
        img: "taunt_jumping_jack",
        eng: "Taunt: The Jumping Jack",
        schema: 30845,
        pol: "Drwina: Pneumoskoczek",
        fre: "Raillerie : Marteau piqueur",
        sch: "嘲讽：跳跳钻",
        bra: "Provocação: A Britadeira",
        hun: "Beszólás: Paprikajancsi",
        swe: "Hån: Rida slagborr"
    },
    1630: {
        img: "taunt_meet_the_medic",
        eng: "Taunt: The Meet the Medic",
        schema: 477,
        pol: "Drwina: Poznajcie Medyka",
        fre: "Raillerie : À la Rencontre du Medic",
        sch: "嘲讽：医生的英姿",
        bra: "Provocação: O Conheça o Medic",
        hun: "Beszólás: Meet the Medic",
        swe: "Hån: Möt Sjukvårdaren"
    },
    1631: {
        img: "taunt_surgeons_squeezebox",
        eng: "Taunt: Surgeon's Squeezebox",
        schema: 30918,
        pol: "Drwina: Arteterapia akordeonowa",
        fre: "Raillerie : La mélodie du docteur",
        sch: "嘲讽：手风琴独奏",
        bra: "Provocação: Acordeão do Cirurgião",
        hun: "Beszólás: Zeneterápia",
        swe: "Hån: Doktorns dragspel"
    },
    1632: {
        img: "taunt_didgeridrongo",
        eng: "Taunt: Didgeridrongo",
        schema: 30839,
        pol: "Drwina: Didgeridureń",
        fre: "Raillerie : Didgeridrongo",
        sch: "嘲讽：迪吉里杜管",
        bra: "Provocação: Didgeridiota",
        hun: "Beszólás: Didzseridióta",
        swe: "Hån: Didgeridrongo"
    },
    1633: {
        img: "taunt_killer_solo",
        eng: "Taunt: The Killer Solo",
        schema: 30609,
        pol: "Drwina: Zabójcze solo",
        fre: "Raillerie : Le solo de la mort",
        sch: "嘲讽：孤胆杀手",
        bra: "Provocação: O Solo Matador",
        hun: "Beszólás: A gyilkos szólója",
        swe: "Hån: Killer-solot"
    },
    1634: {
        img: "taunt_most_wanted",
        eng: "Taunt: Most Wanted",
        schema: 30614,
        pol: "Drwina: Najbardziej poszukiwany",
        fre: "Raillerie : Recherché",
        sch: "嘲讽：最高通缉",
        bra: "Provocação: Lista de Alvos",
        hun: "Beszólás: A legkeresettebb",
        swe: "Hån: Most Wanted"
    },
    1635: {
        img: "taunt_box_trot",
        eng: "Taunt: The Box Trot",
        schema: 30615,
        pol: "Drwina: Kartonowe szpiegostwo",
        fre: "Raillerie : La Box Trot",
        sch: "嘲讽：盒子戏法",
        bra: "Provocação: A Caixa Camuflada",
        hun: "Beszólás: Dobozba bújva",
        swe: "Hån: Gubben i lådan"
    },
    1636: {
        img: "taunt_disco_fever",
        eng: "Taunt: Disco Fever",
        schema: 30762,
        pol: "Drwina: Gorączka disco",
        fre: "Raillerie : La fièvre du disco",
        sch: "嘲讽：狂热迪斯科",
        bra: "Provocação: Disco Contagiante",
        hun: "Beszólás: Diszkóláz",
        swe: "Hån: Discofeber"
    },
    1637: {
        img: "taunt_luxury_lounge",
        eng: "Taunt: Luxury Lounge",
        schema: 30922,
        pol: "Drwina: Wykwintny wypoczynek",
        fre: "Raillerie : Le fauteuil d'argent",
        sch: "嘲讽：头等之座",
        bra: "Provocação: Conforto Luxuoso",
        hun: "Beszólás: Luxuslakosztály",
        swe: "Hån: En stund för dig själv"
    },
    1638: {
        img: "taunt_directors_vision",
        eng: "Taunt: The Director's Vision",
        schema: 438,
        pol: "Drwina: Wizja reżysera",
        fre: "Raillerie : La vue du Réalisateur",
        sch: "嘲讽：导演的视角",
        bra: "Provocação: A Visão do Diretor",
        hun: "Beszólás: A rendező elképzelése",
        swe: "Hån: Regissörens Synvinkel"
    },
    1639: {
        img: "taunt_kazotsky_kick",
        eng: "Taunt: Kazotsky Kick",
        schema: 1157,
        pol: "Drwina: Kozacki kop",
        fre: "Raillerie : La danse Kazotsky",
        sch: "嘲讽：俄罗斯踢踏舞",
        bra: "Provocação: Dança dos Cossacos",
        hun: "Beszólás: Kozáktánc",
        swe: "Hån: Kazotskyspark"
    },
    1640: {
        img: "taunt_mannrobics",
        eng: "Taunt: Mannrobics",
        schema: 1162,
        pol: "Drwina: Mannorobik",
        fre: "Raillerie : Mannrobic",
        sch: "嘲讽：曼恩团体操",
        bra: "Provocação: Manneróbica",
        hun: "Beszólás: Mannerobik",
        swe: "Hån: Mannrobics"
    },
    1641: {
        img: "taunt_second_rate_sorcery",
        eng: "Taunt: Second Rate Sorcery",
        schema: 30816,
        pol: "Drwina: Czary z drugiej ręki",
        fre: "Raillerie : Sorcellerie de seconde classe",
        sch: "嘲讽：二流魔法师",
        bra: "Provocação: Feitiçaria de Segunda Classe",
        hun: "Beszólás: Másodrendű bűbáj",
        swe: "Hån: Andrasorteringsmagi"
    },
    1642: {
        img: "taunt_shred_alert",
        eng: "Taunt: The Shred Alert",
        schema: 1015,
        pol: "Szyderstwo szarpidruta",
        fre: "Raillerie : La Shred Alert",
        sch: "吉他警报",
        bra: "O Metalerta Vermelho",
        hun: "A Húros Riadó",
        swe: "Shred Alert"
    },
    1643: {
        img: "taunt_victory_lap",
        eng: "Taunt: The Victory Lap",
        schema: 1172,
        pol: "Drwina: Zwycięskie okrążenie",
        fre: "Raillerie : Tour d'honneur",
        sch: "嘲讽：我是老司机",
        bra: "Provocação: A Volta da Vitória",
        hun: "Beszólás: A győzelmi kör",
        swe: "Hån: Segervarvet"
    },
    1644: {
        img: "taunt_yeti_punch",
        eng: "Taunt: Yeti Punch",
        schema: 1182,
        pol: "Drwina: Uderzenie yeti",
        fre: "Raillerie : En plein dans le migou",
        sch: "嘲讽：拳打雪人",
        bra: "Provocação: Soco no Yeti",
        hun: "Beszólás: Jeti-pofon",
        swe: "Hån: Yeti-slag"
    },
    1645: {
        img: "taunt_yeti_smash",
        eng: "Taunt: Yeti Smash",
        schema: 1183,
        pol: "Drwina: Zniszczenie yeti",
        fre: "Raillerie : L'abominable mercenaire des neiges",
        sch: "嘲讽：变身雪人",
        bra: "Provocação: Yeti Esmaga",
        hun: "Beszólás: Jeti-zúzás",
        swe: "Hån: Yeti-smash"
    },
    1646: {
        img: "taunt_zoomin_broom",
        eng: "Taunt: Zoomin' Broom",
        schema: 30672,
        pol: "Drwina: Lot na miotle",
        fre: "Raillerie : Le balai filant",
        sch: "嘲讽：扫帚飞行术",
        bra: "Provocação: Vassoura Veloz",
        hun: "Beszólás: Seprűn söprés",
        swe: "Hån: Hast på Kvast"
    },
    1647: {
        img: "pyro_shark",
        eng: "Pyro Shark",
        pol: "Żarzący żarłacz",
        fre: "Pyrequin",
        sch: "怒火狂鲨",
        bra: "Tuba-Pyro",
        schema: 31068,
        hun: "Cápiró",
        swe: "Pyrohaj"
    },
    1648: {
        img: "avian_amante",
        eng: "Avian Amante",
        pol: "Ptasi pasjonat",
        fre: "Corbeau mégot",
        sch: "鸦人面具",
        bra: "Amante Aviário",
        schema: 31073,
        hun: "Szárnyasszerető",
        swe: "Kacklande kråka"
    },
    1649: {
        img: "eingineer",
        eng: "Eingineer",
        pol: "Einżynier",
        fre: "Eingineer",
        sch: "金丝雀矿工",
        bra: "Eingineer",
        schema: 31075,
        hun: "Mérnökkanári",
        swe: "Eingenjör"
    },
    1650: {
        img: "remorseless_raptor",
        eng: "Remorseless Raptor",
        pol: "Groźna gadzina",
        fre: "Raptor sans remords",
        sch: "无情迅猛龙",
        bra: "Predador Perverso",
        schema: 31082,
        hun: "Rettentő Raptor",
        swe: "Velociraptor"
    },
    1651: {
        img: "wild_whip",
        eng: "Wild Whip",
        pol: "Dziki dromeozaur",
        fre: "Reliquat d'évolution",
        sch: "狂野尾鞭",
        bra: "Cauda Cretácea",
        schema: 31083,
        hun: "Söprögető",
        swe: "Snärtande svans"
    },
    1652: {
        img: "horseless_headless_horsemanns_headtaker",
        eng: "Horseless Headless Horsemann's Headtaker",
        pol: "Ścinacz głów Bezkonnego Bezgłowego Jeźdźca",
        fre: "Le Décapiteur du Cavalier sans Tête et sans Cheval",
        sch: "脱缰无头骑士的斩首斧",
        bra: "O Corta-cabeças do Cavaleiro Carente de Cavalo e Cabeça",
        schema: 266,
        hun: "A Lovatlan Fejetlen Lovas Fejvevője",
        swe: "Hästlöse Huvudlöse Horsemanns Halshuggare"
    },
    1653: {
        img: "necro_smasher",
        eng: "Necro Smasher",
        pol: "Nekrozgniatacz",
        fre: "Necro Smasher",
        sch: "大力碎尸锤",
        bra: "Necroesmagador",
        schema: 1123,
        hun: "Hullaalázó",
        swe: "Kusliga klubban"
    },
    1654: {
        img: "ghastly_gibus",
        eng: "Ghastly Gibus",
        pol: "Upiorny cylinder",
        fre: "Horrible Gibus",
        sch: "可怕的折叠礼帽",
        bra: "Cartola Medonha",
        schema: 116,
        hun: "Cudar Cilinder",
        swe: "Spöklik Stormhatt"
    },
    1655: {
        img: "mildly_disturbing_halloween_mask",
        eng: "Mildly Disturbing Halloween Mask",
        pol: "Lekko niepokojąca maska halloweenowa",
        fre: "Masque d'Halloween un peu dérangeant",
        sch: "令人不安的万圣节面具",
        bra: "Máscara Levemente Perturbadora de Dia das Bruxas",
        schema: 115,
        hun: "Enyhén Ijesztő Halloween-álarc",
        swe: "Aningen Läskig Halloween-Mask"
    },
    1656: {
        img: "ghastlier_gibus",
        eng: "Ghastlier Gibus",
        pol: "Upiorniejszy cylinder",
        fre: "Infâme Gibus",
        sch: "更可怕的摺叠礼帽",
        bra: "Cartola Mais Medonha",
        schema: 279,
        hun: "Cudarabb Cilinder",
        swe: "Spöklikare Stormhatt"
    },
    1657: {
        img: "horseless_headless_horsemanns_head",
        eng: "Horseless Headless Horsemann's Head",
        pol: "Głowa Bezkonnego Bezgłowego Jeźdźca",
        fre: "Tête du Cavalier sans Tête et sans Cheval",
        sch: "脱缰的无头骑士南瓜头",
        bra: "Cabeça do Cavaleiro Carente de Cavalo e Cabeça",
        schema: 278,
        hun: "A Lovatlan Fejetlen Lovas Feje",
        swe: "Hästlöse Huvudlöse Horsemanns Huvud"
    },
    1658: {
        img: "spine_chilling_skull",
        eng: "Spine-Chilling Skull",
        pol: "Mrożąca krew w żyłach czacha",
        fre: "Crâne qui vous glace le sang",
        sch: "毛骨悚然的骷髅头",
        bra: "Crânio Arrepiante",
        schema: 287,
        hun: "Kísérteties Koponya",
        swe: "Ryslig Dödskalle"
    },
    1659: {
        img: "voodoo_juju",
        eng: "Voodoo Juju",
        pol: "Juju voodoo",
        fre: "Juju Vaudou",
        sch: "巫毒护符",
        bra: "Vudu Juju",
        schema: 289,
        hun: "Vudu Juju"
    },
    1660: {
        img: "ghastlierest_gibus",
        eng: "Ghastlierest Gibus",
        pol: "Najupiorniejszy cylinder",
        fre: "Épouvantable Gibus",
        sch: "最可怕的折叠礼帽",
        bra: "Cartola Muito Mais Medonha",
        schema: 116,
        hun: "Legcudarabb Cilinder",
        swe: "Spöklikareast Stormhatt"
    },
    1661: {
        img: "monoculus",
        eng: "MONOCULUS!",
        pol: "MONOCULUS!",
        fre: "Le MONOCULUS !",
        sch: "独眼怪胎！",
        bra: "O MONOCULUS!",
        schema: 581,
        hun: "A MONOCULUS!",
        ger: "Der MONOKULUS!"
    },
    1662: {
        img: "spine_chilling_skull_2011",
        eng: "Spine-Chilling Skull 2011",
        pol: "Mrożąca krew w żyłach czacha 2011",
        fre: "Le Crâne qui vous glace le sang 2011",
        sch: "毛骨悚然的骷髅头 2011 年版",
        bra: "O Crânio Arrepiante de 2011",
        schema: 576,
        hun: "A Kísérteties Koponya 2011",
        swe: "Ryslig Dödskalle 2011"
    },
    1663: {
        img: "ghostly_gibus",
        eng: "Ghostly Gibus",
        pol: "Widmowy cylinder",
        fre: "Fantomatique Gibus",
        sch: "幽灵折叠礼帽",
        bra: "Cartola Fantasmedonha",
        schema: 940,
        hun: "Cidris Cilinder",
        swe: "Spöklik Stormhatt"
    },
    1664: {
        img: "skull_island_topper",
        eng: "Skull Island Topper",
        pol: "Czaszkowy cylinder czarnoksiężnika",
        fre: "Le couvre-chef de l'Île au crâne",
        sch: "骷髅岛主",
        bra: "O Crânio da Ilha da Caveira",
        schema: 941,
        hun: "A Koponyaszigeti Kalap",
        swe: "Dödskalleöns Huvudprydnad"
    },
    1665: {
        img: "pallet_of_crates",
        eng: "Pallet of Crates",
        pol: "Paleta skrzynek",
        fre: "Palette de caisses",
        sch: "成堆的补给箱",
        bra: "Palete de Caixas",
        schema: 1037,
        hun: "Egy raklap Láda",
        swe: "Lastpall med Lådor"
    },
    1666: {
        img: "elf_care_provider",
        eng: "Elf Care Provider",
        schema: 31176,
        pol: "Elfia służba zdrowia",
        bra: "Cuidado Pré-Natal",
        hun: "Manómentő-szolgálat",
        fre: "Lutin-soignant",
        swe: "Hälsonisse"
    },
    1667: {
        img: "bear_walker",
        eng: "Bear Walker",
        schema: 31180,
        pol: "Syberyjskie spanko",
        bra: "Passo do Ursinho",
        hun: "Medvetappancs",
        fre: "Pantoufles velues",
        swe: "Toffelhjälte"
    },
    1668: {
        img: "festive_flip_thwomps",
        eng: "Festive Flip-thwomps",
        schema: 31167,
        pol: "Prezentowe płaskostopie",
        bra: "Embrulho de Pé-sente",
        hun: "Díszdobozos Kivitel",
        fre: "Savates emballées",
        ger: "Geschenkpantoffeln"
    },
    1669: {
        img: "shoestring_santa",
        eng: "Shoestring Santa",
        schema: 31169,
        pol: "Mikołaj z przeceny",
        bra: "Noel de Araque",
        hun: "Megszorult Mikulás",
        fre: "Père Noël fauché",
        swe: "Lågpristomte"
    },
    1670: {
        img: "jolly_jingler",
        eng: "Jolly Jingler",
        schema: 31165,
        pol: "Rozdzwoniony wesołek",
        bra: "Guizo Natalino",
        hun: "Csavaros Csingilingi",
        fre: "Clochette pimpante",
        swe: "Fjäder i hatten"
    },
    1671: {
        img: "candy_cantlers",
        eng: "Candy Cantlers",
        schema: 31164,
        pol: "Cukierkowe poroże",
        bra: "Galhada Adocicada",
        hun: "Cukorkagancs",
        fre: "Ramures sucrées",
        ger: "Kandisgeweih"
    },
    1672: {
        img: "festive_fascinator",
        eng: "Festive Fascinator",
        schema: 31172,
        pol: "Fikuśny fascynator",
        bra: "Fascinador Festivo",
        hun: "Vidám Villongás",
        fre: "Serre-tête festif",
        ger: "Festliches Stirnband"
    },
    1673: {
        img: "reindoonibeanie",
        eng: "Reindoonibeanie",
        schema: 31171,
        pol: "Reniferożcowe beanie",
        bra: "Renagorro",
        hun: "Rénkornisapka",
        fre: "Bonnet rennicorne"
    },
    1674: {
        img: "particulate_protector",
        eng: "Particulate Protector",
        schema: 31163,
        pol: "Przeciwcząsteczkowa protekcja",
        bra: "Protetor Particular",
        hun: "Részecskeszűrő",
        fre: "Protecteur de particules",
        ger: "Partikelmaske"
    },
    1675: {
        img: "the_killing_tree",
        eng: "The Killing Tree",
        schema: 31181,
        pol: "Śmiercionośny starodrzew",
        bra: "Pinheiro Certeiro",
        hun: "Karácsonyfa Alávaló",
        fre: "Sapin mortel",
        ger: "Serienförster"
    },
    1676: {
        img: "the_smissmass_sorcerer",
        eng: "Smissmass Sorcerer",
        schema: 31166,
        pol: "Śniąteczny czarodziej",
        bra: "Feiticeiro Festivo"
    },
    1677: {
        img: "sandmanns_brush",
        eng: "SandMann's Brush",
        schema: 31178,
        pol: "Muśnięcie Piaskuna",
        bra: "Gorrinho do Soninho",
        hun: "Álommannus Sipka",
        fre: "Marchand de sommeil",
        ger: "SandMannmütze"
    },
    1678: {
        img: "bedbug_protection",
        eng: "BedBug Protection",
        schema: 31179,
        pol: "Piżamka proletariusza",
        bra: "Proteção Anti-Papão",
        hun: "Álombiztonsági Egyenruha",
        fre: "Pyjama protecteur",
        ger: "Anti-Bettwanzen-Pyjama"
    },
    1679: {
        img: "professionals_pom_pom",
        eng: "Professional's Pom Pom",
        schema: 31170,
        pol: "Pompon profesjonalisty",
        bra: "Pompom do Profissional",
        hun: "Profi Pompon",
        fre: "Pro du pompon",
        ger: "Profi-Bommelmütze"
    },
    1680: {
        img: "blitzen_bowl",
        eng: "Blitzen Bowl",
        schema: 31175,
        pol: "Błyskawiczny kask",
        bra: "Engenharia Renal",
        hun: "Rénbányász-sisak",
        fre: "L'écuelle de Comète",
        ger: "Blitzenhelm"
    },
    1681: {
        img: "night_ward",
        eng: "Night Ward",
        schema: 31177,
        pol: "Nocny dyżur",
        bra: "Plantão Noturno",
        hun: "Éjjeli Ügyelet",
        fre: "Médecin de garde",
        swe: "Nattjour"
    },
    1682: {
        img: "helm_helm",
        eng: "Helm Helm",
        schema: 31182,
        pol: "Ster u steru",
        bra: "O Estrondoso Timão",
        hun: "Hajóssapka",
        fre: "Commandant de bord",
        swe: "Skepparhatt"
    },
    1683: {
        img: "towering_pillar_of_beanies",
        eng: "Towering Pillar of Beanies",
        schema: 31168,
        pol: "Niebotyczny słup beanie",
        bra: "Pilar Monumental de Gorros",
        hun: "Magasan Tornyosuló Téli Sapkák",
        fre: "Imposante pile de bonnets",
        ger: "Hochragende Säule von Beanies"
    },
    1684: {
        img: "the_round_a_bout",
        eng: "The Round-A-Bout",
        schema: 31174,
        pol: "Karnawałowa karuzela",
        bra: "Pyrossel",
        hun: "Körhinta",
        fre: "Manège portable",
        swe: "Karusellkrona"
    },
    1685: {
        img: "towering_pile_of_presents",
        eng: "Towering Pile Of Presents",
        pol: "Niebotyczny słup prezentów",
        bra: "Pilha Monumental de Presentes",
        hun: "Magasan Tornyosuló Ajándékhalom",
        fre: "Imposante pile de cadeaux",
        ger: "Hochragender Geschenkehaufen",
        schema: 31173
    },
    1686: {
        img: "snowflake_swirled_war_paint",
        eng: "Snowflake Swirled War Paint",
        schema: 17277,
        pol: "Wir z płatkami śniegu | Barwy wojenne",
        bra: "Espiral Flocada Tinta de Guerra",
        hun: "Kavargós-hópihés Harci festés",
        rom: "Snowflake Swirled Vopsea de război",
        fre: "Tourbillon de flocons | Peinture de guerre",
        swe: "Virvlande snöflingor Krigsfärg"
    },
    1687: {
        img: "snow_globalization_war_paint",
        eng: "Snow Globalization War Paint",
        schema: 17275,
        pol: "Śnieżna globalizacja | Barwy wojenne",
        bra: "Globalização de Neve Tinta de Guerra",
        hun: "Hócsatározás Harci festés",
        rom: "Snow Globalization Vopsea de război",
        fre: "Hiver universel | Peinture de guerre",
        swe: "Snögubbar och tögubbar Krigsfärg"
    },
    1688: {
        img: "glacial_glazed_war_paint",
        eng: "Glacial Glazed War Paint",
        schema: 17282,
        pol: "Zimowe zeszklenie | Barwy wojenne",
        bra: "Azulejos Glaciais Tinta de Guerra",
        hun: "Gleccserbevonatú Harci festés",
        rom: "Glacial Glazed Vopsea de război",
        fre: "Banquise polaire | Peinture de guerre",
        swe: "Kristallklar Krigsfärg"
    },
    1689: {
        img: "gifting_manns_wrapping_paper_war_paint",
        eng: "Gifting Mann's Wrapping Paper War Paint",
        schema: 17276,
        pol: "Prezentowy papier do pakowania | Barwy wojenne",
        bra: "Papel de Presente do Mann Presente Tinta de Guerra",
        hun: "Manntasztikus csomagolópapír Harci festés",
        rom: "Gifting Mann's Wrapping Paper Vopsea de război",
        fre: "Papier cadeau Mannifique | Peinture de guerre",
        swe: "Manntastiskt presentpapper Krigsfärg"
    },
    1690: {
        img: "peppermint_swirl_war_paint",
        eng: "Peppermint Swirl War Paint",
        schema: 17273,
        pol: "Cukierkowy wir | Barwy wojenne",
        bra: "Espiral de Hortelã-pimenta Tinta de Guerra",
        hun: "Borsmenta forgatag Harci festés",
        rom: "Peppermint Swirl Vopsea de război",
        fre: "Berlingot | Peinture de guerre",
        swe: "Polkagrisrandig Krigsfärg"
    },
    1691: {
        img: "gingerbread_winner_war_paint",
        eng: "Gingerbread Winner War Paint",
        schema: 17270,
        pol: "Piernikowy zwycięzca | Barwy wojenne",
        bra: "Ganha-Pão de Mel Tinta de Guerra",
        hun: "Nyerő mézeskalács Harci festés",
        rom: "Gingerbread Winner Vopsea de război",
        fre: "Gagne-pain d'épice | Peinture de guerre",
        swe: "Pepparkakskrusidull Krigsfärg"
    },
    1692: {
        img: "smissmas_spycrabs_war_paint",
        eng: "Smissmas Spycrabs War Paint",
        schema: 17278,
        pol: "Śniąteczne szpiegokraby | Barwy wojenne",
        bra: "Spyranguejos de Natal Tinta de Guerra",
        hun: "Galácsonyi kémrákok Harci festés",
        rom: "Smissmas Spycrabs Vopsea de război",
        fre: "Spycrabs de Smissmas | Peinture de guerre",
        swe: "Spionkrabban firar jul Krigsfärg"
    },
    1693: {
        img: "elfin_enamel_war_paint",
        eng: "Elfin Enamel War Paint",
        schema: 17272,
        pol: "Elfia emalia | Barwy wojenne",
        bra: "Esmalte Élfico Tinta de Guerra",
        hun: "Manós máz Harci festés",
        rom: "Elfin Enamel Vopsea de război",
        fre: "Elfe émaillé | Peinture de guerre",
        swe: "Alvisk emalj Krigsfärg"
    },
    1694: {
        img: "frozen_aurora_war_paint",
        eng: "Frozen Aurora War Paint",
        schema: 17279,
        pol: "Mroźna zorza | Barwy wojenne",
        bra: "Aurora Congelada Tinta de Guerra",
        hun: "Fagyott sarki fény Harci festés",
        rom: "Frozen Aurora Vopsea de război",
        fre: "Aurore gelée | Peinture de guerre",
        swe: "Iskall Aurora Krigsfärg"
    },
    1695: {
        img: "cookie_fortress_war_paint",
        eng: "Cookie Fortress War Paint",
        schema: 17283,
        pol: "Ciasteczkowa forteca | Barwy wojenne",
        bra: "Fortaleza de Biscolachas Tinta de Guerra",
        hun: "Sütierőd Harci festés",
        rom: "Cookie Fortress Vopsea de război",
        fre: "Pain d'épice | Peinture de guerre",
        swe: "Kaksoldater Krigsfärg"
    },
    1696: {
        img: "frosty_delivery_war_paint",
        eng: "Frosty Delivery War Paint",
        schema: 17281,
        pol: "Mroźna dostawa | Barwy wojenne",
        bra: "Entrega Gélida Tinta de Guerra",
        hun: "Deres szállítmány Harci festés",
        rom: "Frosty Delivery Vopsea de război",
        fre: "Livraison givrée | Peinture de guerre",
        swe: "Frostpost Krigsfärg"
    },
    1697: {
        img: "saccharine_striped_war_paint",
        eng: "Saccharine Striped War Paint",
        schema: 17271,
        pol: "Przesłodkie pasy | Barwy wojenne",
        bra: "Listras Adocicadas Tinta de Guerra",
        hun: "Cukorkacsíkos Harci festés",
        rom: "Saccharine Striped Vopsea de război",
        fre: "Stries saccharines | Peinture de guerre",
        swe: "Raffigt räfflad Krigsfärg"
    },
    1698: {
        img: "starlight_serenity_war_paint",
        eng: "Starlight Serenity War Paint",
        schema: 17280,
        pol: "Gwiezdne ukojenie | Barwy wojenne",
        bra: "Serenidade Estrelada Tinta de Guerra",
        hun: "Csillagfényes csendesség Harci festés",
        rom: "Starlight Serenity Vopsea de război",
        fre: "Astres radieux | Peinture de guerre",
        swe: "Rogivande stjärnljus Krigsfärg"
    },
    1699: {
        img: "taunt_the_pooped_deck",
        eng: "Taunt: The Pooped Deck",
        schema: 31153,
        pol: "Drwina: Pijacki pokład",
        bra: "Provocação: Escocês Exausto",
        hun: "Beszólás: Napozófedélzet",
        fre: "Raillerie : En poupe",
        ger: "Verspottung: Locker im Liegestuhl"
    },
    1700: {
        img: "taunt_time_out_therapy",
        eng: "Taunt: Time Out Therapy",
        schema: 31154,
        pol: "Drwina: Teufordzki terapeuta",
        bra: "Provocação: Pausa Fora de Hora",
        hun: "Beszólás: Pihentető terápia",
        fre: "Raillerie : Une pause bien méritée",
        ger: "Verspottung: Auszeittherapie"
    },
    1701: {
        img: "taunt_rocket_jockey",
        eng: "Taunt: Rocket Jockey",
        schema: 31155,
        pol: "Drwina: Rakietowy dżokej",
        bra: "Provocação: Fogueteiro Fantástico",
        hun: "Beszólás: Rakétazsoké",
        fre: "Raillerie : Rodéo sur fusée",
        swe: "Hån: Raketjockey"
    },
    1702: {
        img: "taunt_the_boston_boarder",
        eng: "Taunt: The Boston Boarder",
        schema: 31156,
        pol: "Drwina: Bostoński skejter",
        bra: "Provocação: Charlie Bonk Jr.",
        hun: "Beszólás: Bostoni aszfaltbetyár",
        fre: "Raillerie : Le skateur de Boston",
        ger: "Verspottung: Der Boston-Boarder"
    },
    1703: {
        img: "taunt_scorchers_solo",
        eng: "Taunt: Scorcher's Solo",
        schema: 31157,
        pol: "Drwina: Solówka spopielacza",
        bra: "Provocação: Fique Pianinho",
        hun: "Beszólás: Tüzes szóló",
        fre: "Raillerie : Solo d'enfer",
        ger: "Verspottung: Schwelendes Solo"
    },
    1704: {
        img: "taunt_texas_truckin",
        eng: "Taunt: Texas Truckin'",
        schema: 31160,
        pol: "Drwina: Teksański traktorzysta",
        bra: "Provocação: Tratorzinho do Texas",
        hun: "Beszólás: Texasi traktoros",
        fre: "Raillerie : Transport texan",
        ger: "Verspottung: Texas-Traktor"
    },
    1705: {
        img: "taunt_spin_to_win",
        eng: "Taunt: Spin-to-Win",
        schema: 31161,
        pol: "Drwina: Znak zwycięstwa",
        bra: "Provocação: Dando a Seta",
        hun: "Beszólás: Szerencsekerék",
        fre: "Raillerie : Signalisation",
        ger: "Verspottung: Schild schmeißen"
    },
    1706: {
        img: "taunt_the_fist_bump",
        eng: "Taunt: The Fist Bump",
        schema: 31162,
        pol: "Drwina: Żółwik",
        bra: "Provocação: Soquinho",
        hun: "Beszólás: Ökölpacsi",
        fre: "Raillerie : Check",
        ger: "Verspottung: Fauststoß"
    },
    1707: {
        img: "manndatory_attire",
        eng: "Manndatory Attire",
        schema: 31184,
        pol: "Pod krawatem",
        hun: "Kötelező Viselet"
    },
    1708: {
        img: "roaming_roman",
        eng: "Roaming Roman",
        schema: 31198,
        pol: "Żwawy Rzymianin",
        bra: "Centurião Errante",
        hun: "Romboló Római"
    },
    1709: {
        img: "brim_of_fire",
        eng: "Brim of Fire",
        schema: 31185,
        pol: "Ogniste rondo",
        bra: "Aba Labareda",
        hun: "Tüzes Karima"
    },
    1710: {
        img: "wild_brim_slouch",
        eng: "Wild Brim Slouch",
        schema: 31192,
        pol: "Zębata zdobycz",
        bra: "Couro do Pântano",
        hun: "Vadonkalap"
    },
    1711: {
        img: "fried_batter",
        eng: "Fried Batter",
        schema: 31197,
        pol: "Smażony sprinter",
        bra: "Corredor do Caixa",
        hun: "Ütős Sütő"
    },
    1712: {
        img: "reel_fly_hat",
        eng: "Reel Fly Hat",
        schema: 31186,
        pol: "Kapelusz fanatyka",
        bra: "Isca Cocuruta",
        hun: "Pecáskalap"
    },
    1713: {
        img: "two_punch_mann",
        eng: "Two Punch Mann",
        schema: 31191,
        pol: "Dwubij",
        bra: "Porta-Luvas de Boxe",
        hun: "Duplán Ütős"
    },
    1714: {
        img: "hook_line_and_cinder",
        eng: "Hook, Line, and Cinder",
        schema: 31187,
        pol: "Haczyk, żyłka i popiół",
        bra: "Vai Pescá",
        hun: "Kifogta, Hazavitte, Megsütötte"
    },
    1715: {
        img: "thousand_yard_stare",
        eng: "Thousand-Yard Stare",
        schema: 31200,
        pol: "Wojenny wytrzeszcz",
        bra: "Olho no Olho",
        hun: "Tágra Nyílt Szemek"
    },
    1716: {
        img: "snack_stack",
        eng: "Snack Stack",
        schema: 31190,
        pol: "Sterta smakołyków",
        bra: "Pilha de Sandviches",
        hun: "Harapnivaló Halom"
    },
    1717: {
        img: "crocodile_dandy",
        eng: "Crocodile Dandy",
        schema: 31193,
        pol: "Krokodyl dandys",
        bra: "Crocodilo Dândi",
        hun: "Krokodilos Piperkőc"
    },
    1718: {
        img: "meal_dealer",
        eng: "Meal Dealer",
        schema: 31196,
        pol: "Sprzedawca smakowitości",
        bra: "Atendente de Lanchonete",
        hun: "Ételfutár"
    },
    1719: {
        img: "water_waders",
        eng: "Water Waders",
        schema: 31188,
        pol: "Wodne wdzianko",
        bra: "Pesca à Prova d'Água",
        hun: "Gázlónadrág"
    },
    1720: {
        img: "the_sightliner",
        eng: "The Sightliner",
        schema: 31189,
        pol: "Działkowizor",
        hun: "Célbamérő",
        bra: "Os Sentinolhos"
    },
    1721: {
        img: "the_panisher",
        eng: "The Panisher",
        schema: 31199,
        pol: "Patelniowy pogromca",
        bra: "O Frigideiro",
        hun: "A Serpenyítő"
    },
    1722: {
        img: "fast_food",
        eng: "Fast Food",
        schema: 31195,
        pol: "Szybka szama",
        bra: "Entrega Rápida",
        hun: "Gyorskaja"
    },
    1723: {
        img: "crustaceous_cowl",
        eng: "Crustaceous Cowl",
        schema: 31194,
        pol: "Krabowy kaptur",
        bra: "Capuz Crustáceo",
        hun: "Rákámzsa"
    },
    1724: {
        img: "ballooniphones",
        eng: "Ballooniphones",
        schema: 31183,
        pol: "Balonofonia",
        bra: "Balãonifones",
        hun: "Lufifüles"
    },
    1725: {
        img: "beanie_the_all_gnawing",
        eng: "Beanie The All-Gnawing",
        schema: 31212,
        pol: "Wszechjedzące beanie",
        hun: "Mindentrágó Sapi",
        swe: "Beanie, den evigt gnagande"
    },
    1726: {
        img: "death_stare",
        eng: "Death Stare",
        schema: 31225,
        pol: "Śmiertelne spojrzenie",
        bra: "Olho Gordo",
        hun: "Halálos Pillantás",
        swe: "Dödens blick"
    },
    1727: {
        img: "hat_outta_hell",
        eng: "Hat Outta Hell",
        schema: 31216,
        pol: "Czorcia czapka",
        hun: "Pokolsityak",
        swe: "Djävulskepa"
    },
    1728: {
        img: "the_scariest_mask_ever",
        eng: "The Scariest Mask EVER",
        schema: 31219,
        pol: "Najstraszniejsza maska JAKA ISTNIEJE",
        hun: "A LEGESlegfélelmetesebb Álarc",
        swe: "Läskigaste masken NÅGONSIN"
    },
    1729: {
        img: "twisted_topper",
        eng: "Twisted Topper",
        schema: 31211,
        pol: "Pokręcony cylinder",
        hun: "Csíkos Cilinder",
        swe: "Tjusig tigerhatt"
    },
    1730: {
        img: "creepy_crawlers",
        eng: "Creepy Crawlers",
        schema: 31208,
        pol: "Przerażające pełzaki",
        hun: "Rémséges Pókok",
        swe: "Krälande kryp"
    },
    1731: {
        img: "spooky_head_bouncers",
        eng: "Spooky Head-Bouncers",
        schema: 31209,
        pol: "Przerażające podskakiwacze",
        hun: "Ijesztő Fejenrezgők",
        swe: "Läskiga antenner"
    },
    1732: {
        img: "tricksters_treats",
        eng: "Trickster's Treats",
        schema: 31220,
        pol: "Słodkości szachraja",
        bra: "Gostosuras do Travesso",
        hun: "A Rosszcsont Édessége",
        swe: "Busens godis"
    },
    1733: {
        img: "the_corpse_carrier",
        eng: "The Corpse Carrier",
        schema: 31226,
        pol: "Tragarz zwłok",
        bra: "Carregador de Corpos",
        hun: "Hullahordozó",
        swe: "Likbud"
    },
    1734: {
        img: "smiling_somen",
        eng: "Smiling Somen",
        schema: 31222,
        pol: "Śmieszkujący sōmen",
        hun: "Vigyorgó Somen",
        swe: "Grymolle"
    },
    1735: {
        img: "second_head_headwear",
        eng: "Second-head Headwear",
        schema: 31214,
        pol: "Co dwie głowy, to nie jedna",
        bra: "Chapéu em Segunda Cabeça",
        hun: "Fejfedő Másodfejtől",
        swe: "Andrahuvuds huvudbonad"
    },
    1736: {
        img: "eyequarium",
        eng: "Eyequarium",
        schema: 31213,
        pol: "Okwarium",
        hun: "Szemnedvesítő",
        swe: "Ögonfuktare"
    },
    1737: {
        img: "goalkeeper",
        eng: "Goalkeeper",
        schema: 31217,
        pol: "Bramkarz",
        hun: "Kapus",
        swe: "Målvakt"
    },
    1738: {
        img: "alakablamicon",
        eng: "Alakablamicon",
        schema: 31215,
        pol: "Abrakabumicon",
        hun: "Bummbanomicon"
    },
    1739: {
        img: "the_hook_line_and_thinker",
        eng: "The Hook, Line, and Thinker",
        schema: 31223,
        pol: "Pomysłowy połów",
        hun: "Megakasztott Agyalás",
        swe: "Fiskarens olycka"
    },
    1740: {
        img: "optic_nerve",
        eng: "Optic Nerve",
        schema: 31224,
        pol: "Nerw wzrokowy",
        hun: "Látóideg",
        swe: "Synnerv"
    },
    1741: {
        img: "gruesome_gourd",
        eng: "Gruesome Gourd",
        schema: 31227,
        pol: "Gustowna gurda",
        hun: "Irtózatos Tökfej",
        swe: "Pampig pumpamask"
    },
    1742: {
        img: "the_bone_cone",
        eng: "The Bone Cone",
        schema: 31218,
        pol: "Stożek kości",
        hun: "Csontkúp",
        swe: "Benstrut"
    },
    1743: {
        img: "wandering_wraith",
        eng: "Wandering Wraith",
        schema: 31221,
        pol: "Wędrujące widmo",
        hun: "Lángoló Lidérc",
        swe: "Vandrande vålnad"
    },
    1744: {
        img: "towering_patch_of_pumpkins",
        eng: "Towering Patch of Pumpkins",
        schema: 31210,
        pol: "Niebotyczny słup dyń",
        hun: "Magasan Tornyosuló Tökök",
        swe: "Reslig pumpapelare"
    },
    1745: {
        img: "poopy_doe",
        eng: "Poopy Doe",
        schema: 31228
    },
    1746: {
        img: "batters_beak",
        eng: "Batter's Beak",
        schema: 31229,
        pol: "Pierzasty pałkarz",
        hun: "Csőrös Ütős",
        swe: "Flink fink"
    },
    1747: {
        img: "war_dog",
        eng: "War Dog",
        schema: 31230,
        pol: "Pies wojny",
        hun: "Harci Kutya",
        swe: "Stridshund"
    },
    1748: {
        img: "miami_rooster",
        eng: "The Miami Rooster",
        schema: 31231
    },
    1749: {
        img: "computron_5000",
        eng: "Computron 5000",
        schema: 31232,
        pol: "Komputron 5000"
    },
    1750: {
        img: "sarsparilla_sprayed_war_paint",
        eng: "Sarsparilla Sprayed War Paint",
        schema: 17284,
        pol: "Śmiechu warte | Barwy wojenne",
        hun: "Sarsaparilla-mintás Harci festés",
        swe: "Sarsaparillasprejad Krigsfärg"
    },
    1751: {
        img: "kiln_conquer_war_paint",
        eng: "Kiln & Conquer War Paint",
        schema: 17296,
        pol: "Palić i podbijać | Barwy wojenne",
        hun: "Ölj és hódíts Harci festés"
    },
    1752: {
        img: "searing_souls_war_paint",
        eng: "Searing Souls War Paint",
        schema: 17293,
        pol: "Płonące dusze | Barwy wojenne",
        hun: "Lángoló Lelkek Harci festés"
    },
    1753: {
        img: "potent_poison_war_paint",
        eng: "Potent Poison War Paint",
        schema: 17292,
        pol: "Silna trucizna | Barwy wojenne",
        hun: "Hatásos méreg Harci festés"
    },
    1754: {
        img: "simple_spirits_war_paint",
        eng: "Simple Spirits War Paint",
        schema: 17290,
        pol: "Drobne duszki | Barwy wojenne",
        hun: "Egyszerűen szellemes Harci festés"
    },
    1755: {
        img: "skull_cracked_war_paint",
        eng: "Skull Cracked War Paint",
        schema: 17286,
        pol: "Spękane czaszki | Barwy wojenne",
        hun: "Koponyarepesztő Harci festés"
    },
    1756: {
        img: "necromanced_war_paint",
        eng: "Necromanced War Paint",
        schema: 17297,
        pol: "Nekromancja | Barwy wojenne",
        hun: "Felélesztve Harci festés"
    },
    1757: {
        img: "polter_guised_war_paint",
        eng: "Polter-Guised War Paint",
        schema: 17295,
        pol: "Cierpiące dusze | Barwy wojenne",
        hun: "Kísérteties külső Harci festés"
    },
    1758: {
        img: "neon_ween_war_paint",
        eng: "Neon-ween War Paint",
        schema: 17289,
        pol: "Neonween | Barwy wojenne",
        hun: "Neon-ween Harci festés"
    },
    1759: {
        img: "swashbuckled_war_paint",
        eng: "Swashbuckled War Paint",
        schema: 17285,
        pol: "Pirat | Barwy wojenne",
        hun: "Kalózos Harci festés"
    },
    1760: {
        img: "broken_bones_war_paint",
        eng: "Broken Bones War Paint",
        schema: 17291,
        pol: "Połamane kości | Barwy wojenne",
        hun: "Törött csontok Harci festés"
    },
    1761: {
        img: "party_phantoms_war_paint",
        eng: "Party Phantoms War Paint",
        schema: 17294,
        pol: "Imprezowe upiory | Barwy wojenne",
        hun: "Bulifantomok Harci festés"
    },
    1762: {
        img: "misfortunate_war_paint",
        eng: "Misfortunate War Paint",
        schema: 17287,
        pol: "Nieszczęście | Barwy wojenne",
        hun: "Szerencsétlen Harci festés"
    },
    1763: {
        img: "taunt_the_drunken_sailor",
        eng: "Taunt: The Drunken Sailor",
        schema: 31201,
        pol: "Drwina: Pijany żeglarz",
        hun: "Beszólás: A részeg tengerész",
        swe: "Hån: Den berusade matrosen"
    },
    1764: {
        img: "taunt_the_profane_puppeteer",
        eng: "Taunt: The Profane Puppeteer",
        schema: 31202,
        pol: "Drwina: Pyskaty pacynkarz",
        hun: "Beszólás: A borzasztó bábos"
    },
    1765: {
        img: "taunt_the_mannbulance",
        eng: "Taunt: The Mannbulance!",
        schema: 31203,
        pol: "Drwina: Mannbulans!",
        hun: "Beszólás: A Manntőautó!"
    },
    1766: {
        img: "taunt_bare_knuckle_beatdown",
        eng: "Taunt: Bare Knuckle Beatdown",
        schema: 31207,
        pol: "Drwina: Srogi łomot",
        hun: "Beszlás: Pusztakezes lezúzás"
    },
    1767: {
        img: "taunt_the_homerunners_hobby",
        eng: "Taunt: The Homerunner's Hobby",
        schema: 31233,
        pol: "Drwina: Hobby chłystka",
        hun: "Beszólás: A hazafutó hobbija"
    },
    1768: {
        img: "festive_cover_up",
        eng: "Festive Cover-Up",
        schema: 31257
    },
    1769: {
        img: "el_fiestibrero",
        eng: "El Fiestibrero",
        schema: 31241
    },
    1770: {
        img: "ominous_offering",
        eng: "Ominous Offering",
        schema: 31251
    },
    1771: {
        img: "brain_cane",
        eng: "Brain Cane",
        schema: 31248
    },
    1772: {
        img: "cozy_catchers",
        eng: "Cozy Catchers",
        schema: 31250
    },
    1773: {
        img: "the_giftcrafter",
        eng: "The Giftcrafter",
        schema: 31246
    },
    1774: {
        img: "festive_frames",
        eng: "Festive Frames",
        schema: 31252
    },
    1775: {
        img: "mooshanka",
        eng: "Mooshanka",
        schema: 31255
    },
    1776: {
        img: "elf_defense",
        eng: "Elf Defense",
        schema: 31256
    },
    1777: {
        img: "gnome_dome",
        eng: "Gnome Dome",
        schema: 31242
    },
    1778: {
        img: "elf_made_bandanna",
        eng: "Elf-Made Bandanna",
        schema: 31260
    },
    1779: {
        img: "seasonal_spring",
        eng: "Seasonal Spring",
        schema: 31244
    },
    1780: {
        img: "elf_ignition",
        eng: "Elf Ignition",
        schema: 31253
    },
    1781: {
        img: "train_of_thought",
        eng: "Train Of Thought",
        schema: 31254
    },
    1782: {
        img: "seasonal_employee",
        eng: "Seasonal Employee",
        schema: 31258
    },
    1783: {
        img: "reindoonihorns",
        eng: "Reindoonihorns",
        schema: 31249
    },
    1784: {
        img: "jolly_jester",
        eng: "Jolly Jester",
        schema: 31243
    },
    1785: {
        img: "merry_cone",
        eng: "Merry Cone",
        schema: 31247
    },
    1786: {
        img: "hat_chocolate",
        eng: "Hat Chocolate",
        schema: 31259
    },
    1787: {
        img: "oh_deer",
        eng: "Oh Deer!",
        schema: 31245
    },
    1788: {
        img: "taunt_doctors_defibrillators",
        eng: "Taunt: Doctor's Defibrillators",
        schema: 31236
    },
    1789: {
        img: "taunt_shooters_stakeout",
        eng: "Taunt: Shooter's Stakeout",
        schema: 31237
    },
    1790: {
        img: "taunt_the_hot_wheeler",
        eng: "Taunt: The Hot Wheeler",
        schema: 31239
    }
}